import { BoxContainer, COLOR } from '@components/atoms';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PageText = styled.div`
  margin-bottom: 20px;
`;

export const PageTitleArea = styled.div`
  margin-bottom: 20px;
  display: flex;
  align-items: center;
`;

export const PageTitleItem = styled.div`
  margin-right: 8px;
  &:last-child {
    cursor: pointer;
    margin-right: 0;
  }
`;

export const PageTitleIcon = styled.img`
  width: 32px;
  height: 32px;
`;

export const ListArea = styled(BoxContainer)`
  width: 1100px;
  padding: 40px;
  &.smallListArea {
    width: 902px;
  }
`;

export const TitleArea = styled.div`
  display: flex;
  margin-bottom: 40px;
`;

export const OptionArea = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  align-items: center;
  justify-content: space-between;
`;

export const OptionLeftArea = styled.div``;

export const OptionRightArea = styled.div`
  position: relative;
`;

export const OptionRightAreaDropdownList = styled.div`
  width: 140px;
  height: 120px;
  background-color: ${COLOR['fill/white']};
  border-radius: 8px;
  filter: drop-shadow(0px 1px 20px rgba(0, 0, 0, 0.25));
  position: absolute;
  top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 999;
`;

export const OptionRightAreaDropdownItem = styled.div`
  width: 100%;
  cursor: pointer;
  color: ${COLOR['fill/dark']};
  &:hover {
    color: ${COLOR['fill/black']};
  }
  text-align: center;
`;

export const ListContainer = styled.div`
  border-radius: 8px;
  border: 1px solid ${COLOR['disabled/foreground']};
`;

export const ListDefaultItem = styled.div`
  display: flex;
  flex-direction: row;
  height: 60px;
  width: 100%;
`;

export const ListHeaderItem = styled(ListDefaultItem)`
  background-color: ${COLOR['fill/light']};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  text-align: center;
  white-space: break-spaces;
`;

export const IconButtonItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-left: 12px;
  margin-right: 12px;
`;

export const LoaderArea = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 20px 0;
`;

export const EmptyContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 41px;
  margin: 4px 0;
  padding: 12px;
  background-color: ${COLOR['fill/light']};
  border-radius: 8px;
`;

export const TooltipMessageArea = styled.div``;

export const TooltipMessage = styled.div``;

export const TooltipImage = styled.img`
  width: 12px;
  height: 12px;
  margin-right: 4px;
`;
