import { daehanParmImage, samsungAd } from '@assets';
import BCarousel from '@components/meraki-ui/BCarousel';
import { trackNewReservationRequestedMixpanelEvents } from '@utils/mixpanel/newReservation/requested';

function LNBCarousel() {
  const onClickDaehanParm = () => {
    window?.open('https://forms.gle/Rzh6NzR7y5nTRFMs8', '_blank');
    trackNewReservationRequestedMixpanelEvents('clickAdBanner')(
      'Daehan Parm',
      'LNB',
    );
  };

  const onClickSamsungAd = () => {
    window?.open(
      'https://direct.samsunglife.com/damoa.eds?cid=jh:jh_mydoctor:damoa:imddbanner',
      '_blank',
    );
    trackNewReservationRequestedMixpanelEvents('clickAdBanner')(
      'Samsung AD',
      'LNB',
    );
  };

  return (
    <BCarousel
      width="100%"
      height={328}
      borderRadius={8}
      imageList={[
        {
          image: daehanParmImage,
          onClick: onClickDaehanParm,
        },
        {
          image: samsungAd,
          onClick: onClickSamsungAd,
        },
      ]}
      interval={6000}
    />
  );
}

export default LNBCarousel;
