import { useAppDispatch, useAppSelector } from '@stores/hooks';
import { selectSetFirstLoginVisible } from '@stores/modalStore/setFirstLoginModal';
import { firstPasswordSetting } from '@stores/userInformationStore';
import { commonHooks } from '@hooks';
import { useCallback } from 'react';

const useSetFirstLoginModalHook = () => {
  const dispatch = useAppDispatch();
  const { useInput } = commonHooks;
  const isVisible = useAppSelector(selectSetFirstLoginVisible);

  const [password, onChangePassword] = useInput('');
  const [passwordConfirm, onChangePasswordConfirm] = useInput('');

  const setFirstPassword = useCallback(() => {
    dispatch(firstPasswordSetting({ password, passwordConfirm }));
  }, [dispatch, password, passwordConfirm]);

  const buttonVisible =
    password.length >= 8 &&
    passwordConfirm.length >= 8 &&
    password === passwordConfirm;

  return {
    isVisible,
    password,
    passwordConfirm,
    onChangePassword,
    onChangePasswordConfirm,
    buttonVisible,
    setFirstPassword,
  };
};

export default useSetFirstLoginModalHook;
