import { Button, COLOR, Font } from '@components/atoms';
import SvgIcon from '@components/atoms/images';
import BModal from '@components/meraki-ui/BModal';
import {
  dietShotSearchList,
  dutasterideSearchList,
  eatingAcneMedicineSearchList,
  finasterideSearchList,
  linimentAcneMedicineSearchList,
  medicineCategoryList,
  medicineGenericCodeKoreanUnit,
  medicineGenericCodeUnit,
  medicineNameConfig,
  searchMedicineList,
} from '@components/organisms/managementMedicineTable/config/domain';
import { commonHooks } from '@hooks';
import { myDoctorAPI } from '@services/myDoctor';
import { ManagedMedicineItemType } from '@services/myDoctor/types';
import { useAppDispatch, useAppSelector } from '@stores/hooks';
import {
  getManagementMedicineList,
  selectMedicineManagementList,
} from '@stores/telepharmacyStore/telepharmacyList';
import { selectUserInformationPharmacistData } from '@stores/userInformationStore';
import { hangulIncludes } from '@toss/hangul';
import { useEffect, useRef, useState } from 'react';
import useMedicineManagementCommonModalHook from '../medicineManagmentCommonModal/hooks';
import useMedicineManagementModalHook from './hooks';
import * as Style from './index.style';

const getFilteredMedicineListInCategory = (
  medicineList: ManagedMedicineItemType[],
  searchValue: string,
) => {
  return medicineList.filter((medicine) => {
    if (
      hangulIncludes(
        `(${convertToInsuranceCode(medicine.standard_code)}) ${
          medicine.korean_product_name
        }`,
        searchValue,
      )
    ) {
      return medicine;
    }

    return null;
  });
};

function formatNumberWithCommas(value: string) {
  if (!value) return '';
  return new Intl.NumberFormat().format(Number(value));
}

function convertToInsuranceCode(code: string) {
  const insuranceCode = code.slice(3, -1);

  return parseInt(insuranceCode, 10);
}

function MedicineManagementModal() {
  const pharmacist = useAppSelector(selectUserInformationPharmacistData);
  const {
    isVisible,
    modalType,
    targetMedicine,
    closeModal: closeRegisterModal,
  } = useMedicineManagementModalHook();
  const managementMedicineList = useAppSelector(selectMedicineManagementList);
  const {
    openModal: openConfirmModal,
    setManagementMedicineModalType,
    setManagementMedicineTargetMedicine,
  } = useMedicineManagementCommonModalHook();

  const [filteredSearchMedicineList, setFilteredSearchMedicineList] =
    useState(searchMedicineList);
  const [selectedMedicineCategory, setSelectedMedicineCategory] = useState(
    medicineCategoryList[0],
  );
  const [isMedicineSearchInputFocus, setIsMedicineSearchInputFocus] =
    useState(false);
  const [selectedMedicine, setSelectedMedicine] =
    useState<ManagedMedicineItemType | null>(null);
  const [medicineSearchInputValue, setMedicineSearchInputValue] = useState('');

  const medicineSearchInputRef = useRef<HTMLInputElement>(null);
  const medicineSumInputRef = useRef<HTMLInputElement>(null);

  const [isShowMedicineUnitPrice, setIsShowMedicineUnitPrice] = useState(false);
  const [isMedicineSumInputAreaFocus, setIsMedicineSumInputAreaFocus] =
    useState(false);
  const [medicineSumInputValue, setMedicineSumInputValue] = useState('');

  const [isDisabledAddMedicineButton, setIsDisabledAddMedicineButton] =
    useState(true);

  const isEditModal = modalType === 'edit';

  useEffect(() => {
    if (targetMedicine && targetMedicine?.id) {
      const findMedicineCategoryList = medicineCategoryList.find((item) =>
        item.codes.includes(targetMedicine.pharmacy_medicine.generic_name_code),
      );

      if (findMedicineCategoryList) {
        setSelectedMedicineCategory(findMedicineCategoryList);
      }

      setSelectedMedicine({
        id: targetMedicine.id,
        standard_code: targetMedicine.pharmacy_medicine.standard_code,
        generic_name_code: targetMedicine.pharmacy_medicine.generic_name_code,
        korean_product_name:
          medicineNameConfig[targetMedicine.pharmacy_medicine.standard_code],
        product_total_quantity: String(targetMedicine.quantity),
        dosage_form:
          medicineGenericCodeKoreanUnit[
            targetMedicine.pharmacy_medicine.generic_name_code
          ],
        packaging_type: targetMedicine.unit,
        special_general_type: '전문의약품',
        generic_name: null,
      });
      setMedicineSearchInputValue(
        `(${convertToInsuranceCode(
          targetMedicine.pharmacy_medicine.standard_code,
        )}) ${
          medicineNameConfig[targetMedicine.pharmacy_medicine.standard_code]
        }`,
      );
      setIsShowMedicineUnitPrice(true);
      setMedicineSumInputValue(String(targetMedicine.price));
    }
  }, [targetMedicine]);

  const handleChangeMedicineSumInput = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const inputValue = e.target.value;
    const filteredValue = inputValue.replace(/[^0-9]/g, '');
    setMedicineSumInputValue(filteredValue);
  };

  const init = () => {
    setMedicineSearchInputValue('');
    setIsShowMedicineUnitPrice(false);
    setSelectedMedicine(null);
    setMedicineSumInputValue('');
    setIsMedicineSumInputAreaFocus(false);
    setIsMedicineSearchInputFocus(false);
    setSelectedMedicineCategory(medicineCategoryList[0]);
  };

  const handleClickMedicineCategory = (medicine: {
    id: number;
    label: string;
    codes: string[];
  }) => {
    setSelectedMedicineCategory(medicine);
    setMedicineSearchInputValue('');
    setIsShowMedicineUnitPrice(false);
    setSelectedMedicine(null);
    setMedicineSumInputValue('');
    setIsMedicineSumInputAreaFocus(false);
    setIsMedicineSearchInputFocus(false);
  };

  const handleSelectMedicine = (medicine: ManagedMedicineItemType) => {
    setSelectedMedicine(medicine);
    setMedicineSearchInputValue(
      `(${convertToInsuranceCode(medicine.standard_code)}) ${
        medicine.korean_product_name
      }`,
    );
    setIsMedicineSearchInputFocus(false);
    setIsShowMedicineUnitPrice(true);
  };

  const { useQuery } = commonHooks;
  const query = useQuery();
  const dispatch = useAppDispatch();
  const [limit, setLimit] = useState<number>(30);
  const [isLoading, setIsLoading] = useState(false);

  const pageQuery = query.get('page');
  const page = Number(pageQuery) || 0;

  const handleChangeMedicineSearchInput = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const inputValue = e.target.value;
    setMedicineSearchInputValue(inputValue);
  };

  const handleAddMedicine = async () => {
    if (!selectedMedicine) return;
    // if (isLoading) return;

    if (managementMedicineList && modalType === 'add') {
      const alreadyExistMedicine = managementMedicineList.find(
        (item) =>
          item.pharmacy_medicine.standard_code ===
          selectedMedicine.standard_code,
      );

      if (alreadyExistMedicine) {
        setManagementMedicineModalType('alreadyExist');
        setManagementMedicineTargetMedicine(alreadyExistMedicine);
        closeRegisterModal();
        openConfirmModal();
        return;
      }
    }

    setIsLoading(true);
    const {
      standard_code,
      generic_name_code,
      dosage_form,
      product_total_quantity,
    } = selectedMedicine;

    if (isEditModal) {
      try {
        await myDoctorAPI
          .patchManagementMedicine({
            id: selectedMedicine.id,
            pharmacy_medicine: {
              id: selectedMedicine.id,
              standard_code: selectedMedicine.standard_code,
              created: null,
              modified: null,
              generic_name_code: selectedMedicine.generic_name_code,
              is_active: targetMedicine?.pharmacy_medicine.is_active || true,
              pharmacy: Number(pharmacist.pharmacy_id) || 0, // 약국 id
            },
            created: null,
            modified: null,
            unit: medicineGenericCodeUnit[selectedMedicine.generic_name_code], // PEN
            quantity: Number(product_total_quantity) || 1,
            price: Number(medicineSumInputValue),
            is_deleted: false,
          })
          .then(() => {
            dispatch(
              getManagementMedicineList({
                id: pharmacist.pharmacy_id || 0,
                offset: page * limit,
                limit,
              }),
            );
          })
          .then(() => {
            setIsLoading(false);
            closeRegisterModal();
          })
          .finally(() => {
            setIsLoading(false);
          });
      } catch (e) {
        setIsLoading(false);
        console.log('handleAddMedicine error', e);
      }
    }

    try {
      await myDoctorAPI
        .postManagementMedicineList({
          id: null,
          pharmacy_medicine: {
            id: null,
            standard_code,
            created: null,
            modified: null,
            generic_name_code,
            is_active: true,
            pharmacy: Number(pharmacist.pharmacy_id) || 0, // 약국 id
          },
          created: null,
          modified: null,
          type: 'PHARMACY',
          unit: medicineGenericCodeUnit[generic_name_code], // PEN
          quantity: Number(product_total_quantity) || 1,
          price: Number(medicineSumInputValue),
        })
        .then(() => {
          dispatch(
            getManagementMedicineList({
              id: pharmacist.pharmacy_id || 0,
              offset: page * limit,
              limit,
            }),
          );
        })
        .then(() => {
          setIsLoading(false);
          closeRegisterModal();
        });
    } catch (e) {
      console.log('handleAddMedicine error', e);
    }
  };

  useEffect(() => {
    if (!isEditModal) {
      init();
    }
  }, [isEditModal, isVisible]);

  useEffect(() => {
    if (isMedicineSumInputAreaFocus) {
      medicineSumInputRef.current?.focus();
    }
  }, [isMedicineSumInputAreaFocus]);

  useEffect(() => {
    if (isMedicineSearchInputFocus) {
      medicineSearchInputRef.current?.focus();
    }
  }, [isMedicineSearchInputFocus]);

  useEffect(() => {
    if (selectedMedicineCategory.label === medicineCategoryList[0].label) {
      setFilteredSearchMedicineList(finasterideSearchList);
    } else if (
      selectedMedicineCategory.label === medicineCategoryList[1].label
    ) {
      setFilteredSearchMedicineList(dutasterideSearchList);
    } else if (
      selectedMedicineCategory.label === medicineCategoryList[2].label
    ) {
      setFilteredSearchMedicineList(dietShotSearchList);
    } else if (
      selectedMedicineCategory.label === medicineCategoryList[3].label
    ) {
      setFilteredSearchMedicineList(eatingAcneMedicineSearchList);
    } else if (
      selectedMedicineCategory.label === medicineCategoryList[4].label
    ) {
      setFilteredSearchMedicineList(linimentAcneMedicineSearchList);
    }
  }, [selectedMedicineCategory]);

  useEffect(() => {
    if (selectedMedicineCategory.label === medicineCategoryList[0].label) {
      setFilteredSearchMedicineList(
        getFilteredMedicineListInCategory(
          finasterideSearchList,
          medicineSearchInputValue,
        ),
      );
    } else if (
      selectedMedicineCategory.label === medicineCategoryList[1].label
    ) {
      setFilteredSearchMedicineList(
        getFilteredMedicineListInCategory(
          dutasterideSearchList,
          medicineSearchInputValue,
        ),
      );
    } else if (
      selectedMedicineCategory.label === medicineCategoryList[2].label
    ) {
      setFilteredSearchMedicineList(
        getFilteredMedicineListInCategory(
          dietShotSearchList,
          medicineSearchInputValue,
        ),
      );
    } else if (
      selectedMedicineCategory.label === medicineCategoryList[3].label
    ) {
      setFilteredSearchMedicineList(
        getFilteredMedicineListInCategory(
          eatingAcneMedicineSearchList,
          medicineSearchInputValue,
        ),
      );
    } else if (
      selectedMedicineCategory.label === medicineCategoryList[4].label
    ) {
      setFilteredSearchMedicineList(
        getFilteredMedicineListInCategory(
          linimentAcneMedicineSearchList,
          medicineSearchInputValue,
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [medicineSearchInputValue]);

  useEffect(() => {
    if (
      medicineSumInputValue.length >= 3 &&
      selectedMedicine?.korean_product_name
    ) {
      setIsDisabledAddMedicineButton(false);
    } else {
      setIsDisabledAddMedicineButton(true);
    }
  }, [medicineSumInputValue, selectedMedicine]);

  return (
    <BModal
      open={isVisible}
      width={480}
      padding={30}
      onOpenChange={() => {
        init();
        closeRegisterModal();
      }}
    >
      <Style.ModalArea>
        <Style.TitleArea>
          <Font fontType="h1">
            {isEditModal ? '의약품 가격 수정' : '의약품 추가'}
          </Font>
          <SvgIcon
            icon="xLg"
            width={16}
            height={16}
            color="fill/black"
            onClick={closeRegisterModal}
          />
        </Style.TitleArea>

        <Style.ModalBodyArea>
          <Style.ModalBodyWarningArea>
            <SvgIcon
              icon="infoIcon"
              color="state/distructive"
              width={16}
              height={16}
            />
            <Font fontType="body2" color="state/distructive">
              의약품 가격은 사입가가 아닌 조제료가 포함된 판매가로 등록해
              주세요.
            </Font>
          </Style.ModalBodyWarningArea>

          {!isEditModal && (
            <Style.ModalBodyContentArea>
              <Style.SubTitleArea>
                <Font fontType="body1_medium" color="fill/black">
                  카테고리
                </Font>
                <Style.RequiredUI>
                  <Font fontType="body3_medium" color="blue500">
                    필수
                  </Font>
                </Style.RequiredUI>
              </Style.SubTitleArea>

              <Style.MedicineCategoryButtonsArea>
                {medicineCategoryList.map((medicine) => {
                  const { id, label, codes } = medicine;
                  const isSelected = selectedMedicineCategory.label === label;

                  return (
                    <Button
                      key={id}
                      width="none"
                      padding="9px 16px"
                      borderRadius="12px"
                      backgroundColor={
                        isSelected
                          ? COLOR['label/lightBlue']
                          : COLOR['fill/white']
                      }
                      borderColor={
                        isSelected
                          ? COLOR['fill/accent']
                          : COLOR['border/outline']
                      }
                      title={
                        <Style.MedicineSelectedButtonArea>
                          {isSelected && (
                            <SvgIcon
                              icon="checkCircleIcon"
                              width={18}
                              color="fill/accent"
                            />
                          )}
                          <Font
                            fontType="body1"
                            color={isSelected ? 'fill/accent' : 'fill/dark'}
                          >
                            {label}
                          </Font>
                        </Style.MedicineSelectedButtonArea>
                      }
                      onClick={() => handleClickMedicineCategory(medicine)}
                    />
                  );
                })}
              </Style.MedicineCategoryButtonsArea>
            </Style.ModalBodyContentArea>
          )}

          {isEditModal ? (
            <Style.MedicineCategoryButtonsArea>
              <Style.SubTitleArea>
                <Font fontType="body1_medium" color="fill/black">
                  등록된 의약품
                </Font>
              </Style.SubTitleArea>
              <Style.MedicineSearchDisabledInputArea>
                <Style.MedicineSearchDisabledInput>
                  <Font fontType="body1_medium" color="fill/medium">
                    {selectedMedicineCategory.label}
                  </Font>
                  <Font fontType="h3" color="fill/black">
                    {medicineSearchInputValue}
                  </Font>
                </Style.MedicineSearchDisabledInput>
              </Style.MedicineSearchDisabledInputArea>
            </Style.MedicineCategoryButtonsArea>
          ) : (
            <Style.ModalBodyContentArea>
              <Style.SubTitleArea>
                <Font fontType="body1_medium" color="fill/black">
                  약품명
                </Font>
                <Style.RequiredUI>
                  <Font fontType="body3_medium" color="blue500">
                    필수
                  </Font>
                </Style.RequiredUI>
              </Style.SubTitleArea>
              <Style.MedicineSearchInputArea
                isFocus={isMedicineSearchInputFocus}
                onClick={() => {
                  if (selectedMedicine) {
                    setIsMedicineSearchInputFocus(false);
                    return;
                  }
                  setIsMedicineSearchInputFocus(true);
                }}
              >
                <SvgIcon
                  icon="searchIcon"
                  width={18}
                  color={
                    isMedicineSearchInputFocus || selectedMedicine
                      ? 'fill/black'
                      : 'fill/medium'
                  }
                />
                {selectedMedicine ? (
                  <Style.SelectedMedicineArea
                    onClick={() => {
                      setSelectedMedicine(null);
                      setIsShowMedicineUnitPrice(false);
                      setMedicineSumInputValue('');
                    }}
                  >
                    <Font fontType="body2_medium" color="fill/white">
                      {selectedMedicine.korean_product_name}
                    </Font>
                    <SvgIcon icon="xSm" width={10} color="fill/white" />
                  </Style.SelectedMedicineArea>
                ) : (
                  <Style.MedicineSearchInput
                    placeholder="보험코드 혹은 약품명을 입력해주세요"
                    value={medicineSearchInputValue}
                    ref={medicineSearchInputRef}
                    onChange={handleChangeMedicineSearchInput}
                    onBlur={() => setIsMedicineSearchInputFocus(false)}
                  />
                )}
              </Style.MedicineSearchInputArea>
              {isMedicineSearchInputFocus && (
                <Style.MedicineComboBoxArea>
                  {filteredSearchMedicineList.map((medicine) => {
                    const { standard_code, korean_product_name } = medicine;

                    return (
                      <Style.MedicineItemArea
                        key={standard_code}
                        onMouseDown={() => handleSelectMedicine(medicine)}
                      >
                        <Font fontType="body1" color="fill/dark">
                          {`(${convertToInsuranceCode(
                            standard_code,
                          )}) ${korean_product_name}`}
                        </Font>
                      </Style.MedicineItemArea>
                    );
                  })}
                </Style.MedicineComboBoxArea>
              )}
            </Style.ModalBodyContentArea>
          )}

          {isShowMedicineUnitPrice && (
            <Style.ModalBodyContentArea>
              <Style.SubTitleArea>
                <Font fontType="body1_medium" color="fill/black">
                  처방 단위당 금액
                </Font>
                <Style.RequiredUI>
                  <Font fontType="body3_medium" color="blue500">
                    필수
                  </Font>
                </Style.RequiredUI>
              </Style.SubTitleArea>

              <Style.MedicineSumInputsArea>
                <Style.MedicineUnitInputArea>
                  <Style.MedicineSearchInput
                    value={`${
                      selectedMedicine
                        ? `${selectedMedicine.product_total_quantity}${selectedMedicine.dosage_form}`
                        : ''
                    }`}
                    disabled
                  />
                </Style.MedicineUnitInputArea>
                <Style.MedicineSumInputArea
                  isFocus={isMedicineSumInputAreaFocus}
                  onClick={() => setIsMedicineSumInputAreaFocus(true)}
                >
                  <Style.MedicineSearchInput
                    placeholder="숫자만 입력해주세요"
                    ref={medicineSumInputRef}
                    value={formatNumberWithCommas(medicineSumInputValue)}
                    onChange={handleChangeMedicineSumInput}
                    onBlur={() => setIsMedicineSumInputAreaFocus(false)}
                  />
                </Style.MedicineSumInputArea>
                <Font fontType="body1_medium" color="fill/black">
                  원
                </Font>
              </Style.MedicineSumInputsArea>
            </Style.ModalBodyContentArea>
          )}
        </Style.ModalBodyArea>

        <Style.ModalFooterArea>
          <Button
            width="204px"
            height="56px"
            borderRadius="12px"
            backgroundColor={COLOR['fill/white']}
            borderColor={COLOR['border/outline']}
            title={
              <Font fontType="body1" color="fill/dark">
                취소
              </Font>
            }
            onClick={closeRegisterModal}
          />
          <Button
            width="204px"
            height="56px"
            borderRadius="12px"
            backgroundColor={
              isDisabledAddMedicineButton
                ? COLOR['disabled/background']
                : COLOR['fill/accent']
            }
            title={
              <Font
                fontType="body1"
                color={
                  isDisabledAddMedicineButton
                    ? 'disabled/foreground'
                    : 'fill/white'
                }
              >
                {isEditModal ? '저장하기' : '추가하기'}
              </Font>
            }
            onClick={handleAddMedicine}
            disabled={isDisabledAddMedicineButton}
          />
        </Style.ModalFooterArea>
      </Style.ModalArea>
    </BModal>
  );
}

export default MedicineManagementModal;
