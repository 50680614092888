import { Font } from '@components/atoms';
import BButton from '@components/meraki-ui/BButton';
import BFlex from '@components/meraki-ui/BFlex';
import BTooltip from '@components/meraki-ui/BTooltip';
import useTooltip from '@components/meraki-ui/BTooltip/useTooltip';
import { handleCopyClipBoard } from '@utils/clipboard';
import { useCallback } from 'react';
import * as Style from './index.style';

export interface IReservationInformationItem {
  id: number;
  title: string;
  text?: string;
  iconURL: any;
  canCopy: boolean;
}

interface ReservationInformationItemProps {
  reservationInformationItem: IReservationInformationItem;
}

function ReservationInformationItem({
  reservationInformationItem,
}: ReservationInformationItemProps) {
  const { tooltipVisible, openTooltip } = useTooltip();

  const onClick = useCallback(async () => {
    try {
      await handleCopyClipBoard(reservationInformationItem?.text ?? '');

      openTooltip();
    } catch (error) {
      console.error(error);
    }
  }, [openTooltip, reservationInformationItem?.text]);

  return (
    <Style.ContentBodyContainer key={reservationInformationItem.id}>
      <Style.ContentBodyTitleArea>
        <Style.ContentBodyTitle>
          <Font fontType="body1" color="fill/medium">
            {reservationInformationItem.title}
          </Font>
        </Style.ContentBodyTitle>
      </Style.ContentBodyTitleArea>
      <Style.ContentBodyTextArea>
        <Style.ContentBodyText>
          <Font fontType="h4" color="fill/black">
            {reservationInformationItem.text || '-'}
          </Font>
          {reservationInformationItem.iconURL ? (
            <Style.ContentBodyTextIcon
              src={reservationInformationItem.iconURL}
              alt={reservationInformationItem.text}
            />
          ) : null}
          {[0, 1, 2, 3, 4, 5, 6, 7].includes(reservationInformationItem.id) &&
          reservationInformationItem.canCopy ? (
            <BFlex noShrink position="relative">
              <BButton
                buttonType="default"
                size="chip"
                text="복사"
                onClick={onClick}
                fitText={false}
              />
              {tooltipVisible ? (
                <BTooltip
                  visible={tooltipVisible}
                  side="right"
                  margin="-1rem 0 0 5.5rem"
                  title="복사되었습니다."
                  marginNumber={0}
                />
              ) : undefined}
            </BFlex>
          ) : undefined}
        </Style.ContentBodyText>
      </Style.ContentBodyTextArea>
    </Style.ContentBodyContainer>
  );
}

export default ReservationInformationItem;
