import { checkBoxIcon, checkFilledBoxIcon } from '@assets/index';
import { priceFormatter } from '@functions/formatters';
import useAcceptTP from '@mobile/api/hooks/useAcceptTP';
import usePrescriptionDetail from '@mobile/api/hooks/usePrescriptionDetail';
import usePrescriptionEstimatedPrice from '@mobile/api/hooks/usePrescriptionEstimatedPrice';
import AcceptanceHeader from '@mobile/components/common/AcceptanceHeader';
import { LoadingContainer } from '@mobile/components/layout/LoadingContainer';
import { Mixpanel } from '@utils/mixpanel';
import { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { BeatLoader } from 'react-spinners';
import styled from 'styled-components';
import BottomCTA from './components/BottomCTA';
import InsureInfo from './components/InsureInfo';
import UnInsureInfo from './components/UnInsureInfo';
import usePharmacyCost from './hooks/usePharmacyCost';

export type TPAcceptLocationState = {
  state: {
    patientName: string;
    reservationCode: string;
    prescriptionId: string;
    mixpanelData: {
      'TP ID': number;
      'PH ID': string | null;
      'Symptom Keyword': string;
      'Delivery Method': string;
      'OCR Success': boolean;
      'Non-insured Medicine': (string | null | undefined)[];
    };
  };
};

function TPAccept() {
  const { id } = useParams();

  const {
    state: { prescriptionId, patientName, reservationCode, mixpanelData },
  } = useLocation() as TPAcceptLocationState;
  const tppId = Number(id);

  const { isUnInsured, isLoading, prescriptionEstimatedPriceData } =
    usePrescriptionEstimatedPrice();

  const {
    cost,
    handleChangeInput,
    handleCheckZeroCost,
    isButtonDisabled,
    isZeroCost,
  } = usePharmacyCost();

  const navigate = useNavigate();

  const { mutate: acceptTPMutate, isLoading: isAcceptLoading } = useAcceptTP();

  const { data: prescriptionData, isLoading: isPrescriptionLoading } =
    usePrescriptionDetail();

  const sumedUnitList =
    prescriptionData?.[0].prescription_contents?.map(
      (el) =>
        Number(el.dosing_per_day) *
        Number(el.one_dose) *
        Number(el.total_dosing_days),
    ) || [];

  const goBack = () => {
    return navigate(`/mobile/request/detail/${tppId}`, {
      state: { prescriptionId },
    });
  };

  const acceptTP = () => {
    acceptTPMutate({ amount: cost, tppId: String(tppId) });
  };

  useEffect(() => {
    Mixpanel.track('[Modal] Accept TP Requested (Mobile)', mixpanelData);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading || isPrescriptionLoading)
    return (
      <LoadingContainer>
        <BeatLoader size={15} loading color="#3478F6" />
      </LoadingContainer>
    );

  return (
    <>
      <AcceptanceHeader title="조제 수락" goBack={goBack} />

      <Container>
        <PatientInfo>
          <InfoText>{`${patientName} (${reservationCode.toUpperCase()})`}</InfoText>
        </PatientInfo>

        <Content>
          <Wrapper>
            <LabelContainer>
              <LabelText>약제비</LabelText>
              <Label>필수</Label>
            </LabelContainer>

            <InputContainer>
              <Input
                type="text"
                value={`${priceFormatter.prettyPriceFormatter(cost)}`}
                onChange={handleChangeInput}
                placeholder="숫자만 입력해주세요"
                disabled={isZeroCost}
              />
              <Text>원</Text>
            </InputContainer>

            <CheckBoxContainer>
              {isZeroCost ? (
                <CheckBox
                  src={checkFilledBoxIcon}
                  onClick={handleCheckZeroCost}
                />
              ) : (
                <CheckBox src={checkBoxIcon} onClick={handleCheckZeroCost} />
              )}
              <Text>약제비가 0원인 조제입니다.</Text>
            </CheckBoxContainer>

            {!isUnInsured || sumedUnitList.length === 0 ? (
              <InsureInfo />
            ) : (
              <UnInsureInfo
                estimatedPriceData={prescriptionEstimatedPriceData}
                sumedUnitList={sumedUnitList}
              />
            )}
          </Wrapper>
        </Content>

        <BottomCTA
          isButtonDisabled={isButtonDisabled || isAcceptLoading}
          goBack={goBack}
          accept={acceptTP}
        />
      </Container>
    </>
  );
}

export default TPAccept;

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  max-width: 720px;
  height: 100vh;
  height: 100dvh;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  padding-top: var(--acceptance-header-height);
  overflow-y: scroll;
`;

const PatientInfo = styled.div`
  display: flex;
  padding: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`;

const InfoText = styled.div`
  color: #1e2024;

  font-family: Pretendard, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
`;

const Content = styled.div`
  display: flex;
  padding: 0px 20px 40px 20px;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  flex: 1 0 0;
  align-self: stretch;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
`;

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const LabelText = styled.div`
  color: #1e2024;

  font-family: Pretendard, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
`;

const Label = styled.div`
  display: flex;
  padding: 2px 8px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: #ebf3ff;

  color: #4785ff;

  font-family: Pretendard, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: stretch;
`;

const Input = styled.input`
  display: flex;
  height: 52px;
  padding: 0px 16px;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 8px;
  border: 1px solid #dddfe3;
  background: #ffffff;
  font-size: 16px;

  &::placeholder {
    color: #9fa1a8;
    font-weight: 400;
  }
`;

const Text = styled.div`
  color: #474a52;

  font-family: Pretendard, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.32px;
`;

const CheckBoxContainer = styled.div`
  display: flex;
  padding: 8px 0px;
  align-items: center;
  gap: 12px;
`;

const CheckBox = styled.img`
  width: 20px;
  height: 20px;
`;
