/* eslint-disable react-hooks/exhaustive-deps */
import { commonHooks } from '@hooks/index';
import useValidateMobileToken from '@mobile/api/hooks/useValidateMobileToken';
import { useEffect } from 'react';
import { BeatLoader } from 'react-spinners';
import styled from 'styled-components';

function MobileAuth() {
  const { useQuery } = commonHooks;
  const query = useQuery();
  const mobileToken = query.get('token') as string;

  const { mutate: validateToken, isLoading } = useValidateMobileToken();

  useEffect(() => {
    validateToken(mobileToken);
  }, []);

  return isLoading ? (
    <Container>
      <BeatLoader size={15} loading color="#3478F6" />
    </Container>
  ) : null;
}

export default MobileAuth;

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  max-width: 720px;
  height: 100vh;
  height: 100dvh;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
