import {
  coldChainBlackImage,
  parcelBlackImage,
  pickupBlackImage,
  quickBlackImage,
  ReactBootstrapIcons,
} from '@assets';
import { Blank, Button, COLOR, Font } from '@components/atoms';
import { commonCode, timePolicyVariables } from '@constants';
import { formatters } from '@functions';
import { useInterval } from '@hooks/commonHooks';
import { myDoctorAPI } from '@services';
import { calcAutorejectMinute, calcWaitingMinute } from '@utils/timeCalc';
import printJS from 'print-js';
import { MouseEvent, useState } from 'react';
import { TelepharmacyListItemType } from './index.d';
import * as Style from './index.style';

const { COMMON_CODE } = commonCode;
const { TIME_POLICY_VARIABLES } = timePolicyVariables;

function TelepharmacyListItem({
  isSelected,
  item,
  onClick,
}: TelepharmacyListItemType) {
  const { phoneFormatter } = formatters;

  const [autoRejectMinute, setAutorejectMinute] = useState<number>(
    calcAutorejectMinute(item),
  );
  const [waitingMinute, setWaitingMinute] = useState<number>(
    calcWaitingMinute(item),
  );
  useInterval(() => {
    setAutorejectMinute(calcAutorejectMinute(item));
    setWaitingMinute(calcWaitingMinute(item));
  }, 5000);

  let iconURL;
  switch (item.method) {
    case COMMON_CODE.TP_METHOD.PARCEL:
      iconURL = parcelBlackImage;
      break;
    case COMMON_CODE.TP_METHOD.PICKUP:
      iconURL = pickupBlackImage;
      break;
    case COMMON_CODE.TP_METHOD.QUICK:
    case COMMON_CODE.TP_METHOD.ECONOMY_QUICK:
      iconURL = quickBlackImage;
      break;
    case COMMON_CODE.TP_METHOD.COLD_CHAIN:
      iconURL = coldChainBlackImage;
      break;
    default:
      iconURL = parcelBlackImage;
      break;
  }

  const fetchPrescriptionData = async (tp_id: number | undefined) => {
    const { data } = await myDoctorAPI.getTelepharmacyTpprocessPrescriptions(
      Number(tp_id),
    );
    return data;
  };

  return (
    <Style.Container isSelected={isSelected} onClick={() => onClick()}>
      <Style.DeliveryPhotoContainer>
        <Style.DeliveryPhotoArea>
          <Style.DeliveryImage src={iconURL} alt="DeliveryImage" />
        </Style.DeliveryPhotoArea>
      </Style.DeliveryPhotoContainer>
      <Style.TextArea>
        <Style.TopTextArea>
          <Style.WaitingTimeTextArea>
            {autoRejectMinute <= TIME_POLICY_VARIABLES.autoRejectAlertMinute ? (
              <Font fontType="body3" color="state/distructive">
                {autoRejectMinute >= 1 ? autoRejectMinute : 1}분 내 만료
              </Font>
            ) : (
              <Font fontType="body3" color="fill/dark">
                {waitingMinute}분 대기 중
              </Font>
            )}
          </Style.WaitingTimeTextArea>
          <Style.IsUninsuredTextArea>
            <Style.IsUninsuredBadge>
              <Font fontType="body3" color="fill/dark">
                {item.is_tm_uninsured ? '비급여' : '급여'}
              </Font>
            </Style.IsUninsuredBadge>
          </Style.IsUninsuredTextArea>
        </Style.TopTextArea>
        <Style.MiddleTextArea>
          <Font fontType="h4" color={isSelected ? 'fill/accent' : 'fill/black'}>
            {item.patient?.name} 환자 (
            {phoneFormatter.lastFourPhoneNumberFormatter(
              item.patient?.phone || item.requester?.phone,
            )}
            )
          </Font>
        </Style.MiddleTextArea>
        <Style.BottomTextArea>
          <Style.DoctorDisplayArea>
            <Font fontType="body3" color="fill/medium">
              {item.doctor?.name} 의사
            </Font>
          </Style.DoctorDisplayArea>
        </Style.BottomTextArea>
      </Style.TextArea>
      <Style.ButtonArea>
        <Style.PrescriptionButtonArea>
          {item.has_subscription && (
            <Button
              onClick={async (event: MouseEvent<HTMLButtonElement>) => {
                event.stopPropagation();
                event.preventDefault();
                const prescriptionData = await fetchPrescriptionData(item.id);
                printJS({
                  printable: prescriptionData[0].image,
                  type: 'pdf',
                  showModal: true,
                });
              }}
              width="92px"
              height="32px"
              borderColor={COLOR['fill/black']}
              backgroundColor={COLOR['fill/white']}
              title={
                <Style.ButtonContainer>
                  <ReactBootstrapIcons.Printer color={COLOR['fill/black']} />
                  <Blank appoint="Horizontal" size={8} />
                  <Font fontType="body3" color="fill/black">
                    프린트
                  </Font>
                </Style.ButtonContainer>
              }
            />
          )}
        </Style.PrescriptionButtonArea>
      </Style.ButtonArea>
    </Style.Container>
  );
}

export default TelepharmacyListItem;
