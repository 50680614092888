import { Font } from '@components/atoms';
import { commonCode } from '@constants';
import { StatusTagStyleType } from './index.d';
import { StatusTagContainer } from './index.style';

const { COMMON_CODE } = commonCode;

function StatusTag({
  status,
  paymentStatus,
}: {
  status?: string;
  paymentStatus?: string;
}) {
  const data: StatusTagStyleType = {
    title: '',
    borderColor: 'state/success',
    backgroundColor: 'state/success',
    titleColor: 'fill/white',
  };
  switch (status) {
    case COMMON_CODE.TP_STATUS.ACCEPTED:
      data.title = '결제대기';
      data.backgroundColor = 'fill/white';
      data.borderColor = 'fill/white';
      data.titleColor = 'fill/accent';
      break;
    case COMMON_CODE.TP_STATUS.PRE_CONFIRMED:
      data.title = `추가 상품`;
      data.backgroundColor = 'fill/white';
      data.borderColor = 'fill/white';
      data.titleColor = 'state/success';
      data.subTitle = '구매 진행 중';
      break;
    default:
      data.title = '결제대기';
      data.backgroundColor = 'fill/white';
      data.borderColor = 'fill/white';
      data.titleColor = 'fill/accent';
      break;
  }

  switch (paymentStatus) {
    case COMMON_CODE.PAYMENT_STATUS.FAILED:
      data.title = '결제실패';
      data.backgroundColor = 'fill/white';
      data.borderColor = 'fill/white';
      data.titleColor = 'state/distructive';
      data.subTitle = '';
      break;
    default:
      break;
  }

  return (
    <StatusTagContainer data={data}>
      <div>
        <Font fontType="h5" color={data.titleColor || 'fill/white'}>
          {data.title}
        </Font>
      </div>
      {data.subTitle ? (
        <div>
          <Font fontType="h5" color={data.titleColor || 'fill/white'}>
            {data.subTitle}
          </Font>
        </div>
      ) : null}
    </StatusTagContainer>
  );
}

export default StatusTag;
