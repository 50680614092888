import { BoxContainer, COLOR } from '@components/atoms';
import styled from 'styled-components';

export const Container = styled.div<{
  acceptedIsActive: boolean;
  isRequestedPage: boolean;
}>`
  display: flex;
  flex-direction: row;
  justify-content: ${({ acceptedIsActive }) =>
    acceptedIsActive ? 'center' : 'none'};
  width: calc(100vw - 20px);
  margin-bottom: 40px;
  padding: ${({ isRequestedPage }) => (isRequestedPage ? '0' : '20px 0 0 0')};
`;

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 0;
  z-index: 30;
`;

export const ListArea = styled(BoxContainer)`
  height: calc(100vh - 88px);
  overflow: auto;
  border-radius: 0;
  box-shadow: none;
`;

export const ListTitleArea = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

export const ListTitle = styled.div`
  margin-right: 4px;
`;

export const ListIcon = styled.div`
  cursor: pointer;
`;

export const ListMiddleArea = styled.div`
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
`;

export const ListCountArea = styled.div``;

export const ListFilterButtonArea = styled.div`
  display: flex;
  align-items: center;
  padding-right: 4px;
`;

export const TelepharmacyListArea = styled.div`
  overflow: auto;
  max-height: calc(100vh - 37rem);
`;

export const TelepharmacyListDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${COLOR['fill/light']};
  margin: 16px 0;
`;

export const CardArea = styled.div<{ acceptedIsActive: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: ${({ acceptedIsActive }) =>
    acceptedIsActive ? 'center' : 'none'};
  margin-left: ${({ acceptedIsActive }) => (acceptedIsActive ? 0 : 368)}px;
  position: relative;
`;

export const TooltipMessageArea = styled.div``;

export const TooltipMessage = styled.div``;

export const TooltipImage = styled.img`
  width: 12px;
  height: 12px;
  margin-right: 4px;
`;

export const EmptyContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 41px;
  margin: 4px 0;
  padding: 12px;
  background-color: ${COLOR['fill/light']};
  border-radius: 8px;
`;
