export const SPACING_VARIABLES = {
  closedSidebarWidth: `92px`,
  sidebarWidth: `280px`,
  smSpacing: `8px`,
  mdSpacing: `16px`,
  lgSpacing: `20px`,
  xlSpacing: `32px`,
  xxlSpacing: `48px`,
  borderRadius: `8px`,
};

export const BREAK_POINTS = {
  size1920: 1920,
  size1440: 1440,
  size1280: 1280,
  size1024: 1024,
  size960: 960,
  size720: 720,
};
