import { notifyOtherClients } from '@hooks/featureHooks/useSocket';
import { postAcceptTelepharmacy } from '@mobile/api';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

const useAcceptTP = () => {
  const navigate = useNavigate();

  const acceptTP = ({ tppId, amount }: { tppId: string; amount: string }) => {
    return postAcceptTelepharmacy({ tppId, amount });
  };

  return useMutation(acceptTP, {
    onSuccess: () => {
      alert('조제 수락에 성공하였습니다.');
      notifyOtherClients('telepharmacy');
      return navigate(`/mobile/request`);
    },
    onError: () => {
      alert('조제 수락에 실패하였습니다.');
    },
  });
};

export default useAcceptTP;
