import * as Style from './index.style';

interface ToggleButtonProps {
  isActive: boolean;
  onClickToggle: () => void;
}

function ToggleButton({ isActive, onClickToggle }: ToggleButtonProps) {
  return (
    <Style.ToggleWrapper isActive={isActive} onClick={onClickToggle}>
      <Style.ToggleCircle isActive={isActive} />
    </Style.ToggleWrapper>
  );
}

export default ToggleButton;
