import { storageHandler } from '@utils';

export const SERVER_URL_KEY = 'merakiServerURL';

export const ServerConstant = () => {
  const { getLocalStorageState } = storageHandler;
  const localStorageServerURL = getLocalStorageState(SERVER_URL_KEY);

  let serverURL;
  switch (process.env.REACT_APP_SERVER) {
    case 'PRODUCTION':
      serverURL = 'https://api-prod.merakiplace.work/';
      break;
    case 'DEV':
      serverURL = localStorageServerURL || 'https://api-dev.merakiplace.work/';
      break;
    case 'DEBUGGING':
      serverURL = 'https://api-prod.merakiplace.work/';
      break;
    case 'LOCAL':
      serverURL = localStorageServerURL || 'http://api-dev.merakiplace.work/';
      break;
    default:
      serverURL = 'https://api-dev.merakiplace.work/';
      break;
  }
  return serverURL;
};

export const getServerURL = (type?: 'apis' | 'payment') => {
  const { getLocalStorageState } = storageHandler;
  const localStorageServerURL = getLocalStorageState(SERVER_URL_KEY);

  if (type === 'payment') {
    return process.env.REACT_APP_SERVER === 'PRODUCTION'
      ? 'https://payment-api.merakiplace.work'
      : 'https://payment-api-dev.merakiplace.work';
  }
  return process.env.REACT_APP_SERVER === 'PRODUCTION'
    ? 'https://api-prod.merakiplace.work'
    : localStorageServerURL || 'https://api-dev.merakiplace.work';
};
