import { Font } from '@components/atoms';
import { TextInputTitle } from '@components/atoms/textInput/index.style';
import {
  ChangeEvent,
  FocusEvent,
  KeyboardEvent,
  useCallback,
  WheelEvent,
} from 'react';
import styled from 'styled-components';
import { COLOR } from '../color';

interface TextInputProps {
  value: string | number;
  title?: string;
  type: 'text' | 'password' | 'number' | 'date';
  height?: number | string;
  width?: number | string;
  fontSize?: number;

  placeholder?: string;
  disabled?: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onKeyPress?: (e: KeyboardEvent<HTMLInputElement>) => void;
  onFocus?: (e: FocusEvent<HTMLInputElement, Element>) => void;
  onBlur?: (e: FocusEvent<HTMLInputElement, Element>) => void;
  paddingLeft?: number;
}

export default function TextInput({
  title,
  onChange,
  value,
  type,
  placeholder,
  onKeyPress,
  width,
  height,
  disabled,
  fontSize,
  onFocus,
  onBlur,
  paddingLeft,
}: TextInputProps) {
  const onWheel = useCallback((e: WheelEvent<HTMLInputElement>) => {
    e.currentTarget.blur();
  }, []);

  return (
    <TextInputContainer>
      {title && (
        <TextInputTitle>
          <Font fontType="body2" color="fill/black">
            {title}
          </Font>
        </TextInputTitle>
      )}
      <TextInputComponent
        onWheel={onWheel}
        value={value}
        onChange={onChange}
        onKeyPress={onKeyPress}
        placeholder={placeholder}
        type={type}
        width={width}
        height={height}
        fontSize={fontSize}
        disabled={disabled}
        onFocus={onFocus}
        onBlur={onBlur}
        paddingLeft={paddingLeft}
      />
    </TextInputContainer>
  );
}

export const TextInputContainer = styled.div`
  // width: 100%;
`;

interface TextInputContentProps {
  height?: number | string;
  width?: number | string;
  fontSize?: number;
  paddingLeft?: number;
  disabled?: boolean;
}

const TextInputComponent = styled.input<TextInputContentProps>`
  width: ${(props) => (props.width ? `${props.width}px` : '100%')};
  height: ${(props) => (props.height ? props.height : 48)}px;
  padding-left: ${(props) => (props.paddingLeft ? props.paddingLeft : 20)}px;
  border: 1px solid ${COLOR['dim/light']};
  border-radius: 4px;
  font-weight: 400;
  font-size: ${(props) => `${props.fontSize}px` || '18px'};
  font-family: Pretendard, -apple-system, sans-serif;

  &::-webkit-input-placeholder {
    color: ${COLOR['fill/medium']};
  }
  &:-ms-input-placeholder {
    color: ${COLOR['fill/medium']};
  }
  &::placeholder {
    color: ${COLOR['fill/medium']};
  }
`;
