import { lightImage, quickImage, ReactBootstrapIcons } from '@assets';
import { Blank, Button, COLOR, Font } from '@components/atoms';
import BFlex from '@components/meraki-ui/BFlex';
import { styleVariables } from '@constants';
import { commonHooks } from '@hooks';
import { useMount } from '@hooks/commonHooks';
import { useAppDispatch, useAppSelector } from '@stores/hooks';
import {
  getTelepharmacyList_finished_quick,
  selectTelepharmacyCount_finished_quick,
  selectTelepharmacyList_finished_quick,
} from '@stores/telepharmacyStore/telepharmacyList';
import { trackConfirmedTreatmentOngoingQuickMixpanelEvents } from '@utils/mixpanel/confirmedTreatment/ongoingQuick';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BeatLoader from 'react-spinners/BeatLoader';
import ReactTooltip from 'react-tooltip';
import AcceptedTelepharmacyCarousel from '../acceptedTelepharmacyTable/AcceptedTelepharmacyCarousel';
import * as Style from './index.style';
import { ListContent, ListItem, Pagination } from './sections';

const { BREAK_POINTS } = styleVariables;

export const LISTITEM_FLEX = [
  { flex: 64, title: '진행상태' },
  { flex: 130, title: '퀵 매칭상태\n(기사님 연락처)' },
  { flex: 109, title: '배송방법' },
  { flex: 109, title: '환자명\n(예약번호)' },
  { flex: 153, title: '연락처' },
  { flex: 120, title: '약제비' },
  { flex: 120, title: '추가 결제비' },
  { flex: 200, title: '주소' },
  { flex: 227, title: '처방전' },
];

function OngoingQuickTelepharmacyTable() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { useQuery, useWindowSize } = commonHooks;
  const { windowWidth } = useWindowSize();
  const query = useQuery();

  const [isLoading, setIsLoading] = useState(true);
  const telepharmacyData = useAppSelector(
    selectTelepharmacyList_finished_quick,
  );
  const telepharmacyCount = useAppSelector(
    selectTelepharmacyCount_finished_quick,
  );

  const [isLimitFilterClicked, setIsLimitFilterClicked] = useState(false);
  const clickLimitFilter = () => {
    setIsLimitFilterClicked(!isLimitFilterClicked);
  };

  // 필터
  const [limit, setLimit] = useState<number>(30);

  const handleLimitFilter = (count: number) => {
    setLimit(count);
  };

  const pageQuery = query.get('page');
  const page = Number(pageQuery) || 0;
  const maxPage = telepharmacyCount
    ? Math.floor(telepharmacyCount / limit) + 1
    : 0;

  useMount(() => {
    trackConfirmedTreatmentOngoingQuickMixpanelEvents(
      'pageConfirmedTreatmentOngoingQuick',
    )();
  });

  useEffect(() => {
    setIsLoading(true);
    dispatch(
      getTelepharmacyList_finished_quick({
        offset: page * limit,
        limit,
      }),
    ).then(() => {
      setIsLoading(false);
    });
    return () => setIsLoading(false);
  }, [dispatch, page, limit]);

  return (
    <Style.Container>
      <BFlex padding="0 0 40px 0">
        <AcceptedTelepharmacyCarousel pageType="confirmedTreatmentOngoingQuick" />
      </BFlex>
      <Style.PageTitleArea>
        <Style.PageTitleItem>
          <Style.PageTitleIcon src={quickImage} alt="퀵" />
        </Style.PageTitleItem>
        <Style.PageTitleItem>
          <Font fontType="display1">퀵 수거대기</Font>
        </Style.PageTitleItem>
        <Style.PageTitleItem>
          <ReactBootstrapIcons.QuestionCircle
            size={16}
            color={COLOR['fill/dark']}
            data-for="showToolTip_ongoingQuick"
            data-tip
          />
          <ReactTooltip id="showToolTip_ongoingQuick" place="right" isCapture>
            <Style.TooltipMessageArea>
              <Blank appoint="Vertical" size={4} />
              <Style.TooltipMessage>
                <Style.TooltipImage src={lightImage} alt="light" />퀵 수거대기
              </Style.TooltipMessage>
              <Blank appoint="Vertical" size={12} />
              <Style.TooltipMessage>
                ㆍ 기사님의 수거를 기다리는 조제(퀵)입니다.
              </Style.TooltipMessage>
              <Blank appoint="Vertical" size={8} />
              <Style.TooltipMessage>
                ㆍ 수거 이후 자동으로 수거완료 처리가 됩니다.
              </Style.TooltipMessage>
              <Blank appoint="Vertical" size={4} />
            </Style.TooltipMessageArea>
          </ReactTooltip>
        </Style.PageTitleItem>
      </Style.PageTitleArea>
      {/* <FilterComponent handleFilters={handleFilters} /> */}
      <Style.ListArea
        className={
          Number(windowWidth) < BREAK_POINTS.size1024 ? 'smallListArea' : ''
        }
      >
        <Style.TitleArea>
          <Font fontType="h1">
            전체내역 {isLoading ? '(0건)' : `(${telepharmacyCount}건)`}
          </Font>
        </Style.TitleArea>
        <Style.OptionArea>
          {/* 
                @동훈
                FIXME: 여기는 레이아웃만 쪼갰습니다. 
            */}
          <Style.OptionLeftArea />
          {/* 
                @동훈
                FIXME: 여기는 레이아웃만 쪼개고, 버튼 들어갈 공간만 확보하고 멈췄습니다. 
            */}
          <Style.OptionRightArea>
            <Button
              width="140px"
              height="32px"
              borderColor={COLOR['fill/dark']}
              backgroundColor={COLOR['fill/white']}
              title={
                <Style.IconButtonItem>
                  <Font fontType="body3">{limit}개씩 보기</Font>
                  <Blank appoint="Horizontal" size={8} />
                  {isLimitFilterClicked ? (
                    <ReactBootstrapIcons.ChevronUp
                      size={12}
                      color={COLOR['fill/dark']}
                    />
                  ) : (
                    <ReactBootstrapIcons.ChevronDown
                      size={12}
                      color={COLOR['fill/dark']}
                    />
                  )}
                </Style.IconButtonItem>
              }
              onClick={(event) => {
                event.stopPropagation();
                clickLimitFilter();
              }}
              onBlur={() => {
                setIsLimitFilterClicked(false);
              }}
            />
            {isLimitFilterClicked ? (
              <Style.OptionRightAreaDropdownList
                onMouseDown={(event) => event.preventDefault()}
              >
                <Style.OptionRightAreaDropdownItem
                  onClick={() => {
                    handleLimitFilter(30);
                    clickLimitFilter();
                  }}
                >
                  30개씩 보기
                </Style.OptionRightAreaDropdownItem>
                <Blank appoint="Vertical" size={16} />
                <Style.OptionRightAreaDropdownItem
                  onClick={() => {
                    handleLimitFilter(50);
                    clickLimitFilter();
                  }}
                >
                  50개씩 보기
                </Style.OptionRightAreaDropdownItem>
                <Blank appoint="Vertical" size={16} />
                <Style.OptionRightAreaDropdownItem
                  onClick={() => {
                    handleLimitFilter(100);
                    clickLimitFilter();
                  }}
                >
                  100개씩 보기
                </Style.OptionRightAreaDropdownItem>
              </Style.OptionRightAreaDropdownList>
            ) : null}
          </Style.OptionRightArea>
        </Style.OptionArea>
        {telepharmacyCount ? (
          <>
            <Style.ListContainer>
              <Style.ListHeaderItem>
                {LISTITEM_FLEX.map((item, index) => {
                  if (index === 0) return null;
                  if (
                    Number(windowWidth) < BREAK_POINTS.size1024 &&
                    (index === 2 || index === 8)
                  )
                    return null;
                  return (
                    <ListContent.Bold key={index.toString()} flex={item.flex}>
                      {item.title}
                    </ListContent.Bold>
                  );
                })}
              </Style.ListHeaderItem>
              {isLoading ? (
                <Style.LoaderArea>
                  <BeatLoader size={15} loading color={COLOR['fill/accent']} />
                </Style.LoaderArea>
              ) : (
                telepharmacyData?.map((item) => (
                  <ListItem navigate={navigate} item={item} key={item.id} />
                ))
              )}
            </Style.ListContainer>
            <Pagination page={page} maxPage={maxPage} limit={limit} />
          </>
        ) : (
          <Style.EmptyContainer>
            <Font fontType="h5">수거 대기중인 퀵 내역이 없습니다.</Font>
          </Style.EmptyContainer>
        )}
      </Style.ListArea>
    </Style.Container>
  );
}

export default OngoingQuickTelepharmacyTable;
