import { ManagedMedicineItemType } from '@services/myDoctor/types';

export const medicineGenericCodeKoreanUnit: Record<string, string> = {
  '159002ATB': '알',
  '458801ACS': '알',
  '458801ATB': '알',
  '612230BIJ': '펜',
  '178701ACS': '알',
  '135639CLQ': '개',
  '135635CLQ': '개',
  '135633CLQ': '개',
  '135602CLQ': '개',
  '135636CLQ': '개',
};

export const medicineGenericCodeUnit: Record<string, string> = {
  '159002ATB': 'PILL',
  '458801ACS': 'PILL',
  '458801ATB': 'PILL',
  '612230BIJ': 'PEN',
  '178701ACS': 'PILL',
  '135639CLQ': 'EA',
  '135635CLQ': 'EA',
  '135633CLQ': 'EA',
  '135602CLQ': 'EA',
  '135636CLQ': 'EA',
};

export const medicineCategoryList = [
  {
    id: 1,
    label: `피나스테리드`,
    codes: ['159002ATB'],
  },
  {
    id: 2,
    label: `두타스테리드`,
    codes: ['458801ACS', '458801ATB'],
  },
  {
    id: 3,
    label: `다이어트 주사`,
    codes: ['612230BIJ'],
  },
  {
    id: 4,
    label: `먹는 여드름약`,
    codes: ['178701ACS'],
  },
  {
    id: 5,
    label: `바르는 여드름약`,
    codes: ['135639CLQ', '135635CLQ', '135633CLQ', '135602CLQ', '135636CLQ'],
  },
];

export const searchMedicineList: ManagedMedicineItemType[] = [
  {
    id: 4476,
    standard_code: '8806650026818',
    generic_name_code: '458801ATB',
    korean_product_name: '아보다칸정0.5밀리그램(두타스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: null,
  },
  {
    id: 6425,
    standard_code: '8806589000811',
    generic_name_code: '135602CLQ',
    korean_product_name: '다크린외용액(클린다마이신포스페이트)',
    product_total_quantity: '1',
    dosage_form: '개',
    packaging_type: '병',
    special_general_type: '전문의약품',
    generic_name: null,
  },
  {
    id: 6426,
    standard_code: '8806589000828',
    generic_name_code: '135602CLQ',
    korean_product_name: '다크린외용액(클린다마이신포스페이트)',
    product_total_quantity: '1',
    dosage_form: '개',
    packaging_type: '튜브',
    special_general_type: '전문의약품',
    generic_name: null,
  },
  {
    id: 8055,
    standard_code: '8806625039713',
    generic_name_code: '458801ATB',
    korean_product_name: '두테스정0.5밀리그램(두타스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: null,
  },
  {
    id: 12760,
    standard_code: '8806786017018',
    generic_name_code: '458801ATB',
    korean_product_name: '두타프렌정0.5밀리그램(두타스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: null,
  },
  {
    id: 13656,
    standard_code: '8806416018712',
    generic_name_code: '135602CLQ',
    korean_product_name: '아클리신액(클린다마이신포스페이트)',
    product_total_quantity: '1',
    dosage_form: '개',
    packaging_type: '병',
    special_general_type: '전문의약품',
    generic_name: null,
  },
  {
    id: 14925,
    standard_code: '8806416072417',
    generic_name_code: '458801ATB',
    korean_product_name: '두타겟정(두타스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: null,
  },
  {
    id: 16743,
    standard_code: '8806573072411',
    generic_name_code: '458801ATB',
    korean_product_name: '두타리드정0.5밀리그램(두타스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: null,
  },
  {
    id: 21884,
    standard_code: '8806903028712',
    generic_name_code: '458801ATB',
    korean_product_name: '머카나정0.5밀리그램(두타스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: null,
  },
  {
    id: 24015,
    standard_code: '8806460017617',
    generic_name_code: '135602CLQ',
    korean_product_name: '아크닌액(클린다마이신포스페이트)',
    product_total_quantity: '1',
    dosage_form: '개',
    packaging_type: '병',
    special_general_type: '전문의약품',
    generic_name: null,
  },
  {
    id: 24016,
    standard_code: '8806460017624',
    generic_name_code: '135602CLQ',
    korean_product_name: '아크닌액(클린다마이신포스페이트)',
    product_total_quantity: '1',
    dosage_form: '개',
    packaging_type: '병',
    special_general_type: '전문의약품',
    generic_name: null,
  },
  {
    id: 24017,
    standard_code: '8806460017631',
    generic_name_code: '135602CLQ',
    korean_product_name: '아크닌액(클린다마이신포스페이트)',
    product_total_quantity: '1',
    dosage_form: '개',
    packaging_type: '병',
    special_general_type: '전문의약품',
    generic_name: null,
  },
  {
    id: 27862,
    standard_code: '8806431053712',
    generic_name_code: '458801ATB',
    korean_product_name: '두보트정0.5밀리그램(두타스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: null,
  },
  {
    id: 51301,
    standard_code: '8806452097115',
    generic_name_code: '458801ATB',
    korean_product_name: '두타브정0.5밀리그램(두타스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: null,
  },
  {
    id: 60415,
    standard_code: '8806289018512',
    generic_name_code: '458801ATB',
    korean_product_name: '두타엠정0.5밀리그램(두타스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: null,
  },
  {
    id: 62223,
    standard_code: '8800524019418',
    generic_name_code: '458801ATB',
    korean_product_name: '모모다트정(두타스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: null,
  },
  {
    id: 72955,
    standard_code: '8806255011110',
    generic_name_code: '458801ATB',
    korean_product_name: '두리드정0.5밀리그램(두타스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: null,
  },
  {
    id: 75639,
    standard_code: '8806574004916',
    generic_name_code: '135602CLQ',
    korean_product_name: '아크네신액(클린다마이신포스페이트)',
    product_total_quantity: '1',
    dosage_form: '개',
    packaging_type: '병',
    special_general_type: '전문의약품',
    generic_name: null,
  },
  {
    id: 75640,
    standard_code: '8806574004923',
    generic_name_code: '135602CLQ',
    korean_product_name: '아크네신액(클린다마이신포스페이트)',
    product_total_quantity: '1',
    dosage_form: '개',
    packaging_type: '병',
    special_general_type: '전문의약품',
    generic_name: null,
  },
  {
    id: 80117,
    standard_code: '8806932018616',
    generic_name_code: '458801ATB',
    korean_product_name: '아보그로정0.5밀리그램(두타스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: null,
  },
  {
    id: 85749,
    standard_code: '8806530062318',
    generic_name_code: '458801ATB',
    korean_product_name: '아보타스정(두타스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: null,
  },
  {
    id: 88968,
    standard_code: '8806521036915',
    generic_name_code: '135602CLQ',
    korean_product_name: '크레오신티외용액1％',
    product_total_quantity: '1',
    dosage_form: '개',
    packaging_type: '병',
    special_general_type: '일반의약품',
    generic_name: null,
  },
  {
    id: 88972,
    standard_code: '8806521038117',
    generic_name_code: '135602CLQ',
    korean_product_name: '크레오신티외용액1%(클린다마이신포스페이트)',
    product_total_quantity: '1',
    dosage_form: '개',
    packaging_type: '병',
    special_general_type: '전문의약품',
    generic_name: null,
  },
  {
    id: 93775,
    standard_code: '8806252019416',
    generic_name_code: '458801ATB',
    korean_product_name: '두타스타정0.5밀리그램(두타스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: null,
  },
  {
    id: 97050,
    standard_code: '8806706085912',
    generic_name_code: '458801ATB',
    korean_product_name: '휴메리나정0.5밀리그램(두타스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: null,
  },
  {
    id: 99730,
    standard_code: '8806476015911',
    generic_name_code: '135602CLQ',
    korean_product_name: '퓨어킨외용액(클린다마이신포스페이트)',
    product_total_quantity: '1',
    dosage_form: '통',
    packaging_type: '병',
    special_general_type: '전문의약품',
    generic_name: null,
  },
  {
    id: 99731,
    standard_code: '8806476015928',
    generic_name_code: '135602CLQ',
    korean_product_name: '퓨어킨외용액(클린다마이신포스페이트)',
    product_total_quantity: '1',
    dosage_form: '개',
    packaging_type: '병',
    special_general_type: '전문의약품',
    generic_name: null,
  },
  {
    id: 102001,
    standard_code: '8806481046719',
    generic_name_code: '458801ATB',
    korean_product_name: '두로칸정0.5밀리그램(두타스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: null,
  },
  {
    id: 109850,
    standard_code: '8806418030514',
    generic_name_code: '135602CLQ',
    korean_product_name: '클리시아외용액(클린다마이신포스페이트)(수출용)',
    product_total_quantity: '1',
    dosage_form: '개',
    packaging_type: '기타',
    special_general_type: '전문의약품',
    generic_name: null,
  },
  {
    id: 110574,
    standard_code: '8806418066513',
    generic_name_code: '458801ATB',
    korean_product_name: '두아모정(두타스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: null,
  },
  {
    id: 111864,
    standard_code: '8806698051315',
    generic_name_code: '458801ATB',
    korean_product_name: '듀타그렌정0.5mg(두타스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: null,
  },
  {
    id: 125564,
    standard_code: '8806940017618',
    generic_name_code: '458801ATB',
    korean_product_name: '대웅바이오두타스테리드정0.5밀리그램',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: null,
  },
  {
    id: 129955,
    standard_code: '8806699072616',
    generic_name_code: '458801ATB',
    korean_product_name: '엔피다트정(두타스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: null,
  },
  {
    id: 138092,
    standard_code: '8806432002610',
    generic_name_code: '135602CLQ',
    korean_product_name: '듀오크린액(클린다마이신포스페이트)',
    product_total_quantity: '1',
    dosage_form: '개',
    packaging_type: '병',
    special_general_type: '전문의약품',
    generic_name: null,
  },
  {
    id: 140879,
    standard_code: '8806425075119',
    generic_name_code: '458801ATB',
    korean_product_name: '두타반플러스정(두타스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: null,
  },
  {
    id: 144596,
    standard_code: '8806641009219',
    generic_name_code: '135602CLQ',
    korean_product_name: '에피외용액(클린다마이신포스페이트)',
    product_total_quantity: '1',
    dosage_form: '개',
    packaging_type: '병',
    special_general_type: '전문의약품',
    generic_name: null,
  },
  {
    id: 146845,
    standard_code: '8806258011414',
    generic_name_code: '458801ATB',
    korean_product_name: '아리드정0.5밀리그램(두타스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: null,
  },
  {
    id: 158912,
    standard_code: '8806423082010',
    generic_name_code: '458801ATB',
    korean_product_name: '삼성두타스테리드정0.5밀리그램',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: null,
  },
  {
    id: 166025,
    standard_code: '8806512054416',
    generic_name_code: '458801ATB',
    korean_product_name: '삼천당두타스테리드정0.5밀리그램',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: null,
  },
  {
    id: 169626,
    standard_code: '8806438006018',
    generic_name_code: '135602CLQ',
    korean_product_name: '아크프리외용액',
    product_total_quantity: '1',
    dosage_form: '개',
    packaging_type: '병',
    special_general_type: '전문의약품',
    generic_name: null,
  },
  {
    id: 181200,
    standard_code: '8806502049415',
    generic_name_code: '458801ATB',
    korean_product_name: '에이두타정0.5밀리그램(두타스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: null,
  },
  {
    id: 181393,
    standard_code: '8806502056611',
    generic_name_code: '458801ACS',
    korean_product_name: '안국두타연질캡슐0.5밀리그램(두타스테리드)',
    product_total_quantity: '30',
    dosage_form: '캡슐',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '두타스테리드',
  },
  {
    id: 184386,
    standard_code: '8806526064616',
    generic_name_code: '458801ATB',
    korean_product_name: '두타론정0.5밀리그램(두타스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: null,
  },
  {
    id: 187945,
    standard_code: '8806473036414',
    generic_name_code: '458801ATB',
    korean_product_name: '두타스테린정0.5밀리그램(두타스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: null,
  },
  {
    id: 190242,
    standard_code: '8806400076117',
    generic_name_code: '458801ATB',
    korean_product_name: '케이두타다트정0.5밀리그램(두타스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: null,
  },
  {
    id: 194715,
    standard_code: '8806619054210',
    generic_name_code: '458801ATB',
    korean_product_name: '아보테드정0.5밀리그램(두타스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: null,
  },
  {
    id: 212815,
    standard_code: '8806789011914',
    generic_name_code: '458801ATB',
    korean_product_name: '두타모아정0.5밀리그램(두타스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: null,
  },
  {
    id: 213704,
    standard_code: '8806441032011',
    generic_name_code: '458801ATB',
    korean_product_name: '네오다트정0.5밀리그램(두타스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: null,
  },
  {
    id: 215655,
    standard_code: '8806449145010',
    generic_name_code: '458801ATB',
    korean_product_name: '제이다트정0.5밀리그램(두타스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: null,
  },
  {
    id: 222879,
    standard_code: '8800576009610',
    generic_name_code: '458801ATB',
    korean_product_name: '모벤두타정0.5밀리그램(두타스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: null,
  },
  {
    id: 223020,
    standard_code: '8800570004512',
    generic_name_code: '458801ATB',
    korean_product_name: '두타케어정0.5밀리그램(두타스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: null,
  },
  {
    id: 230608,
    standard_code: '8806717040115',
    generic_name_code: '135602CLQ',
    korean_product_name: '클린티외용액(클린다마이신포스페이트)',
    product_total_quantity: '1',
    dosage_form: '개',
    packaging_type: '병',
    special_general_type: '전문의약품',
    generic_name: null,
  },
  {
    id: 234669,
    standard_code: '8800590009016',
    generic_name_code: '458801ATB',
    korean_product_name: '두타시드정0.5밀리그램(두타스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: null,
  },
  {
    id: 234793,
    standard_code: '8806734001311',
    generic_name_code: '458801ATB',
    korean_product_name: '두타엘정0.5밀리그램(두타스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: null,
  },
  {
    id: 242488,
    standard_code: '8800551006115',
    generic_name_code: '458801ACS',
    korean_product_name: '아보투윈연질캡슐0.5밀리그램(두타스테리드)',
    product_total_quantity: '30',
    dosage_form: '캡슐',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '두타스테리드',
  },
  {
    id: 245782,
    standard_code: '8806448018315',
    generic_name_code: '135602CLQ',
    korean_product_name: '크리신외용액(클린다마이신포스페이트)',
    product_total_quantity: '1',
    dosage_form: '개',
    packaging_type: '병',
    special_general_type: '전문의약품',
    generic_name: null,
  },
  {
    id: 245783,
    standard_code: '8806448018322',
    generic_name_code: '135602CLQ',
    korean_product_name: '크리신외용액(클린다마이신포스페이트)',
    product_total_quantity: '1',
    dosage_form: '개',
    packaging_type: '병',
    special_general_type: '전문의약품',
    generic_name: null,
  },
  {
    id: 245784,
    standard_code: '8806448018339',
    generic_name_code: '135602CLQ',
    korean_product_name: '크리신외용액(클린다마이신포스페이트)',
    product_total_quantity: '1',
    dosage_form: '개',
    packaging_type: '병',
    special_general_type: '전문의약품',
    generic_name: null,
  },
  {
    id: 249841,
    standard_code: '8806578062417',
    generic_name_code: '458801ATB',
    korean_product_name: '두로케어정0.5밀리그램(두타스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: null,
  },
  {
    id: 258461,
    standard_code: '8806230062915',
    generic_name_code: '458801ATB',
    korean_product_name: '두타린정0.5밀리그램(두타스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: null,
  },
  {
    id: 265938,
    standard_code: '8806655081812',
    generic_name_code: '458801ATB',
    korean_product_name: '유다트정0.5밀리그램(두타스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: null,
  },
  {
    id: 272384,
    standard_code: '8806636079814',
    generic_name_code: '458801ATB',
    korean_product_name: '아보리드정0.5밀리그램(두타스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: null,
  },
  {
    id: 272836,
    standard_code: '8806489010712',
    generic_name_code: '135602CLQ',
    korean_product_name: '크레오신티외용액1%',
    product_total_quantity: '1',
    dosage_form: '개',
    packaging_type: '병',
    special_general_type: '일반의약품',
    generic_name: null,
  },
  {
    id: 274442,
    standard_code: '8806491039817',
    generic_name_code: '458801ATB',
    korean_product_name: '원투스테리드정0.5mg(두타스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: null,
  },
  {
    id: 283971,
    standard_code: '8806556052218',
    generic_name_code: '458801ATB',
    korean_product_name: '아다모정0.5밀리그램(두타스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: null,
  },
  {
    id: 288178,
    standard_code: '8806420042314',
    generic_name_code: '458801ATB',
    korean_product_name: '다모다트정0.5밀리그램(두타스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: null,
  },
  // {
  //   id: 297561,
  //   standard_code: '8806472059308',
  //   generic_name_code: '159002ATB',
  //   korean_product_name: '피노테정1밀리그램(피나스테리드)_(1mg/1정)',
  //   product_total_quantity: '1',
  //   dosage_form: '정',
  //   packaging_type: '',
  //   special_general_type: '전문의약품',
  //   generic_name: '피나스테리드',
  // },
  {
    id: 187844,
    standard_code: '8806473032515',
    generic_name_code: '135633CLQ',
    korean_product_name: '클린다-케이외용액(클린다마이신포스페이트)',
    product_total_quantity: '1',
    dosage_form: '개',
    packaging_type: '병',
    special_general_type: '전문의약품',
    generic_name: '클린다마이신포스페이트',
  },
  {
    id: 3470,
    standard_code: '8806581072717',
    generic_name_code: '159002ATB',
    korean_product_name: '뉴페시아정1밀리그램(피나스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 3471,
    standard_code: '8806581072724',
    generic_name_code: '159002ATB',
    korean_product_name: '뉴페시아정1밀리그램(피나스테리드)',
    product_total_quantity: '90',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 8071,
    standard_code: '8806625040313',
    generic_name_code: '159002ATB',
    korean_product_name: '피나스정1밀리그램(피나스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 8072,
    standard_code: '8806625040320',
    generic_name_code: '159002ATB',
    korean_product_name: '피나스정1밀리그램(피나스테리드)',
    product_total_quantity: '90',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 8410,
    standard_code: '8806436003019',
    generic_name_code: '159002ATB',
    korean_product_name: '네오페시아정1밀리그램(피나스테리드)',
    product_total_quantity: '28',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 12269,
    standard_code: '8806646027119',
    generic_name_code: '159002ATB',
    korean_product_name: '다림피나스테리드정1밀리그램',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 12270,
    standard_code: '8806646027126',
    generic_name_code: '159002ATB',
    korean_product_name: '다림피나스테리드정1밀리그램',
    product_total_quantity: '90',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 12400,
    standard_code: '8806786004612',
    generic_name_code: '159002ATB',
    korean_product_name: '모더페시아정1밀리그램(피나스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 13503,
    standard_code: '8806416014219',
    generic_name_code: '159002ATB',
    korean_product_name: '베아리모정(피나스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 13504,
    standard_code: '8806416014226',
    generic_name_code: '159002ATB',
    korean_product_name: '베아리모정(피나스테리드)',
    product_total_quantity: '10',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 15808,
    standard_code: '8806573026711',
    generic_name_code: '159002ATB',
    korean_product_name: '알로펙정(피나스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 19897,
    standard_code: '8806559007819',
    generic_name_code: '159002ATB',
    korean_product_name: '레나시딘정1밀리그램(피나스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 19898,
    standard_code: '8806559007826',
    generic_name_code: '159002ATB',
    korean_product_name: '레나시딘정1밀리그램(피나스테리드)',
    product_total_quantity: '100',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 19899,
    standard_code: '8806559007833',
    generic_name_code: '159002ATB',
    korean_product_name: '레나시딘정1밀리그램(피나스테리드)',
    product_total_quantity: '28',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 21278,
    standard_code: '8806903008516',
    generic_name_code: '159002ATB',
    korean_product_name: '제이페시아정1mg(피나스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 23242,
    standard_code: '8806228045616',
    generic_name_code: '159002ATB',
    korean_product_name: '알로피나정1밀리그램(피나스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 23538,
    standard_code: '8806460002811',
    generic_name_code: '159002ATB',
    korean_product_name: '다모페시아정(피나스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 26363,
    standard_code: '8806562050116',
    generic_name_code: '159002ATB',
    korean_product_name: '모어에이블정1밀리그램(피나스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 27849,
    standard_code: '8806431053118',
    generic_name_code: '159002ATB',
    korean_product_name: '모리턴정1밀리그램(피나스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 33172,
    standard_code: '8806582045413',
    generic_name_code: '159002ATB',
    korean_product_name: '다나페시아정1밀리그램(피나스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 33173,
    standard_code: '8806582045420',
    generic_name_code: '159002ATB',
    korean_product_name: '다나페시아정1밀리그램(피나스테리드)',
    product_total_quantity: '90',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 35133,
    standard_code: '8806891033514',
    generic_name_code: '159002ATB',
    korean_product_name: '티나제정1밀리그램(피나스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 38374,
    standard_code: '8806529036610',
    generic_name_code: '159002ATB',
    korean_product_name: '모나정(피나스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 40162,
    standard_code: '8806939010316',
    generic_name_code: '159002ATB',
    korean_product_name: '모나스틴정(피나스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: '병',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 40163,
    standard_code: '8806939010323',
    generic_name_code: '159002ATB',
    korean_product_name: '모나스틴정(피나스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 40164,
    standard_code: '8806939010330',
    generic_name_code: '159002ATB',
    korean_product_name: '모나스틴정(피나스테리드)',
    product_total_quantity: '90',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 41166,
    standard_code: '8806890005314',
    generic_name_code: '159002ATB',
    korean_product_name: '피나테크정1밀리그램(피나스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 41167,
    standard_code: '8806890005321',
    generic_name_code: '159002ATB',
    korean_product_name: '피나테크정1밀리그램(피나스테리드)',
    product_total_quantity: '90',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 43373,
    standard_code: '8806486029014',
    generic_name_code: '159002ATB',
    korean_product_name: '씨엠프로정1mg(피나스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 44023,
    standard_code: '8806695003515',
    generic_name_code: '159002ATB',
    korean_product_name: '마이시아정(피나스테리드)',
    product_total_quantity: '28',
    dosage_form: '정',
    packaging_type: 'Foil',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 44700,
    standard_code: '8800582002612',
    generic_name_code: '159002ATB',
    korean_product_name: '아스테리정1밀리그램(피나스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 46885,
    standard_code: '8806696045019',
    generic_name_code: '159002ATB',
    korean_product_name: '베르모나정1밀리그램(피나스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 48985,
    standard_code: '8806700006913',
    generic_name_code: '159002ATB',
    korean_product_name: '피나필로정1밀리그램(피나스테리드)(비매품)',
    product_total_quantity: '10',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 48986,
    standard_code: '8806700006920',
    generic_name_code: '159002ATB',
    korean_product_name: '피나필로정1밀리그램(피나스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 49338,
    standard_code: '8806452001914',
    generic_name_code: '159002ATB',
    korean_product_name: '그로모정1밀리그램(피나스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: '병',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 49339,
    standard_code: '8806452001921',
    generic_name_code: '159002ATB',
    korean_product_name: '그로모정1밀리그램(피나스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 53003,
    standard_code: '8806297018214',
    generic_name_code: '159002ATB',
    korean_product_name: '헤오나정1밀리그램(피나스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 55661,
    standard_code: '8800588002012',
    generic_name_code: '159002ATB',
    korean_product_name: '피나앤정1밀리그램(피나스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 59022,
    standard_code: '8806421025910',
    generic_name_code: '159002ATB',
    korean_product_name: '페로시아정1밀리그램(피나스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 59023,
    standard_code: '8806421025927',
    generic_name_code: '159002ATB',
    korean_product_name: '페로시아정1밀리그램(피나스테리드)(비매품)',
    product_total_quantity: '10',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 60284,
    standard_code: '8806289013913',
    generic_name_code: '159002ATB',
    korean_product_name: '멕스페시아정1밀리그램(피나스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 62177,
    standard_code: '8800524018312',
    generic_name_code: '159002ATB',
    korean_product_name: '피나엠정1밀리그램(피나스테리드)',
    product_total_quantity: '28',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 62178,
    standard_code: '8800524018329',
    generic_name_code: '159002ATB',
    korean_product_name: '피나엠정1밀리그램(피나스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 62179,
    standard_code: '8800524018336',
    generic_name_code: '159002ATB',
    korean_product_name: '피나엠정1밀리그램(피나스테리드)',
    product_total_quantity: '90',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 63257,
    standard_code: '8806409039311',
    generic_name_code: '159002ATB',
    korean_product_name: '피나스트정1밀리그램(피나스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 63258,
    standard_code: '8806409039328',
    generic_name_code: '159002ATB',
    korean_product_name: '피나스트정1밀리그램(피나스테리드)',
    product_total_quantity: '90',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 67295,
    standard_code: '8806433043018',
    generic_name_code: '159002ATB',
    korean_product_name: '헤어스모정(피나스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 67296,
    standard_code: '8806433043025',
    generic_name_code: '159002ATB',
    korean_product_name: '헤어스모정',
    product_total_quantity: '10',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 72514,
    standard_code: '8806985045010',
    generic_name_code: '159002ATB',
    korean_product_name: '에이페시아정1밀리그램(피나스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 72945,
    standard_code: '8806255010717',
    generic_name_code: '159002ATB',
    korean_product_name: '스테리정1밀리그램(피나스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 72946,
    standard_code: '8806255010724',
    generic_name_code: '159002ATB',
    korean_product_name: '스테리정1밀리그램(피나스테리드)',
    product_total_quantity: '90',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 73442,
    standard_code: '8806798016016',
    generic_name_code: '159002ATB',
    korean_product_name: '모나페시아정1밀리그램(피나스테리드)',
    product_total_quantity: '90',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 75116,
    standard_code: '8806701042613',
    generic_name_code: '159002ATB',
    korean_product_name: '피나프로스정1밀리그램(피나스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 75117,
    standard_code: '8806701042620',
    generic_name_code: '159002ATB',
    korean_product_name: '피나프로스정1밀리그램(피나스테리드)',
    product_total_quantity: '90',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 77990,
    standard_code: '8806515001714',
    generic_name_code: '159002ATB',
    korean_product_name: '두피나정(피나스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 77991,
    standard_code: '8806515001721',
    generic_name_code: '159002ATB',
    korean_product_name: '두피나정(피나스테리드)(비매품)',
    product_total_quantity: '3',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 87340,
    standard_code: '8806218033913',
    generic_name_code: '159002ATB',
    korean_product_name: '리모날정1밀리그램(피나스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 87341,
    standard_code: '8806218033920',
    generic_name_code: '159002ATB',
    korean_product_name: '리모날정1밀리그램(피나스테리드)',
    product_total_quantity: '90',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 94486,
    standard_code: '8806288007012',
    generic_name_code: '159002ATB',
    korean_product_name: '모페시아정(피나스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 96575,
    standard_code: '8806706069714',
    generic_name_code: '159002ATB',
    korean_product_name: '메리나정(피나스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 100946,
    standard_code: '8806481014510',
    generic_name_code: '159002ATB',
    korean_product_name: '알로스칸정(피나스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: '병',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 100947,
    standard_code: '8806481014527',
    generic_name_code: '159002ATB',
    korean_product_name: '알로스칸정(피나스테리드)',
    product_total_quantity: '100',
    dosage_form: '정',
    packaging_type: '병',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 100948,
    standard_code: '8806481014534',
    generic_name_code: '159002ATB',
    korean_product_name: '알로스칸정(피나스테리드)',
    product_total_quantity: '500',
    dosage_form: '정',
    packaging_type: '병',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 100949,
    standard_code: '8806481014541',
    generic_name_code: '159002ATB',
    korean_product_name: '알로스칸정(피나스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 108585,
    standard_code: '8806428005717',
    generic_name_code: '159002ATB',
    korean_product_name: '메디페시아정1밀리그램(피나스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 112710,
    standard_code: '8806437016612',
    generic_name_code: '159002ATB',
    korean_product_name: '알로페시드정(피나스테리드)',
    product_total_quantity: '28',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 125324,
    standard_code: '8806940009712',
    generic_name_code: '159002ATB',
    korean_product_name: '대웅바이오피나스테리드정1밀리그램',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 127085,
    standard_code: '8806718035912',
    generic_name_code: '159002ATB',
    korean_product_name: '핀카정1밀리그램(피나스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 129984,
    standard_code: '8806699073910',
    generic_name_code: '159002ATB',
    korean_product_name: '엔피나정(피나스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 129985,
    standard_code: '8806699073927',
    generic_name_code: '159002ATB',
    korean_product_name: '엔피나정(피나스테리드)',
    product_total_quantity: '90',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 134439,
    standard_code: '8800577002313',
    generic_name_code: '159002ATB',
    korean_product_name: '피나온정1밀리그램(피나스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 134440,
    standard_code: '8800577002320',
    generic_name_code: '159002ATB',
    korean_product_name: '피나온정1밀리그램(피나스테리드)',
    product_total_quantity: '90',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 135286,
    standard_code: '8806459033710',
    generic_name_code: '159002ATB',
    korean_product_name: '피나스텔정1mg(피나스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 136740,
    standard_code: '8806534019011',
    generic_name_code: '159002ATB',
    korean_product_name: '피나시딜정(피나스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: '기타',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 138320,
    standard_code: '8806432012916',
    generic_name_code: '159002ATB',
    korean_product_name: '헤어피나정1mg',
    product_total_quantity: '28',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 143571,
    standard_code: '8806427038013',
    generic_name_code: '159002ATB',
    korean_product_name: '헤어페시아정(피나스테리드)',
    product_total_quantity: '28',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 143572,
    standard_code: '8806427038020',
    generic_name_code: '159002ATB',
    korean_product_name: '헤어페시아정(피나스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 146177,
    standard_code: '8806415025810',
    generic_name_code: '159002ATB',
    korean_product_name: '프로아모정1밀리그램(피나스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 146178,
    standard_code: '8806415025827',
    generic_name_code: '159002ATB',
    korean_product_name: '프로아모정1밀리그램(피나스테리드)',
    product_total_quantity: '90',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 146977,
    standard_code: '8806498002715',
    generic_name_code: '159002ATB',
    korean_product_name: '다모케어정(피나스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 146978,
    standard_code: '8806498002722',
    generic_name_code: '159002ATB',
    korean_product_name: '다모케어정(피나스테리드)(수출용)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 146979,
    standard_code: '8806498002739',
    generic_name_code: '159002ATB',
    korean_product_name: '다모케어정(피나스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: '병',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 151911,
    standard_code: '8806575039511',
    generic_name_code: '159002ATB',
    korean_product_name: '오뉴페시아정(피나스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 151912,
    standard_code: '8806575039528',
    generic_name_code: '159002ATB',
    korean_product_name: '오뉴페시아정(피나스테리드)',
    product_total_quantity: '90',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 158898,
    standard_code: '8806423081419',
    generic_name_code: '159002ATB',
    korean_product_name: '삼성피나스테리드정1밀리그램',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 158899,
    standard_code: '8806423081426',
    generic_name_code: '159002ATB',
    korean_product_name: '삼성피나스테리드정1밀리그램',
    product_total_quantity: '90',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 159229,
    standard_code: '8806457004415',
    generic_name_code: '159002ATB',
    korean_product_name: '발트만정(피나스테리드)',
    product_total_quantity: '24',
    dosage_form: '정',
    packaging_type: '병',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 159754,
    standard_code: '8806457028817',
    generic_name_code: '159002ATB',
    korean_product_name: '알로테드정1밀리그램(피나스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 161717,
    standard_code: '8806440018412',
    generic_name_code: '159002ATB',
    korean_product_name: '파세드정1mg(피나스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 162362,
    standard_code: '8806439002415',
    generic_name_code: '159002ATB',
    korean_product_name: '리드페시아정(피나스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 165300,
    standard_code: '8806512021319',
    generic_name_code: '159002ATB',
    korean_product_name: '프로시나정1mg30T',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: '기타',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 168089,
    standard_code: '8806649016813',
    generic_name_code: '159002ATB',
    korean_product_name: '피나리드정1밀리그램(피나스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 168090,
    standard_code: '8806649016820',
    generic_name_code: '159002ATB',
    korean_product_name: '피나리드정1밀리그램(피나스테리드)',
    product_total_quantity: '90',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 168212,
    standard_code: '8806649021015',
    generic_name_code: '159002ATB',
    korean_product_name: '피나모정1mg(피나스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 170136,
    standard_code: '8806438028515',
    generic_name_code: '159002ATB',
    korean_product_name: '신신피나스테리드정1밀리그램',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 170925,
    standard_code: '8806538017013',
    generic_name_code: '159002ATB',
    korean_product_name: '알로게인정(피나스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 172289,
    standard_code: '8806485010419',
    generic_name_code: '159002ATB',
    korean_product_name: '바로피나정(피나스테리드)',
    product_total_quantity: '10',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 172290,
    standard_code: '8806485010426',
    generic_name_code: '159002ATB',
    korean_product_name: '바로피나정(피나스테리드)',
    product_total_quantity: '28',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 172291,
    standard_code: '8806485010433',
    generic_name_code: '159002ATB',
    korean_product_name: '바로피나정(피나스테리드)',
    product_total_quantity: '56',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 172292,
    standard_code: '8806485010440',
    generic_name_code: '159002ATB',
    korean_product_name: '바로피나정(피나스테리드)',
    product_total_quantity: '84',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 172293,
    standard_code: '8806485010457',
    generic_name_code: '159002ATB',
    korean_product_name: '바로피나정(피나스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 172294,
    standard_code: '8806485010464',
    generic_name_code: '159002ATB',
    korean_product_name: '바로피나정(피나스테리드)',
    product_total_quantity: '90',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 176344,
    standard_code: '8806552002316',
    generic_name_code: '159002ATB',
    korean_product_name: '모겐정1밀리그램(피나스테리드)',
    product_total_quantity: '28',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 177718,
    standard_code: '8806744015810',
    generic_name_code: '159002ATB',
    korean_product_name: '모나시아정1mg(피나스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 177719,
    standard_code: '8806744015827',
    generic_name_code: '159002ATB',
    korean_product_name: '모나시아정1mg(피나스테리드)',
    product_total_quantity: '90',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 178512,
    standard_code: '8806540019913',
    generic_name_code: '159002ATB',
    korean_product_name: '헤나모정1밀리그람(피나스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 181380,
    standard_code: '8806502056215',
    generic_name_code: '159002ATB',
    korean_product_name: '안국피나스테리드정1밀리그램',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 181381,
    standard_code: '8806502056222',
    generic_name_code: '159002ATB',
    korean_product_name: '안국피나스테리드정1밀리그램',
    product_total_quantity: '90',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 182543,
    standard_code: '8806560046715',
    generic_name_code: '159002ATB',
    korean_product_name: '피나스로정1mg(피나스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 184014,
    standard_code: '8806526050619',
    generic_name_code: '159002ATB',
    korean_product_name: '레나시딘정1밀리그램(피나스테리드)',
    product_total_quantity: '28',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 184015,
    standard_code: '8806526050626',
    generic_name_code: '159002ATB',
    korean_product_name: '레나시딘정1밀리그램(피나스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 184016,
    standard_code: '8806526050633',
    generic_name_code: '159002ATB',
    korean_product_name: '레나시딘정1밀리그램(피나스테리드)',
    product_total_quantity: '100',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 188049,
    standard_code: '8806473040213',
    generic_name_code: '159002ATB',
    korean_product_name: '피나스테아정1밀리그램(피나스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 190128,
    standard_code: '8806400071914',
    generic_name_code: '159002ATB',
    korean_product_name: '씨제이피나스테리드정1밀리그램',
    product_total_quantity: '10',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 190129,
    standard_code: '8806400071921',
    generic_name_code: '159002ATB',
    korean_product_name: '씨제이피나스테리드정1밀리그램',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 190130,
    standard_code: '8806400071938',
    generic_name_code: '159002ATB',
    korean_product_name: '이노엔피나스테리드정1밀리그램',
    product_total_quantity: '10',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 190131,
    standard_code: '8806400071945',
    generic_name_code: '159002ATB',
    korean_product_name: '이노엔피나스테리드정1밀리그램',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 190558,
    standard_code: '8800548007415',
    generic_name_code: '159002ATB',
    korean_product_name: '피나스텐정1밀리그램(피나스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 190559,
    standard_code: '8800548007422',
    generic_name_code: '159002ATB',
    korean_product_name: '피나스텐정1밀리그램(피나스테리드)',
    product_total_quantity: '90',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 191489,
    standard_code: '8806494028511',
    generic_name_code: '159002ATB',
    korean_product_name: '피나베린정1밀리그램(피나스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 191490,
    standard_code: '8806494028528',
    generic_name_code: '159002ATB',
    korean_product_name: '피나베린정1밀리그램(피나스테리드)',
    product_total_quantity: '90',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 196790,
    standard_code: '8806543054515',
    generic_name_code: '159002ATB',
    korean_product_name: '핀스타정1밀리그램(피나스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 198999,
    standard_code: '8806495015213',
    generic_name_code: '159002ATB',
    korean_product_name: '알로시아정(피나스테리드)',
    product_total_quantity: '7',
    dosage_form: '정',
    packaging_type: '병',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 199000,
    standard_code: '8806495015220',
    generic_name_code: '159002ATB',
    korean_product_name: '알로시아정(피나스테리드)',
    product_total_quantity: '28',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 199001,
    standard_code: '8806495015237',
    generic_name_code: '159002ATB',
    korean_product_name: '알로시아정(피나스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 203166,
    standard_code: '8806585022213',
    generic_name_code: '159002ATB',
    korean_product_name: '피나세트정1mg(피나스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 203167,
    standard_code: '8806585022220',
    generic_name_code: '159002ATB',
    korean_product_name: '피나세트정1mg(피나스테리드)',
    product_total_quantity: '90',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 210813,
    standard_code: '8806942054512',
    generic_name_code: '159002ATB',
    korean_product_name: '모그로정1밀리그램(피나스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 210814,
    standard_code: '8806942054529',
    generic_name_code: '159002ATB',
    korean_product_name: '모그로정1밀리그램(피나스테리드)',
    product_total_quantity: '90',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 212508,
    standard_code: '8806980044919',
    generic_name_code: '159002ATB',
    korean_product_name: '제노페시아정(피나스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 212509,
    standard_code: '8806980044926',
    generic_name_code: '159002ATB',
    korean_product_name: '제노페시아정(피나스테리드)',
    product_total_quantity: '90',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 212817,
    standard_code: '8806789012010',
    generic_name_code: '159002ATB',
    korean_product_name: '피나모아정1밀리그램(피나스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 212818,
    standard_code: '8806789012027',
    generic_name_code: '159002ATB',
    korean_product_name: '피나모아정1밀리그램(피나스테리드)',
    product_total_quantity: '90',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 212930,
    standard_code: '8806441002311',
    generic_name_code: '159002ATB',
    korean_product_name: '모나드정(피나스테리드)',
    product_total_quantity: '28',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 212931,
    standard_code: '8806441002328',
    generic_name_code: '159002ATB',
    korean_product_name: '모나드정(피나스테리드)',
    product_total_quantity: '7',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 212932,
    standard_code: '8806441002335',
    generic_name_code: '159002ATB',
    korean_product_name: '모나드정(피나스테리드)',
    product_total_quantity: '7',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 212933,
    standard_code: '8806441002342',
    generic_name_code: '159002ATB',
    korean_product_name: '모나드정(피나스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 212934,
    standard_code: '8806441002359',
    generic_name_code: '159002ATB',
    korean_product_name: '모나드정(피나스테리드)',
    product_total_quantity: '90',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 212935,
    standard_code: '8806441002366',
    generic_name_code: '159002ATB',
    korean_product_name: '모나드정(피나스테리드)',
    product_total_quantity: '10',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 214041,
    standard_code: '8806449009718',
    generic_name_code: '159002ATB',
    korean_product_name: '모나스타정1밀리그램(피나스테리드)',
    product_total_quantity: '7',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 214042,
    standard_code: '8806449009725',
    generic_name_code: '159002ATB',
    korean_product_name: '모나스타정1밀리그램(피나스테리드)',
    product_total_quantity: '28',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 214043,
    standard_code: '8806449009732',
    generic_name_code: '159002ATB',
    korean_product_name: '모나스타정1밀리그램(피나스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 214044,
    standard_code: '8806449009749',
    generic_name_code: '159002ATB',
    korean_product_name: '모나스타정1밀리그램(피나스테리드)',
    product_total_quantity: '90',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 217250,
    standard_code: '8806454025611',
    generic_name_code: '159002ATB',
    korean_product_name: '모나필정1밀리그램(피나스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 222816,
    standard_code: '8800576007418',
    generic_name_code: '159002ATB',
    korean_product_name: '모벤시아정(피나스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 222817,
    standard_code: '8800576007425',
    generic_name_code: '159002ATB',
    korean_product_name: '모벤시아정(피나스테리드)',
    product_total_quantity: '90',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 222940,
    standard_code: '8800570001719',
    generic_name_code: '159002ATB',
    korean_product_name: '모모페시아정1밀리그램(피나스테리드)',
    product_total_quantity: '90',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 226402,
    standard_code: '8800605002315',
    generic_name_code: '159002ATB',
    korean_product_name: '메가페시아정1밀리그램(피나스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 226403,
    standard_code: '8800605002322',
    generic_name_code: '159002ATB',
    korean_product_name: '메가페시아정1밀리그램(피나스테리드)',
    product_total_quantity: '90',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 229678,
    standard_code: '8806717003813',
    generic_name_code: '159002ATB',
    korean_product_name: '마이페시아정(피나스테리드)',
    product_total_quantity: '28',
    dosage_form: '정',
    packaging_type: 'Foil',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 229679,
    standard_code: '8806717003820',
    generic_name_code: '159002ATB',
    korean_product_name: '마이페시아정(피나스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'Foil',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 234691,
    standard_code: '8800590009818',
    generic_name_code: '159002ATB',
    korean_product_name: '피나시드정1밀리그램(피나스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 234863,
    standard_code: '8806734003612',
    generic_name_code: '159002ATB',
    korean_product_name: '모시아모정(피나스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 236564,
    standard_code: '8806497025517',
    generic_name_code: '159002ATB',
    korean_product_name: '피나스톤정1밀리그램(피나스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 236565,
    standard_code: '8806497025524',
    generic_name_code: '159002ATB',
    korean_product_name: '피나스톤정1밀리그램(피나스테리드)',
    product_total_quantity: '90',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 237039,
    standard_code: '8806503005519',
    generic_name_code: '159002ATB',
    korean_product_name: '모나리드정1밀리그램(피나스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 241676,
    standard_code: '8806547010210',
    generic_name_code: '159002ATB',
    korean_product_name: '프로시정(피나스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 242428,
    standard_code: '8800551003817',
    generic_name_code: '159002ATB',
    korean_product_name: '피나윈정1밀리그램(피나스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 242429,
    standard_code: '8800551003824',
    generic_name_code: '159002ATB',
    korean_product_name: '피나윈정1밀리그램(피나스테리드)',
    product_total_quantity: '90',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 244901,
    standard_code: '8800527001717',
    generic_name_code: '159002ATB',
    korean_product_name: '티나제정1밀리그램(피나스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 246111,
    standard_code: '8806448030119',
    generic_name_code: '159002ATB',
    korean_product_name: '피나모린정(피나스테리드)',
    product_total_quantity: '28',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 246112,
    standard_code: '8806448030126',
    generic_name_code: '159002ATB',
    korean_product_name: '피나모린정(피나스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 247669,
    standard_code: '8806490012316',
    generic_name_code: '159002ATB',
    korean_product_name: '에피나시정1밀리그램(피나스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 250209,
    standard_code: '8806578076612',
    generic_name_code: '159002ATB',
    korean_product_name: '원페시아정1밀리그램(피나스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 258502,
    standard_code: '8806230064513',
    generic_name_code: '159002ATB',
    korean_product_name: '다모테리드정1밀리그램(피나스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 258503,
    standard_code: '8806230064520',
    generic_name_code: '159002ATB',
    korean_product_name: '다모테리드정1밀리그램(피나스테리드)',
    product_total_quantity: '90',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 260310,
    standard_code: '8806555006311',
    generic_name_code: '159002ATB',
    korean_product_name: '프로페시아정1밀리그램(피나스테리드)',
    product_total_quantity: '28',
    dosage_form: '정',
    packaging_type: '팩',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 260311,
    standard_code: '8806555006328',
    generic_name_code: '159002ATB',
    korean_product_name: '프로페시아정1밀리그램(피나스테리드)',
    product_total_quantity: '84',
    dosage_form: '정',
    packaging_type: '팩',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 260674,
    standard_code: '8800731001312',
    generic_name_code: '159002ATB',
    korean_product_name: '프로페시아정1밀리그램(피나스테리드)',
    product_total_quantity: '28',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 260675,
    standard_code: '8800731001329',
    generic_name_code: '159002ATB',
    korean_product_name: '프로페시아정1밀리그램(피나스테리드)',
    product_total_quantity: '84',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 263450,
    standard_code: '8806443061910',
    generic_name_code: '159002ATB',
    korean_product_name: '유나시아정(피나스테리드)',
    product_total_quantity: '7',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 263451,
    standard_code: '8806443061927',
    generic_name_code: '159002ATB',
    korean_product_name: '유나시아정(피나스테리드)',
    product_total_quantity: '28',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 263452,
    standard_code: '8806443061934',
    generic_name_code: '159002ATB',
    korean_product_name: '유나시아정(피나스테리드)',
    product_total_quantity: '28',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 265992,
    standard_code: '8806655083915',
    generic_name_code: '159002ATB',
    korean_product_name: '그로페시아정1밀리그램(피나스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 265993,
    standard_code: '8806655083922',
    generic_name_code: '159002ATB',
    korean_product_name: '그로페시아정1밀리그램(피나스테리드)',
    product_total_quantity: '90',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 274352,
    standard_code: '8806491036410',
    generic_name_code: '159002ATB',
    korean_product_name: '프로스테리드정1mg(피나스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 279076,
    standard_code: '8806435034410',
    generic_name_code: '159002ATB',
    korean_product_name: '피나테드정1밀리그램(피나스테리드)',
    product_total_quantity: '10',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 279077,
    standard_code: '8806435034427',
    generic_name_code: '159002ATB',
    korean_product_name: '피나테드정1밀리그램(피나스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 283567,
    standard_code: '8806556032319',
    generic_name_code: '159002ATB',
    korean_product_name: '헤어그로정1밀리그램(피나스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 283568,
    standard_code: '8806556032326',
    generic_name_code: '159002ATB',
    korean_product_name: '헤어그로정1밀리그램(피나스테리드)',
    product_total_quantity: '15',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 283569,
    standard_code: '8806556032333',
    generic_name_code: '159002ATB',
    korean_product_name: '헤어그로정1밀리그램(피나스테리드)',
    product_total_quantity: '90',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 286207,
    standard_code: '8806516008712',
    generic_name_code: '159002ATB',
    korean_product_name: '헤어게인정(피나스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 287217,
    standard_code: '8806420003513',
    generic_name_code: '159002ATB',
    korean_product_name: '미노페시아정1밀리그람(피나스테리드)',
    product_total_quantity: '28',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 287218,
    standard_code: '8806420003520',
    generic_name_code: '159002ATB',
    korean_product_name: '미노페시아정1밀리그람(피나스테리드)',
    product_total_quantity: '84',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 289416,
    standard_code: '8806845015313',
    generic_name_code: '159002ATB',
    korean_product_name: '피나세타정1밀리그램(피나스테리드)',
    product_total_quantity: '30',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 289417,
    standard_code: '8806845015320',
    generic_name_code: '159002ATB',
    korean_product_name: '피나세타정1밀리그램(피나스테리드)',
    product_total_quantity: '90',
    dosage_form: '정',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '피나스테리드',
  },
  {
    id: 16175,
    standard_code: '8806573041417',
    generic_name_code: '178701ACS',
    korean_product_name: '트레논연질캡슐(이소트레티노인)',
    product_total_quantity: '60',
    dosage_form: '캡슐',
    packaging_type: '병',
    special_general_type: '전문의약품',
    generic_name: '이소트레티노인',
  },
  {
    id: 16176,
    standard_code: '8806573041424',
    generic_name_code: '178701ACS',
    korean_product_name: '트레논연질캡슐(이소트레티노인)',
    product_total_quantity: '100',
    dosage_form: '캡슐',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '이소트레티노인',
  },
  {
    id: 22625,
    standard_code: '8806228022112',
    generic_name_code: '178701ACS',
    korean_product_name: '로이탄연질캡슐(이소트레티노인)',
    product_total_quantity: '100',
    dosage_form: '캡슐',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '이소트레티노인',
  },
  {
    id: 22626,
    standard_code: '8806228022129',
    generic_name_code: '178701ACS',
    korean_product_name: '로이탄연질캡슐(이소트레티노인)',
    product_total_quantity: '200',
    dosage_form: '캡슐',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '이소트레티노인',
  },
  {
    id: 22627,
    standard_code: '8806228022136',
    generic_name_code: '178701ACS',
    korean_product_name: '로이탄연질캡슐(이소트레티노인)',
    product_total_quantity: '30',
    dosage_form: '캡슐',
    packaging_type: '병',
    special_general_type: '전문의약품',
    generic_name: '이소트레티노인',
  },
  {
    id: 22628,
    standard_code: '8806228022143',
    generic_name_code: '178701ACS',
    korean_product_name: '로이탄연질캡슐(이소트레티노인)',
    product_total_quantity: '300',
    dosage_form: '캡슐',
    packaging_type: '병',
    special_general_type: '전문의약품',
    generic_name: '이소트레티노인',
  },
  {
    id: 23512,
    standard_code: '8806460002125',
    generic_name_code: '178701ACS',
    korean_product_name: '니메겐연질캡슐(이소트레티노인)',
    product_total_quantity: '50',
    dosage_form: '캡슐',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '이소트레티노인',
  },
  {
    id: 23513,
    standard_code: '8806460002132',
    generic_name_code: '178701ACS',
    korean_product_name: '니메겐연질캡슐(이소트레티노인)',
    product_total_quantity: '50',
    dosage_form: '캡슐',
    packaging_type: '병',
    special_general_type: '전문의약품',
    generic_name: '이소트레티노인',
  },
  {
    id: 23515,
    standard_code: '8806460002156',
    generic_name_code: '178701ACS',
    korean_product_name: '니메겐연질캡슐(이소트레티노인)',
    product_total_quantity: '60',
    dosage_form: '캡슐',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '이소트레티노인',
  },
  {
    id: 23516,
    standard_code: '8806460002163',
    generic_name_code: '178701ACS',
    korean_product_name: '니메겐연질캡슐(이소트레티노인)',
    product_total_quantity: '90',
    dosage_form: '캡슐',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '이소트레티노인',
  },
  {
    id: 27525,
    standard_code: '8806431041511',
    generic_name_code: '178701ACS',
    korean_product_name: '레아큐연질캡슐(이소트레티노인)',
    product_total_quantity: '100',
    dosage_form: '캡슐',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '이소트레티노인',
  },
  {
    id: 41020,
    standard_code: '8806890000319',
    generic_name_code: '178701ACS',
    korean_product_name: '로아크연질캡슐(이소트레티노인)',
    product_total_quantity: '30',
    dosage_form: '캡슐',
    packaging_type: '병',
    special_general_type: '전문의약품',
    generic_name: '이소트레티노인',
  },
  {
    id: 41021,
    standard_code: '8806890000326',
    generic_name_code: '178701ACS',
    korean_product_name: '로아크연질캡슐(이소트레티노인)',
    product_total_quantity: '100',
    dosage_form: '캡슐',
    packaging_type: '병',
    special_general_type: '전문의약품',
    generic_name: '이소트레티노인',
  },
  {
    id: 41022,
    standard_code: '8806890000333',
    generic_name_code: '178701ACS',
    korean_product_name: '로아크연질캡슐(이소트레티노인)',
    product_total_quantity: '100',
    dosage_form: '캡슐',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '이소트레티노인',
  },
  {
    id: 46867,
    standard_code: '8806696044418',
    generic_name_code: '178701ACS',
    korean_product_name: '오피큐탄연질캡슐(이소트레티노인)',
    product_total_quantity: '30',
    dosage_form: '캡슐',
    packaging_type: '병',
    special_general_type: '전문의약품',
    generic_name: '이소트레티노인',
  },
  {
    id: 46868,
    standard_code: '8806696044425',
    generic_name_code: '178701ACS',
    korean_product_name: '오피큐탄연질캡슐(이소트레티노인)',
    product_total_quantity: '100',
    dosage_form: '캡슐',
    packaging_type: '병',
    special_general_type: '전문의약품',
    generic_name: '이소트레티노인',
  },
  {
    id: 46869,
    standard_code: '8806696044432',
    generic_name_code: '178701ACS',
    korean_product_name: '오피큐탄연질캡슐(이소트레티노인)',
    product_total_quantity: '30',
    dosage_form: '캡슐',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '이소트레티노인',
  },
  {
    id: 46870,
    standard_code: '8806696044449',
    generic_name_code: '178701ACS',
    korean_product_name: '오피큐탄연질캡슐(이소트레티노인)',
    product_total_quantity: '100',
    dosage_form: '캡슐',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '이소트레티노인',
  },
  {
    id: 71716,
    standard_code: '8806985009715',
    generic_name_code: '178701ACS',
    korean_product_name: '이소레인연질캡슐(이소트레티노인)(수출용)',
    product_total_quantity: '30',
    dosage_form: '캡슐',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '이소트레티노인',
  },
  {
    id: 75020,
    standard_code: '8806701039118',
    generic_name_code: '178701ACS',
    korean_product_name: '우리큐탄연질캡슐(이소트레티노인)',
    product_total_quantity: '100',
    dosage_form: '캡슐',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '이소트레티노인',
  },
  {
    id: 108751,
    standard_code: '8806428011411',
    generic_name_code: '178701ACS',
    korean_product_name: '이소트렌연질캡슐(이소트레티노인)',
    product_total_quantity: '30',
    dosage_form: '캡슐',
    packaging_type: '병',
    special_general_type: '전문의약품',
    generic_name: '이소트레티노인',
  },
  {
    id: 108752,
    standard_code: '8806428011428',
    generic_name_code: '178701ACS',
    korean_product_name: '이소트렌연질캡슐(이소트레티노인)',
    product_total_quantity: '100',
    dosage_form: '캡슐',
    packaging_type: '병',
    special_general_type: '전문의약품',
    generic_name: '이소트레티노인',
  },
  {
    id: 108755,
    standard_code: '8806428011459',
    generic_name_code: '178701ACS',
    korean_product_name: '이소트렌연질캡슐(이소트레티노인)',
    product_total_quantity: '30',
    dosage_form: '캡슐',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '이소트레티노인',
  },
  {
    id: 134628,
    standard_code: '8806459001610',
    generic_name_code: '178701ACS',
    korean_product_name: '뉴티논연질캡슐(이소트레티노인)',
    product_total_quantity: '100',
    dosage_form: '캡슐',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '이소트레티노인',
  },
  {
    id: 167932,
    standard_code: '8806649011511',
    generic_name_code: '178701ACS',
    korean_product_name: '이소탐연질캡슐(이소트레티노인)',
    product_total_quantity: '30',
    dosage_form: '캡슐',
    packaging_type: '병',
    special_general_type: '전문의약품',
    generic_name: '이소트레티노인',
  },
  {
    id: 167933,
    standard_code: '8806649011528',
    generic_name_code: '178701ACS',
    korean_product_name: '이소탐연질캡슐(이소트레티노인)',
    product_total_quantity: '300',
    dosage_form: '캡슐',
    packaging_type: '병',
    special_general_type: '전문의약품',
    generic_name: '이소트레티노인',
  },
  {
    id: 178313,
    standard_code: '8806540012419',
    generic_name_code: '178701ACS',
    korean_product_name: '아키놀연질캡슐(이소트레티노인)',
    product_total_quantity: '100',
    dosage_form: '캡슐',
    packaging_type: '병',
    special_general_type: '전문의약품',
    generic_name: '이소트레티노인',
  },
  {
    id: 178314,
    standard_code: '8806540012426',
    generic_name_code: '178701ACS',
    korean_product_name: '아키놀연질캡슐(이소트레티노인)',
    product_total_quantity: '30',
    dosage_form: '캡슐',
    packaging_type: '병',
    special_general_type: '전문의약품',
    generic_name: '이소트레티노인',
  },
  {
    id: 178316,
    standard_code: '8806540012440',
    generic_name_code: '178701ACS',
    korean_product_name: '아키놀연질캡슐(이소트레티노인)',
    product_total_quantity: '100',
    dosage_form: '캡슐',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '이소트레티노인',
  },
  {
    id: 197876,
    standard_code: '8806607002612',
    generic_name_code: '178701ACS',
    korean_product_name: '레씨범연질캡슐(이소트레티노인)',
    product_total_quantity: '30',
    dosage_form: '캡슐',
    packaging_type: '병',
    special_general_type: '전문의약품',
    generic_name: '이소트레티노인',
  },
  {
    id: 197877,
    standard_code: '8806607002629',
    generic_name_code: '178701ACS',
    korean_product_name: '레씨범연질캡슐(이소트레티노인)',
    product_total_quantity: '200',
    dosage_form: '캡슐',
    packaging_type: '병',
    special_general_type: '전문의약품',
    generic_name: '이소트레티노인',
  },
  {
    id: 211942,
    standard_code: '8806980025819',
    generic_name_code: '178701ACS',
    korean_product_name: '트레인연질캡슐(이소트레티노인)',
    product_total_quantity: '30',
    dosage_form: '캡슐',
    packaging_type: '병',
    special_general_type: '전문의약품',
    generic_name: '이소트레티노인',
  },
  {
    id: 211943,
    standard_code: '8806980025826',
    generic_name_code: '178701ACS',
    korean_product_name: '트레인연질캡슐(이소트레티노인)',
    product_total_quantity: '100',
    dosage_form: '캡슐',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '이소트레티노인',
  },
  {
    id: 213443,
    standard_code: '8806441022616',
    generic_name_code: '178701ACS',
    korean_product_name: '제로큐탄연질캡슐(이소트레티노인)',
    product_total_quantity: '100',
    dosage_form: '캡슐',
    packaging_type: '병',
    special_general_type: '전문의약품',
    generic_name: '이소트레티노인',
  },
  {
    id: 213444,
    standard_code: '8806441022623',
    generic_name_code: '178701ACS',
    korean_product_name: '제로큐탄연질캡슐(이소트레티노인)',
    product_total_quantity: '3',
    dosage_form: '캡슐',
    packaging_type: '병',
    special_general_type: '전문의약품',
    generic_name: '이소트레티노인',
  },
  {
    id: 213445,
    standard_code: '8806441022630',
    generic_name_code: '178701ACS',
    korean_product_name: '제로큐탄연질캡슐(이소트레티노인)',
    product_total_quantity: '30',
    dosage_form: '캡슐',
    packaging_type: '병',
    special_general_type: '전문의약품',
    generic_name: '이소트레티노인',
  },
  {
    id: 213446,
    standard_code: '8806441022647',
    generic_name_code: '178701ACS',
    korean_product_name: '제로큐탄연질캡슐(이소트레티노인)',
    product_total_quantity: '100',
    dosage_form: '캡슐',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '이소트레티노인',
  },
  {
    id: 223311,
    standard_code: '8800570014313',
    generic_name_code: '178701ACS',
    korean_product_name: '모아큐탄연질캡슐(이소트레티노인)',
    product_total_quantity: '60',
    dosage_form: '캡슐',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '이소트레티노인',
  },
  {
    id: 223312,
    standard_code: '8800570014320',
    generic_name_code: '178701ACS',
    korean_product_name: '모아큐탄연질캡슐(이소트레티노인)',
    product_total_quantity: '100',
    dosage_form: '캡슐',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '이소트레티노인',
  },
  {
    id: 223358,
    standard_code: '8800570015815',
    generic_name_code: '178701ACS',
    korean_product_name: '핀플연질캡슐(이소트레티노인)',
    product_total_quantity: '30',
    dosage_form: '캡슐',
    packaging_type: '병',
    special_general_type: '전문의약품',
    generic_name: '이소트레티노인',
  },
  {
    id: 223359,
    standard_code: '8800570015822',
    generic_name_code: '178701ACS',
    korean_product_name: '핀플연질캡슐(이소트레티노인)',
    product_total_quantity: '100',
    dosage_form: '캡슐',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '이소트레티노인',
  },
  {
    id: 240249,
    standard_code: '8806518025014',
    generic_name_code: '178701ACS',
    korean_product_name: '이소틴연질캡슐',
    product_total_quantity: '30',
    dosage_form: '캡슐',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '이소트레티노인',
  },
  {
    id: 243650,
    standard_code: '8806703018319',
    generic_name_code: '178701ACS',
    korean_product_name: '트레틴연질캡슐10mg(이소트레티노인)',
    product_total_quantity: '30',
    dosage_form: '캡슐',
    packaging_type: '병',
    special_general_type: '전문의약품',
    generic_name: '이소트레티노인',
  },
  {
    id: 243651,
    standard_code: '8806703018326',
    generic_name_code: '178701ACS',
    korean_product_name: '트레틴연질캡슐10mg(이소트레티노인)',
    product_total_quantity: '100',
    dosage_form: '캡슐',
    packaging_type: '병',
    special_general_type: '전문의약품',
    generic_name: '이소트레티노인',
  },
  {
    id: 243652,
    standard_code: '8806703018333',
    generic_name_code: '178701ACS',
    korean_product_name: '트레틴연질캅셀10mg(이소트레티노인)',
    product_total_quantity: '100',
    dosage_form: '캡슐',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '이소트레티노인',
  },
  {
    id: 278658,
    standard_code: '8806435021915',
    generic_name_code: '178701ACS',
    korean_product_name: '이소티논연질캡슐10밀리그램(이소트레티노인)',
    product_total_quantity: '10',
    dosage_form: '캡슐',
    packaging_type: '병',
    special_general_type: '전문의약품',
    generic_name: '이소트레티노인',
  },
  {
    id: 278659,
    standard_code: '8806435021922',
    generic_name_code: '178701ACS',
    korean_product_name: '이소티논연질캡슐10밀리그램(이소트레티노인)',
    product_total_quantity: '30',
    dosage_form: '캡슐',
    packaging_type: '병',
    special_general_type: '전문의약품',
    generic_name: '이소트레티노인',
  },
  {
    id: 278660,
    standard_code: '8806435021939',
    generic_name_code: '178701ACS',
    korean_product_name: '이소티논연질캡슐10밀리그램(이소트레티노인)',
    product_total_quantity: '100',
    dosage_form: '캡슐',
    packaging_type: '병',
    special_general_type: '전문의약품',
    generic_name: '이소트레티노인',
  },
  {
    id: 278661,
    standard_code: '8806435021946',
    generic_name_code: '178701ACS',
    korean_product_name: '이소티논연질캡슐10밀리그램(이소트레티노인)',
    product_total_quantity: '300',
    dosage_form: '캡슐',
    packaging_type: '병',
    special_general_type: '전문의약품',
    generic_name: '이소트레티노인',
  },
  {
    id: 278662,
    standard_code: '8806435021953',
    generic_name_code: '178701ACS',
    korean_product_name: '이소티논연질캡슐10밀리그램(이소트레티노인)',
    product_total_quantity: '100',
    dosage_form: '캡슐',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '이소트레티노인',
  },
  {
    id: 3424,
    standard_code: '8806581071017',
    generic_name_code: '458801ACS',
    korean_product_name: '두타윈연질캡슐0.5밀리그램(두타스테리드)',
    product_total_quantity: '30',
    dosage_form: '캡슐',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '두타스테리드',
  },
  {
    id: 5795,
    standard_code: '8806500007813',
    generic_name_code: '458801ACS',
    korean_product_name: '아보다트연질캡슐0.5밀리그램(두타스테리드)',
    product_total_quantity: '30',
    dosage_form: '캡슐',
    packaging_type: 'Foil',
    special_general_type: '전문의약품',
    generic_name: '두타스테리드',
  },
  {
    id: 5796,
    standard_code: '8806500007820',
    generic_name_code: '458801ACS',
    korean_product_name: '아보다트연질캡슐0.5밀리그램(두타스테리드)',
    product_total_quantity: '90',
    dosage_form: '캡슐',
    packaging_type: 'Foil',
    special_general_type: '전문의약품',
    generic_name: '두타스테리드',
  },
  {
    id: 14642,
    standard_code: '8806416061916',
    generic_name_code: '458801ACS',
    korean_product_name: '두타겟연질캡슐0.5밀리그램(두타스테리드)',
    product_total_quantity: '30',
    dosage_form: '캡슐',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '두타스테리드',
  },
  {
    id: 14643,
    standard_code: '8806416061923',
    generic_name_code: '458801ACS',
    korean_product_name: '두타겟연질캡슐0.5밀리그램(두타스테리드)',
    product_total_quantity: '15',
    dosage_form: '캡슐',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '두타스테리드',
  },
  {
    id: 14644,
    standard_code: '8806416061930',
    generic_name_code: '458801ACS',
    korean_product_name: '두타겟연질캡슐0.5밀리그램(두타스테리드)',
    product_total_quantity: '90',
    dosage_form: '캡슐',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '두타스테리드',
  },
  {
    id: 14645,
    standard_code: '8806416061947',
    generic_name_code: '458801ACS',
    korean_product_name: '두타겟연질캡슐0.5밀리그램(두타스테리드)',
    product_total_quantity: '10',
    dosage_form: '캡슐',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '두타스테리드',
  },
  {
    id: 14646,
    standard_code: '8806416061954',
    generic_name_code: '458801ACS',
    korean_product_name: '두타겟연질캡슐0.5밀리그램(두타스테리드)',
    product_total_quantity: '60',
    dosage_form: '캡슐',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '두타스테리드',
  },
  {
    id: 17183,
    standard_code: '8806573088818',
    generic_name_code: '458801ACS',
    korean_product_name: '동구두타리드연질캡슐(두타스테리드)',
    product_total_quantity: '30',
    dosage_form: '캡슐',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '두타스테리드',
  },
  {
    id: 23304,
    standard_code: '8806228047917',
    generic_name_code: '458801ACS',
    korean_product_name: '두타엠연질캡슐0.5밀리그램(두타스테리드)',
    product_total_quantity: '30',
    dosage_form: '캡슐',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '두타스테리드',
  },
  {
    id: 23379,
    standard_code: '8806228050412',
    generic_name_code: '458801ACS',
    korean_product_name: '아보엠연질캡슐0.5밀리그램(두타스테리드)',
    product_total_quantity: '30',
    dosage_form: '캡슐',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '두타스테리드',
  },
  {
    id: 32996,
    standard_code: '8806582038118',
    generic_name_code: '458801ACS',
    korean_product_name: '타테린연질캡슐0.5밀리그램(두타스테리드)',
    product_total_quantity: '30',
    dosage_form: '캡슐',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '두타스테리드',
  },
  {
    id: 41556,
    standard_code: '8806890017218',
    generic_name_code: '458801ACS',
    korean_product_name: '두리스연질캡슐0.5밀리그램(두타스테리드)',
    product_total_quantity: '30',
    dosage_form: '캡슐',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '두타스테리드',
  },
  {
    id: 43754,
    standard_code: '8806486042419',
    generic_name_code: '458801ACS',
    korean_product_name: '씨엠두타연질캡슐0.5mg(두타스테리드)',
    product_total_quantity: '30',
    dosage_form: '캡슐',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '두타스테리드',
  },
  {
    id: 44430,
    standard_code: '8806695020215',
    generic_name_code: '458801ACS',
    korean_product_name: '유로다트연질캡슐0.5mg(두타스테리드)',
    product_total_quantity: '30',
    dosage_form: '캡슐',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '두타스테리드',
  },
  {
    id: 57454,
    standard_code: '8806445018110',
    generic_name_code: '458801ACS',
    korean_product_name: '유힐릭스연질캡슐0.5밀리그램(두타스테리드)',
    product_total_quantity: '30',
    dosage_form: '캡슐',
    packaging_type: '병',
    special_general_type: '전문의약품',
    generic_name: '두타스테리드',
  },
  {
    id: 57455,
    standard_code: '8806445018127',
    generic_name_code: '458801ACS',
    korean_product_name: '유힐릭스연질캡슐0.5밀리그램(두타스테리드)',
    product_total_quantity: '30',
    dosage_form: '캡슐',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '두타스테리드',
  },
  {
    id: 57456,
    standard_code: '8806445018134',
    generic_name_code: '458801ACS',
    korean_product_name: '유힐릭스연질캡슐0.5밀리그램(두타스테리드)',
    product_total_quantity: '1000',
    dosage_form: '캡슐',
    packaging_type: '병',
    special_general_type: '전문의약품',
    generic_name: '두타스테리드',
  },
  {
    id: 57457,
    standard_code: '8806445018141',
    generic_name_code: '458801ACS',
    korean_product_name: '유힐릭스연질캡슐0.5밀리그램(두타스테리드)',
    product_total_quantity: '10',
    dosage_form: '캡슐',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '두타스테리드',
  },
  {
    id: 57458,
    standard_code: '8806445018158',
    generic_name_code: '458801ACS',
    korean_product_name: '유힐릭스연질캡슐0.5밀리그램(두타스테리드)',
    product_total_quantity: '15',
    dosage_form: '캡슐',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '두타스테리드',
  },
  {
    id: 57459,
    standard_code: '8806445018165',
    generic_name_code: '458801ACS',
    korean_product_name: '유힐릭스연질캡슐0.5밀리그램(두타스테리드)',
    product_total_quantity: '30',
    dosage_form: '캡슐',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '두타스테리드',
  },
  {
    id: 59283,
    standard_code: '8806421040814',
    generic_name_code: '458801ACS',
    korean_product_name: '아보테리드연질캡슐0.5밀리그램(두타스테리드)',
    product_total_quantity: '30',
    dosage_form: '캡슐',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '두타스테리드',
  },
  {
    id: 67635,
    standard_code: '8806433059316',
    generic_name_code: '458801ACS',
    korean_product_name: '두테스몰연질캡슐0.5밀리그램(두타스테리드)',
    product_total_quantity: '30',
    dosage_form: '캡슐',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '두타스테리드',
  },
  {
    id: 67636,
    standard_code: '8806433059323',
    generic_name_code: '458801ACS',
    korean_product_name: '두테스몰연질캡슐0.5밀리그램(두타스테리드)',
    product_total_quantity: '10',
    dosage_form: '캡슐',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '두타스테리드',
  },
  {
    id: 67637,
    standard_code: '8806433059330',
    generic_name_code: '458801ACS',
    korean_product_name: '두테스몰연질캡슐0.5밀리그램(두타스테리드)',
    product_total_quantity: '100',
    dosage_form: '캡슐',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '두타스테리드',
  },
  {
    id: 67638,
    standard_code: '8806433059347',
    generic_name_code: '458801ACS',
    korean_product_name: '두테스몰연질캡슐0.5밀리그램(두타스테리드)',
    product_total_quantity: '15',
    dosage_form: '캡슐',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '두타스테리드',
  },
  {
    id: 68829,
    standard_code: '8800533006812',
    generic_name_code: '458801ACS',
    korean_product_name: '중헌두타스테리드연질캡슐0.5밀리그램',
    product_total_quantity: '30',
    dosage_form: '캡슐',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '두타스테리드',
  },
  {
    id: 75183,
    standard_code: '8806701045119',
    generic_name_code: '458801ACS',
    korean_product_name: '팜젠두타스테리드연질캡슐',
    product_total_quantity: '30',
    dosage_form: '캡슐',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '두타스테리드',
  },
  {
    id: 87979,
    standard_code: '8806599010619',
    generic_name_code: '458801ACS',
    korean_product_name: '아보두타연질캡슐0.5밀리그램(두타스테리드)',
    product_total_quantity: '30',
    dosage_form: '캡슐',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '두타스테리드',
  },
  {
    id: 94506,
    standard_code: '8806288007715',
    generic_name_code: '458801ACS',
    korean_product_name: '모플러스연질캡슐0.5밀리그램(두타스테리드)',
    product_total_quantity: '30',
    dosage_form: '캡슐',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '두타스테리드',
  },
  {
    id: 97104,
    standard_code: '8806706087916',
    generic_name_code: '458801ACS',
    korean_product_name: '휴다트연질캡슐0.5밀리그램(두타스테리드)',
    product_total_quantity: '30',
    dosage_form: '캡슐',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '두타스테리드',
  },
  {
    id: 97105,
    standard_code: '8806706087923',
    generic_name_code: '458801ACS',
    korean_product_name: '휴다트연질캡슐0.5밀리그램(두타스테리드)',
    product_total_quantity: '90',
    dosage_form: '캡슐',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '두타스테리드',
  },
  {
    id: 98464,
    standard_code: '8800730010513',
    generic_name_code: '458801ACS',
    korean_product_name: '두모보연질캡슐0.5밀리그램(두타스테리드)',
    product_total_quantity: '30',
    dosage_form: '캡슐',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '두타스테리드',
  },
  {
    id: 101723,
    standard_code: '8806481036017',
    generic_name_code: '458801ACS',
    korean_product_name: '두로칸연질캡슐0.5밀리그램(두타스테리드)',
    product_total_quantity: '30',
    dosage_form: '캡슐',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '두타스테리드',
  },
  {
    id: 101724,
    standard_code: '8806481036024',
    generic_name_code: '458801ACS',
    korean_product_name: '두로칸연질캡슐0.5밀리그램(두타스테리드)',
    product_total_quantity: '10',
    dosage_form: '캡슐',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '두타스테리드',
  },
  {
    id: 110363,
    standard_code: '8806418058013',
    generic_name_code: '458801ACS',
    korean_product_name: '두아보연질캡슐0.5밀리그램(두타스테리드)',
    product_total_quantity: '30',
    dosage_form: '캡슐',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '두타스테리드',
  },
  {
    id: 110364,
    standard_code: '8806418058020',
    generic_name_code: '458801ACS',
    korean_product_name: '두아보연질캡슐0.5밀리그램(두타스테리드)',
    product_total_quantity: '15',
    dosage_form: '캡슐',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '두타스테리드',
  },
  {
    id: 113334,
    standard_code: '8806437039819',
    generic_name_code: '458801ACS',
    korean_product_name: '아보스티드연질캡슐0.5밀리그램(두타스테리드)',
    product_total_quantity: '30',
    dosage_form: '캡슐',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '두타스테리드',
  },
  {
    id: 113335,
    standard_code: '8806437039826',
    generic_name_code: '458801ACS',
    korean_product_name: '아보스티드연질캡슐0.5밀리그램(두타스테리드)',
    product_total_quantity: '30',
    dosage_form: '캡슐',
    packaging_type: '병',
    special_general_type: '전문의약품',
    generic_name: '두타스테리드',
  },
  {
    id: 113336,
    standard_code: '8806437039833',
    generic_name_code: '458801ACS',
    korean_product_name: '아보스티드연질캡슐0.5밀리그램(두타스테리드)',
    product_total_quantity: '15',
    dosage_form: '캡슐',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '두타스테리드',
  },
  {
    id: 113337,
    standard_code: '8806437039840',
    generic_name_code: '458801ACS',
    korean_product_name: '아보스티드연질캡슐0.5밀리그램(두타스테리드)',
    product_total_quantity: '10',
    dosage_form: '캡슐',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '두타스테리드',
  },
  {
    id: 125326,
    standard_code: '8806940009811',
    generic_name_code: '458801ACS',
    korean_product_name: '대웅바이오두타스테리드연질캡슐0.5밀리그램',
    product_total_quantity: '30',
    dosage_form: '캡슐',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '두타스테리드',
  },
  {
    id: 127374,
    standard_code: '8806718052711',
    generic_name_code: '458801ACS',
    korean_product_name: '두타텍트연질캡슐0.5밀리그램(두타스테리드)',
    product_total_quantity: '30',
    dosage_form: '캡슐',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '두타스테리드',
  },
  {
    id: 127375,
    standard_code: '8806718052728',
    generic_name_code: '458801ACS',
    korean_product_name: '두타텍트연질캡슐0.5밀리그램(두타스테리드)',
    product_total_quantity: '10',
    dosage_form: '캡슐',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '두타스테리드',
  },
  {
    id: 127376,
    standard_code: '8806718052735',
    generic_name_code: '458801ACS',
    korean_product_name: '두타텍트연질캡슐0.5밀리그램(두타스테리드)',
    product_total_quantity: '15',
    dosage_form: '캡슐',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '두타스테리드',
  },
  {
    id: 135714,
    standard_code: '8806459059314',
    generic_name_code: '458801ACS',
    korean_product_name: '두테론연질캡슐0.5밀리그램(두타스테리드)',
    product_total_quantity: '15',
    dosage_form: '캡슐',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '두타스테리드',
  },
  {
    id: 135715,
    standard_code: '8806459059321',
    generic_name_code: '458801ACS',
    korean_product_name: '두테론연질캡슐0.5밀리그램(두타스테리드)',
    product_total_quantity: '30',
    dosage_form: '캡슐',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '두타스테리드',
  },
  {
    id: 135716,
    standard_code: '8806459059338',
    generic_name_code: '458801ACS',
    korean_product_name: '두테론연질캡슐0.5밀리그램(두타스테리드)',
    product_total_quantity: '10',
    dosage_form: '캡슐',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '두타스테리드',
  },
  {
    id: 137157,
    standard_code: '8806534033314',
    generic_name_code: '458801ACS',
    korean_product_name: '두타드연질캡슐0.5밀리그램(두타스테리드)',
    product_total_quantity: '10',
    dosage_form: '캡슐',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '두타스테리드',
  },
  {
    id: 137158,
    standard_code: '8806534033321',
    generic_name_code: '458801ACS',
    korean_product_name: '두타드연질캡슐0.5밀리그램(두타스테리드)',
    product_total_quantity: '30',
    dosage_form: '캡슐',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '두타스테리드',
  },
  {
    id: 137159,
    standard_code: '8806534033338',
    generic_name_code: '458801ACS',
    korean_product_name: '두타드연질캡슐0.5밀리그램(두타스테리드)',
    product_total_quantity: '15',
    dosage_form: '캡슐',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '두타스테리드',
  },
  {
    id: 140521,
    standard_code: '8806425063611',
    generic_name_code: '458801ACS',
    korean_product_name: '두타반연질캡슐(두타스테리드)',
    product_total_quantity: '30',
    dosage_form: '캡슐',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '두타스테리드',
  },
  {
    id: 146220,
    standard_code: '8806415027210',
    generic_name_code: '458801ACS',
    korean_product_name: '두타온연질캡슐0.5밀리그램(두타스테리드)',
    product_total_quantity: '30',
    dosage_form: '캡슐',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '두타스테리드',
  },
  {
    id: 164388,
    standard_code: '8806478043516',
    generic_name_code: '458801ACS',
    korean_product_name: '아두타연질캡슐0.5밀리그램(두타스테리드)',
    product_total_quantity: '30',
    dosage_form: '캡슐',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '두타스테리드',
  },
  {
    id: 165780,
    standard_code: '8806512044615',
    generic_name_code: '458801ACS',
    korean_product_name: '삼천당두타스테리드연질캡슐0.5밀리그램',
    product_total_quantity: '30',
    dosage_form: '캡슐',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '두타스테리드',
  },
  {
    id: 178706,
    standard_code: '8806540038310',
    generic_name_code: '458801ACS',
    korean_product_name: '아나딘연질캡슐0.5밀리그램(두타스테리드)',
    product_total_quantity: '100',
    dosage_form: '캡슐',
    packaging_type: '병',
    special_general_type: '전문의약품',
    generic_name: '두타스테리드',
  },
  {
    id: 178707,
    standard_code: '8806540038327',
    generic_name_code: '458801ACS',
    korean_product_name: '아나딘연질캡슐0.5밀리그램(두타스테리드)',
    product_total_quantity: '30',
    dosage_form: '캡슐',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '두타스테리드',
  },
  {
    id: 182426,
    standard_code: '8806560042014',
    generic_name_code: '458801ACS',
    korean_product_name: '두타락연질캡슐0.5밀리그램(두타스테리드)',
    product_total_quantity: '30',
    dosage_form: '캡슐',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '두타스테리드',
  },
  {
    id: 202032,
    standard_code: '8806586032211',
    generic_name_code: '458801ACS',
    korean_product_name: '두타스카연질캡슐0.5밀리그램(두타스테리드)',
    product_total_quantity: '30',
    dosage_form: '캡슐',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '두타스테리드',
  },
  {
    id: 202033,
    standard_code: '8806586032228',
    generic_name_code: '458801ACS',
    korean_product_name: '두타스카연질캡슐0.5밀리그램(두타스테리드)',
    product_total_quantity: '30',
    dosage_form: '캡슐',
    packaging_type: '병',
    special_general_type: '전문의약품',
    generic_name: '두타스테리드',
  },
  {
    id: 202034,
    standard_code: '8806586032235',
    generic_name_code: '458801ACS',
    korean_product_name: '두타스카연질캡슐0.5밀리그램(두타스테리드)',
    product_total_quantity: '1000',
    dosage_form: '캡슐',
    packaging_type: '병',
    special_general_type: '전문의약품',
    generic_name: '두타스테리드',
  },
  {
    id: 208090,
    standard_code: '8806417037910',
    generic_name_code: '458801ACS',
    korean_product_name: '아보스타연질캡슐0.5밀리그램(두타스테리드)',
    product_total_quantity: '30',
    dosage_form: '캡슐',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '두타스테리드',
  },
  {
    id: 213330,
    standard_code: '8806441018411',
    generic_name_code: '458801ACS',
    korean_product_name: '네오다트연질캡슐0.5밀리그램(두타스테리드)',
    product_total_quantity: '30',
    dosage_form: '캡슐',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '두타스테리드',
  },
  {
    id: 217620,
    standard_code: '8806454043912',
    generic_name_code: '458801ACS',
    korean_product_name: '두타젠연질캡슐0.5밀리그램(두타스테리드)',
    product_total_quantity: '30',
    dosage_form: '캡슐',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '두타스테리드',
  },
  {
    id: 230896,
    standard_code: '8806717049811',
    generic_name_code: '458801ACS',
    korean_product_name: '프로다트연질캡슐0.5밀리그램(두타스테리드)',
    product_total_quantity: '30',
    dosage_form: '캡슐',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '두타스테리드',
  },
  {
    id: 233027,
    standard_code: '8806269006218',
    generic_name_code: '458801ACS',
    korean_product_name: '자이가드연질캡슐0.5밀리그램(두타스테리드)',
    product_total_quantity: '30',
    dosage_form: '캡슐',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '두타스테리드',
  },
  {
    id: 234802,
    standard_code: '8806734001618',
    generic_name_code: '458801ACS',
    korean_product_name: '두타라이드연질캡슐0.5밀리그램(두타스테리드)',
    product_total_quantity: '30',
    dosage_form: '캡슐',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '두타스테리드',
  },
  {
    id: 241181,
    standard_code: '8806707018216',
    generic_name_code: '458801ACS',
    korean_product_name: '두타렉스연질캡슐0.5밀리그램(두타스테리드)',
    product_total_quantity: '30',
    dosage_form: '캡슐',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '두타스테리드',
  },
  {
    id: 241182,
    standard_code: '8806707018223',
    generic_name_code: '458801ACS',
    korean_product_name: '두타렉스연질캡슐0.5밀리그램(두타스테리드)',
    product_total_quantity: '10',
    dosage_form: '캡슐',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '두타스테리드',
  },
  {
    id: 263976,
    standard_code: '8806443089815',
    generic_name_code: '458801ACS',
    korean_product_name: '아보탄연질캡슐0.5밀리그램(두타스테리드)',
    product_total_quantity: '30',
    dosage_form: '캡슐',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '두타스테리드',
  },
  {
    id: 263977,
    standard_code: '8806443089822',
    generic_name_code: '458801ACS',
    korean_product_name: '아보탄연질캡슐0.5밀리그램(두타스테리드)',
    product_total_quantity: '10',
    dosage_form: '캡슐',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '두타스테리드',
  },
  {
    id: 269423,
    standard_code: '8806472052613',
    generic_name_code: '458801ACS',
    korean_product_name: '두타스텐연질캡슐0.5밀리그램(두타스테리드)',
    product_total_quantity: '30',
    dosage_form: '캡슐',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '두타스테리드',
  },
  {
    id: 274099,
    standard_code: '8806491026817',
    generic_name_code: '458801ACS',
    korean_product_name: '원투스테리드연질캡슐(두타스테리드)',
    product_total_quantity: '30',
    dosage_form: '캡슐',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '두타스테리드',
  },
  {
    id: 279680,
    standard_code: '8806435058713',
    generic_name_code: '458801ACS',
    korean_product_name: '두테드연질캡슐0.5밀리그램(두타스테리드)',
    product_total_quantity: '30',
    dosage_form: '캡슐',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '두타스테리드',
  },
  {
    id: 279681,
    standard_code: '8806435058720',
    generic_name_code: '458801ACS',
    korean_product_name: '두테드연질캡슐0.5밀리그램(두타스테리드)',
    product_total_quantity: '10',
    dosage_form: '캡슐',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '두타스테리드',
  },
  {
    id: 279682,
    standard_code: '8806435058737',
    generic_name_code: '458801ACS',
    korean_product_name: '두테드연질캡슐0.5밀리그램(두타스테리드)',
    product_total_quantity: '10',
    dosage_form: '캡슐',
    packaging_type: '병',
    special_general_type: '전문의약품',
    generic_name: '두타스테리드',
  },
  {
    id: 283921,
    standard_code: '8806556050412',
    generic_name_code: '458801ACS',
    korean_product_name: '아다모연질캡슐0.5밀리그램(두타스테리드)',
    product_total_quantity: '15',
    dosage_form: '캡슐',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '두타스테리드',
  },
  {
    id: 283922,
    standard_code: '8806556050429',
    generic_name_code: '458801ACS',
    korean_product_name: '아다모연질캡슐0.5밀리그램(두타스테리드)',
    product_total_quantity: '30',
    dosage_form: '캡슐',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '두타스테리드',
  },
  {
    id: 288026,
    standard_code: '8806420036412',
    generic_name_code: '458801ACS',
    korean_product_name: '다모다트연질캡슐0.5밀리그램(두타스테리드)',
    product_total_quantity: '30',
    dosage_form: '캡슐',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '두타스테리드',
  },
  {
    id: 288027,
    standard_code: '8806420036429',
    generic_name_code: '458801ACS',
    korean_product_name: '다모다트연질캡슐0.5밀리그램(두타스테리드)',
    product_total_quantity: '10',
    dosage_form: '캡슐',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '두타스테리드',
  },
  {
    id: 288028,
    standard_code: '8806420036436',
    generic_name_code: '458801ACS',
    korean_product_name: '다모다트연질캡슐0.5밀리그램(두타스테리드)',
    product_total_quantity: '60',
    dosage_form: '캡슐',
    packaging_type: 'PTP',
    special_general_type: '전문의약품',
    generic_name: '두타스테리드',
  },
  {
    id: 116659,
    standard_code: '8806544005714',
    generic_name_code: '612230BIJ',
    korean_product_name: '삭센다펜주6밀리그램/밀리리터(리라글루티드)',
    product_total_quantity: '1',
    dosage_form: '개',
    packaging_type: '펜',
    special_general_type: '전문의약품',
    generic_name: '리라글루티드',
  },
  {
    id: 116660,
    standard_code: '8806544005721',
    generic_name_code: '612230BIJ',
    korean_product_name: '삭센다펜주6밀리그램/밀리리터(리라글루티드)',
    product_total_quantity: '5',
    dosage_form: '개',
    packaging_type: '펜',
    special_general_type: '전문의약품',
    generic_name: '리라글루티드',
  },
];

export const finasterideSearchList = searchMedicineList.filter((medicine) => {
  if (medicineCategoryList[0].codes.includes(medicine.generic_name_code)) {
    return medicine;
  }

  return null;
});

export const dutasterideSearchList = searchMedicineList.filter((medicine) => {
  if (medicineCategoryList[1].codes.includes(medicine.generic_name_code)) {
    return medicine;
  }

  return null;
});

export const dietShotSearchList = searchMedicineList.filter((medicine) => {
  if (medicineCategoryList[2].codes.includes(medicine.generic_name_code)) {
    return medicine;
  }

  return null;
});

export const eatingAcneMedicineSearchList = searchMedicineList.filter(
  (medicine) => {
    if (medicineCategoryList[3].codes.includes(medicine.generic_name_code)) {
      return medicine;
    }

    return null;
  },
);

export const linimentAcneMedicineSearchList = searchMedicineList.filter(
  (medicine) => {
    if (medicineCategoryList[4].codes.includes(medicine.generic_name_code)) {
      return medicine;
    }

    return null;
  },
);

export const managementMedicineTabList = [
  {
    id: 0,
    label: `전체`,
    codes: [],
  },
  ...medicineCategoryList,
];

export const medicineManagementCategoryConfig: Record<string, string> = {
  '159002ATB': '피나스테리드',
  '458801ATB': '두타스테리드',
  '458801ACS': '두타스테리드',
  '612230BIJ': '다이어트 주사',
  '178701ACS': '먹는 여드름약',
  '135639CLQ': '바르는 여드름약',
  '135635CLQ': '바르는 여드름약',
  '135633CLQ': '바르는 여드름약',
  '135602CLQ': '바르는 여드름약',
  '135636CLQ': '바르는 여드름약',
};

function convertMedicineNameConfig(medicineList: ManagedMedicineItemType[]) {
  const medicineConfig: Record<string, string> = {};
  medicineList.forEach((medicine) => {
    if (medicine.standard_code && medicine.korean_product_name) {
      medicineConfig[medicine.standard_code] = medicine.korean_product_name;
    }
  });

  return medicineConfig;
}

export const medicineNameConfig: Record<string, string> =
  convertMedicineNameConfig(searchMedicineList);
