import {
  coldChainImage,
  parcelImage,
  pickupImage,
  quickImage,
  ReactBootstrapIcons,
} from '@assets';
import { Blank, Button, COLOR, Font } from '@components/atoms';
import { commonCode, styleVariables } from '@constants';
import { formatters } from '@functions';
import { commonHooks } from '@hooks';
import useFinishTelepharmacyModalHook from '@pages/wrapper/modals/finishTelepharmacyModal/hooks';
import { myDoctorAPI } from '@services';
import { useAppDispatch } from '@stores/hooks';
import { openModal_finishTelepharmacyModal } from '@stores/modalStore/finishTelepharmacyModal';
import moment from 'moment';
import printJS from 'print-js';
import { MouseEvent } from 'react';
import ReactTooltip from 'react-tooltip';
import { MyDoctorModelType } from 'types/index.d';
import { LISTITEM_FLEX } from '../../index';
import { ListContent } from '../index';
import * as Style from './index.style';

const { COMMON_CODE } = commonCode;
const { BREAK_POINTS } = styleVariables;

const NO_PASSWORD_CASE_TEXT = '비밀번호 없이 출입 가능해요';

function ListItem({
  navigate,
  item,
}: {
  navigate: any;
  item: MyDoctorModelType.TelepharmacyModelType.TelepharmacyListItemModelType;
}) {
  const { phoneFormatter, priceFormatter } = formatters;
  const { useWindowSize } = commonHooks;
  const { windowWidth } = useWindowSize();
  const dispatch = useAppDispatch();

  const { finishTelepharmacy } = useFinishTelepharmacyModalHook();

  let deliveryType: string;
  let iconURL;

  switch (item.method) {
    case COMMON_CODE.TP_METHOD.PARCEL:
      deliveryType = '택배';
      iconURL = parcelImage;
      break;
    case COMMON_CODE.TP_METHOD.PICKUP:
      deliveryType = '방문';
      iconURL = pickupImage;
      break;
    case COMMON_CODE.TP_METHOD.QUICK:
    case COMMON_CODE.TP_METHOD.ECONOMY_QUICK:
      deliveryType = '퀵';
      iconURL = quickImage;
      break;
    case COMMON_CODE.TP_METHOD.COLD_CHAIN:
      deliveryType = '냉장';
      iconURL = coldChainImage;
      break;
    default:
      deliveryType = '택배';
      iconURL = parcelImage;
      break;
  }

  const confirmed_waitingTime = parseInt(
    String((moment().unix() - moment(item.accept_time).unix()) / 60 + 1),
    10,
  );

  const fetchPrescriptionData = async (tp_id: number | undefined) => {
    const { data } = await myDoctorAPI.getTelepharmacyTpprocessPrescriptions(
      Number(tp_id),
    );
    return data;
  };

  const isEmptyObject = (param: any) => {
    if (param === undefined) return true;
    return Object.keys(param).length === 0 && param.constructor === Object;
  };

  const isEmptyObjectPharmacyProductPayment = isEmptyObject(
    item.pharmacy_product_payment,
  );

  const commerceTotalAmount =
    !isEmptyObjectPharmacyProductPayment &&
    item.pharmacy_product_payment &&
    item.pharmacy_product_payment.total_amount
      ? item.pharmacy_product_payment.total_amount
      : 0;

  return (
    <Style.ListItem to={`/home/waitForDispense/confirmedDetail?id=${item.id}`}>
      <ListContent.Regular flex={LISTITEM_FLEX[1].flex}>
        <Font fontType="body3" color="fill/accent">
          {confirmed_waitingTime}분 대기
        </Font>
      </ListContent.Regular>
      <ListContent.Regular flex={LISTITEM_FLEX[2].flex}>
        {deliveryType}{' '}
        {iconURL ? (
          <Style.ListItemIcon src={iconURL} alt={deliveryType} />
        ) : undefined}
      </ListContent.Regular>
      <ListContent.Regular flex={LISTITEM_FLEX[3].flex}>
        {item.patient?.name}
        {item.telemedicine ? (
          <>
            <br />({item.telemedicine.telemedicine_hash})
          </>
        ) : null}
      </ListContent.Regular>
      <ListContent.Regular flex={LISTITEM_FLEX[4].flex}>
        {phoneFormatter.prettyPhoneNumberFormatter(
          item.patient?.phone || item.requester?.phone,
        )}
      </ListContent.Regular>
      <ListContent.Element flex={LISTITEM_FLEX[5].flex} flexDirection="column">
        <div>
          <Font fontType="body3">
            {`${priceFormatter.commaFormatter(
              item.amount ? item.amount?.toString() : '0',
            )}원`}
          </Font>
        </div>
        <div>
          <Font fontType="body3">
            {item.is_tp_uninsured ? '(비급여)' : '(급여)'}
          </Font>
        </div>
      </ListContent.Element>
      <ListContent.Regular flex={LISTITEM_FLEX[6].flex}>
        {commerceTotalAmount > 0
          ? `${priceFormatter.commaFormatter(commerceTotalAmount.toString())}원`
          : '-'}
      </ListContent.Regular>
      <ListContent.Element
        flex={
          Number(windowWidth) < BREAK_POINTS.size1024
            ? LISTITEM_FLEX[7].flex1024
            : LISTITEM_FLEX[7].flex
        }
      >
        {item.has_subscription ? (
          <Button
            width={
              Number(windowWidth) < BREAK_POINTS.size1024 ? '32px' : '92px'
            }
            height="32px"
            borderColor={COLOR['fill/dark']}
            backgroundColor={COLOR['fill/white']}
            borderRadius={
              Number(windowWidth) < BREAK_POINTS.size1024 ? '50%' : '4px'
            }
            title={
              <>
                <Style.IconButtonItem
                  data-for={`showToolTipPrint_${item.id}`}
                  data-tip
                >
                  <ReactBootstrapIcons.Printer
                    size={12}
                    color={COLOR['fill/dark']}
                  />
                  {Number(windowWidth) >= BREAK_POINTS.size1024 && (
                    <>
                      <Blank appoint="Horizontal" size={8} />
                      <Font fontType="body3">프린트</Font>
                    </>
                  )}
                </Style.IconButtonItem>
                {Number(windowWidth) < BREAK_POINTS.size1024 && (
                  <ReactTooltip
                    id={`showToolTipPrint_${item.id}`}
                    isCapture
                    place="left"
                    getContent={() => '프린트'}
                  />
                )}
              </>
            }
            onClick={async (event: MouseEvent<HTMLButtonElement>) => {
              event.stopPropagation();
              event.preventDefault();
              // 처방전 수정기능 이후엔 멀쩡한 처방전으로 출력하는 기능이 추가되어야함.
              const prescriptionData = await fetchPrescriptionData(item.id);
              printJS({
                printable: prescriptionData[0].image,
                type: 'pdf',
                showModal: true,
              });
            }}
          />
        ) : null}
        <Blank appoint="Horizontal" size={4} />
        {item.has_subscription ? (
          <Button
            width={
              Number(windowWidth) < BREAK_POINTS.size1024 ? '32px' : '104px'
            }
            height="32px"
            borderColor={COLOR['fill/dark']}
            backgroundColor={COLOR['fill/white']}
            borderRadius={
              Number(windowWidth) < BREAK_POINTS.size1024 ? '50%' : '4px'
            }
            title={
              <>
                <Style.IconButtonItem
                  data-for={`showToolTipPdf_${item.id}`}
                  data-tip
                >
                  <ReactBootstrapIcons.FilePdf
                    size={12}
                    color={COLOR['fill/dark']}
                  />
                  {Number(windowWidth) >= BREAK_POINTS.size1024 && (
                    <>
                      <Blank appoint="Horizontal" size={8} />
                      <Font fontType="body3">PDF 원본</Font>
                    </>
                  )}
                </Style.IconButtonItem>
                {Number(windowWidth) < BREAK_POINTS.size1024 && (
                  <ReactTooltip
                    id={`showToolTipPdf_${item.id}`}
                    isCapture
                    place="left"
                    getContent={() => 'PDF 원본'}
                  />
                )}
              </>
            }
            onClick={async (event: MouseEvent<HTMLButtonElement>) => {
              event.stopPropagation();
              event.preventDefault();
              // 처방전 수정기능 이후엔 멀쩡한 처방전으로 출력하는 기능이 추가되어야함.
              const prescriptionData = await fetchPrescriptionData(item.id);
              window.open(prescriptionData ? prescriptionData[0].image : '');
            }}
          />
        ) : null}
        <Blank appoint="Horizontal" size={4} />
        <Button
          width={Number(windowWidth) < BREAK_POINTS.size1024 ? '32px' : '100px'}
          height="32px"
          backgroundColor={COLOR['fill/accent']}
          borderRadius={
            Number(windowWidth) < BREAK_POINTS.size1024 ? '50%' : '4px'
          }
          title={
            <Style.IconButtonItem
              data-for={`showToolTipFinishTelepharmacy_${item.id}`}
              data-tip
            >
              <Style.ListItemIcon src={iconURL} alt={deliveryType} />
              {Number(windowWidth) >= BREAK_POINTS.size1024 && (
                <>
                  <Blank appoint="Horizontal" size={8} />
                  <Font fontType="body3" color="fill/white">
                    {deliveryType === '방문' ? '방문요청' : '수거요청'}
                  </Font>
                </>
              )}
              {Number(windowWidth) < BREAK_POINTS.size1024 && (
                <ReactTooltip
                  id={`showToolTipFinishTelepharmacy_${item.id}`}
                  isCapture
                  place="left"
                  getContent={() => '조제완료 및 수거요청'}
                />
              )}
            </Style.IconButtonItem>
          }
          onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
            // 처방전 수정기능 이후엔 멀쩡한 처방전으로 출력하는 기능이 추가되어야함.
            if (deliveryType === '방문') {
              finishTelepharmacy({ isPickup: true, id: item.id });
              return;
            }
            dispatch(
              openModal_finishTelepharmacyModal({
                item: {
                  id: item.id,
                  patientName: item.patient?.name,
                  patientAddress: item.address_string,
                  addressMemo: item.address_memo,
                  method: item.method,
                  patientPhone: item.patient?.phone || item.requester?.phone,
                  pharmacy_product_payment: item.pharmacy_product_payment,
                  address_entrance_password:
                    item.address_entrance_password || NO_PASSWORD_CASE_TEXT,
                  pharmacy_request_detail: item.pharmacy_request_detail,
                },
              }),
            );
          }}
        />
      </ListContent.Element>
    </Style.ListItem>
  );
}

export default ListItem;
