import { myDoctorAPI } from '@services';

import { commonCode, tokenList } from '@constants';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '@stores';
import {
  closeModal_setFirstLoginModal,
  openModal_setFirstLoginModal,
} from '@stores/modalStore/setFirstLoginModal';
import { getTelepharmacyCount } from '@stores/telepharmacyStore/telepharmacyCount';
import { getTelepharmacyList_ALL } from '@stores/telepharmacyStore/telepharmacyList';
import { storageHandler } from '@utils';

import { UserInformationType } from 'types/index.d';

const { COMMON_CODE } = commonCode;
const { REST_AUTH_TOKEN } = tokenList;

const initialState: UserInformationType.UserInformationStateType = {
  isLogin: false,
  userData: {
    user_type: '',
    user_id: 0,
    name: '',
    is_verified: false,
    gender: '',
    birthday: '',
    foreigner: false,
    phone: '',
    profile_image: '',
    is_agree_must: false,
    is_agree_marketing: false,
    total_point: '',
    available_point: '',
    expire_this_month_point: '',
  },
  pharmacistData: {
    nickname: '',
    pharmacist_working_time: [],
    has_payment_permission: false,
  },
  pharmacyWorkingTimeData: [],
};

export const fetchLogin = createAsyncThunk(
  'userInformation/fetchLogin',
  async (
    { email, password }: UserInformationType.FetchLoginParameterType,
    { dispatch },
  ) => {
    const { setLocalStorageState } = storageHandler;
    const result = { isLogin: false, message: '', userID: '', userType: '' };
    const fetchData = await myDoctorAPI.postLoginEmail({
      email,
      password,
    });
    if (fetchData.access_token && fetchData.refresh_token) {
      setLocalStorageState(
        REST_AUTH_TOKEN.ACCESS_TOKEN,
        fetchData.access_token,
      );
      setLocalStorageState(
        REST_AUTH_TOKEN.REFRESH_TOKEN,
        fetchData.refresh_token,
      );
      result.isLogin = true;
      result.userID = fetchData?.user?.pk;
      result.userType = fetchData?.user?.user_type;
      dispatch(RegistrationFCMToken());
    } else if (fetchData.non_field_errors) {
      result.isLogin = false;
      result.message =
        '로그인에 실패했습니다. 아이디 및 비밀번호를 확인해주세요.';
    } else {
      result.isLogin = false;
      result.message =
        '로그인에 실패했습니다. 아이디 및 비밀번호를 확인해주세요. 문제가 계속되면 고객센터에 문의 부탁드립니다.';
    }

    return result;
  },
);

export const firstLogin = createAsyncThunk(
  'userInformation/firstLogin',
  async (
    { email, password }: UserInformationType.FetchLoginParameterType,
    { dispatch },
  ) => {
    const { setLocalStorageState } = storageHandler;
    const result = { isLogin: false, key: '' };
    const fetchData = await myDoctorAPI.postLoginEmail({
      email,
      password,
    });
    if (fetchData.access_token && fetchData.refresh_token) {
      setLocalStorageState(
        REST_AUTH_TOKEN.ACCESS_TOKEN,
        fetchData.access_token,
      );
      setLocalStorageState(
        REST_AUTH_TOKEN.REFRESH_TOKEN,
        fetchData.refresh_token,
      );
      dispatch(openModal_setFirstLoginModal());
    }
    return result;
  },
);

export const firstPasswordSetting = createAsyncThunk(
  'userInformation/firstPasswordSetting',
  async (
    {
      password,
      passwordConfirm,
    }: UserInformationType.FirstPasswordSettingParamType,
    { dispatch },
  ) => {
    const result = { isLogin: false, message: '' };

    const fetchData = await myDoctorAPI.postPasswordChange({
      new_password1: password,
      new_password2: passwordConfirm,
    });

    if (fetchData.detail) {
      dispatch(closeModal_setFirstLoginModal());
      dispatch(RegistrationFCMToken());
      result.isLogin = true;
    } else if (fetchData.new_password2) {
      result.message = '비밀번호가 일치하지 않습니다.';
    } else {
      result.message =
        '로그인에 실패했습니다. 비밀번호와 비밀번호 확인을 제대로 입력했는데 계속 같은 문제가 발생되면, 고객센터로 연락부탁드립니다.';
    }
    return result;
  },
);

export const RegistrationFCMToken = createAsyncThunk(
  'userInformation/registrationFCMToken',
  async (action, { dispatch }) => {
    const { getSessionStorageState } = storageHandler;
    const result = {
      isSuccess: false,
      data: initialState.userData,
    };
    const FCMToken = getSessionStorageState('FCMToken');
    result.data = await myDoctorAPI.postFCMLogin({ fcm_token: FCMToken });

    if (
      result.data.user_type === COMMON_CODE.USER_TYPE.DEVELOPER ||
      result.data.user_type === COMMON_CODE.USER_TYPE.MERAKI ||
      result.data.user_type === COMMON_CODE.USER_TYPE.PHARMACIST
    ) {
      if (result.data.user_id !== undefined) {
        dispatch(getTelepharmacyCount());
        dispatch(getTelepharmacyList_ALL());
        result.isSuccess = true;
        dispatch(getPharmacistData(result.data.user_id));
      }
    } else {
      alert('약사 회원만 로그인이 가능합니다');
    }

    return result;
  },
);

export const getPharmacistData = createAsyncThunk(
  'userInformation/getPharmacistData',
  async (profile: number) => {
    return myDoctorAPI.getPharmacistData({}, profile);
  },
);

export const getPharmacyWorkingTimeData = createAsyncThunk(
  'userInformation/getPharmacyWorkingTimeData',
  async (pharmacyId: number) => {
    const result = await myDoctorAPI.getPharmacyWorkingTime(pharmacyId);
    return result.data;
  },
);

export const userInformationSlice = createSlice({
  name: 'userInformation',
  initialState,
  reducers: {
    storeLogout: () => {
      return { ...initialState };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLogin.fulfilled, (state, action) => {
        if (!action.payload.isLogin) {
          alert(action.payload.message);
        }
      })
      .addCase(firstPasswordSetting.fulfilled, (state, action) => {
        if (!action.payload.isLogin) {
          alert(action.payload.message);
        }
      })
      .addCase(RegistrationFCMToken.fulfilled, (state, action) => {
        if (action.payload.isSuccess) {
          state.isLogin = true;
          state.userData = action.payload.data;
        } else {
          state.isLogin = false;
        }
      })
      .addCase(getPharmacistData.fulfilled, (state, action) => {
        if (action.payload) {
          state.pharmacistData = action.payload;
        }
      })
      .addCase(getPharmacyWorkingTimeData.fulfilled, (state, action) => {
        if (action.payload) {
          state.pharmacyWorkingTimeData = action.payload;
        }
      });
  },
});

export const { storeLogout } = userInformationSlice.actions;

export const selectUserInformationIsLogin = (state: RootState) =>
  state.userInformation.isLogin &&
  storageHandler.getLocalStorageState(REST_AUTH_TOKEN.ACCESS_TOKEN);
export const selectUserInformationUserData = (state: RootState) =>
  state.userInformation.userData;
export const selectUserInformationPharmacistData = (state: RootState) =>
  state.userInformation.pharmacistData;
export const selectPharmacyWorkingTimeData = (state: RootState) =>
  state.userInformation.pharmacyWorkingTimeData;

export default userInformationSlice.reducer;
