import { Button, COLOR, Font } from '@components/atoms';
import {
  CallOutMessage,
  ModalCommonStructure as Modal,
} from '@components/molecules';
import { GetReadyModalTemplateType } from './index.d';
import * as Style from './index.style';

function GetReadyModalTemplate({
  isVisible,
  title,
  closeModal,
}: GetReadyModalTemplateType) {
  return (
    <Modal
      isOpen={isVisible}
      onRequestClose={() => closeModal()}
      modalHeader={
        <Style.TitleArea>
          <Font fontType="h1" color="fill/dark">
            {title}
          </Font>
        </Style.TitleArea>
      }
      modalBody={
        <>
          <Style.DescriptionArea>
            <CallOutMessage iconColor="greyscale6">
              <Font fontType="body1" color="fill/dark">
                {title} 기능은 출시 준비 중입니다.
              </Font>
            </CallOutMessage>
          </Style.DescriptionArea>
          <Style.CustomerCenterButtonArea>
            <Button
              onClick={() => closeModal()}
              backgroundColor={COLOR['fill/black']}
              title={
                <Font fontType="h4" color="fill/white">
                  이전 페이지로 돌아가기
                </Font>
              }
            />
          </Style.CustomerCenterButtonArea>
        </>
      }
    />
  );
}

export default GetReadyModalTemplate;
