function DownloadIcon() {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.375 7.42502C0.582107 7.42502 0.75 7.59291 0.75 7.80002V9.67502C0.75 10.0892 1.08579 10.425 1.5 10.425H10.5C10.9142 10.425 11.25 10.0892 11.25 9.67502V7.80002C11.25 7.59291 11.4179 7.42502 11.625 7.42502C11.8321 7.42502 12 7.59291 12 7.80002V9.67502C12 10.5034 11.3284 11.175 10.5 11.175H1.5C0.671573 11.175 0 10.5034 0 9.67502V7.80002C0 7.59291 0.167893 7.42502 0.375 7.42502Z"
        fill="#212529"
      />
      <path
        d="M5.73484 8.89017C5.88128 9.03661 6.11872 9.03661 6.26517 8.89017L8.51517 6.64016C8.66161 6.49372 8.66161 6.25628 8.51517 6.10984C8.36872 5.96339 8.13128 5.96339 7.98483 6.10984L6.375 7.71967V1.125C6.375 0.917893 6.20711 0.75 6 0.75C5.79289 0.75 5.625 0.917893 5.625 1.125V7.71967L4.01516 6.10984C3.86872 5.96339 3.63128 5.96339 3.48484 6.10984C3.33839 6.25628 3.33839 6.49372 3.48484 6.64016L5.73484 8.89017Z"
        fill="#212529"
      />
    </svg>
  );
}

export default DownloadIcon;
