import Caution from '@assets/svg/Caution';
import History from '@assets/svg/History';
import { PrescriptionDetail } from '@mobile/api';
import styled from 'styled-components';
import SmallDivider from '../../SmallDivider';
import MedicineInfoItem from './MedicineInfoItem';

type Props = {
  medicine_info?: PrescriptionDetail['info'];
  onToggle: () => void;
  handleMixpanelEvent: () => void;
};

function MedicineInfo({ medicine_info, onToggle, handleMixpanelEvent }: Props) {
  const medicineInfo = medicine_info?.[0]?.prescription_contents
    ? medicine_info?.[0]?.prescription_contents
    : [
        {
          drug_code: null,
          drug_name: null,
          one_dose: null,
          dosing_per_day: null,
          total_dosing_days: null,
          self_pay_rate_code: null,
          generic_name: null,
        },
      ];

  const isDataNullInMedcineInfo = () => {
    return (
      medicineInfo.length === 0 ||
      medicineInfo.some((info) => {
        return (
          info.drug_code === null ||
          info.drug_name === null ||
          info.one_dose === null ||
          info.dosing_per_day === null ||
          info.total_dosing_days === null ||
          info.self_pay_rate_code === null
        );
      })
    );
  };

  return (
    <Container>
      <TitleSection>
        <Title>처방 의약품</Title>
        <PrescriptionButton
          onClick={() => {
            onToggle();
            handleMixpanelEvent();
          }}
        >
          <History />
          처방전 보기
        </PrescriptionButton>
      </TitleSection>

      {isDataNullInMedcineInfo() && (
        <CautionContainer>
          <Caution />
          <CautionText>
            {
              '처방전 데이터를 불러오지 못했습니다.\n[처방전 보기]를 눌러 처방전을 확인해주세요.'
            }
          </CautionText>
        </CautionContainer>
      )}

      <ContentsSection>
        {medicineInfo.map((info, index) => {
          return (
            <>
              <SmallDivider key={index} index={index} />
              <MedicineInfoItem
                key={info.drug_code ? info.drug_code : index}
                daily_doses={info.dosing_per_day ?? '-'}
                medicine_name={info.drug_name ?? '-'}
                medicine_number={info.drug_code ?? '-'}
                one_dose={info.one_dose ?? '-'}
                total_days_taken={info.total_dosing_days ?? '-'}
                medicine_generic={info.generic_name}
                insured_text={info.self_pay_rate_code ?? '-'}
              />
            </>
          );
        })}
      </ContentsSection>
    </Container>
  );
}

export default MedicineInfo;

const Container = styled.section`
  display: flex;
  width: 100%;
  padding: 20px 20px 40px 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  border-top: 4px solid #f1f2f4;
  border-bottom: 4px solid #f1f2f4;
`;

const TitleSection = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: space-between;
`;

const Title = styled.div`
  color: #1e2024;

  font-family: Pretendard, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
`;

const PrescriptionButton = styled.button`
  display: flex;
  padding: 9px 16px 9px 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;

  border-radius: 8px;
  background: #ebf3ff;
  border: none;

  font-family: Pretendard, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;

  color: #2f6eff;
`;

const ContentsSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
`;

const CautionContainer = styled.div`
  width: 100%;
  display: flex;
  padding: 10px 18px;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: #f9fafa;
`;

const CautionText = styled.div`
  color: #474a52;

  font-family: Pretendard, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  white-space: pre-line;
`;
