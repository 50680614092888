import { Mixpanel } from '@utils/mixpanel';
import { clickAdBanner, pageType } from '@utils/mixpanel/constants';

export const confirmedTreatmentOngoingQuickMixpanelEvents = {
  pageConfirmedTreatmentOngoingQuick:
    '[Page] Confirmed Treatment Ongoing Quick',

  clickAdBanner,
} as const;

type ConfirmedTreatmentOngoingQuickMixpanelEvents =
  keyof typeof confirmedTreatmentOngoingQuickMixpanelEvents;

const confirmedTreatmentOngoingQuickMixpanelEventsMap: Record<
  ConfirmedTreatmentOngoingQuickMixpanelEvents,
  (adID?: string, adType?: string) => void
> = {
  pageConfirmedTreatmentOngoingQuick: () =>
    Mixpanel.track(
      confirmedTreatmentOngoingQuickMixpanelEvents.pageConfirmedTreatmentOngoingQuick,
    ),

  clickAdBanner: (adID?: string, adType?: string) =>
    Mixpanel.track(confirmedTreatmentOngoingQuickMixpanelEvents.clickAdBanner, {
      'Page Type': pageType.confirmedTreatmentOngoingQuick,
      'AD ID': adID,
      'AD Type': adType,
    }),
};

export const trackConfirmedTreatmentOngoingQuickMixpanelEvents = (
  confirmedTreatmentOngoingQuickMixpanelEvent: ConfirmedTreatmentOngoingQuickMixpanelEvents,
) => {
  return confirmedTreatmentOngoingQuickMixpanelEventsMap[
    confirmedTreatmentOngoingQuickMixpanelEvent
  ];
};
