import { Font } from '@components/atoms';
import { formatters } from '@functions';
import { HeaderLeftPartItemType } from './index.d';
import * as Style from './index.style';

function HeaderLeftPartItem({ patientName, phoneNum }: HeaderLeftPartItemType) {
  const { phoneFormatter } = formatters;
  return (
    <Style.HeaderPatientName>
      <Font fontType="display2" color="fill/black">
        {patientName} 환자 (
        {phoneFormatter.lastFourPhoneNumberFormatter(phoneNum)})
      </Font>
    </Style.HeaderPatientName>
  );
}

export default HeaderLeftPartItem;
