/* eslint-disable react-hooks/exhaustive-deps */

import { COMMON_CODE } from '@constants/commonCode';
import usePrescriptionDetail from '@mobile/api/hooks/usePrescriptionDetail';
import useTPProcessDetail from '@mobile/api/hooks/useTPProcessDetail';
import useTPProcessCount from '@mobile/api/hooks/useTPprocessCount';
import { LoadingContainer } from '@mobile/components/layout/LoadingContainer';
import { telepharmcyStorage } from '@mobile/utils';
import { Mixpanel } from '@utils/mixpanel';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { BeatLoader } from 'react-spinners';
import styled from 'styled-components';
import BottomCTA from './components/BottomCTA';
import Header from './components/Header';
import PDFViewer from './components/PDFViewer';
import usePDF from './components/PDFViewer/hooks/usePDf';
import HospitalInfo from './components/section/HospitalInfo';
import MedicineInfo from './components/section/MedicineInfo';
import ParcelInfo from './components/section/ParcelInfo';
import PatientInfo from './components/section/PatientInfo';
import QuickInfo from './components/section/QuickInfo';
import ReservationInfo from './components/section/ReservationInfo';

function TPDetail() {
  const {
    isPDFShow,
    toggleShowPDF,
    handleDocumentLoadSuccess,
    handleNextPage,
    handlePrevPage,
    pageNumber,
    prescriptionNumPages,
  } = usePDF();

  const { data: tpData, isLoading: isTpDataLoading } = useTPProcessDetail();
  const { data: tpCount, isLoading: isTpCountLoading } = useTPProcessCount();
  const { data: prescriptionData, isLoading: isPrescriptionLoading } =
    usePrescriptionDetail();

  const loadingCondition =
    isTpDataLoading || isTpCountLoading || isPrescriptionLoading;

  const ocrInfo = prescriptionData?.[0]?.prescription_contents
    ? prescriptionData?.[0]?.prescription_contents
    : [
        {
          drug_code: null,
          drug_name: null,
          one_dose: null,
          dosing_per_day: null,
          total_dosing_days: null,
          self_pay_rate_code: null,
          generic_name: null,
        },
      ];

  const isNotOcrSuccess = () => {
    return (
      ocrInfo.length === 0 ||
      ocrInfo.some((info) => {
        return (
          info.drug_code === null ||
          info.drug_name === null ||
          info.one_dose === null ||
          info.dosing_per_day === null ||
          info.total_dosing_days === null ||
          info.self_pay_rate_code === null
        );
      })
    );
  };

  const nonInsuredMedicineList = ocrInfo.map((info) => info.generic_name) || [];

  const { id } = useParams();
  const tppId = Number(id);

  const mixpanelData = {
    'TP ID': tppId,
    'PH ID': telepharmcyStorage.telepharmacyId,
    'Symptom Keyword': tpData?.tm?.symptom_keyword_history
      ? tpData?.tm?.symptom_keyword_history[0].symptom_keyword.name
      : '',
    'Delivery Method': tpData?.method || '',
    'OCR Success': !isNotOcrSuccess(),
    'Non-insured Medicine': nonInsuredMedicineList,
  };

  const handleClickMixpanelEvent = () => {
    Mixpanel.track('[Click] Prescription Detail (Mobile)', mixpanelData);
  };

  const renderDeliveryInfo = () => {
    if (tpData?.method === COMMON_CODE.TP_METHOD.PARCEL)
      return (
        <ParcelInfo
          address={tpData?.address_string ?? '-'}
          addressMemo={tpData?.address_memo ?? '-'}
          entrancePassword={
            tpData?.address_entrance_password === ''
              ? '비밀번호 없이 출입 가능'
              : '-'
          }
          deliveryCompany={
            tpData?.tp_delivery?.delivery_company_name === ''
              ? '택배사 접수가 진행 중입니다'
              : '-'
          }
          trackingNumber={
            tpData?.tp_delivery?.tracking_number === ''
              ? '운송장 등록 후 노출됩니다'
              : '-'
          }
        />
      );

    if (tpData?.method === COMMON_CODE.TP_METHOD.QUICK)
      return (
        <QuickInfo
          address={tpData?.address_string ?? '-'}
          addressMemo={tpData?.address_memo ?? '-'}
          quickOrderId={
            tpData?.tp_delivery?.quick_order_id ??
            '수거 요청 완료 후 노출됩니다'
          }
          quickPickerPhone={
            tpData?.tp_delivery?.quick_picker_phone ??
            '기사님 매칭 후 노출됩니다'
          }
          entrancePassword={
            tpData?.address_entrance_password === ''
              ? '비밀번호 없이 출입 가능'
              : '-'
          }
        />
      );

    return null;
  };

  useEffect(() => {
    if (tpData && prescriptionData) {
      Mixpanel.track('[Page] TP Requested (Mobile)', mixpanelData);
    }
  }, [tpData && prescriptionData]);

  if (loadingCondition)
    return (
      <LoadingContainer>
        <BeatLoader size={15} loading color="#3478F6" />
      </LoadingContainer>
    );

  return (
    <>
      <Header requestCount={tpCount?.requested ? tpCount?.requested : 0} />

      <Container>
        <PatientInfo
          patientName={tpData?.tm?.patient?.name}
          reservationCode={tpData?.telepharmacy_hash}
          deliveryMethod={tpData?.method}
          symptom={
            tpData?.tm?.symptom_keyword_history
              ? tpData?.tm?.symptom_keyword_history[0].symptom_keyword.name
              : ''
          }
        />

        <MedicineInfo
          medicine_info={prescriptionData}
          onToggle={toggleShowPDF}
          handleMixpanelEvent={handleClickMixpanelEvent}
        />

        <ReservationInfo
          methodText={tpData?.method_name ?? '방문수령 👟'}
          requestInfo={tpData?.pharmacy_request_detail}
          requestTime={tpData?.requested_time}
          symptom={tpData?.tm?.symptom_text}
        />

        <HospitalInfo
          doctorName={tpData?.tm?.doctor?.name}
          hospitalName={tpData?.tm?.hospital_name}
          hospitalCallNumber={tpData?.tm?.hospital_phone}
        />

        {renderDeliveryInfo()}
      </Container>

      <BottomCTA
        patientName={tpData?.tm?.patient?.name}
        reservationCode={tpData?.telepharmacy_hash}
        mixpanelData={mixpanelData}
      />

      {isPDFShow && (
        <PDFViewer
          path={tpData?.tm?.prescription[0].image ?? ''}
          onToggle={toggleShowPDF}
          onLoadSuccess={handleDocumentLoadSuccess}
          onNextPage={handleNextPage}
          onPrevPage={handlePrevPage}
          pageNumber={pageNumber}
          prescriptionNumPages={prescriptionNumPages}
        />
      )}
    </>
  );
}

export default TPDetail;

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  max-width: 720px;
  height: 100vh;
  height: 100dvh;
  margin: 0 auto;

  display: flex;
  padding-top: calc(var(--detail-header-height) + 20px);
  padding-bottom: 150px;
  flex-direction: column;
  align-items: flex-start;

  overflow-y: scroll;
`;
