import Badge from '@mobile/components/common/Badge';
import styled from 'styled-components';

type Props = {
  medicine_number: string;
  medicine_name: string;
  one_dose: string;
  daily_doses: string;
  total_days_taken: string;
  medicine_generic?: string | null;
  insured_text: string;
};

function MedicineInfoItem({
  daily_doses,
  medicine_name,
  medicine_number,
  one_dose,
  total_days_taken,
  medicine_generic,
  insured_text,
}: Props) {
  return (
    <Container>
      <TitleSection>
        <NumberText>{`${medicine_number} (${insured_text})`}</NumberText>
        <MedicineNameWrapper>
          <Text>{medicine_name}</Text>
          {medicine_generic ? (
            <Badge type="default" text={medicine_generic} />
          ) : null}
        </MedicineNameWrapper>
      </TitleSection>
      <ContentsSection>
        <ItemContainer>
          <Description>1회 복용량</Description>
          <Text>{one_dose}</Text>
        </ItemContainer>
        <ItemContainer>
          <Description>일일 복용 횟수</Description>
          <Text>{daily_doses}</Text>
        </ItemContainer>
        <ItemContainer>
          <Description>총 복용 일수</Description>
          <Text>{total_days_taken}</Text>
        </ItemContainer>
      </ContentsSection>
    </Container>
  );
}

export default MedicineInfoItem;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`;

const TitleSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 2px;
  align-self: stretch;
`;

const ContentsSection = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: stretch;
`;

const NumberText = styled.div`
  color: #2f6eff;

  font-family: Pretendard, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
`;

const Text = styled.div`
  overflow: hidden;
  color: #474a52;
  text-overflow: ellipsis;

  font-family: Pretendard, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
`;

const Description = styled.div`
  color: #9fa1a8;

  font-family: Pretendard, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  flex: 1 0 0;
`;

const MedicineNameWrapper = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  gap: 8px;
  align-self: stretch;
  flex-wrap: wrap;
`;
