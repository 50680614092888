import { COLOR } from '@components/atoms';
import styled from 'styled-components';

export const TitleArea = styled.div``;

export const PatientNameArea = styled.div``;

export const PatientPaymentArea = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  height: 150px;
`;

export const InformationLine = styled.div`
  display: flex;
  padding-left: 8px;
`;

export const InformationTitle = styled.li`
  width: 150px;
  color: ${COLOR['fill/dark']};
`;

export const CheckBoxWidth = styled.div`
  width: 150px;
`;

export const ImageItem = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
`;

export const DeleteImageIcon = styled.img`
  cursor: pointer;
  width: 24px;
  height: 24px;
  margin-left: 13px;
`;

export const InformationColumnArea = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
`;

export const CheckButtonMargin = styled.div`
  margin-top: 12px;
`;

export const DescriptionArea = styled.div`
  margin-bottom: 40px;
`;

export const FooterArea = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const CheckButtonArea = styled.div``;

export const ConfirmButtonArea = styled.div`
  display: flex;
  margin-bottom: 12px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
