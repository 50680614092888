import { Blank, Font } from '@components/atoms';
import { pathnameList } from '@constants';
import { formatters } from '@functions';
import { useMemo } from 'react';
import {
  PaymentInformationDataType,
  PaymentInformationPartItemType,
} from './index.d';
import * as Style from './index.style';

const { COMMON_PATHNAME, PHARMACIST_ADMIN_PATHNAME } = pathnameList;
const { priceFormatter } = formatters;

function PaymentInformationPartItem({
  pathname,
  item,
}: PaymentInformationPartItemType) {
  const tpAmount = useMemo(() => item.amount || 0, [item.amount]);
  const pharmacyProductTotalAmount = useMemo(
    () => item.pharmacy_product_payment?.total_amount || 0,
    [item.pharmacy_product_payment?.total_amount],
  );
  const totalPaymentAmount = useMemo(
    () => tpAmount + pharmacyProductTotalAmount,
    [pharmacyProductTotalAmount, tpAmount],
  );

  const pharmacyProductDataList = useMemo(
    () =>
      item.pharmacy_product_payment &&
      item.pharmacy_product_payment.items &&
      item.pharmacy_product_payment.items.length > 0
        ? item.pharmacy_product_payment.items
        : [],
    [item.pharmacy_product_payment],
  );

  const paymentInformationList: PaymentInformationDataType[] = useMemo(
    () => [
      {
        id: 0,
        title: '처방약',
        dataList: [
          {
            description: `${
              item.tm?.doctor?.name || '의사'
            } 선생님 진료 약제비`,
            amount: item.amount || 0,
            count: 0,
          },
        ],
      },
      {
        id: 1,
        title: '추가 구매상품',
        dataList: pharmacyProductDataList.map((data) => ({
          description: data.pharmacy_product_name
            ? `${data.pharmacy_product_name}${
                data.pharmacy_product_capacity
                  ? `, ${data.pharmacy_product_capacity}`
                  : ''
              }`
            : '-',
          amount: data.amount || 0,
          count: data.quantity || 0,
        })),
      },
    ],
    [item.amount, item.tm?.doctor?.name, pharmacyProductDataList],
  );

  return pathname === COMMON_PATHNAME.COMPLETE_TREATMENT_DETAIL ||
    pathname ===
      PHARMACIST_ADMIN_PATHNAME.CONFIRMED_TREATMENT_ONGOING_PARCEL_DETAIL ||
    pathname ===
      PHARMACIST_ADMIN_PATHNAME.CONFIRMED_TREATMENT_ONGOING_QUICK_DETAIL ||
    pathname ===
      PHARMACIST_ADMIN_PATHNAME.CONFIRMED_TREATMENT_CONFIRMED_DETAIL ? (
    <Style.RightContentItem>
      <Style.ContentHeaderArea>
        <Style.ContentHeaderTitle>
          <Font fontType="h1" color="fill/black">
            결제금액
          </Font>
        </Style.ContentHeaderTitle>
        <Style.ContentHeaderText>
          <Font fontType="h1" color="fill/accent">
            {priceFormatter.commaFormatter(totalPaymentAmount.toString())}원
          </Font>
        </Style.ContentHeaderText>
      </Style.ContentHeaderArea>
      <Blank appoint="Vertical" size={12} />
      <Style.ContentBodyArea>
        {paymentInformationList.map((paymentInformationItem) => {
          if (paymentInformationItem.dataList.length > 0) {
            return (
              <Style.ContentBodyContainer key={paymentInformationItem.id}>
                <Style.ContentBodyTitleArea>
                  <Style.ContentBodyTitle>
                    <Font fontType="body1">{paymentInformationItem.title}</Font>
                  </Style.ContentBodyTitle>
                </Style.ContentBodyTitleArea>
                <Style.ContentBodyTextArea>
                  {paymentInformationItem.dataList.map((data, index) => (
                    <Style.ContentBodyText key={index}>
                      <Style.ContentBodyTextLeft>
                        <Font fontType="body1" color="fill/black">
                          {data.description}
                        </Font>
                      </Style.ContentBodyTextLeft>
                      <Style.ContentBodyTextRight>
                        <Style.ContentBodyTextRightTop>
                          <Font fontType="body1" color="fill/black">
                            {priceFormatter.commaFormatter(
                              data.amount.toString(),
                            )}
                            원
                          </Font>
                        </Style.ContentBodyTextRightTop>
                        <Style.ContentBodyTextRightBottom>
                          <Font fontType="body2" color="fill/accent">
                            {data.count > 0 ? `수량 ${data.count}개` : ''}
                          </Font>
                        </Style.ContentBodyTextRightBottom>
                      </Style.ContentBodyTextRight>
                    </Style.ContentBodyText>
                  ))}
                </Style.ContentBodyTextArea>
              </Style.ContentBodyContainer>
            );
          }
          return null;
        })}
      </Style.ContentBodyArea>
    </Style.RightContentItem>
  ) : null;
}

export default PaymentInformationPartItem;
