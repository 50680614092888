import { TelepharmacyCountReturnType } from '@services/myDoctor/types';
import { TelepharmacyItemType } from 'types/telepharmacyType';
import http from './httpInstance';

export type ServerResponse<T> = {
  data: T;
  status: number;
};

export type PrescriptionDetail = {
  info?:
    | {
        prescription_contents?: {
          drug_code?: string | null;
          drug_name?: string | null;
          one_dose?: string | null;
          dosing_per_day?: string | null;
          total_dosing_days?: string | null;
          generic_name?: string | null;
          self_pay_rate_code?: string | null;
        }[];
      }[];
};

type TpRequestListItem = {
  id: number;
  method: string;
  name: string;
  telepharmacy_hash: string;
  auto_reject_time: string;
  symptom_keyword_name: string;
  prescription_id: number;
};

type TpRequestList = {
  results: TpRequestListItem[];
};

export const postValidateMobileToken = async ({
  mobileToken,
}: {
  mobileToken: string;
}) => {
  const response = await http.post<
    ServerResponse<{
      token: string;
      user_id: number;
      tpp_id: number;
      expired_at: string;
      prescription_id: string;
      pharmacy_id: number;
    }>
  >(`pharmacist-api/mobile/token/validate`, {
    body: JSON.stringify({ token: mobileToken }),
  });

  return response.data.data;
};

export const getTPProcessDetail = async ({ tppId }: { tppId: number }) => {
  const response = await http.get<TelepharmacyItemType>(
    `telepharmacy/pharmacist/${tppId}/`,
  );

  return response.data;
};

export const getTPProcessCount = async () => {
  const response = await http.get<TelepharmacyCountReturnType>(
    'pharmacist-api/telepharmacy/tpprocess/count/',
  );

  return response.data.data;
};

export const getPrescriptionDetail = async ({
  prescriptionId,
}: {
  prescriptionId: string;
}) => {
  const response = await http.get<ServerResponse<PrescriptionDetail>>(
    `prescription/${prescriptionId}`,
  );

  return response.data.data.info;
};

export const getTPProcessRequestList = async () => {
  const response = await http.get<ServerResponse<TpRequestList>>(
    'pharmacist-api/mobile/tpps',
  );

  return response.data.data.results;
};

export const getPharmacyInfo = async ({
  pharmacy_id,
}: {
  pharmacy_id: number;
}) => {
  const response = await http.get<
    ServerResponse<{
      pharmacy_name: string;
      is_open_now: boolean;
      working_time_display_text: string;
    }>
  >(`patient-api/pharmacist/v2/${pharmacy_id}`);

  return response.data.data;
};

export const getPrescriptionEstimatedPrice = async ({
  pharmacyId,
  prescriptionId,
}: {
  pharmacyId: number;
  prescriptionId: string;
}) => {
  const response = await http.get(
    `pharmacist-api/mobile/pharmacy/${pharmacyId}/prescription/${prescriptionId}/expect-medicine-cost`,
  );

  return response;
};

export const postAcceptTelepharmacy = async ({
  tppId,
  amount,
}: {
  tppId: string;
  amount: string;
}) =>
  http.post(`telepharmacy/pharmacist/${tppId}/accept/`, {
    body: JSON.stringify({
      amount: Number(amount),
      guide: '',
      is_uninsured: null,
    }),
  });

export const postRejectTelepharmacy = async ({
  reject_reason,
  tppId,
}: {
  reject_reason: string;
  tppId: string;
}) =>
  http.post(`telepharmacy/pharmacist/${tppId}/reject/`, {
    body: JSON.stringify({
      reject_reason,
    }),
  });
