import { Button, COLOR, Font } from '@components/atoms';
import SvgIcon from '@components/atoms/images';
import { loadingSpinner } from '@components/atoms/lottie';
import BFlex from '@components/meraki-ui/BFlex';
import { managementMedicineTabList } from '@components/organisms/managementMedicineTable/config/domain';
import { commonHooks } from '@hooks';
import useMedicineManagementModalHook from '@pages/wrapper/modals/medicineManagementModal/hooks';
import { GetManagedMedicineItemType } from '@services/myDoctor/types';
import { useAppDispatch, useAppSelector } from '@stores/hooks';
import {
  getManagementMedicineList,
  selectMedicineManagementList,
  selectTelepharmacyCount_finished_pickup,
} from '@stores/telepharmacyStore/telepharmacyList';
import { selectUserInformationPharmacistData } from '@stores/userInformationStore';
import Lottie from 'lottie-react';
import { useEffect, useState } from 'react';
import AcceptedTelepharmacyCarousel from '../acceptedTelepharmacyTable/AcceptedTelepharmacyCarousel';
import * as Style from './index.style';
import { ListContent, ListItem, Pagination } from './sections';

export const LISTITEM_FLEX = [
  { flex: 140, title: '카테고리' },
  { flex: 120, title: '보험코드' },
  { flex: 270, title: '약품명' },
  { flex: 100, title: '처방 단위' },
  { flex: 140, title: '가격' },
  { flex: 110, title: '재고 여부' },
  { flex: 190, title: '' },
];

function MedicineManagementTable() {
  const {
    openModal: openRegisterModal,
    setManagementMedicineModalType: setManagementMedicineRegisterModalType,
  } = useMedicineManagementModalHook();

  const [selectedTabIndex, setSelectedTab] = useState(0);

  const handleClickRegisterManagedMedicine = () => {
    setManagementMedicineRegisterModalType('add');
    openRegisterModal();
  };

  return (
    <Style.Container>
      <Style.ListArea>
        <Style.HeaderArea>
          <Style.TitleArea>
            <Font fontType="display2" color="fill/dark">
              의약품 관리
            </Font>
            <Style.WarningArea>
              <SvgIcon
                icon="infoIcon"
                color="state/distructive"
                width={16}
                height={16}
              />
              <Font fontType="body2" color="state/distructive">
                의약품 가격은 사입가가 아닌 조제료가 포함된 판매가로 등록해
                주세요.
              </Font>
            </Style.WarningArea>
          </Style.TitleArea>
          <Button
            width="120px"
            borderRadius="12px"
            backgroundColor={COLOR['fill/accent']}
            textColor={COLOR['fill/white']}
            title={
              <Font fontType="body1" color="fill/white">
                추가하기
              </Font>
            }
            onClick={handleClickRegisterManagedMedicine}
          />
        </Style.HeaderArea>

        <Style.OptionArea
          style={{ borderBottom: `1px solid ${COLOR['border/divider']}` }}
        >
          <Style.TabContainer>
            {managementMedicineTabList.map(({ id, label }) => (
              <Style.TabArea
                key={id}
                isActive={selectedTabIndex === id}
                onClick={() => setSelectedTab(id)}
              >
                {label}
              </Style.TabArea>
            ))}
          </Style.TabContainer>
        </Style.OptionArea>

        <ManagementMedicineList
          showCodes={managementMedicineTabList[selectedTabIndex].codes}
        />
      </Style.ListArea>

      <BFlex width="1024px" padding="1.25rem 0 0 0">
        <AcceptedTelepharmacyCarousel pageType="confirmedTreatmentPickup" />
      </BFlex>
    </Style.Container>
  );
}

interface ManagementMedicineListProps {
  showCodes: string[];
}

function ManagementMedicineList({ showCodes }: ManagementMedicineListProps) {
  const { openModal: openRegisterModal } = useMedicineManagementModalHook();
  const { useQuery } = commonHooks;
  const query = useQuery();
  const dispatch = useAppDispatch();
  const pharmacist = useAppSelector(selectUserInformationPharmacistData);
  const managementMedicineList = useAppSelector(selectMedicineManagementList);
  const telepharmacyCount = useAppSelector(
    selectTelepharmacyCount_finished_pickup,
  );

  const [limit, setLimit] = useState<number>(30);
  const [isLoading, setIsLoading] = useState(true);

  const pageQuery = query.get('page');
  const page = Number(pageQuery) || 0;
  const maxPage = telepharmacyCount
    ? Math.floor(telepharmacyCount / limit) + 1
    : 0;
  const isShowAll = showCodes.length === 0;

  useEffect(() => {
    if (pharmacist && pharmacist.pharmacy_id) {
      dispatch(
        getManagementMedicineList({
          id: pharmacist.pharmacy_id,
          offset: page * limit,
          limit,
        }),
      ).then(() => {
        setIsLoading(false);
      });
    }

    setIsLoading(true);

    return () => setIsLoading(false);
  }, [dispatch, page, limit, pharmacist]);

  const [isMedicineRegisterYet, setIsMedicineRegisterYet] = useState(false);
  const [filteredMedicineList, setFilteredMedicineList] = useState<
    GetManagedMedicineItemType[]
  >([]);

  useEffect(() => {
    const managementMedicineListClient = managementMedicineList || [];

    const filterMedicineList = managementMedicineListClient.filter((item) =>
      showCodes.includes(item.pharmacy_medicine.generic_name_code),
    );

    setFilteredMedicineList(filterMedicineList);

    setIsMedicineRegisterYet(filterMedicineList.length === 0 && !isShowAll);
  }, [managementMedicineList, isShowAll, showCodes]);

  return (
    <>
      <Style.ListContainer>
        <Style.ListHeaderItem>
          {LISTITEM_FLEX.map((item, index) => {
            return (
              <ListContent.Bold
                key={index.toString()}
                flex={item.flex}
                border="none"
              >
                {item.title}
              </ListContent.Bold>
            );
          })}
        </Style.ListHeaderItem>

        {isLoading ? (
          <Style.LoaderArea>
            <Lottie
              animationData={loadingSpinner}
              style={{ width: 48, aspectRatio: 1 }}
              loop
            />
          </Style.LoaderArea>
        ) : isMedicineRegisterYet || managementMedicineList?.length === 0 ? (
          <Style.EmptyContainer>
            <Font fontType="body2" color="fill/medium">
              아직 등록된 의약품이 없어요 <br /> 새로운 의약품을 등록하시겠어요?
            </Font>
            <Button
              width="80px"
              height="40px"
              borderRadius="8px"
              backgroundColor={COLOR['label/lightBlue']}
              textColor={COLOR['fill/white']}
              title={
                <Font fontType="body2_medium" color="fill/accent">
                  추가하기
                </Font>
              }
              onClick={openRegisterModal}
            />
          </Style.EmptyContainer>
        ) : isShowAll ? (
          managementMedicineList?.map((item) => {
            return <ListItem item={item} key={item.id} />;
          })
        ) : (
          filteredMedicineList?.map((item) => {
            return <ListItem item={item} key={item.id} />;
          })
        )}
      </Style.ListContainer>
      <Pagination page={page} maxPage={maxPage} limit={limit} />
    </>
  );
}

export default MedicineManagementTable;
