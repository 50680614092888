import { useAppSelector } from '@stores/hooks';
import { selectUserInformationIsLogin } from '@stores/userInformationStore';
import { ReactChild, ReactFragment, ReactPortal } from 'react';
import * as Style from './index.style';
import AcceptTelepharmacyModal from './modals/acceptTelepharmacyModal';
import CancelTelepharmacyModal from './modals/cancelTelepharmacyModal';
import ChangeServerURLModal from './modals/changeServerURLModal';
import CompanyInfoModal from './modals/companyInfoModal';
import CustomerCenterModal from './modals/customerCenterModal';
import FindIdPasswordModal from './modals/findIdPasswordModal';
import FinishTelepharmacyModal from './modals/finishTelepharmacyModal';
import GetReadyModal from './modals/getReadyModal';
import MedicineManagementModal from './modals/medicineManagementModal';
import MedicineManagementCommonModal from './modals/medicineManagmentCommonModal';
import ModifyMedicineGuideModal from './modals/modifyMedicineGuideModal';
import PharmacyAutoConfirmedModal from './modals/PharmacyAutoConfirmModal';
import RecheckRequestModal from './modals/recheckRequestModal';
import RejectTelepharmacyModal from './modals/rejectTelepharmacyModal';
import SetFirstLoginModal from './modals/setFirstLoginModal';
import SignupModal from './modals/signupModal';
import SymptomImageModal from './modals/symptomImageModal';

function Wrapper(props: {
  children:
    | boolean
    | ReactChild
    | ReactFragment
    | ReactPortal
    | null
    | undefined;
}) {
  const isLogin = useAppSelector(selectUserInformationIsLogin);
  return (
    <Style.Container>
      {props.children}
      <SetFirstLoginModal />
      <FindIdPasswordModal />
      <SignupModal />
      <ChangeServerURLModal />
      {isLogin ? (
        <>
          <CustomerCenterModal />
          <CompanyInfoModal />
          <RejectTelepharmacyModal />
          <AcceptTelepharmacyModal />
          <CancelTelepharmacyModal />
          <RecheckRequestModal />
          <FinishTelepharmacyModal />
          <SymptomImageModal />
          <GetReadyModal />
          <MedicineManagementModal />
          <MedicineManagementCommonModal />
          <PharmacyAutoConfirmedModal />
          <ModifyMedicineGuideModal />
        </>
      ) : null}
    </Style.Container>
  );
}

export default Wrapper;
