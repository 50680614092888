import { ServerResponse, getPrescriptionEstimatedPrice } from '@mobile/api';
import AuthError from '@mobile/pages/AuthError';
import { TPAcceptLocationState } from '@mobile/pages/TPAccept';
import { telepharmcyStorage } from '@mobile/utils';
import { useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';

export type PrescriptionEstimatedPrice = {
  generic_name: string;
  prices: {
    unit: 'PILL' | 'EA' | 'PEN';
    quantity: string;
    price: string;
  }[];
};

const usePrescriptionEstimatedPrice = () => {
  const telePharmacyId = telepharmcyStorage.telepharmacyId;

  const {
    state: { prescriptionId },
  } = useLocation() as TPAcceptLocationState;

  const navigate = useNavigate();

  const queryData = useQuery({
    queryFn: () =>
      getPrescriptionEstimatedPrice({
        prescriptionId,
        pharmacyId: Number(telePharmacyId),
      }),
    queryKey: [`TP_PRESCRIPTION_COST_${prescriptionId}`],
    retry: false,
    onError: (error) => {
      if (error instanceof AuthError) {
        navigate('/mobile/auth-error');
      }
    },
  });

  const { data: response, isLoading } = queryData;

  const prescriptionEstimatedPriceData =
    response?.status === 204
      ? undefined
      : (response?.data as ServerResponse<PrescriptionEstimatedPrice[]>);

  const isUnInsured = prescriptionEstimatedPriceData !== undefined;

  return {
    isUnInsured,
    isLoading,
    prescriptionEstimatedPriceData: prescriptionEstimatedPriceData?.data,
  };
};

export default usePrescriptionEstimatedPrice;
