import { commerceInvitationBannerWideImage, DAEHAN_LARGE } from '@assets';
import BCarousel from '@components/meraki-ui/BCarousel';

function CompletedTelepharmacyCarousel() {
  return (
    <BCarousel
      width="100%"
      height={96}
      borderRadius={8}
      imageList={[
        {
          image: DAEHAN_LARGE,
          onClick: () => {
            window?.open('https://forms.gle/Rzh6NzR7y5nTRFMs8', '_blank');
          },
        },
        {
          image: commerceInvitationBannerWideImage,
          onClick: () => {
            window?.open('https://forms.gle/D3WaNfuqNhbhjQrz9', '_blank');
          },
        },
      ]}
      interval={10000}
    />
  );
}

export default CompletedTelepharmacyCarousel;
