import { Button, COLOR, Font } from '@components/atoms';
import SvgIcon from '@components/atoms/images';
import { getSubInjectTimeFormatter } from '@functions/formatters/timeFormatter';
import { offScheduleKey } from '@pages/timeManagementPage/OffDayContainer';
import { myDoctorAPI } from '@services/myDoctor';
import { useAppSelector } from '@stores/hooks';
import { selectUserInformationUserData } from '@stores/userInformationStore';
import { logOnError } from '@utils/log';
import { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import styled from 'styled-components';
import {
  DoctorOffScheduleItemType,
  DoctorOffScheduleListType,
} from 'types/userInformationType';

interface OffDayItemProps {
  offDay: DoctorOffScheduleItemType;
}

export default function OffDayItem({ offDay }: OffDayItemProps) {
  const userData = useAppSelector(selectUserInformationUserData);

  const queryClient = useQueryClient();

  const mutation = useMutation(
    (params: Omit<DoctorOffScheduleItemType, 'id'>) => {
      return myDoctorAPI.patchDoctorInfoOffSchedule(params, offDay.id);
    },
    {
      mutationKey: [offScheduleKey],
      onSuccess: async (data) => {
        queryClient.setQueryData<DoctorOffScheduleListType | undefined>(
          [offScheduleKey],
          (scheduleList) => {
            if (scheduleList?.results?.length) {
              const index = scheduleList.results.findIndex(
                (r) => r.id === data.id,
              );

              if (index !== -1) {
                scheduleList.results.splice(index, 1);
              }
            }
            return scheduleList;
          },
        );
      },
    },
  );

  const onClick = useCallback(async () => {
    try {
      await mutation.mutateAsync({
        doctor: `${userData?.user_id ?? ''}`,
        description: '',
        the_day: '1980-01-01',
        start_time: '00:00:00',
        end_time: '00:00:00',
        is_enabled: false,
      });
    } catch (err) {
      logOnError('err', err as Error);
    }
  }, [mutation, userData?.user_id]);

  return (
    <MainContainer>
      <TitleContainer>
        <SvgIcon icon="calendar_minus" color="fill/light" width={16} />
        <Font fontType="body2" color="fill/light" noWhiteSpace>
          {offDay.description}
        </Font>
      </TitleContainer>

      <DateContainer>
        <Font fontType="h5" color="state/distructive">
          {getSubInjectTimeFormatter(offDay.the_day)}
        </Font>

        <Button
          title={<Font fontType="body3">삭제</Font>}
          backgroundColor={COLOR['fill/light']}
          onClick={onClick}
          width="39px"
          height="23px"
        />
      </DateContainer>
    </MainContainer>
  );
}

const MainContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const TitleContainer = styled.div`
  display: flex;
  gap: 0.375rem;
  width: 10rem;
  max-width: 10rem;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const DateContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;
