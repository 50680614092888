import {
  coldChainImage,
  parcelImage,
  pickupImage,
  quickImage,
  ReactBootstrapIcons,
} from '@assets';
import { Blank, Button, COLOR, Font } from '@components/atoms';
import { commonCode, styleVariables } from '@constants';
import { formatters } from '@functions';
import { commonHooks } from '@hooks';
import { myDoctorAPI } from '@services';
import printJS from 'print-js';
import { MouseEvent } from 'react';
import ReactTooltip from 'react-tooltip';
import { MyDoctorModelType } from 'types/index.d';
import { LISTITEM_FLEX } from '../../index';
import { ListContent, StatusTag } from '../index';
import * as Style from './index.style';

const { COMMON_CODE } = commonCode;
const { BREAK_POINTS } = styleVariables;

function ListItem({
  navigate,
  item,
}: {
  navigate: any;
  item: MyDoctorModelType.TelepharmacyModelType.TelepharmacyListItemModelType;
}) {
  const { phoneFormatter, priceFormatter } = formatters;
  const { useWindowSize } = commonHooks;
  const { windowWidth } = useWindowSize();

  let deliveryType;
  let iconURL;

  switch (item.method) {
    case COMMON_CODE.TP_METHOD.PARCEL:
      deliveryType = '택배';
      iconURL = parcelImage;
      break;
    case COMMON_CODE.TP_METHOD.PICKUP:
      deliveryType = '방문';
      iconURL = pickupImage;
      break;
    case COMMON_CODE.TP_METHOD.QUICK:
    case COMMON_CODE.TP_METHOD.ECONOMY_QUICK:
      deliveryType = '퀵';
      iconURL = quickImage;
      break;
    case COMMON_CODE.TP_METHOD.COLD_CHAIN:
      deliveryType = '냉장';
      iconURL = coldChainImage;
      break;
    default:
      deliveryType = '택배';
      iconURL = parcelImage;
      break;
  }

  const fetchPrescriptionData = async (tp_id: number | undefined) => {
    const { data } = await myDoctorAPI.getTelepharmacyTpprocessPrescriptions(
      Number(tp_id),
    );
    return data;
  };

  return (
    <Style.ListItem
      to={`/home/newReservation/accepted/newReservationAcceptedDetail?id=${item.id}`}
    >
      <ListContent.Element flex={LISTITEM_FLEX[1].flex}>
        <StatusTag status={item.status} paymentStatus={item.payment_status} />
      </ListContent.Element>
      <ListContent.Regular flex={LISTITEM_FLEX[2].flex}>
        {deliveryType}{' '}
        {iconURL ? (
          <Style.ListItemIcon src={iconURL} alt={deliveryType} />
        ) : undefined}
      </ListContent.Regular>
      <ListContent.Regular flex={LISTITEM_FLEX[3].flex}>
        {item.patient?.name}
        {item.telemedicine ? (
          <>
            <br />({item.telemedicine.telemedicine_hash})
          </>
        ) : null}
      </ListContent.Regular>

      <ListContent.Regular flex={LISTITEM_FLEX[4].flex}>
        {phoneFormatter.prettyPhoneNumberFormatter(
          item.patient?.phone || item.requester?.phone,
        )}
      </ListContent.Regular>
      <ListContent.Regular flex={LISTITEM_FLEX[5].flex}>
        {item.is_tp_uninsured ? '비급여' : '급여'}
      </ListContent.Regular>
      <ListContent.Regular flex={LISTITEM_FLEX[6].flex}>
        {`${priceFormatter.commaFormatter(
          item.amount ? item.amount?.toString() : '0',
        )}원`}
      </ListContent.Regular>
      <ListContent.Element
        flex={
          Number(windowWidth) < BREAK_POINTS.size1024
            ? LISTITEM_FLEX[7].flex1024
            : LISTITEM_FLEX[7].flex
        }
      >
        {item.has_subscription ? (
          <Button
            width={
              Number(windowWidth) < BREAK_POINTS.size1024 ? '32px' : '92px'
            }
            height="32px"
            borderColor={COLOR['fill/dark']}
            backgroundColor={COLOR['fill/white']}
            borderRadius={
              Number(windowWidth) < BREAK_POINTS.size1024 ? '50%' : '4px'
            }
            title={
              <>
                <Style.IconButtonItem
                  data-for={`showToolTipPrint_${item.id}`}
                  data-tip
                >
                  <ReactBootstrapIcons.Printer
                    size={12}
                    color={COLOR['fill/dark']}
                  />
                  {Number(windowWidth) >= BREAK_POINTS.size1024 && (
                    <>
                      <Blank appoint="Horizontal" size={8} />
                      <Font fontType="body3" color="fill/dark">
                        프린트
                      </Font>
                    </>
                  )}
                </Style.IconButtonItem>
                {Number(windowWidth) < BREAK_POINTS.size1024 && (
                  <ReactTooltip
                    id={`showToolTipPrint_${item.id}`}
                    isCapture
                    place="left"
                    getContent={() => '프린트'}
                  />
                )}
              </>
            }
            onClick={async (event: MouseEvent<HTMLButtonElement>) => {
              event.stopPropagation();
              event.preventDefault();
              // 처방전 수정기능 이후엔 멀쩡한 처방전으로 출력하는 기능이 추가되어야함.
              const prescriptionData = await fetchPrescriptionData(item.id);
              printJS({
                printable: prescriptionData[0].image,
                type: 'pdf',
                showModal: true,
              });
            }}
          />
        ) : null}
        <Blank appoint="Horizontal" size={4} />
        {item.has_subscription ? (
          <Button
            width={
              Number(windowWidth) < BREAK_POINTS.size1024 ? '32px' : '104px'
            }
            height="32px"
            borderColor={COLOR['fill/dark']}
            backgroundColor={COLOR['fill/white']}
            borderRadius={
              Number(windowWidth) < BREAK_POINTS.size1024 ? '50%' : '4px'
            }
            title={
              <>
                <Style.IconButtonItem
                  data-for={`showToolTipPdf_${item.id}`}
                  data-tip
                >
                  <ReactBootstrapIcons.FilePdf
                    size={12}
                    color={COLOR['fill/dark']}
                  />
                  {Number(windowWidth) >= BREAK_POINTS.size1024 && (
                    <>
                      <Blank appoint="Horizontal" size={8} />
                      <Font fontType="body3" color="fill/dark">
                        PDF 원본
                      </Font>
                    </>
                  )}
                </Style.IconButtonItem>
                {Number(windowWidth) < BREAK_POINTS.size1024 && (
                  <ReactTooltip
                    id={`showToolTipPdf_${item.id}`}
                    isCapture
                    place="left"
                    getContent={() => 'PDF 원본'}
                  />
                )}
              </>
            }
            onClick={async (event: MouseEvent<HTMLButtonElement>) => {
              event.stopPropagation();
              event.preventDefault();
              // 처방전 수정기능 이후엔 멀쩡한 처방전으로 출력하는 기능이 추가되어야함.
              const prescriptionData = await fetchPrescriptionData(item.id);
              window.open(prescriptionData ? prescriptionData[0].image : '');
            }}
          />
        ) : null}
      </ListContent.Element>
    </Style.ListItem>
  );
}

export default ListItem;
