import { COLOR } from '@components/atoms/color';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const ListDefaultItem = styled(Link)`
  display: flex;
  flex-direction: row;
  height: 60px;
  width: 100%;
  text-decoration: none;
  color: inherit;
`;

export const ListItem = styled(ListDefaultItem)`
  background-color: ${COLOR['fill/white']};
  border-top: 2px solid ${COLOR['disabled/foreground']};
  cursor: pointer;
  &:visited {
    background-color: ${COLOR['label/lightBlue']};
  }
  &:hover {
    background-color: ${COLOR['fill/light']};
  }
  &:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`;

export const IconButtonItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-left: 12px;
  margin-right: 12px;
`;

export const ListItemIcon = styled.img`
  width: 16px;
  height: 16px;
`;
