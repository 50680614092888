import { COLOR, Font } from '@components/atoms';
import {
  CallOutMessage,
  ModalCommonStructure as Modal,
} from '@components/molecules';
import { CompanyInfoModalTemplateType } from './index.d';
import * as Style from './index.style';

function CompanyInfoModalTemplate({
  isVisible,
  closeModal,
}: CompanyInfoModalTemplateType) {
  return (
    <Modal
      isOpen={isVisible}
      onRequestClose={() => closeModal()}
      modalHeader={
        <Style.TitleArea>
          <Font fontType="h1">사업자 정보 안내</Font>
        </Style.TitleArea>
      }
      modalBody={
        <>
          <Style.DescriptionArea>
            <CallOutMessage backgroundColor={COLOR.yellow50}>
              <Font fontType="body1">
                (주)메라키플레이스
                <br />
                • 대표: 선재원, 손웅래
                <br />
                • 사업자등록번호: 554-86-02396
                <br />
                • 통신판매업신고: 2021-서울서초-3474
                <br />
                • 주소: 서울특별시 강남구 영동대로85길 34, 8층
                <br />
                • 이메일: help@merakiplace.kr
                <br />
                • 전화번호: 1544-6172
                <br />
              </Font>
            </CallOutMessage>
          </Style.DescriptionArea>
        </>
      }
    />
  );
}

export default CompanyInfoModalTemplate;
