function loadChannelTalkLogin() {
  // eslint-disable-next-line func-names, consistent-return
  (function () {
    const w = window;
    if (w.ChannelIO) {
      return (window.console.error || window.console.log || function () {})(
        'ChannelIO script included twice.',
      );
    }
    // eslint-disable-next-line vars-on-top, no-var
    var ch = function () {
      // eslint-disable-next-line prefer-rest-params
      ch.c(arguments);
    };
    ch.q = [];
    ch.c = function (args) {
      ch.q.push(args);
    };
    w.ChannelIO = ch;
    function l() {
      if (w.ChannelIOInitialized) {
        return;
      }
      w.ChannelIOInitialized = true;
      const s = document.createElement('script');
      s.type = 'text/javascript';
      s.async = true;
      s.src = 'https://cdn.channel.io/plugin/ch-plugin-web.js';
      s.charset = 'UTF-8';
      const x = document.getElementsByTagName('script')[0];
      x.parentNode.insertBefore(s, x);
    }
    if (document.readyState === 'complete') {
      l();
    } else if (window.attachEvent) {
      window.attachEvent('onload', l);
    } else {
      window.addEventListener('DOMContentLoaded', l, false);
      window.addEventListener('load', l, false);
    }
  })();
  // eslint-disable-next-line no-undef
  ChannelIO('boot', {
    pluginKey: '175bf8ac-ec56-4b2e-8839-850301f472af',
  });
}

export default loadChannelTalkLogin;
