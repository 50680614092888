import { BoxContainer, COLOR } from '@components/atoms';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ListArea = styled(BoxContainer)`
  width: 1024px;
  padding: 30px;
  &.smallListArea {
    width: 902px;
  }
`;

export const HeaderArea = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

export const TitleArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const ContentArea = styled.div`
  padding: 1rem;
  border-radius: 8px;
  border: 1px solid ${COLOR['border/divider']};
`;

export const NotYetTextArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const AutoConfirmedMedicineContainer = styled.div`
  display: flex;
  gap: 24px;
`;

export const AutoConfirmedMedicineRowContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const Divider = styled.div`
  width: 1px;
  background-color: ${COLOR['border/divider']};
`;

export const AutoConfirmedMedicineArea = styled.div`
  display: flex;
  align-content: center;
  justify-content: space-between;
  width: 272px;
`;

export const AutoConfirmedMedicineTitleArea = styled.div`
  display: flex;
  gap: 8px;
`;
