import { ReactBootstrapIcons } from '@assets';
import { COLOR, Font } from '../index';
import { TextButtonType } from './index.d';
import { ButtonContainer, CheckBox } from './index.style';

function TextButton({ title, onClick, isSelected }: TextButtonType) {
  return (
    <ButtonContainer isSelected={isSelected} onClick={() => onClick()}>
      <CheckBox isSelected={isSelected}>
        {isSelected ? (
          <ReactBootstrapIcons.CheckSquareFill color={COLOR['fill/accent']} />
        ) : null}
      </CheckBox>
      <Font fontType="h4" color={isSelected ? 'fill/accent' : 'hover/accent'}>
        {title}
      </Font>
    </ButtonContainer>
  );
}

export default TextButton;
