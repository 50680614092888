import MedicineManagementTable from '@components/organisms/managementMedicineTable';
import PharmacyAutoConfirmed from '@components/organisms/pharmacyAutoConfirmed';
import * as Style from './index.style';

function MedicineManagementPage() {
  return (
    <Style.Container>
      <PharmacyAutoConfirmed />
      <MedicineManagementTable />
    </Style.Container>
  );
}

export default MedicineManagementPage;
