import useSetFirstLoginModalHook from './hooks';
import SetFirstLoginModalTemplate from './templates';

function SetFirstLoginModal() {
  const {
    isVisible,
    password,
    passwordConfirm,
    onChangePassword,
    onChangePasswordConfirm,
    buttonVisible,
    setFirstPassword,
  } = useSetFirstLoginModalHook();

  return (
    <SetFirstLoginModalTemplate
      isVisible={isVisible}
      password={password}
      passwordConfirm={passwordConfirm}
      onChangePassword={onChangePassword}
      onChangePasswordConfirm={onChangePasswordConfirm}
      buttonVisible={buttonVisible}
      setFirstPassword={setFirstPassword}
    />
  );
}

export default SetFirstLoginModal;
