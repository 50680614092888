import {
  coldChainImage,
  parcelImage,
  pickupImage,
  quickImage,
  ReactBootstrapIcons,
} from '@assets';
import { Blank, Button, COLOR, Font } from '@components/atoms';
import { commonCode, styleVariables } from '@constants';
import { formatters } from '@functions';
import { commonHooks } from '@hooks';
import { myDoctorAPI } from '@services';
import printJS from 'print-js';
import { MouseEvent, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { MyDoctorModelType } from 'types/index.d';
import { LISTITEM_FLEX } from '../../index';
import { ListContent, StatusTag } from '../index';
import * as Style from './index.style';

const { COMMON_CODE } = commonCode;
const { BREAK_POINTS } = styleVariables;

function ListItem({
  navigate,
  item,
}: {
  navigate: any;
  item: MyDoctorModelType.TelepharmacyModelType.TelepharmacyListItemModelType;
}) {
  const { timeFormatter, phoneFormatter, priceFormatter } = formatters;
  const { useWindowSize } = commonHooks;
  const { windowWidth } = useWindowSize();
  const [isButtonVisible, setIsButtonVisible] = useState(false);

  let deliveryType;
  let iconURL;

  switch (item.method) {
    case COMMON_CODE.TP_METHOD.PARCEL:
      deliveryType = '택배';
      iconURL = parcelImage;
      break;
    case COMMON_CODE.TP_METHOD.PICKUP:
      deliveryType = '방문';
      iconURL = pickupImage;
      break;
    case COMMON_CODE.TP_METHOD.QUICK:
    case COMMON_CODE.TP_METHOD.ECONOMY_QUICK:
      deliveryType = '퀵';
      iconURL = quickImage;
      break;
    case COMMON_CODE.TP_METHOD.COLD_CHAIN:
      deliveryType = '냉장';
      iconURL = coldChainImage;
      break;
    default:
      deliveryType = '택배';
      iconURL = parcelImage;
      break;
  }

  // 탈퇴 유저 이름에 붙는 '(탈퇴(Number))' 형식을 '앱 탈퇴 고객'으로 바꾸기 위한 예외처리
  let patientName = item.patient?.name || '';
  if (patientName.includes('(탈퇴')) {
    const newText = patientName.split('(');
    for (let i = 1; i < newText.length; i += 1) {
      const replaceText = newText[i].split(')')[0];
      patientName = patientName.replace(replaceText, '앱 탈퇴 고객');
    }
  }

  const fetchPrescriptionData = async (tp_id: number | undefined) => {
    const { data } = await myDoctorAPI.getTelepharmacyTpprocessPrescriptions(
      Number(tp_id),
    );
    return data;
  };

  const isEmptyObject = (param: any) => {
    if (param === undefined) return true;
    return Object.keys(param).length === 0 && param.constructor === Object;
  };

  const isEmptyObjectPharmacyProductPayment = isEmptyObject(
    item.pharmacy_product_payment,
  );

  const commerceTotalAmount =
    !isEmptyObjectPharmacyProductPayment &&
    item.pharmacy_product_payment &&
    item.pharmacy_product_payment.total_amount
      ? item.pharmacy_product_payment.total_amount
      : 0;

  const isCancelCase =
    item.status === COMMON_CODE.TP_STATUS.PH_CANCEL ||
    item.status === COMMON_CODE.TP_STATUS.P_CANCEL ||
    item.status === COMMON_CODE.TP_STATUS.REJECTED;

  return (
    <Style.ListItem
      onMouseEnter={() => setIsButtonVisible(true)}
      onMouseLeave={() => setIsButtonVisible(false)}
      to={`/home/completeTreatment/completeTreatmentDetail?id=${item.id}`}
    >
      <ListContent.Element flex={LISTITEM_FLEX[1].flex}>
        <StatusTag
          tpStatus={item.status}
          deliveryStatus={item.delivery_status}
          paymentStatus={item.payment_status}
        />
      </ListContent.Element>
      <ListContent.Regular flex={LISTITEM_FLEX[2].flex}>
        {deliveryType}{' '}
        {iconURL ? (
          <Style.ListItemIcon src={iconURL} alt={deliveryType} />
        ) : undefined}
      </ListContent.Regular>
      <ListContent.Regular flex={LISTITEM_FLEX[3].flex}>
        {isCancelCase ? (
          <div>
            <Font fontType="body3" color="fill/dark">
              -
            </Font>
          </div>
        ) : item.method === COMMON_CODE.TP_METHOD.PARCEL ? (
          item.delivery_info?.tracking_number ? (
            <>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Font fontType="body3" color="fill/dark">
                  {item.delivery_info?.tracking_number || '-'}
                </Font>
              </div>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Font fontType="body3" color="fill/dark">
                  {`(${item.delivery_info?.delivery_company_name || '-'})`}
                </Font>
              </div>
            </>
          ) : (
            <Font fontType="body3" color="fill/dark">
              -
            </Font>
          )
        ) : deliveryType === '방문' ? (
          <Font fontType="body3" color="fill/dark">
            -
          </Font>
        ) : (
          <div>
            <Font fontType="body3" color="fill/dark">
              카카오퀵
            </Font>
          </div>
        )}
      </ListContent.Regular>
      {Number(windowWidth) >= BREAK_POINTS.size1024 && (
        <ListContent.Regular flex={LISTITEM_FLEX[4].flex}>
          <Font fontType="body3" color="fill/dark">
            {item.payment_date
              ? timeFormatter.getInjectTimeFormatter(item.payment_date)
              : '-'}
          </Font>
        </ListContent.Regular>
      )}
      <ListContent.Regular flex={LISTITEM_FLEX[5].flex}>
        {patientName}
        {item.telemedicine ? (
          <>
            <br />({item.telemedicine.telemedicine_hash})
          </>
        ) : null}
      </ListContent.Regular>
      <ListContent.Regular flex={LISTITEM_FLEX[6].flex}>
        {phoneFormatter.prettyPhoneNumberFormatter(
          item.patient?.phone || item.requester?.phone,
        )}
      </ListContent.Regular>
      <ListContent.Element flex={LISTITEM_FLEX[7].flex} flexDirection="column">
        <div>
          <Font fontType="body3" color="fill/dark">
            {`${priceFormatter.commaFormatter(
              item.amount ? item.amount?.toString() : '0',
            )}원`}
          </Font>
        </div>
        <div>
          <Font fontType="body3" color="fill/dark">
            {item.is_tp_uninsured ? '(비급여)' : '(급여)'}
          </Font>
        </div>
      </ListContent.Element>
      <ListContent.Regular flex={LISTITEM_FLEX[8].flex}>
        {commerceTotalAmount > 0
          ? `${priceFormatter.commaFormatter(commerceTotalAmount.toString())}원`
          : '-'}
      </ListContent.Regular>
      <ListContent.Element flex={LISTITEM_FLEX[9].flex}>
        <Font fontType="body3" color="fill/dark">
          {item.address_string || '-'}
        </Font>
      </ListContent.Element>
      {Number(windowWidth) >= BREAK_POINTS.size1440 && (
        <ListContent.Element flex={LISTITEM_FLEX[10].flex}>
          {item.has_subscription ? (
            <Button
              width="92px"
              height="32px"
              borderColor={COLOR['fill/dark']}
              backgroundColor={COLOR['fill/white']}
              title={
                <Style.IconButtonItem>
                  <ReactBootstrapIcons.Printer
                    size={12}
                    color={COLOR['fill/dark']}
                  />
                  <Blank appoint="Horizontal" size={8} />
                  <Font fontType="body3" color="fill/dark">
                    프린트
                  </Font>
                </Style.IconButtonItem>
              }
              onClick={async (event: MouseEvent<HTMLButtonElement>) => {
                event.stopPropagation();
                event.preventDefault();
                // 처방전 수정기능 이후엔 멀쩡한 처방전으로 출력하는 기능이 추가되어야함.
                const prescriptionData = await fetchPrescriptionData(item.id);
                printJS({
                  printable: prescriptionData[0].image,
                  type: 'pdf',
                  showModal: true,
                });
              }}
            />
          ) : null}
          <Blank appoint="Horizontal" size={4} />
          {item.has_subscription ? (
            <Button
              width="104px"
              height="32px"
              borderColor={COLOR['fill/dark']}
              backgroundColor={COLOR['fill/white']}
              title={
                <Style.IconButtonItem>
                  <ReactBootstrapIcons.FilePdf
                    size={12}
                    color={COLOR['fill/dark']}
                  />
                  <Blank appoint="Horizontal" size={8} />
                  <Font fontType="body3" color="fill/dark">
                    PDF 원본
                  </Font>
                </Style.IconButtonItem>
              }
              onClick={async (event: MouseEvent<HTMLButtonElement>) => {
                event.stopPropagation();
                event.preventDefault();
                // 처방전 수정기능 이후엔 멀쩡한 처방전으로 출력하는 기능이 추가되어야함.
                const prescriptionData = await fetchPrescriptionData(item.id);
                window.open(prescriptionData ? prescriptionData[0].image : '');
              }}
            />
          ) : null}
        </ListContent.Element>
      )}
      {Number(windowWidth) < BREAK_POINTS.size1440 && isButtonVisible && (
        <Style.ButtonItemArea>
          {item.has_subscription ? (
            <>
              <Style.ButtonItem
                data-for={`showToolTipPrint_${item.id}`}
                data-tip
                onClick={async (event: MouseEvent<HTMLButtonElement>) => {
                  event.stopPropagation();
                  event.preventDefault();
                  // 처방전 수정기능 이후엔 멀쩡한 처방전으로 출력하는 기능이 추가되어야함.
                  const prescriptionData = await fetchPrescriptionData(item.id);
                  printJS({
                    printable: prescriptionData[0].image,
                    type: 'pdf',
                    showModal: true,
                  });
                }}
              >
                <ReactBootstrapIcons.Printer
                  size={16}
                  color={COLOR['fill/white']}
                />
              </Style.ButtonItem>
              <ReactTooltip
                id={`showToolTipPrint_${item.id}`}
                isCapture
                place="left"
                getContent={() => '프린트'}
              />
              <Style.ButtonItem
                data-for={`showToolTipPdf_${item.id}`}
                data-tip
                onClick={async (event: MouseEvent<HTMLButtonElement>) => {
                  event.stopPropagation();
                  event.preventDefault();
                  // 처방전 수정기능 이후엔 멀쩡한 처방전으로 출력하는 기능이 추가되어야함.
                  const prescriptionData = await fetchPrescriptionData(item.id);
                  window.open(
                    prescriptionData ? prescriptionData[0].image : '',
                  );
                }}
              >
                <ReactBootstrapIcons.FileEarmarkMedical
                  size={16}
                  color={COLOR['fill/white']}
                />
              </Style.ButtonItem>
              <ReactTooltip
                id={`showToolTipPdf_${item.id}`}
                isCapture
                place="left"
                getContent={() => 'PDF 원본'}
              />
            </>
          ) : null}
        </Style.ButtonItemArea>
      )}
    </Style.ListItem>
  );
}

export default ListItem;
