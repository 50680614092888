import { supporters } from '@functions';
import { commonHooks } from '@hooks';
import { useAppDispatch, useAppSelector } from '@stores/hooks';
import { openModal_changeServerURLModal } from '@stores/modalStore/changeServerURLModal';
import { openModal_signupModal } from '@stores/modalStore/signupModal';
import {
  fetchLogin,
  firstLogin,
  selectUserInformationIsLogin,
} from '@stores/userInformationStore';
import {
  trackFailLoginMixpanelEvents,
  trackSuccessLoginMixpanelEvents,
} from '@utils/mixpanel/login';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const useLoginPageHook = () => {
  const navigate = useNavigate();
  const { useQuery, useMount, useInput } = commonHooks;
  const query = useQuery();
  const { logoutSupporter } = supporters;

  const isLogin = useAppSelector(selectUserInformationIsLogin);
  const dispatch = useAppDispatch();

  console.log(123, process.env.REACT_APP_SERVER);

  useEffect(() => {
    if (isLogin) {
      navigate('/home', { replace: true });
    }
  });

  useMount(() => {
    const queryEmail = query.get('id');
    const queryPassword = query.get('password');

    if (queryEmail && queryPassword) {
      dispatch(firstLogin({ email: queryEmail, password: queryPassword }));
    }
  });

  const [email, onChangeEmail] = useInput('');
  const [password, onChangePassword, setPassword] = useInput('');

  const login = useCallback(async () => {
    await logoutSupporter.logout();
    const response = await dispatch(fetchLogin({ email, password }));
    const loginData = response.payload as {
      isLogin: boolean;
      message: string;
      userID: string;
      userType: string;
    };
    if (loginData?.isLogin) {
      trackSuccessLoginMixpanelEvents(loginData?.userID, loginData?.userType);
    } else {
      trackFailLoginMixpanelEvents(loginData?.message);
    }

    setPassword('');
  }, [dispatch, email, logoutSupporter, password, setPassword]);

  const openSignupModal = useCallback(() => {
    dispatch(openModal_signupModal());
  }, [dispatch]);

  const openFindIdPasswordModal = useCallback(() => {
    // dispatch(openModal_findIdPasswordModal());
    window.open('https://form.typeform.com/to/iWsUlerS', '_black');
  }, []);

  const onKeyPress = useCallback(
    (e: any) => {
      if (e.key === 'Enter') {
        login();
      }
    },
    [login],
  );

  const [logoClickCount, setLogoClickCount] = useState<number>(0);

  const increaseLogoClickCount = useCallback(() => {
    if (
      process.env.REACT_APP_SERVER === 'DEV' ||
      process.env.REACT_APP_SERVER === 'LOCAL'
    ) {
      setLogoClickCount((value) => value + 1);
      if (logoClickCount >= 5) {
        dispatch(openModal_changeServerURLModal());
        setLogoClickCount(0);
      }
    }
  }, [dispatch, logoClickCount]);

  return {
    email,
    password,
    onChangeEmail,
    onChangePassword,
    onKeyPress,
    login,
    openFindIdPasswordModal,
    openSignupModal,
    increaseLogoClickCount,
  };
};

export default useLoginPageHook;
