import { SpaceType } from '@components/meraki-ui/BTheme';
import { BREAK_POINTS } from '@constants/styleVariables';
import { useInterval } from '@hooks/commonHooks';
import { AnimatePresence, motion } from 'framer-motion';
import { useCallback, useState } from 'react';
import styled from 'styled-components';

const variants = {
  enter: () => {
    return {
      // x: direction > 0 ? 1000 : -1000,
      opacity: 0.3,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: () => {
    return {
      zIndex: 0,
      // x: direction < 0 ? 1000 : -1000,
      opacity: 0.3,
    };
  },
};

const swipeConfidenceThreshold = 2500;
const swipePower = (offset: number, velocity: number) => {
  return Math.abs(offset) * velocity;
};

interface BCarouselProps {
  width: string | number;
  height: string | number;
  borderRadius: SpaceType;
  imageList: {
    image: string;
    onClick: () => void;
  }[];
  interval?: number;
}

function BCarousel({
  width,
  height,
  borderRadius,
  imageList,
  interval,
}: BCarouselProps) {
  const [[page, direction], setPage] = useState([0, 0]);

  const paginate = useCallback(
    (newDirection: number) => {
      setPage([page + newDirection, newDirection]);
    },
    [page],
  );

  useInterval(() => {
    if (page === imageList.length - 1) {
      paginate(-1);
    } else {
      paginate(1);
    }
  }, interval);

  return (
    <>
      <AnimatePresence initial={false} custom={direction}>
        <CustomFlex borderRadius={borderRadius} style={{ overflow: 'hidden' }}>
          <motion.img
            key={page}
            src={imageList[page].image}
            onClick={imageList[page].onClick}
            custom={direction}
            variants={variants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{
              // x: { stiffness: 200, damping: 200 },
              // layout: { duration: 2 },
              opacity: { ease: 'linear', duration: 1.2 },
            }}
            drag="x"
            dragConstraints={{ left: 0, right: 0 }}
            dragElastic={1}
            onDragEnd={(e, { offset, velocity }) => {
              const swipeResult = swipePower(offset.x, velocity.x);

              if (swipeResult < -swipeConfidenceThreshold) {
                if (page === imageList.length - 1) {
                  paginate(-1);
                } else {
                  paginate(1);
                }
              } else if (swipeResult > swipeConfidenceThreshold) {
                if (page === 0) {
                  paginate(1);
                } else {
                  paginate(-1);
                }
              }
            }}
            width={width}
            height={height}
            style={{ cursor: 'pointer' }}
          />
        </CustomFlex>
      </AnimatePresence>
    </>
  );
}

const CustomFlex = styled('div')<{ borderRadius: number }>`
  display: flex;
  border-radius: ${(props) => props.borderRadius}px;
  overflow: hidden;
  @media (max-width: ${BREAK_POINTS.size720}px) {
    display: none;
  }
`;

export default BCarousel;
