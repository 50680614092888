import { getPharmacyInfo } from '@mobile/api';
import { telepharmcyStorage } from '@mobile/utils';
import { useQuery } from 'react-query';

const usePharmacyInfo = () => {
  return useQuery({
    queryFn: () =>
      getPharmacyInfo({
        pharmacy_id: Number(telepharmcyStorage.telepharmacyId),
      }),
    queryKey: ['TP_PHARMACY_INFO'],
    retry: false,
  });
};

export default usePharmacyInfo;
