import { BoxContainer, COLOR } from '@components/atoms';
import styled from 'styled-components';

export const ContentItem = styled(BoxContainer)`
  display: flex;
  flex-direction: column;
  padding: 32px;
  border-radius: 16px;
  box-shadow: 1px 1px 20px 1px rgba(196, 196, 196, 0.3);
  width: 600px;
  margin-bottom: 12px;
  margin-right: 12px;
`;

export const LeftContentItem = styled(BoxContainer)`
  overflow: hidden;
  position: relative;

  display: flex;
  flex-direction: column;
  padding: 30px;
  border-radius: 16px;
  box-shadow: 1px 1px 20px 1px rgba(196, 196, 196, 0.3);
  min-width: 780px;
`;

export const ContentHeaderItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const ContentHeaderArea = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ContentHeaderTitle = styled.div``;

export const ContentHeaderButton = styled.div`
  display: flex;
  align-items: center;
`;

export const ContentBodyArea = styled.div``;

export const PrescriptionContent = styled.div`
  display: flex;
  border: 1px solid ${COLOR['fill/dark']};
  border-radius: 4px;
  flex: 1;
  flex-direction: column;
`;

export const PrescriptionHeader = styled.div`
  display: flex;
  position: relative;

  background-color: ${COLOR['fill/dark']};
  height: 30px;
  align-items: center;
  // justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
`;

export const Prescription = styled.div`
  flex: 1;
  position: relative;
  padding: 4px;
`;

export const ChangePageButton = styled.img`
  cursor: pointer;
  width: 16px;
  height: 16px;
  margin-right: 10px;
`;

export const LoaderArea = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 20px 0;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const DownloadContainer = styled.a`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;
  border: 0;
  outline: none;
`;
