import Modal from 'react-modal';
import { modalCommonStyle } from '@components/atoms';
import { ReactBootstrapIcons } from '@assets';
import { ModalCommonStructureType } from './index.d';
import {
  ModalBody,
  ModalCloseButtonArea,
  ModalFooter,
  ModalHeader,
} from './index.style';

function ModalCommonStructure({
  isOpen,
  modalStyle = modalCommonStyle,
  onRequestClose,
  onAfterOpen,
  modalHeader,
  modalBody,
  modalFooter,
  closeButton = true,
  isRecheckModal = false,
}: ModalCommonStructureType) {
  return (
    <Modal
      isOpen={isOpen}
      style={modalStyle}
      onRequestClose={() => (onRequestClose ? onRequestClose() : {})}
      onAfterOpen={() => (onAfterOpen ? onAfterOpen() : {})}
      appElement={document.getElementById('root') as HTMLElement}
    >
      <ModalHeader isRecheckModal={isRecheckModal}>
        {modalHeader}
        {closeButton ? (
          <ModalCloseButtonArea>
            <ReactBootstrapIcons.XLg
              size={24}
              onClick={() => (onRequestClose ? onRequestClose() : {})}
            />
          </ModalCloseButtonArea>
        ) : null}
      </ModalHeader>
      <ModalBody isRecheckModal={isRecheckModal}>{modalBody}</ModalBody>
      {modalFooter ? <ModalFooter>{modalFooter}</ModalFooter> : null}
    </Modal>
  );
}

export default ModalCommonStructure;
