import { blueInfo, dot } from '@assets/index';
import styled from 'styled-components';

function InsureInfo() {
  return (
    <InfoContainer>
      <InfoWrapper>
        <InfoTitle>
          <InfoIcon src={blueInfo} />
          <div style={{ paddingTop: '2px' }}>
            <Text>약제비를 확인하고 입력해주세요.</Text>
          </div>
        </InfoTitle>

        <InfoContent>
          <InfoRow>
            <DotIcon src={dot} />
            <InfoRowText>
              {
                '비급여 약제비 계산 기능은 가격 정보를 제공해주신\n일부 의약품에서만 가능해요.'
              }
            </InfoRowText>
          </InfoRow>
          <InfoRow>
            <DotIcon src={dot} />
            <InfoRowText>
              급여 약제비 계산 기능은 곧 제공될 예정이에요.
            </InfoRowText>
          </InfoRow>
        </InfoContent>
      </InfoWrapper>
    </InfoContainer>
  );
}

export default InsureInfo;

const InfoContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 12px 16px;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  border-radius: 8px;
  background: var(--fill-light, #f9fafa);
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
  align-self: stretch;
`;

const InfoTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  align-self: stretch;
`;

const InfoIcon = styled.img`
  width: 20px;
  height: 20px;
`;

const InfoContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
`;

const DotIcon = styled.img`
  width: 20px;
  height: 22px;
`;

const InfoRow = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 6px;
  align-self: stretch;
`;

const InfoRowText = styled.div`
  color: var(--fill-dark, #474a52);

  font-family: Pretendard, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.28px;
  white-space: pre-line;
`;

const Text = styled.div`
  color: #474a52;

  font-family: Pretendard, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.32px;
`;
