import CalendarIcon from '@components/atoms/images/CalendarIcon';
import CalendarMinusIcon from '@components/atoms/images/CalendarMinusIcon';
import CheckIcon from '@components/atoms/images/CheckIcon';
import ExclamationIcon from '@components/atoms/images/ExclamationIcon';
import XLgIcon from '@components/atoms/images/XLgIcon';
import { useMemo } from 'react';
import styled from 'styled-components';
import { ColorType } from '../color';
import CheckCircleIcon from './CheckCircle';
import CheckLargeIcon from './CheckLargeIcon';
import DrugIcon from './DrugIcon';
import InfoIcon from './InfoIcon';
import MedicineIcon from './MedicineIcon';
import PinkPillIcon from './PinkPillIcon';
import SearchIcon from './SearchIcon';
import ShotIcon from './ShotIcon';
import YellowPillIcon from './YellowPillIcon';

export type IconType =
  | 'xLg'
  | 'xSm'
  | 'check'
  | 'exclamation'
  | 'calendar'
  | 'calendar_minus'
  | 'check_large'
  | 'checkCircleIcon'
  | 'searchIcon'
  | 'infoIcon'
  | 'pinkPillIcon'
  | 'yellowPillIcon'
  | 'drugIcon'
  | 'medicineIcon'
  | 'shotIcon';

interface SvgIconProps {
  icon: IconType;
  width: number;
  height?: number;
  color: ColorType;
  onClick?: () => void;
}

export type IconDetailProps = Omit<SvgIconProps, 'icon' | 'onClick'>;

export default function SvgIcon({
  icon,
  width,
  height,
  color,
  onClick,
}: SvgIconProps) {
  const iconMap: { [iconType: string]: JSX.Element } = useMemo(() => {
    return {
      xLg: <XLgIcon width={width} height={height} color={color} />,
      xSm: <XLgIcon width={width} height={height} color={color} />,
      check: <CheckIcon width={width} height={height} color={color} />,
      exclamation: (
        <ExclamationIcon width={width} height={height} color={color} />
      ),
      calendar: <CalendarIcon width={width} height={height} color={color} />,
      calendar_minus: (
        <CalendarMinusIcon width={width} height={height} color={color} />
      ),
      check_large: (
        <CheckLargeIcon width={width} height={height} color={color} />
      ),
      checkCircleIcon: (
        <CheckCircleIcon width={width} height={height} color={color} />
      ),
      searchIcon: <SearchIcon width={width} height={height} color={color} />,
      infoIcon: <InfoIcon width={width} height={height} color={color} />,
      pinkPillIcon: (
        <PinkPillIcon width={width} height={height} color={color} />
      ),
      yellowPillIcon: (
        <YellowPillIcon width={width} height={height} color={color} />
      ),
      drugIcon: <DrugIcon width={width} height={height} color={color} />,
      medicineIcon: (
        <MedicineIcon width={width} height={height} color={color} />
      ),
      shotIcon: <ShotIcon width={width} height={height} color={color} />,
    };
  }, [color, height, width]);

  return (
    <IconContainer clickable={Boolean(onClick)} onClick={onClick}>
      {iconMap[icon]}
    </IconContainer>
  );
}

const IconContainer = styled.div<{ clickable: boolean }>`
  cursor: ${(props) => (props.clickable ? 'pointer' : undefined)};
  display: flex;
  align-items: center;
`;
