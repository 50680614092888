import { BoxContainer } from '@components/atoms';
import styled from 'styled-components';

export const ContentItem = styled(BoxContainer)`
  display: flex;
  flex-direction: column;
  padding: 30px;
  border-radius: 16px;
  box-shadow: 1px 1px 20px 1px rgba(196, 196, 196, 0.3);
  width: 600px;
`;

export const RightContentItem = styled(ContentItem)`
  width: 480px;
`;

export const ContentHeaderArea = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ContentHeaderTitle = styled.div``;

export const ContentBodyArea = styled.div``;

export const ContentBodyContainer = styled.div`
  display: flex;
  padding: 12px 0;
`;

export const ContentBodyTitleArea = styled.div`
  display: flex;
  width: 180px;
  align-items: flex-start;
`;

export const ContentBodyIcon = styled.div`
  margin-right: 8px;
`;

export const ContentBodyTitle = styled.div``;

export const ContentBodyTextArea = styled.div`
  display: flex;
  position: relative;
  width: 260px;
`;

export const ContentBodyText = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-right: 16px;
  width: 260px;
  height: 100%;
  word-wrap: break-word;
`;

export const ContentBodyTextIcon = styled.img`
  width: 16px;
  height: 16px;
`;
