import { commerceInvitationBannerNarrowImage, DAEHAN_NARROW } from '@assets';
import BCarousel from '@components/meraki-ui/BCarousel';
import { PageType } from '@utils/mixpanel/constants';

type PageParams = Exclude<
  PageType,
  'completeTreatment' | 'newReservationRequested'
>;

function AcceptedTelepharmacyCarousel({ pageType }: { pageType: PageParams }) {
  const onClickCommerceInvitation = () => {
    window?.open('https://forms.gle/D3WaNfuqNhbhjQrz9', '_blank');
  };

  const openDaehanparm = () => {
    window?.open('https://forms.gle/Rzh6NzR7y5nTRFMs8', '_blank');
  };

  return (
    <BCarousel
      width="100%"
      height={96}
      borderRadius={8}
      imageList={[
        {
          image: DAEHAN_NARROW,
          onClick: openDaehanparm,
        },
        {
          image: commerceInvitationBannerNarrowImage,
          onClick: onClickCommerceInvitation,
        },
      ]}
      interval={30000}
    />
  );
}

export default AcceptedTelepharmacyCarousel;
