import {
  coldChainImage,
  parcelImage,
  pickupImage,
  quickImage,
  ReactBootstrapIcons,
} from '@assets';
import { Button, COLOR, Font } from '@components/atoms';
import SvgIcon from '@components/atoms/images';
import { TP_FLOW_STATUS_CODE } from '@components/organisms/telepharmacyDetailInformation/sections/progressInformationPartItem/progressStatus';
import { commonCode, styleVariables } from '@constants';
import { formatters } from '@functions';
import { timeFormatter } from '@functions/formatters';
import { commonHooks } from '@hooks';
import { myDoctorAPI } from '@services';
import fetchAPI from '@utils/fetchAPI';
import { MouseEvent, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { MyDoctorModelType } from 'types/index.d';
import { LISTITEM_FLEX } from '../../index';
import { ListContent } from '../index';
import * as Style from './index.style';

const { COMMON_CODE } = commonCode;
const { BREAK_POINTS } = styleVariables;

function ListItem({
  navigate,
  item,
}: {
  navigate: any;
  item: MyDoctorModelType.TelepharmacyModelType.TelepharmacyListItemModelType;
}) {
  const { phoneFormatter, priceFormatter } = formatters;
  const { useWindowSize } = commonHooks;
  const { windowWidth } = useWindowSize();
  const [isButtonVisible, setIsButtonVisible] = useState(false);

  let deliveryType;
  let iconURL;

  switch (item.method) {
    case COMMON_CODE.TP_METHOD.PARCEL:
      deliveryType = '택배';
      iconURL = parcelImage;
      break;
    case COMMON_CODE.TP_METHOD.PICKUP:
      deliveryType = '방문수령';
      iconURL = pickupImage;
      break;
    case COMMON_CODE.TP_METHOD.QUICK:
    case COMMON_CODE.TP_METHOD.ECONOMY_QUICK:
      deliveryType = '퀵';
      iconURL = quickImage;
      break;
    case COMMON_CODE.TP_METHOD.COLD_CHAIN:
      deliveryType = '냉장';
      iconURL = coldChainImage;
      break;
    default:
      deliveryType = '택배';
      iconURL = parcelImage;
      break;
  }

  const fetchPrescriptionData = async (tp_id: number | undefined) => {
    const { data } = await myDoctorAPI.getTelepharmacyTpprocessPrescriptions(
      Number(tp_id),
    );
    return data;
  };

  const isEmptyObject = (param: any) => {
    if (param === undefined) return true;
    return Object.keys(param).length === 0 && param.constructor === Object;
  };

  const isEmptyObjectPharmacyProductPayment = isEmptyObject(
    item.pharmacy_product_payment,
  );

  const commerceTotalAmount =
    !isEmptyObjectPharmacyProductPayment &&
    item.pharmacy_product_payment &&
    item.pharmacy_product_payment.total_amount
      ? item.pharmacy_product_payment.total_amount
      : 0;

  const deliveryStatus = item.delivery_status?.split('__');

  const commonCodeDeliveryStatus = deliveryStatus && deliveryStatus[0];
  const commonCodeDeliveryStatusDetail = deliveryStatus && deliveryStatus[1];

  return (
    <Style.ListItem
      onMouseEnter={() => setIsButtonVisible(true)}
      onMouseLeave={() => setIsButtonVisible(false)}
      to={`/home/confirmedTreatment/pick-up/confirmedPickupDetail?id=${item.id}`}
    >
      <ListContent.Regular flex={LISTITEM_FLEX[0].flex}>
        {deliveryType}
        {iconURL ? (
          <Style.ListItemIcon src={iconURL} alt={deliveryType} />
        ) : undefined}
      </ListContent.Regular>

      <ListContent.Regular flex={LISTITEM_FLEX[1].flex}>
        {commonCodeDeliveryStatusDetail
          ? TP_FLOW_STATUS_CODE[commonCodeDeliveryStatusDetail]
          : '-'}
      </ListContent.Regular>

      <ListContent.Regular flex={LISTITEM_FLEX[2].flex}>
        {timeFormatter.getInjectTimeFormatter(item.created)}
      </ListContent.Regular>

      <ListContent.Regular flex={LISTITEM_FLEX[3].flex}>
        {item.patient?.name}
        {item.telemedicine ? (
          <>{` (${item.telemedicine.telemedicine_hash})`}</>
        ) : null}
      </ListContent.Regular>

      <ListContent.Regular flex={LISTITEM_FLEX[4].flex}>
        {phoneFormatter.prettyPhoneNumberFormatter(
          item.patient?.phone || item.requester?.phone,
        )}
      </ListContent.Regular>

      <ListContent.Element flex={LISTITEM_FLEX[5].flex}>
        <Font fontType="body2">
          {`${priceFormatter.commaFormatter(
            item.amount ? item.amount?.toString() : '0',
          )}원`}
        </Font>
      </ListContent.Element>

      <ListContent.Element flex={LISTITEM_FLEX[6].flex}>
        <Button
          width="90px"
          height="32px"
          backgroundColor={COLOR['fill/accent']}
          title={
            <Style.IconButtonItem
              data-for={`showToolTipFinishTelepharmacy_${item.id}`}
              data-tip
            >
              <SvgIcon icon="checkCircleIcon" width={16} color="fill/white" />
              <Font fontType="body2" color="fill/white">
                방문완료
              </Font>
            </Style.IconButtonItem>
          }
          onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
            fetchAPI
              .post(
                `pharmacist-api/telepharmacy/tpprocess/${item.id}/pickup-finish/`,
                {},
                'myDoctor',
              )
              .then((response) => {
                if (response.status === 200) {
                  alert('방문 완료되었습니다.');
                  navigate('/home/confirmedTreatment/pick-up');
                }
              });
          }}
        />
      </ListContent.Element>

      {Number(windowWidth) < BREAK_POINTS.size1024 && isButtonVisible && (
        <Style.ButtonItemArea>
          {item.has_subscription ? (
            <>
              <Style.ButtonItem
                data-for={`showToolTipPrescription_${item.id}`}
                data-tip
                onClick={async (event: MouseEvent<HTMLButtonElement>) => {
                  event.stopPropagation();
                  event.preventDefault();
                  // 처방전 수정기능 이후엔 멀쩡한 처방전으로 출력하는 기능이 추가되어야함.
                  const prescriptionData = await fetchPrescriptionData(item.id);
                  window.open(
                    prescriptionData ? prescriptionData[0].image : '',
                  );
                }}
              >
                <ReactBootstrapIcons.FileEarmarkMedical
                  size={16}
                  color={COLOR['fill/white']}
                />
              </Style.ButtonItem>
              <ReactTooltip
                id={`showToolTipPrescription_${item.id}`}
                isCapture
                place="left"
                getContent={() => '처방전'}
              />
            </>
          ) : null}
        </Style.ButtonItemArea>
      )}
    </Style.ListItem>
  );
}

export default ListItem;
