import { blueInfo, dot } from '@assets/index';
import { PrescriptionEstimatedPrice } from '@mobile/api/hooks/usePrescriptionEstimatedPrice';
import styled from 'styled-components';
import { parseEstimatedPriceData } from './utils';

type Props = {
  estimatedPriceData?: PrescriptionEstimatedPrice[];
  sumedUnitList?: number[];
};

function UnInsureInfo({ estimatedPriceData, sumedUnitList }: Props) {
  const { totalPrice, results } = parseEstimatedPriceData(
    estimatedPriceData,
    sumedUnitList,
  );

  return (
    <InfoContainer>
      <InfoWrapper>
        <InfoTitle>
          <InfoIcon src={blueInfo} />
          <div style={{ paddingTop: '2px' }}>
            <Text>{`비급여 예상 약제비 : ${totalPrice.toLocaleString()}원`}</Text>
          </div>
        </InfoTitle>

        <InfoContent>
          {results.map((item, index) => (
            <InfoRow key={index}>
              <DotIcon src={dot} />
              <InfoRowText>{item}</InfoRowText>
            </InfoRow>
          ))}

          <InfoRow>
            <DotIcon src={dot} />
            <InfoRowText>
              제출해주신 비급여 가격 기준으로 계산되었어요.
            </InfoRowText>
          </InfoRow>
        </InfoContent>
      </InfoWrapper>
    </InfoContainer>
  );
}

export default UnInsureInfo;

const InfoContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 12px 16px;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  border-radius: 8px;
  background: var(--fill-light, #f9fafa);
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
  align-self: stretch;
`;

const InfoTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  align-self: stretch;
`;

const InfoIcon = styled.img`
  width: 20px;
  height: 20px;
`;

const InfoContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
`;

const DotIcon = styled.img`
  width: 20px;
  height: 22px;
`;

const InfoRow = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 6px;
  align-self: stretch;
`;

const InfoRowText = styled.div`
  color: var(--fill-dark, #474a52);

  font-family: Pretendard, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.28px;
  white-space: pre-line;
`;

const Text = styled.div`
  color: #474a52;

  font-family: Pretendard, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.32px;
`;
