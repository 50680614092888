import { createSlice } from '@reduxjs/toolkit';
import { GetManagedMedicineItemType } from '@services/myDoctor/types';
import { RootState } from '@stores';

export type RegisterModalType = 'add' | 'edit';
interface InitialStateType {
  isVisible: boolean;
  modalType: RegisterModalType | null;
  targetMedicine: GetManagedMedicineItemType | null;
}

const initialState: InitialStateType = {
  isVisible: false,
  modalType: null,
  targetMedicine: null,
};

export const medicineManagementModal = createSlice({
  name: 'modal/medicineManagementModal',
  initialState,
  reducers: {
    openModal_medicineManagementModal: (state) => {
      state.isVisible = true;
    },
    closeModal_medicineManagementModal: (state) => {
      state.isVisible = false;
    },
    set_modalType_medicineManagementModal: (state, action) => {
      state.modalType = action.payload;
    },
    set_targetMedicine_medicineManagementModal: (state, action) => {
      state.targetMedicine = action.payload;
    },
  },
});

export const {
  openModal_medicineManagementModal,
  closeModal_medicineManagementModal,
  set_modalType_medicineManagementModal,
  set_targetMedicine_medicineManagementModal,
} = medicineManagementModal.actions;

export const selectMedicineManagementModalVisible = (state: RootState) =>
  state.medicineManagementModal.isVisible;

export const selectMedicineManagementModalModalType = (state: RootState) =>
  state.medicineManagementModal.modalType;

export const selectMedicineManagementModalTargetMedicine = (state: RootState) =>
  state.medicineManagementModal.targetMedicine;

export default medicineManagementModal.reducer;
