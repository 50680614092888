import { Mixpanel } from '@utils/mixpanel';
import { clickAdBanner, pageType } from '@utils/mixpanel/constants';

export const confirmedTreatmentConfirmedMixpanelEvents = {
  pageConfirmedTreatmentConfirmed: '[Page] TP Requested',

  clickAdBanner,
} as const;

type ConfirmedTreatmentConfirmedMixpanelEvents =
  keyof typeof confirmedTreatmentConfirmedMixpanelEvents;

const confirmedTreatmentConfirmedMixpanelEventsMap: Record<
  ConfirmedTreatmentConfirmedMixpanelEvents,
  (adID?: string, adType?: string) => void
> = {
  pageConfirmedTreatmentConfirmed: () =>
    Mixpanel.track(
      confirmedTreatmentConfirmedMixpanelEvents.pageConfirmedTreatmentConfirmed,
    ),

  clickAdBanner: (adID?: string, adType?: string) =>
    Mixpanel.track(confirmedTreatmentConfirmedMixpanelEvents.clickAdBanner, {
      'Page Type': pageType.confirmedTreatmentConfirmed,
      'AD ID': adID,
      'AD Type': adType,
    }),
};

export const trackConfirmedTreatmentConfirmedMixpanelEvents = (
  confirmedTreatmentConfirmedMixpanelEvent: ConfirmedTreatmentConfirmedMixpanelEvents,
) => {
  return confirmedTreatmentConfirmedMixpanelEventsMap[
    confirmedTreatmentConfirmedMixpanelEvent
  ];
};
