import { lightImage, ReactBootstrapIcons } from '@assets';
import { Blank, COLOR, Font } from '@components/atoms';
import BFlex from '@components/meraki-ui/BFlex';
import { CallOutMessage } from '@components/molecules';
import { TelepharmacyListItem } from '@components/organisms';
import { pathnameList } from '@constants';
import { commonHooks } from '@hooks';
import { useInterval, useMount } from '@hooks/commonHooks';
import { useAppDispatch, useAppSelector } from '@stores/hooks';
import { openModal_noticeModal } from '@stores/modalStore/noticeModal';
import {
  getTelepharmacyCount,
  selectTelepharmacyCount_requested_expire_min,
} from '@stores/telepharmacyStore/telepharmacyCount';
import {
  getTelepharmacyList_requested,
  selectTelepharmacyCount_requested,
  selectTelepharmacyList_requested,
} from '@stores/telepharmacyStore/telepharmacyList';
import { storageHandler } from '@utils';
import { trackNewReservationRequestedMixpanelEvents } from '@utils/mixpanel/newReservation/requested';
import { calcAutorejectMinute } from '@utils/timeCalc';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { TelepharmacyListItemModelType } from 'types/myDoctorModelType/telepharmacyModelType';
import * as Style from './index.style';
import LNBCarousel from './LNBCarousel';

const { PHARMACIST_ADMIN_PATHNAME } = pathnameList;

function NewReservationPage({ children }: { children: ReactNode }) {
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { getLocalStorageState } = storageHandler;
  const { useQuery } = commonHooks;
  const [isLoading, setIsLoading] = useState(true);
  const query = useQuery();

  const isRequestedPage = pathname.includes('/requested');

  const [acceptedIsActive, setAcceptedIsActive] = useState<boolean>(false);
  const requestedTelepharmacyCount = useAppSelector(
    selectTelepharmacyCount_requested,
  );
  const requestedTelepharmacyList = useAppSelector(
    selectTelepharmacyList_requested,
  );
  const autoRejectAlertMinute = useAppSelector(
    selectTelepharmacyCount_requested_expire_min,
  );
  const [almostAutoRejectCount, setAlmostAutoRejectCount] = useState<number>(0);
  const updateAlmostAutoRejectCount = useCallback(() => {
    let count = 0;
    if (requestedTelepharmacyList) {
      requestedTelepharmacyList.forEach(
        (item: TelepharmacyListItemModelType) => {
          if (calcAutorejectMinute(item) <= autoRejectAlertMinute) {
            count += 1;
          }
        },
      );
    }
    setAlmostAutoRejectCount(count);
  }, [
    setAlmostAutoRejectCount,
    requestedTelepharmacyList,
    autoRejectAlertMinute,
  ]);

  useCallback(() => {
    updateAlmostAutoRejectCount();
  }, [updateAlmostAutoRejectCount]);
  useInterval(() => {
    updateAlmostAutoRejectCount();
  }, 5000);
  const telepharmacyId = Number(query.get('id'));

  useMount(() => {
    trackNewReservationRequestedMixpanelEvents('pageNewReservationRequested')();
  });

  useEffect(() => {
    if (
      requestedTelepharmacyList &&
      requestedTelepharmacyList.length === 0 &&
      pathname === `/home/newReservation/requested`
    ) {
      navigate(`/home/newReservation/requested`);
    } else if (
      !telepharmacyId &&
      requestedTelepharmacyList &&
      requestedTelepharmacyList.length > 0 &&
      pathname.indexOf('/home/newReservation/requested') > -1
    ) {
      navigate(`?id=${requestedTelepharmacyList[0].id}`);
    }
  }, [navigate, pathname, requestedTelepharmacyList, telepharmacyId]);

  useEffect(() => {
    if (pathname.indexOf('/home/newReservation/requested') > -1) {
      setIsLoading(true);
      Promise.all([
        dispatch(getTelepharmacyCount()),
        dispatch(getTelepharmacyList_requested({ limit: 60, offset: 0 })),
      ]).then(() => {
        setIsLoading(false);
      });
    }
    return () => setIsLoading(false);
  }, [dispatch, pathname]);

  useEffect(() => {
    const popUpKey = 'pharmacistAdminPopup';
    const popUpValue = 'show2';
    if (getLocalStorageState(popUpKey) !== popUpValue) {
      dispatch(openModal_noticeModal());
    }
  }, [dispatch, getLocalStorageState]);

  useEffect(() => {
    if (pathname.includes('/accepted')) {
      setAcceptedIsActive(true);
    } else {
      setAcceptedIsActive(false);
    }

    return () => setAcceptedIsActive(false);
  }, [pathname]);

  return (
    <Style.Container
      acceptedIsActive={acceptedIsActive}
      isRequestedPage={isRequestedPage}
    >
      {pathname === PHARMACIST_ADMIN_PATHNAME.NEW_RESERVATION_REQUESTED ? (
        <Style.ListContainer>
          <Style.ListArea minHeight={176} width={368}>
            <Style.ListTitleArea>
              <Style.ListTitle>
                <Font fontType="body1" color="fill/black">
                  접수대기
                </Font>
              </Style.ListTitle>
              <Style.ListIcon>
                <ReactBootstrapIcons.QuestionCircle
                  size={12}
                  data-for="showToolTip_requested"
                  data-tip
                />
                <ReactTooltip
                  id="showToolTip_requested"
                  place="right"
                  isCapture
                >
                  <Style.TooltipMessageArea>
                    <Blank appoint="Vertical" size={4} />
                    <Style.TooltipMessage>
                      <Style.TooltipImage src={lightImage} alt="light" />
                      접수대기
                    </Style.TooltipMessage>
                    <Blank appoint="Vertical" size={12} />
                    <Style.TooltipMessage>
                      ㆍ 약사님의 조제수락을 기다리는
                    </Style.TooltipMessage>
                    <Blank appoint="Vertical" size={4} />
                    <Style.TooltipMessage>
                      &emsp;신규 조제요청입니다.
                    </Style.TooltipMessage>
                    <Blank appoint="Vertical" size={8} />
                    <Style.TooltipMessage>
                      ㆍ 10분 동안 수락되지 않은 조제는
                    </Style.TooltipMessage>
                    <Blank appoint="Vertical" size={4} />
                    <Style.TooltipMessage>
                      &emsp;자동으로 취소됩니다.
                    </Style.TooltipMessage>
                    <Blank appoint="Vertical" size={4} />
                  </Style.TooltipMessageArea>
                </ReactTooltip>
              </Style.ListIcon>
            </Style.ListTitleArea>
            <Style.ListMiddleArea>
              <Style.ListCountArea>
                <Font fontType="display2" color="fill/black">
                  {requestedTelepharmacyCount}
                </Font>{' '}
                <Font fontType="body3" color="fill/black">
                  건
                </Font>
              </Style.ListCountArea>
              <Style.ListFilterButtonArea>
                <Font fontType="body3" color="fill/black">
                  요청순
                </Font>
              </Style.ListFilterButtonArea>
            </Style.ListMiddleArea>
            <Style.TelepharmacyListArea>
              {almostAutoRejectCount > 0 ? (
                <>
                  <CallOutMessage
                    iconColor="fill/white"
                    backgroundColor={COLOR['state/distructive']}
                  >
                    <Font fontType="body1" color="fill/white">
                      {almostAutoRejectCount}건의 요청이 {autoRejectAlertMinute}
                      분 내 만료됩니다.
                    </Font>
                  </CallOutMessage>
                  <Blank appoint="Vertical" size={12} />
                </>
              ) : null}
              {requestedTelepharmacyCount ? (
                requestedTelepharmacyList?.map((item, index) => (
                  <div key={item.id}>
                    <TelepharmacyListItem
                      isSelected={item.id === telepharmacyId}
                      item={item}
                      onClick={() => {
                        navigate(
                          `/home/newReservation/requested?id=${item.id}`,
                        );
                      }}
                    />
                    {index === almostAutoRejectCount - 1 ? (
                      <Style.TelepharmacyListDivider />
                    ) : null}
                  </div>
                ))
              ) : (
                <Style.EmptyContainer>
                  <Font fontType="h5">접수대기 중인 조제가 없습니다.</Font>
                </Style.EmptyContainer>
              )}
            </Style.TelepharmacyListArea>
          </Style.ListArea>
          <Blank appoint="Vertical" size={20} />
        </Style.ListContainer>
      ) : undefined}
      <Style.CardArea acceptedIsActive={acceptedIsActive}>
        {children}
      </Style.CardArea>
      {pathname === PHARMACIST_ADMIN_PATHNAME.NEW_RESERVATION_REQUESTED ? (
        <BFlex position="fixed" bottom="2.5rem" left="1.25rem" zIndex={100}>
          <LNBCarousel />
        </BFlex>
      ) : undefined}
    </Style.Container>
  );
}

export default NewReservationPage;
