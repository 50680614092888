import { mobileParcel, mobilePickup, mobileQuick } from '@assets/index';
import { COMMON_CODE } from '@constants/commonCode';
import Badge from '@mobile/components/common/Badge';
import styled from 'styled-components';

type Props = {
  deliveryMethod?: string;
  patientName?: string;
  symptom?: string;
  reservationCode?: string;
};

function PatientInfo({
  deliveryMethod = '-',
  patientName = '-',
  symptom = '-',
  reservationCode = '-',
}: Props) {
  const renderImage = () => {
    if (deliveryMethod === COMMON_CODE.TP_METHOD.PARCEL) return mobileParcel;
    if (deliveryMethod === COMMON_CODE.TP_METHOD.QUICK) return mobileQuick;
    return mobilePickup;
  };

  return (
    <Container>
      <Image src={renderImage()} />
      <Contents>
        <NameWrapper>
          <Text>{`${patientName} 환자`}</Text>
          <ReservationCode>{reservationCode.toUpperCase()}</ReservationCode>
        </NameWrapper>
        <BadgeArea>
          <Badge type="default" text={symptom} />
        </BadgeArea>
      </Contents>
    </Container>
  );
}

export default PatientInfo;

const Container = styled.section`
  width: 100%;
  display: flex;
  padding: 12px 20px 32px 20px;
  align-items: center;
  gap: 12px;
  border-bottom: 4px solid #f1f2f4;
`;

const Image = styled.img`
  width: 48px;
  height: 56px;
`;

const Contents = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
  align-self: stretch;
`;

const Text = styled.div`
  color: #1e2024;

  font-family: Pretendard, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
`;

const BadgeArea = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const NameWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

const ReservationCode = styled.div`
  color: #9fa1a8;

  font-family: Pretendard, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.32px;
`;
