import { PrescriptionEstimatedPrice } from '@mobile/api/hooks/usePrescriptionEstimatedPrice';

const UNIT = {
  PILL: '정',
  EA: '개',
  PEN: '펜',
} as const;

export const parseEstimatedPriceData = (
  estimatedPriceData?: PrescriptionEstimatedPrice[],
  sumedUnitList?: number[],
) => {
  const results: string[] = [];
  let totalPrice = 0;

  estimatedPriceData?.forEach((item, index) => {
    item.prices.forEach((priceInfo) => {
      const unitCost = Number(priceInfo.price) / Number(priceInfo.quantity);

      totalPrice += unitCost * Number(sumedUnitList?.[index]);

      const result = `${item.generic_name} ${Number(
        unitCost.toFixed(),
      ).toLocaleString()}원 * ${sumedUnitList?.[index]}${
        UNIT[priceInfo.unit]
      } = ${(unitCost * Number(sumedUnitList?.[index])).toLocaleString()}원`;

      results.push(result);
    });
  });

  return { results, totalPrice };
};
