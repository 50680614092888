export const SocketServerConstant = () => {
  let socketServerURL;
  switch (process.env.REACT_APP_SERVER) {
    case 'PRODUCTION':
      socketServerURL = 'wss://socket.merakiplace.work/pharmacist';
      break;
    case 'DEV':
      socketServerURL = 'wss://socket-dev.merakiplace.work/pharmacist';
      break;
    case 'DEBUGGING':
      socketServerURL = 'wss://socket-prod.merakiplace.work/pharmacist';
      break;
    case 'LOCAL':
      socketServerURL = 'wss://socket-dev.merakiplace.work/pharmacist';
      break;
    default:
      socketServerURL = 'wss://socket-dev.merakiplace.work/pharmacist';
      break;
  }
  return socketServerURL;
};
