import { coldChainImage, parcelImage, pickupImage, quickImage } from '@assets';
import { Blank, Button, COLOR, Font } from '@components/atoms';
import SvgIcon from '@components/atoms/images';
import PrescriptionItem from '@components/organisms/telepharmacyDetailInformation/sections/prescriptionItem';
import { commonCode, styleVariables } from '@constants';
import { commonHooks } from '@hooks';
import useFinishTelepharmacyModalHook from '@pages/wrapper/modals/finishTelepharmacyModal/hooks';
import { myDoctorAPI } from '@services';
import { useAppDispatch } from '@stores/hooks';
import { openModal_acceptTelepharmacyModal } from '@stores/modalStore/acceptTelepharmacyModal';
import { openModal_cancelTelepharmacyModal } from '@stores/modalStore/cancelTelepharmacyModal';
import { openModal_finishTelepharmacyModal } from '@stores/modalStore/finishTelepharmacyModal';
import { openModal_rejectTelepharmacyModal } from '@stores/modalStore/rejectTelepharmacyModal';
import fetchAPI from '@utils/fetchAPI';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TelepharmacyItemType } from './index.d';
import * as Style from './index.style';
import {
  HeaderLeftPartItem,
  HeaderRightPartItem,
  HospitalInformationPartItem,
  ProgressInformationPartItem,
  ReservationInformationPartItem,
} from './sections';

const { COMMON_CODE } = commonCode;
const { BREAK_POINTS } = styleVariables;

export const NO_PASSWORD_CASE_TEXT = '비밀번호 없이 출입 가능해요';

function TelepharmacyDetailInformation({
  pageType,
}: {
  pageType:
    | 'requested'
    | 'accepted'
    | 'confirmed'
    | 'completed'
    | 'ongoing_quick'
    | 'ongoing_parcel'
    | 'pick_up';
}) {
  const { finishTelepharmacy } = useFinishTelepharmacyModalHook();

  const { useQuery, useWindowSize } = commonHooks;
  const { windowWidth } = useWindowSize();
  const query = useQuery();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const telepharmacyID = query.get('id');
  const [item, setItem] = useState<TelepharmacyItemType>({});

  let deliveryType: string;
  let iconURL: any;

  switch (item.method) {
    case COMMON_CODE.TP_METHOD.PARCEL:
      deliveryType = '택배';
      iconURL = parcelImage;
      break;
    case COMMON_CODE.TP_METHOD.PICKUP:
      deliveryType = '방문';
      iconURL = pickupImage;
      break;
    case COMMON_CODE.TP_METHOD.QUICK:
    case COMMON_CODE.TP_METHOD.ECONOMY_QUICK:
      deliveryType = '퀵';
      iconURL = quickImage;
      break;
    case COMMON_CODE.TP_METHOD.COLD_CHAIN:
      deliveryType = '냉장';
      iconURL = coldChainImage;
      break;
    default:
      deliveryType = '택배';
      iconURL = parcelImage;

      break;
  }

  // 탈퇴 유저 이름에 붙는 '(탈퇴(Number))' 형식을 '앱 탈퇴 고객'으로 바꾸기 위한 예외처리
  let patientName = item.tm?.patient?.name || '';
  if (patientName.includes('(탈퇴')) {
    const newText = patientName.split('(');
    for (let i = 1; i < newText.length; i += 1) {
      const replaceText = newText[i].split(')')[0];
      patientName = patientName.replace(replaceText, '앱 탈퇴 고객');
    }
  }

  // tp 데이터 fetch
  useEffect(() => {
    async function fetchData() {
      const data = await myDoctorAPI.getTelepharmacyItem(
        {},
        Number(telepharmacyID),
      );
      setItem(data);
    }

    if (telepharmacyID && Number(telepharmacyID) > 0) {
      fetchData();
    }

    return () => setItem({});
  }, [telepharmacyID]);

  const rejectTelepharmacy = () => {
    if (item.id) {
      dispatch(
        openModal_rejectTelepharmacyModal({
          item,
          isVisible: false,
        }),
      );
    }
  };

  const acceptTelepharmacy = () => {
    dispatch(openModal_acceptTelepharmacyModal({ item }));
  };

  const cancelTelepharmacy = () => {
    dispatch(
      openModal_cancelTelepharmacyModal({
        item,
      }),
    );
  };

  const requestTelepharmacy = () => {
    if (deliveryType === '방문') {
      finishTelepharmacy({ isPickup: true, id: item.id });
      return;
    }

    dispatch(
      openModal_finishTelepharmacyModal({
        item: {
          id: item.id,
          patientName,
          method: item.method,
          patientPhone: item.tm?.phone,
          patientAddress: item.address_string,
          addressMemo: item.address_memo,
          address_entrance_password:
            item.address_entrance_password || NO_PASSWORD_CASE_TEXT,
          pharmacy_request_detail: item.pharmacy_request_detail,
          pharmacy_product_payment: item.pharmacy_product_payment,
        },
      }),
    );
  };

  const visitTelepharmacy = () => {
    fetchAPI
      .post(
        `pharmacist-api/telepharmacy/tpprocess/${item.id}/pickup-finish/`,
        {},
        'myDoctor',
      )
      .then((response) => {
        if (response.status === 200) {
          alert('방문 완료되었습니다.');
          navigate('/home/confirmedTreatment/pick-up');
        }
      });
  };

  // 페이지별 달라지는 요소들
  let pageHeaderButton: JSX.Element | null = null;

  switch (pageType) {
    case 'requested':
      pageHeaderButton = (
        <>
          <Style.HeaderButtonArea>
            <Button
              width="130px"
              borderColor={COLOR.greyscale6}
              backgroundColor={COLOR['fill/light']}
              borderRadius="8px"
              title={
                <Font fontType="h3" color="greyscale6">
                  조제 요청 거절
                </Font>
              }
              onClick={rejectTelepharmacy}
            />
          </Style.HeaderButtonArea>
          <Style.HeaderButtonArea>
            <Button
              width="162px"
              backgroundColor={COLOR['fill/accent']}
              borderRadius="8px"
              title={
                <Style.ButtonContainer>
                  <SvgIcon
                    width={16}
                    height={16}
                    icon="check_large"
                    color="fill/white"
                  />
                  <Blank appoint="Horizontal" size={8} />
                  <Font fontType="h4" color="fill/white">
                    수락 및 가격 입력
                  </Font>
                </Style.ButtonContainer>
              }
              onClick={acceptTelepharmacy}
            />
          </Style.HeaderButtonArea>
        </>
      );
      break;
    case 'accepted':
      pageHeaderButton = null;
      break;
    case 'completed':
      pageHeaderButton = null;
      break;
    case 'confirmed':
      pageHeaderButton = (
        <>
          <Style.HeaderButtonArea>
            <Button
              width="130px"
              backgroundColor={COLOR['fill/light']}
              borderColor={COLOR.greyscale6}
              borderRadius="8px"
              title={
                <Style.ButtonContainer>
                  <SvgIcon color="greyscale6" icon="exclamation" width={16} />
                  <Blank appoint="Horizontal" size={8} />
                  <Font fontType="body1" color="greyscale6">
                    조제 취소
                  </Font>
                </Style.ButtonContainer>
              }
              onClick={cancelTelepharmacy}
            />
          </Style.HeaderButtonArea>
          <Style.HeaderButtonArea>
            <Button
              width="162px"
              backgroundColor={COLOR['fill/accent']}
              borderRadius="8px"
              title={
                <Style.ButtonContainer>
                  <SvgIcon
                    width={16}
                    height={16}
                    icon="check_large"
                    color="fill/white"
                  />
                  <Blank appoint="Horizontal" size={8} />
                  <Font fontType="body1" color="fill/white">
                    약국 방문 요청
                  </Font>
                </Style.ButtonContainer>
              }
              onClick={requestTelepharmacy}
            />
          </Style.HeaderButtonArea>
        </>
      );
      break;
    case 'ongoing_quick':
      pageHeaderButton = null;
      break;
    case 'ongoing_parcel':
      pageHeaderButton = null;
      break;
    case 'pick_up':
      pageHeaderButton = (
        <Style.HeaderButtonArea>
          <Button
            width="162px"
            backgroundColor={COLOR['fill/accent']}
            borderRadius="8px"
            title={
              <Style.ButtonContainer>
                <SvgIcon
                  width={16}
                  height={16}
                  icon="check_large"
                  color="fill/white"
                />
                <Blank appoint="Horizontal" size={8} />
                <Font fontType="body1" color="fill/white">
                  방문 완료
                </Font>
              </Style.ButtonContainer>
            }
            onClick={visitTelepharmacy}
          />
        </Style.HeaderButtonArea>
      );
      break;
    default:
      break;
  }

  if (item.id) {
    return (
      <Style.Container>
        <Style.Header>
          <Style.HeaderLeft>
            <HeaderLeftPartItem
              patientName={patientName}
              phoneNum={item.tm?.phone}
            />
          </Style.HeaderLeft>
          <Style.HeaderRight>
            <HeaderRightPartItem pageHeaderButton={pageHeaderButton} />
          </Style.HeaderRight>
        </Style.Header>
        <Style.Content
          className={
            Number(windowWidth) < BREAK_POINTS.size1440 ? 'smallContent' : ''
          }
        >
          <Style.LeftContent>
            <PrescriptionItem item={item} />
          </Style.LeftContent>
          <Style.RightContent>
            <ProgressInformationPartItem
              item={item}
              deliveryType={deliveryType}
              iconURL={iconURL}
            />
            <ReservationInformationPartItem
              item={item}
              deliveryType={deliveryType}
              iconURL={iconURL}
              patientName={patientName}
            />
            <HospitalInformationPartItem item={item} />
          </Style.RightContent>
        </Style.Content>
      </Style.Container>
    );
  }
  return null;
}

export default TelepharmacyDetailInformation;
