import styled from 'styled-components';

export const MobileLayout = styled.div`
  max-width: 720px;
  min-height: 100vh;
  min-height: 100dvh;
  margin: 0 auto;
  background-color: white;
  padding-top: var(--header-height);
`;
