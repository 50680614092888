import { Font } from '@components/atoms';
import { commonCode } from '@constants';
import { StatusTagStyleType } from './index.d';
import { StatusTagContainer } from './index.style';

const { COMMON_CODE } = commonCode;

const STATUS_TEXT = {
  TP_REJECTED: '조제거절',
  TP_P_CANCEL: '환자취소',
  TP_PH_CANCEL: '약사취소',
  TP_DEFAULT: '조제완료',
  DELIVERY_REQUESTED: '수거대기',
  DELIVERY_COLLECTED: '배송 중',
  DELIVERY_FINISHED: '배송완료',
  DELIVERY_CANCEL: '배송취소',
  PAYMENT_CANCELED: '결제취소',
  PICKUP_FINISHED: '방문완료',
  PICKUP_REQUESTED: '방문대기',
};

function StatusTag({
  tpStatus,
  deliveryStatus,
  paymentStatus,
}: {
  tpStatus?: string;
  deliveryStatus?: string;
  paymentStatus?: string;
}) {
  const data: StatusTagStyleType = {
    title: '',
    borderColor: 'state/success',
    backgroundColor: 'state/success',
    titleColor: 'fill/white',
  };

  console.log(tpStatus === COMMON_CODE.TP_STATUS.FINISHED, deliveryStatus);
  let status;
  if (tpStatus === COMMON_CODE.TP_STATUS.FINISHED) {
    if (deliveryStatus === COMMON_CODE.DELIVERY_STATUS.COLLECTED) {
      status = STATUS_TEXT.DELIVERY_COLLECTED;
    } else if (deliveryStatus === COMMON_CODE.DELIVERY_STATUS.FINISHED) {
      status = STATUS_TEXT.DELIVERY_FINISHED;
    } else if (deliveryStatus === COMMON_CODE.DELIVERY_STATUS.CANCEL) {
      status = STATUS_TEXT.DELIVERY_CANCEL;
    } else if (deliveryStatus === COMMON_CODE.DELIVERY_STATUS.PICKUP_FINISHED) {
      status = STATUS_TEXT.PICKUP_FINISHED;
    } else if (
      deliveryStatus === COMMON_CODE.DELIVERY_STATUS.PICKUP_REQUESTED
    ) {
      status = STATUS_TEXT.PICKUP_REQUESTED;
    } else {
      status = STATUS_TEXT.DELIVERY_REQUESTED;
    }
  } else if (tpStatus === COMMON_CODE.TP_STATUS.PH_CANCEL) {
    status = STATUS_TEXT.TP_PH_CANCEL;
  } else if (tpStatus === COMMON_CODE.TP_STATUS.P_CANCEL) {
    status = STATUS_TEXT.TP_P_CANCEL;
  } else if (tpStatus === COMMON_CODE.TP_STATUS.REJECTED) {
    status = STATUS_TEXT.TP_REJECTED;
  } else {
    status = STATUS_TEXT.TP_DEFAULT;
  }

  if (paymentStatus === COMMON_CODE.PAYMENT_STATUS.CANCELED) {
    status = STATUS_TEXT.PAYMENT_CANCELED;
  }

  switch (status) {
    case STATUS_TEXT.DELIVERY_COLLECTED:
      data.title = '배송 중';
      data.backgroundColor = 'fill/white';
      data.borderColor = 'fill/white';
      data.titleColor = 'fill/accent';
      break;
    case STATUS_TEXT.DELIVERY_FINISHED:
      data.title = '배송완료';
      data.backgroundColor = 'fill/white';
      data.borderColor = 'fill/white';
      data.titleColor = 'fill/accent';
      break;
    case STATUS_TEXT.DELIVERY_REQUESTED:
      data.title = '수거대기';
      data.backgroundColor = 'fill/white';
      data.borderColor = 'fill/white';
      data.titleColor = 'fill/accent';
      break;
    case STATUS_TEXT.DELIVERY_CANCEL:
      data.title = '배송취소';
      data.backgroundColor = 'fill/white';
      data.borderColor = 'fill/white';
      data.titleColor = 'state/distructive';
      break;
    case STATUS_TEXT.TP_PH_CANCEL:
      data.title = '약사취소';
      data.backgroundColor = 'fill/white';
      data.borderColor = 'fill/white';
      data.titleColor = 'state/distructive';
      break;
    case STATUS_TEXT.TP_P_CANCEL:
      data.title = '환자취소';
      data.backgroundColor = 'fill/white';
      data.borderColor = 'fill/white';
      data.titleColor = 'state/distructive';
      break;
    case STATUS_TEXT.TP_REJECTED:
      data.title = '조제거절';
      data.backgroundColor = 'fill/white';
      data.borderColor = 'fill/white';
      data.titleColor = 'state/distructive';
      break;
    case STATUS_TEXT.PAYMENT_CANCELED:
      data.title = '결제취소';
      data.backgroundColor = 'fill/white';
      data.borderColor = 'fill/white';
      data.titleColor = 'state/distructive';
      break;
    case STATUS_TEXT.PICKUP_FINISHED:
      data.title = '방문완료';
      data.backgroundColor = 'fill/white';
      data.borderColor = 'fill/white';
      data.titleColor = 'fill/accent';
      break;
    case STATUS_TEXT.PICKUP_REQUESTED:
      data.title = '방문대기';
      data.backgroundColor = 'fill/white';
      data.borderColor = 'fill/white';
      data.titleColor = 'fill/accent';
      break;
    default:
      data.title = '조제완료';
      data.backgroundColor = 'fill/white';
      data.borderColor = 'fill/white';
      data.titleColor = 'fill/accent';
      break;
  }

  return (
    <StatusTagContainer data={data}>
      <Font fontType="h5" color={data.titleColor}>
        {data.title}
      </Font>
    </StatusTagContainer>
  );
}

export default StatusTag;
