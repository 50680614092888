import { namanLogoBlackSmall, ReactBootstrapIcons } from '@assets';
import { Button, COLOR, Font } from '@components/atoms';
import { pathnameList, styleVariables } from '@constants';
import EmptyVATModal from '@pages/wrapper/modals/EmptyVATModal';
import VATModal from '@pages/wrapper/modals/VATModal';
import { useAppSelector } from '@stores/hooks';
import { selectUserInformationPharmacistData } from '@stores/userInformationStore';
import { MouseEvent, useState } from 'react';
import { GlobalNavBarTemplateType } from './index.d';
import * as Style from './index.style';

const { BREAK_POINTS } = styleVariables;
const { PHARMACIST_ADMIN_PATHNAME } = pathnameList;

function GlobalNavBarTemplate({
  windowWidth,
  requestedCount,
  acceptedCount,
  confirmedCount,
  preConfirmedCount,
  finishedPickupCount,
  finishedQuickCount,
  finishedParcelCount,
  isOpen,
  openGNB,
  closeGNB,
  userData,
  pharmacistData,
  pathname,
  openCustomerCenterModal,
  openCompanyInfoModal,
  onLogout,
  isDescriptionDropdownClicked,
  onDescriptionDropdownClicked,
  navigateToPage,
  completedParcelCount,
}: GlobalNavBarTemplateType) {
  const [isVATModal, setIsVATModal] = useState(false);

  const { has_payment_permission } = useAppSelector(
    selectUserInformationPharmacistData,
  );

  const treatmentLinksArray = [
    {
      id: 0,
      label: '접수대기',
      notification: requestedCount,
      to: 'newReservation/requested',
    },
    {
      id: 1,
      label: '결제대기',
      notification: acceptedCount,
      to: 'newReservation/accepted',
    },
    {
      id: 2,
      label: '조제대기',
      notification: Number(confirmedCount) < 0 ? 0 : confirmedCount,
      to: 'waitForDispense',
    },
    {
      id: 3,
      label: '수거대기',
      notification: Number(completedParcelCount) < 0 ? 0 : completedParcelCount,
      to: 'confirmedTreatment',
    },
    {
      id: 4,
      label: '이전 내역',
      notification: 0,
      to: 'completeTreatment',
    },
    {
      id: 'divider',
      label: '',
      notification: 0,
      to: '',
    },
    {
      id: 5,
      label: '의약품 관리',
      notification: 0,
      to: 'medicineManagement',
    },
    {
      id: 6,
      label: '영업시간 관리',
      notification: 0,
      to: 'timeManagement',
    },
  ];

  const dropdownListArray = [
    {
      id: 0,
      label: '약국 비품 신청',
      clickEvent: () =>
        window.open('https://forms.gle/GJ2svSjWYTaMusYw5', '_blank'),
    },
    {
      id: 1,
      label: '고객센터',
      clickEvent: () => openCustomerCenterModal(),
    },
    {
      id: 2,
      label: '나만의닥터 이용약관',
      clickEvent: () => window.open('/termsOfService', '_blank'),
    },
    {
      id: 3,
      label: '나만의닥터 사업자 정보',
      clickEvent: () => openCompanyInfoModal(),
    },
    {
      id: 4,
      label: '로그아웃',
      clickEvent: () => onLogout(),
    },
  ];

  return (
    <>
      <Style.GNBTopbar>
        <Style.GNBLogoArea>
          <Style.GNBLogo
            src={namanLogoBlackSmall}
            alt="logo"
            onClick={() => {
              navigateToPage(
                PHARMACIST_ADMIN_PATHNAME.NEW_RESERVATION_REQUESTED,
              );
              closeGNB();
            }}
          />
        </Style.GNBLogoArea>
        <Style.GNBLinkContainerArea>
          {treatmentLinksArray.map(({ id, label, notification, to }) => {
            if (id === 'divider') {
              return <Style.GNBDivider key="divider" />;
            }
            return (
              <Style.GNBLinkContainer key={id}>
                <Style.GNBLinkArea
                  isActive={pathname.includes(to)}
                  onClick={() => {
                    navigateToPage(`/home/${to}`);
                    if (id === 3) {
                      openGNB();
                    } else {
                      closeGNB();
                    }
                  }}
                >
                  {label}
                  {!!notification && (
                    <Style.GNBLinkNotification>
                      {notification}
                    </Style.GNBLinkNotification>
                  )}
                </Style.GNBLinkArea>
              </Style.GNBLinkContainer>
            );
          })}
          <Style.GNBLinkContainer>
            <Style.GNBLinkArea
              isActive={false}
              onClick={() => {
                window.open('https://mydoctor-notice-p.oopy.io/');
              }}
            >
              공지사항
              <Style.GNBLinkNotification>1</Style.GNBLinkNotification>
            </Style.GNBLinkArea>
          </Style.GNBLinkContainer>
          <Style.GNBLinkContainer>
            <Style.GNBLinkArea
              isActive={false}
              onClick={() => {
                setIsVATModal(true);
              }}
            >
              부가세신고
            </Style.GNBLinkArea>
          </Style.GNBLinkContainer>
          {/* <Style.GNBDivider />
          {commerceLinksArray.map(({ id, label, to }) => (
            <Style.GNBLinkContainer key={id}>
              {to ? (
                <Style.GNBLinkArea isActive={false}>{label}</Style.GNBLinkArea>
              ) : (
                <Style.GNBButtonLinkArea isActive={false}>
                  {label}
                </Style.GNBButtonLinkArea>
              )}
            </Style.GNBLinkContainer>
          ))} */}
        </Style.GNBLinkContainerArea>
        <Style.GNBDescriptionArea>
          {windowWidth && windowWidth < BREAK_POINTS.size1440 ? (
            <Style.GNBStatusButtonArea
              onClick={(event: MouseEvent<HTMLButtonElement>) => {
                event.stopPropagation();
                onDescriptionDropdownClicked(!isDescriptionDropdownClicked);
              }}
              onBlur={() => {
                onDescriptionDropdownClicked(false);
              }}
            >
              <Style.GNBStatus>
                <Font fontType="body3" color="fill/white">
                  ON
                </Font>
              </Style.GNBStatus>
            </Style.GNBStatusButtonArea>
          ) : (
            <Button
              height="56px"
              borderColor={COLOR['fill/light']}
              backgroundColor={COLOR['fill/light']}
              title={
                <Style.GNBDescriptionButtonContainer>
                  <Style.GNBStatusArea>
                    <Style.GNBStatus>
                      <Font fontType="body3" color="fill/white">
                        ON
                      </Font>
                    </Style.GNBStatus>
                  </Style.GNBStatusArea>
                  <Style.GNBTextArea>
                    <Style.GNBTopTextArea>
                      <Font fontType="h5">{`${userData.name} 선생님`}</Font>
                    </Style.GNBTopTextArea>
                    <Style.GNBBottomTextArea>
                      <Font fontType="body3">
                        {pharmacistData.nickname &&
                        pharmacistData.nickname.length > 14
                          ? `${pharmacistData.nickname?.slice(0, 11)}...`
                          : pharmacistData.nickname}
                      </Font>
                    </Style.GNBBottomTextArea>
                  </Style.GNBTextArea>
                  <Style.GNBIconArea>
                    {isDescriptionDropdownClicked ? (
                      <ReactBootstrapIcons.CaretUpFill />
                    ) : (
                      <ReactBootstrapIcons.CaretDownFill />
                    )}
                  </Style.GNBIconArea>
                </Style.GNBDescriptionButtonContainer>
              }
              onClick={(event) => {
                event.stopPropagation();
                onDescriptionDropdownClicked(!isDescriptionDropdownClicked);
              }}
              onBlur={() => {
                onDescriptionDropdownClicked(false);
              }}
            />
          )}
          {isDescriptionDropdownClicked && (
            <Style.GNBDescriptionDropdownList
              onMouseDown={(event) => event.preventDefault()}
            >
              {dropdownListArray.map(({ id, label, clickEvent }) => (
                <Style.GNBDescriptionDropdownItemArea
                  key={id}
                  onClick={clickEvent}
                >
                  <Style.GNBDescriptionDropdownItem>
                    {label}
                  </Style.GNBDescriptionDropdownItem>
                </Style.GNBDescriptionDropdownItemArea>
              ))}
            </Style.GNBDescriptionDropdownList>
          )}
        </Style.GNBDescriptionArea>
      </Style.GNBTopbar>
      {isVATModal &&
        (has_payment_permission ? (
          <VATModal
            open={isVATModal}
            onClose={() => setIsVATModal(false)}
            callOutMessage={`부가세 신고 자료 조회기간을 설정해주세요.\n조회 시작일은 2022년 9월 17일일부터 가능합니다.`}
            title="부가세 신고 자료 안내"
            VATDescription="상세내역은 파일을 다운로드 후 확인해주세요."
          />
        ) : (
          <EmptyVATModal
            open={isVATModal}
            onClose={() => setIsVATModal(false)}
            callOutMessage={`부가세 신고 자료 조회는 정산 이메일과 동일한 이메일 계정에서만 조회가 가능합니다.
조회에 문제가 있는 경우, 고객센터로 연락 바랍니다.`}
            title="부가세 신고 자료 조회 불가"
          />
        ))}
    </>
  );
}

export default GlobalNavBarTemplate;
