import { COLOR } from '@components/atoms';
import styled from 'styled-components';

export const DefaultListContent = styled.div<{
  flex: number;
  flexDirection: 'row' | 'column';
  border?: string;
}>`
  height: 56px;
  flex: ${({ flex }) => flex};
  display: flex;
  gap: 8px;

  flex-direction: ${({ flexDirection }) => flexDirection};
  padding: 1rem;

  word-break: break-all;
  position: relative;
  align-items: center;

  border-right: ${({ border }) =>
    border === 'none' ? 'none' : `1px solid ${COLOR['border/divider']}`};
  border-bottom: ${({ border }) =>
    border === 'none' ? 'none' : `1px solid ${COLOR['border/divider']}`};

  &:last-child {
    border-right: none;
  }
`;
