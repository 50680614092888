import styled from 'styled-components';
import { COLOR } from '../color';
import { IsSelectedType } from './index.d';

export const ButtonContainer = styled.div<IsSelectedType>`
  display: flex;
  flex-direction: row;
  padding-top: 15px;
  padding-bottom: 15px;
  width: 100%;
  align-items: center;
  cursor: pointer;
  border: 1px solid
    ${(props) =>
      props.isSelected ? COLOR['fill/accent'] : COLOR['fill/medium']};
  border-radius: 8px;
`;

export const CheckBox = styled.div<IsSelectedType>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 18px;
  margin-right: 18px;
  border: 1px solid
    ${(props) =>
      props.isSelected ? COLOR['fill/accent'] : COLOR['fill/medium']};
  width: 18px;
  height: 18px;
  border-radius: 4px;
`;
