import { Font } from '@components/atoms';
import { ReactNode } from 'react';
import * as Style from './index.style';

const ListContent = {
  Element: ({
    children,
    flex,
    flexDirection = 'row',
    border = '',
  }: {
    children?: ReactNode;
    flex: number;
    flexDirection?: 'row' | 'column';
    border?: string;
  }) => {
    return (
      <Style.DefaultListContent
        flexDirection={flexDirection}
        flex={flex}
        border={border}
      >
        {children}
      </Style.DefaultListContent>
    );
  },
  Regular: ({
    children,
    flex,
    flexDirection = 'row',
    border = '',
  }: {
    children?: ReactNode;
    flex: number;
    flexDirection?: 'row' | 'column';
    border?: string;
  }) => {
    return (
      <Style.DefaultListContent
        flexDirection={flexDirection}
        flex={flex}
        border={border}
      >
        <Font fontType="body2_medium">{children}</Font>
      </Style.DefaultListContent>
    );
  },
  Bold: ({
    children,
    flex,
    flexDirection = 'row',
    border = '',
  }: {
    children: ReactNode;
    flex: number;
    flexDirection?: 'row' | 'column';
    border?: string;
  }) => {
    return (
      <Style.DefaultListContent
        flexDirection={flexDirection}
        flex={flex}
        border={border}
      >
        <Font fontType="h5">{children}</Font>
      </Style.DefaultListContent>
    );
  },
};

export default ListContent;
