import { Font } from '@components/atoms';
import { ReactNode } from 'react';
import * as Style from './index.style';

const ListContent = {
  Element: ({ children, flex }: { children?: ReactNode; flex: number }) => {
    return (
      <Style.DefaultListContent flex={flex}>
        {children}
      </Style.DefaultListContent>
    );
  },
  Regular: ({ children, flex }: { children?: ReactNode; flex: number }) => {
    return (
      <Style.DefaultListContent flex={flex}>
        <Font fontType="body2" color="fill/dark">
          {children}
        </Font>
      </Style.DefaultListContent>
    );
  },
  Bold: ({ children, flex }: { children: ReactNode; flex: number }) => {
    return (
      <Style.DefaultListContent flex={flex}>
        <Font fontType="h5" color="fill/dark">
          {children}
        </Font>
      </Style.DefaultListContent>
    );
  },
};

export default ListContent;
