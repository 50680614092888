import { COLOR } from '@components/atoms';
import { createGlobalStyle } from 'styled-components';
import ResetCSS from '../resetCSS';

const GlobalStyle = createGlobalStyle`
  ${ResetCSS}
  * {
    box-sizing: border-box;
    scroll-behavior: smooth;
  }

  @font-face {
    font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto,
    "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR",
    "Malgun Gothic", sans-serif;
    src: url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard.css");
    font-weight: 400;
    font-style: normal;
  }

  body {
    font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto,
    "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR",
    "Malgun Gothic", sans-serif;
    background-color: ${COLOR['bg/secondary']};
  }
`;

export default GlobalStyle;
