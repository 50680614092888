import { ReactBootstrapIcons } from '@assets';
import { COLOR, Font, TextArea } from '@components/atoms';
import { ChangeEvent } from 'react';
import {
  BottomArea,
  CheckBox,
  TextAreaContainer,
  TopArea,
} from './index.style';

interface TextButtonProps {
  title: string;
  isSelected: boolean;
  onClick: () => void;
  value: string | number;
  onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  type: 'text' | 'password' | 'number';
  placeholder?: string;
  height?: number | string;
  width?: number | string;
  fontSize?: number | string;
}

function TextButtonWithTextArea({
  title,
  onClick,
  isSelected,
  value,
  onChange,
  type,
  placeholder,
  height,
  width,
  fontSize,
}: TextButtonProps) {
  return (
    <TextAreaContainer isSelected={isSelected} onClick={onClick}>
      <TopArea>
        <CheckBox isSelected={isSelected}>
          {isSelected ? (
            <ReactBootstrapIcons.CheckSquareFill color={COLOR['fill/accent']} />
          ) : null}
        </CheckBox>
        <Font fontType="body1" color={isSelected ? 'fill/accent' : 'fill/dark'}>
          {title}
        </Font>
      </TopArea>
      {isSelected ? (
        <BottomArea>
          <TextArea
            value={value}
            onChange={onChange}
            type={type}
            placeholder={placeholder}
            height={height}
            width={width}
            fontSize={fontSize}
          />
        </BottomArea>
      ) : null}
    </TextAreaContainer>
  );
}

export default TextButtonWithTextArea;
