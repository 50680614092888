import { ReactBootstrapIcons } from '@assets';
import { COLOR, Font } from '@components/atoms';
import { EmptyArea, EmptyFontArea, EmptyIconArea } from './index.style';

function NoData({ title }: { title: string }) {
  return (
    <EmptyArea>
      <EmptyIconArea>
        <ReactBootstrapIcons.Clipboard size={32} color={COLOR['fill/dark']} />
      </EmptyIconArea>
      <EmptyFontArea>
        <Font fontType="h1" color="fill/dark">
          {title}
        </Font>
      </EmptyFontArea>
    </EmptyArea>
  );
}

export default NoData;
