import { Button, Font } from '@components/atoms';
import {
  CallOutMessage,
  ModalCommonStructure as Modal,
} from '@components/molecules';
import { trackLoginMixpanelEvents } from '@utils/mixpanel/login';
import { SignupModalTemplateType } from './index.d';
import * as Style from './index.style';

function SignupModalTemplate({
  isVisible,
  closeModal,
}: SignupModalTemplateType) {
  const onClick = () => {
    window.open('https://forms.gle/nC8tm1PyJwVJPYxz9', '_blank');
    trackLoginMixpanelEvents('clickSignUp')();
  };

  return (
    <Modal
      isOpen={isVisible}
      onRequestClose={() => closeModal()}
      modalHeader={
        <Style.TitleArea>
          <Font fontType="h1" color="fill/dark">
            회원가입
          </Font>
        </Style.TitleArea>
      }
      modalBody={
        <>
          <Style.DescriptionArea>
            <CallOutMessage>
              <Font fontType="body1" color="fill/dark">
                약사 회원가입 시{' '}
                <Font fontType="h4" color="fill/black">
                  약사 면허증
                </Font>
                과 소속 기관의{' '}
                <Font fontType="h4" color="fill/black">
                  사업자등록증
                </Font>
                이 필요합니다.
              </Font>
            </CallOutMessage>
          </Style.DescriptionArea>
          <Style.CustomerCenterButtonArea>
            <Button
              title={
                <Font fontType="h4" color="fill/white">
                  약사 회원가입 하기
                </Font>
              }
              onClick={onClick}
              backgroundColor="fill/black"
            />
          </Style.CustomerCenterButtonArea>
        </>
      }
    />
  );
}

export default SignupModalTemplate;
