export const commaFormatter = (price: string) => {
  return price.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
};

export const uncommaFormatter = (price: string) => {
  return price.replace(/[^\d]+/g, '');
};

export const prettyPriceFormatter = (price: string) => {
  return commaFormatter(uncommaFormatter(price));
};
