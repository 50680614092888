import { COLOR } from '@components/atoms/color';
import styled from 'styled-components';
import { TelemedicineContainerType } from './index.d';

export const Container = styled.div<TelemedicineContainerType>`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  height: 86px;
  margin: 4px 0;
  padding: 4px 8px;
  justify-content: space-between;
  background-color: ${(props) =>
    props.isSelected ? COLOR['label/lightBlue'] : COLOR['bg/secondary']};
  border-radius: 8px;
  align-items: center;
`;

export const DeliveryPhotoContainer = styled.div`
  flex: 1;
`;

export const DeliveryPhotoArea = styled.div`
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background: ${COLOR['fill/white']};
  margin-right: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DeliveryImage = styled.img`
  width: 40px;
  height: 40px;
`;

export const TextArea = styled.div`
  display: flex;
  flex-direction: column;
  flex: 3;
  margin-right: 8px;
`;

export const TopTextArea = styled.div`
  display: flex;
  margin-bottom: 4px;
`;

export const WaitingTimeTextArea = styled.div`
  margin-right: 8px;
`;

export const IsUninsuredTextArea = styled.div`
  display: flex;
  justify-content: center;
`;

export const IsUninsuredBadge = styled.div`
  height: 20px;
  border-radius: 12px;
  background: ${COLOR['disabled/foreground']};
  display: flex;
  justify-content: center;
  padding: 0 8px;
`;

export const MiddleTextArea = styled.div``;

export const BottomTextArea = styled.div``;

export const ButtonArea = styled.div`
  flex: 2;
  display: flex;
  align-items: center;
`;

export const PrescriptionButtonArea = styled.div`
  flex: 2;
  display: flex;
  justify-content: center;
`;

export const DoctorDisplayArea = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
