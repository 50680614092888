import styled from 'styled-components';

function SmallDivider({ index }: { index?: number }) {
  return index === 0 ? null : <Container />;
}

export default SmallDivider;

const Container = styled.div`
  width: 100%;
  height: 1px;
  background-color: #f1f2f4;
`;
