import { COLOR } from '@components/atoms';
import styled, { css } from 'styled-components';
import { ModalBodyType, ModalHeaderType } from './index.d';

const MODAL_SPACING = 20;

export const ModalHeader = styled.div<ModalHeaderType>`
  width: 100%;
  position: relative;

  ${(props: any) =>
    props.isRecheckModal
      ? css`
          padding: ${MODAL_SPACING}px ${MODAL_SPACING * 2}px
            ${MODAL_SPACING - MODAL_SPACING}px;
        `
      : css`
          padding: ${MODAL_SPACING}px ${MODAL_SPACING * 2}px;
        `};
  ${(props: any) =>
    !props.isRecheckModal &&
    css`
      border-bottom: 1px solid ${COLOR['fill/light']};
    `};
`;

export const ModalCloseButtonArea = styled.div`
  position: absolute;
  top: ${MODAL_SPACING + 4}px;
  right: ${MODAL_SPACING * 2}px;
  cursor: pointer;
`;

export const ModalBody = styled.div<ModalBodyType>`
  width: 100%;
  ${(props: any) =>
    props.isRecheckModal
      ? css`
          padding: ${MODAL_SPACING}px ${MODAL_SPACING * 2}px ${MODAL_SPACING}px;
        `
      : css`
          padding: ${MODAL_SPACING}px ${MODAL_SPACING * 2}px
            ${MODAL_SPACING * 2}px;
        `};
`;

export const ModalFooter = styled.div`
  width: 100%;
  padding: ${MODAL_SPACING}px ${MODAL_SPACING * 2}px;
  border-top: 1px solid ${COLOR['fill/light']};
  display: flex;
  justify-content: flex-end;
`;
