import { namanLogo, pharmacistsLogo } from '@assets';
import { Button, COLOR, Font, TextInput } from '@components/atoms';
import { ChangeEvent, KeyboardEvent } from 'react';
import * as Style from './index.style';

interface LoginPageTemplateProps {
  email: string;
  password: string;
  onChangeEmail: (e: ChangeEvent<HTMLInputElement>) => void;
  onKeyPress: (e: KeyboardEvent<HTMLInputElement>) => void;
  onChangePassword: (e: ChangeEvent<HTMLInputElement>) => void;
  login: Function;
  openFindIdPasswordModal: Function;
  openSignupModal: Function;
  increaseLogoClickCount: Function;
}

function LoginPageTemplate({
  email,
  password,
  onChangeEmail,
  onChangePassword,
  onKeyPress,
  login,
  openFindIdPasswordModal,
  openSignupModal,
  increaseLogoClickCount,
}: LoginPageTemplateProps) {
  return (
    <Style.Container>
      <Style.LoginArea>
        <Style.LogoArea onClick={() => increaseLogoClickCount()}>
          <Style.MainLogo src={namanLogo} />
          <Style.SubLogo src={pharmacistsLogo} />
        </Style.LogoArea>
        <Style.TextInputArea>
          <TextInput
            value={email}
            onChange={onChangeEmail}
            onKeyPress={onKeyPress}
            placeholder="이메일 아이디를 입력해주세요"
            type="text"
            fontSize={16}
          />
        </Style.TextInputArea>
        <Style.TextInputArea>
          <TextInput
            value={password}
            onChange={onChangePassword}
            onKeyPress={onKeyPress}
            placeholder="비밀번호를 입력해주세요"
            type="password"
            fontSize={16}
          />
        </Style.TextInputArea>
        <Style.SignupFindIdPasswordContainer>
          <Style.SignupArea onClick={() => openSignupModal()}>
            <Font fontType="body1">회원가입</Font>
          </Style.SignupArea>
          <Style.VerticalLine />
          <Style.FindIdPasswordArea onClick={() => openFindIdPasswordModal()}>
            <Font fontType="body1">아이디/비밀번호 찾기</Font>
          </Style.FindIdPasswordArea>
        </Style.SignupFindIdPasswordContainer>
        <Style.LoginButtonArea>
          <Button
            title={
              <Font fontType="h4" color="fill/white">
                로그인 하기
              </Font>
            }
            onClick={() => login()}
            backgroundColor={COLOR['fill/accent']}
          />
        </Style.LoginButtonArea>
      </Style.LoginArea>
    </Style.Container>
  );
}

export default LoginPageTemplate;
