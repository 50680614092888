import moment from 'moment';
import { TelepharmacyListItemModelType } from 'types/myDoctorModelType/telepharmacyModelType';

export const calcAutorejectMinute = (item: TelepharmacyListItemModelType) =>
  parseInt(
    String((moment(item.auto_reject_time).unix() - moment().unix()) / 60 + 1),
    10,
  );

export const calcWaitingMinute = (item: TelepharmacyListItemModelType) =>
  parseInt(
    String((moment().unix() - moment(item.requested_time).unix()) / 60 + 1),
    10,
  );
