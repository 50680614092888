import { ReactBootstrapIcons } from '@assets';
import { Blank, Button, COLOR, Font } from '@components/atoms';
import BFlex from '@components/meraki-ui/BFlex';
import { styleVariables } from '@constants';
import { commonHooks } from '@hooks';
import {
  TelepharmacyListCompletedStatusType,
  TelepharmacyListDeliveryMethodType,
  TelepharmacyListIsUninsuredType,
} from '@services/myDoctor/types/index.d';
import { useAppDispatch, useAppSelector } from '@stores/hooks';
import {
  getTelepharmacyList_completed,
  selectTelepharmacyCount_completed,
  selectTelepharmacyList_completed,
} from '@stores/telepharmacyStore/telepharmacyList';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BeatLoader from 'react-spinners/BeatLoader';
import CompletedTelepharmacyCarousel from './CompletedTelepharmacyCarousel';
import { CompletedTelepharmacyTableFilterType } from './index.d';
import * as Style from './index.style';
import {
  CSVDownloadButton,
  FilterComponent,
  ListContent,
  ListItem,
  Pagination,
} from './sections';

const { BREAK_POINTS } = styleVariables;

export const LISTITEM_FLEX = [
  { flex: 64, title: '진행상태' },
  { flex: 109, title: '진행상태' },
  { flex: 109, title: '배송방법' },
  { flex: 140, title: '운송장' },
  { flex: 180, title: '결제완료일시' },
  { flex: 109, title: '환자명\n(예약번호)' },
  { flex: 153, title: '연락처' },
  { flex: 109, title: '약제비' },
  { flex: 109, title: '추가 결제비' },
  { flex: 200, title: '주소' },
  { flex: 260, title: '처방전' },
];

function CompletedTelepharmacyTable() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { useQuery, useWindowSize } = commonHooks;
  const { windowWidth } = useWindowSize();
  const query = useQuery();

  const [isLoading, setIsLoading] = useState(true);
  const telepharmacyData = useAppSelector(selectTelepharmacyList_completed);
  const telepharmacyCount = useAppSelector(selectTelepharmacyCount_completed);

  const [isLimitFilterClicked, setIsLimitFilterClicked] = useState(false);
  const clickLimitFilter = () => {
    setIsLimitFilterClicked(!isLimitFilterClicked);
  };

  // 필터
  const [limit, setLimit] = useState<number>(30);
  const [from_date, setFrom_date] = useState<string | undefined>(
    moment().subtract(90, 'days').format('YYYY-MM-DD'),
  );
  const [to_date, setTo_date] = useState<string | undefined>(
    moment().format('YYYY-MM-DD'),
  );
  const [statusString, setStatusString] = useState<string | undefined>('ALL');
  const [delivery_method, setDelivery_method] = useState<
    TelepharmacyListDeliveryMethodType | undefined
  >(undefined);
  const [is_uninsured, setIs_uninsured] = useState<
    TelepharmacyListIsUninsuredType | undefined
  >(undefined);
  const [q_type, setQ_type] = useState<string | undefined>(undefined);
  const [q, setQ] = useState<string | undefined>(undefined);

  const handleLimitFilter = (count: number) => {
    setLimit(count);
  };

  const handleFilters = (filters: CompletedTelepharmacyTableFilterType) => {
    setFrom_date(filters.from_date);
    setTo_date(filters.to_date);
    setStatusString(filters.statusString);
    setDelivery_method(filters.delivery_method);
    setIs_uninsured(filters.is_uninsured);
    setQ_type(filters.q_type);
    setQ(filters.q);
  };

  const pageQuery = query.get('page');
  const page = Number(pageQuery) || 0;
  const maxPage = telepharmacyCount
    ? Math.floor(telepharmacyCount / limit) + 1
    : 0;

  const getCompleteStatus = (type?: string) => {
    let result: TelepharmacyListCompletedStatusType | undefined;
    switch (type) {
      case 'DELIVERY_FINISH':
        result = 'delivery_finished';
        break;
      case 'DELIVERY_INPROGRESS':
        result = 'delivery_inprogress';
        break;
      case 'DELIVERY_PICKED_WAITED':
        result = 'delivery_pickup_waited';
        break;
      case 'PATIENT_CANCEL':
        result = 'cancel_by_patient';
        break;
      case 'PHARMACIST_CANCEL':
        result = 'cancel_by_pharmacist';
        break;
      default:
        break;
    }

    return result;
  };

  const completed_status = getCompleteStatus(statusString);

  // csv용 필터
  const [csvFilter, setCSVFilter] =
    useState<CompletedTelepharmacyTableFilterType>({
      statusString,
      delivery_method,
      from_date,
      to_date,
      is_uninsured,
      q_type,
      q,
    });

  useEffect(() => {
    setCSVFilter({
      statusString,
      delivery_method,
      from_date,
      to_date,
      is_uninsured,
      q_type,
      q,
    });
  }, [
    delivery_method,
    from_date,
    is_uninsured,
    q,
    q_type,
    statusString,
    to_date,
  ]);

  useEffect(() => {
    setIsLoading(true);

    let patient_name: string | undefined;
    let phone_num: string | undefined;
    let address: string | undefined;
    let hospital_name: string | undefined;
    let doctor_name: string | undefined;
    let waybill_num: string | undefined;

    if (q_type === 'patient_name') patient_name = q;
    if (q_type === 'phone_num') phone_num = q;
    if (q_type === 'address') address = q;
    if (q_type === 'hospital_name') hospital_name = q;
    if (q_type === 'doctor_name') doctor_name = q;
    if (q_type === 'waybill_num') waybill_num = q;

    dispatch(
      getTelepharmacyList_completed({
        offset: page * limit,
        limit,
        completed_status,
        from_date,
        to_date,
        delivery_method,
        is_tp_uninsured: is_uninsured,
        patient_name,
        phone_num,
        address,
        hospital_name,
        doctor_name,
        waybill_num,
      }),
    ).then(() => {
      setIsLoading(false);
    });
    return () => setIsLoading(false);
  }, [
    dispatch,
    page,
    limit,
    q_type,
    q,
    completed_status,
    from_date,
    to_date,
    delivery_method,
    is_uninsured,
  ]);

  return (
    <Style.Container>
      <BFlex padding="0 0 40px 0">
        <CompletedTelepharmacyCarousel />
      </BFlex>
      <Style.PageTitleArea>
        <Font fontType="display1">이전 내역</Font>
      </Style.PageTitleArea>
      <FilterComponent handleFilters={handleFilters} />
      <Style.ListArea
        className={
          Number(windowWidth) < BREAK_POINTS.size1024
            ? 'smallListArea'
            : Number(windowWidth) < BREAK_POINTS.size1440
            ? 'middleListArea'
            : ''
        }
      >
        <Style.TitleArea>
          <Font fontType="h1">
            전체내역 {isLoading ? '(0건)' : `(${telepharmacyCount}건)`}
          </Font>
        </Style.TitleArea>
        <Style.OptionArea>
          {/* 
                @동훈
                FIXME: 여기는 레이아웃만 쪼갰습니다. 
            */}
          <Style.OptionLeftArea />
          {/* 
                @동훈
                FIXME: 여기는 레이아웃만 쪼개고, 버튼 들어갈 공간만 확보하고 멈췄습니다. 
            */}
          <Style.OptionRightArea>
            <CSVDownloadButton
              csvFilter={csvFilter}
              telepharmacyCount={telepharmacyCount}
            />
            <Blank appoint="Horizontal" size={8} />
            <Button
              width="140px"
              height="32px"
              borderColor="fill/dark"
              backgroundColor="fill/white"
              title={
                <Style.IconButtonItem>
                  <Font fontType="body3">{limit}개씩 보기</Font>
                  <Blank appoint="Horizontal" size={8} />
                  {isLimitFilterClicked ? (
                    <ReactBootstrapIcons.ChevronUp
                      size={12}
                      color={COLOR['fill/dark']}
                    />
                  ) : (
                    <ReactBootstrapIcons.ChevronDown
                      size={12}
                      color={COLOR['fill/dark']}
                    />
                  )}
                </Style.IconButtonItem>
              }
              onClick={(event) => {
                event.stopPropagation();
                clickLimitFilter();
              }}
              onBlur={() => {
                setIsLimitFilterClicked(false);
              }}
            />
            {isLimitFilterClicked ? (
              <Style.OptionRightAreaDropdownList
                onMouseDown={(event) => event.preventDefault()}
              >
                <Style.OptionRightAreaDropdownItem
                  onClick={() => {
                    handleLimitFilter(30);
                    clickLimitFilter();
                  }}
                >
                  30개씩 보기
                </Style.OptionRightAreaDropdownItem>
                <Blank appoint="Vertical" size={16} />
                <Style.OptionRightAreaDropdownItem
                  onClick={() => {
                    handleLimitFilter(50);
                    clickLimitFilter();
                  }}
                >
                  50개씩 보기
                </Style.OptionRightAreaDropdownItem>
                <Blank appoint="Vertical" size={16} />
                <Style.OptionRightAreaDropdownItem
                  onClick={() => {
                    handleLimitFilter(100);
                    clickLimitFilter();
                  }}
                >
                  100개씩 보기
                </Style.OptionRightAreaDropdownItem>
              </Style.OptionRightAreaDropdownList>
            ) : null}
          </Style.OptionRightArea>
        </Style.OptionArea>
        {telepharmacyCount ? (
          <>
            <Style.ListContainer>
              <Style.ListHeaderItem>
                {LISTITEM_FLEX.map((item, index) => {
                  if (index === 0) return null;
                  if (
                    Number(windowWidth) < BREAK_POINTS.size1440 &&
                    index === 10
                  )
                    return null;
                  if (
                    Number(windowWidth) < BREAK_POINTS.size1024 &&
                    index === 4
                  )
                    return null;
                  return (
                    <ListContent.Bold key={index.toString()} flex={item.flex}>
                      {item.title}
                    </ListContent.Bold>
                  );
                })}
              </Style.ListHeaderItem>
              {isLoading ? (
                <Style.LoaderArea>
                  <BeatLoader size={15} loading color="fill/accent" />
                </Style.LoaderArea>
              ) : (
                telepharmacyData?.map((item) => (
                  <ListItem navigate={navigate} item={item} key={item.id} />
                ))
              )}
            </Style.ListContainer>
            <Pagination page={page} maxPage={maxPage} limit={limit} />
          </>
        ) : (
          <Style.EmptyContainer>
            <Font fontType="h5">이전 조제 내역이 없습니다.</Font>
          </Style.EmptyContainer>
        )}
      </Style.ListArea>
    </Style.Container>
  );
}

export default CompletedTelepharmacyTable;
