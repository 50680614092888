import { GetManagedMedicineItemType } from '@services/myDoctor/types';
import { useAppDispatch, useAppSelector } from '@stores/hooks';
import {
  closeModal_medicineManagementModal,
  openModal_medicineManagementModal,
  RegisterModalType,
  selectMedicineManagementModalModalType,
  selectMedicineManagementModalTargetMedicine,
  selectMedicineManagementModalVisible,
  set_modalType_medicineManagementModal,
  set_targetMedicine_medicineManagementModal,
} from '@stores/modalStore/medicineManagementModal';
import { useCallback } from 'react';

const useMedicineManagementModalHook = () => {
  const dispatch = useAppDispatch();
  const isVisible = useAppSelector(selectMedicineManagementModalVisible);
  const modalType = useAppSelector(selectMedicineManagementModalModalType);
  const targetMedicine = useAppSelector(
    selectMedicineManagementModalTargetMedicine,
  );

  const closeModal = useCallback(() => {
    dispatch(closeModal_medicineManagementModal());
  }, [dispatch]);

  const openModal = useCallback(() => {
    dispatch(openModal_medicineManagementModal());
  }, [dispatch]);

  const setManagementMedicineModalType = (type: RegisterModalType) => {
    dispatch(set_modalType_medicineManagementModal(type));
  };

  const setManagementMedicineTargetMedicine = (
    medicine: GetManagedMedicineItemType,
  ) => {
    dispatch(set_targetMedicine_medicineManagementModal(medicine));
  };

  return {
    isVisible,
    modalType,
    targetMedicine,
    closeModal,
    openModal,
    setManagementMedicineModalType,
    setManagementMedicineTargetMedicine,
  };
};

export default useMedicineManagementModalHook;
