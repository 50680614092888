import useRejectTP from '@mobile/api/hooks/useRejectTP';
import AcceptanceHeader from '@mobile/components/common/AcceptanceHeader';
import { Mixpanel } from '@utils/mixpanel';
import { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { TPAcceptLocationState } from '../TPAccept';
import BottomCTA from './components/BottomCTA';
import CheckboxOptionItem from './components/CheckBoxOption';
import useHandleRejectReason from './hooks/useHandleRejectReason';

function TPReject() {
  const { id } = useParams();
  const tppId = Number(id);

  const navigate = useNavigate();
  const {
    state: { prescriptionId, patientName, reservationCode, mixpanelData },
  } = useLocation() as TPAcceptLocationState;

  const { mutate: rejectTPMutate, isLoading: isRejectLoading } = useRejectTP();

  const goBack = () => {
    return navigate(`/mobile/request/detail/${tppId}`, {
      state: { prescriptionId },
    });
  };

  const rejectTP = () => {
    rejectTPMutate({ rejectReason: reason, tppId: String(tppId) });
  };

  const {
    reason,
    selectedOption,
    checkBoxOptions,
    handleSelect,
    handleTextAreaChange,
  } = useHandleRejectReason();

  useEffect(() => {
    Mixpanel.track('[Modal] Reject TP Requested (Mobile)', mixpanelData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <AcceptanceHeader title="조제 거절" goBack={goBack} />

      <Container>
        <PatientInfo>
          <InfoText>{`${patientName} (${reservationCode.toUpperCase()})`}</InfoText>
        </PatientInfo>

        <Content>
          <Wrapper>
            <LabelContainer>
              <LabelText>거절 사유</LabelText>
              <Label>필수</Label>
            </LabelContainer>

            <CheckBoxContainer>
              {checkBoxOptions.map((option) => (
                <CheckboxOptionItem
                  key={option.id}
                  option={option}
                  isSelected={selectedOption === option.id}
                  onSelect={handleSelect}
                  onTextAreaChange={
                    option.needsInput ? handleTextAreaChange : undefined
                  }
                />
              ))}
            </CheckBoxContainer>
          </Wrapper>
        </Content>

        <BottomCTA
          isButtonDisabled={reason === '' || isRejectLoading}
          goBack={goBack}
          reject={rejectTP}
        />
      </Container>
    </>
  );
}

export default TPReject;

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  max-width: 720px;
  height: 100vh;
  height: 100dvh;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  padding-top: var(--acceptance-header-height);
  overflow-y: scroll;
`;

const PatientInfo = styled.div`
  display: flex;
  padding: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`;

const InfoText = styled.div`
  color: #1e2024;

  font-family: Pretendard, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
`;

const Content = styled.div`
  display: flex;
  padding: 0px 20px 40px 20px;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  flex: 1 0 0;
  align-self: stretch;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
`;

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const LabelText = styled.div`
  color: #1e2024;

  font-family: Pretendard, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
`;

const Label = styled.div`
  display: flex;
  padding: 2px 8px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: #ebf3ff;

  color: #4785ff;

  font-family: Pretendard, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
`;

const CheckBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`;
