function History() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M5.33333 4.00008C4.96514 4.00008 4.66667 4.29856 4.66667 4.66675C4.66667 5.03494 4.96514 5.33341 5.33333 5.33341L10.6667 5.33341C11.0349 5.33341 11.3333 5.03494 11.3333 4.66675C11.3333 4.29856 11.0349 4.00008 10.6667 4.00008L5.33333 4.00008Z"
        fill="#2F6EFF"
      />
      <path
        d="M4.66667 7.33341C4.66667 6.96522 4.96514 6.66675 5.33333 6.66675L10.6667 6.66675C11.0349 6.66675 11.3333 6.96522 11.3333 7.33341C11.3333 7.7016 11.0349 8.00008 10.6667 8.00008L5.33333 8.00008C4.96514 8.00008 4.66667 7.7016 4.66667 7.33341Z"
        fill="#2F6EFF"
      />
      <path
        d="M5.33333 9.33341C4.96514 9.33341 4.66667 9.63189 4.66667 10.0001C4.66667 10.3683 4.96514 10.6667 5.33333 10.6667H8.66667C9.03486 10.6667 9.33333 10.3683 9.33333 10.0001C9.33333 9.63189 9.03486 9.33341 8.66667 9.33341H5.33333Z"
        fill="#2F6EFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 2.66675C2 1.56218 2.89543 0.666748 4 0.666748H12C13.1046 0.666748 14 1.56218 14 2.66675V13.3334C14 14.438 13.1046 15.3334 12 15.3334H4C2.89543 15.3334 2 14.438 2 13.3334V2.66675ZM4 2.00008H12C12.3682 2.00008 12.6667 2.29856 12.6667 2.66675V13.3334C12.6667 13.7016 12.3682 14.0001 12 14.0001H4C3.63181 14.0001 3.33333 13.7016 3.33333 13.3334V2.66675C3.33333 2.29856 3.63181 2.00008 4 2.00008Z"
        fill="#2F6EFF"
      />
    </svg>
  );
}

export default History;
