import { getTPProcessCount } from '@mobile/api';
import AuthError from '@mobile/pages/AuthError';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

const useTPProcessCount = () => {
  const navigate = useNavigate();

  return useQuery({
    queryFn: () => getTPProcessCount(),
    queryKey: ['TP_PROCESS_COUNT'],
    retry: false,
    onError: (error) => {
      if (error instanceof AuthError) {
        navigate('/mobile/auth-error');
      }
    },
  });
};

export default useTPProcessCount;
