import { coldChainImage, parcelImage, pickupImage, quickImage } from '@assets';
import { Font } from '@components/atoms';
import { QUICK_LISTITEM_FLEX } from '@components/organisms/pickupTelepharmacyTable';
import { commonCode, styleVariables } from '@constants';
import { formatters } from '@functions';
import { commonHooks } from '@hooks';
import { myDoctorAPI } from '@services';
import { useState } from 'react';
import { MyDoctorModelType } from 'types/index.d';
import { ListContent, StatusTag } from '../index';
import * as Style from './index.style';

const { COMMON_CODE } = commonCode;
const { BREAK_POINTS } = styleVariables;

function ListItem({
  navigate,
  item,
}: {
  navigate: any;
  item: MyDoctorModelType.TelepharmacyModelType.TelepharmacyListItemModelType;
}) {
  const { phoneFormatter, priceFormatter } = formatters;
  const { useWindowSize } = commonHooks;
  const { windowWidth } = useWindowSize();
  const [isButtonVisible, setIsButtonVisible] = useState(false);

  let deliveryType;
  let iconURL;

  switch (item.method) {
    case COMMON_CODE.TP_METHOD.PARCEL:
      deliveryType = '택배';
      iconURL = parcelImage;
      break;
    case COMMON_CODE.TP_METHOD.PICKUP:
      deliveryType = '방문';
      iconURL = pickupImage;
      break;
    case COMMON_CODE.TP_METHOD.QUICK:
    case COMMON_CODE.TP_METHOD.ECONOMY_QUICK:
      deliveryType = '퀵';
      iconURL = quickImage;
      break;
    case COMMON_CODE.TP_METHOD.COLD_CHAIN:
      deliveryType = '냉장';
      iconURL = coldChainImage;
      break;
    default:
      deliveryType = '택배';
      iconURL = parcelImage;
      break;
  }

  const fetchPrescriptionData = async (tp_id: number | undefined) => {
    const { data } = await myDoctorAPI.getTelepharmacyTpprocessPrescriptions(
      Number(tp_id),
    );
    return data;
  };

  const isEmptyObject = (param: any) => {
    if (param === undefined) return true;
    return Object.keys(param).length === 0 && param.constructor === Object;
  };

  const isEmptyObjectPharmacyProductPayment = isEmptyObject(
    item.pharmacy_product_payment,
  );

  const commerceTotalAmount =
    !isEmptyObjectPharmacyProductPayment &&
    item.pharmacy_product_payment &&
    item.pharmacy_product_payment.total_amount
      ? item.pharmacy_product_payment.total_amount
      : 0;

  return (
    <Style.ListItem
      onMouseEnter={() => setIsButtonVisible(true)}
      onMouseLeave={() => setIsButtonVisible(false)}
      to={`/home/confirmedTreatment/ongoing-quick/confirmedTreatmentOngoingQuickDetail?id=${item.id}`}
    >
      <ListContent.Regular flex={QUICK_LISTITEM_FLEX[0].flex}>
        {deliveryType}{' '}
        {iconURL ? (
          <Style.ListItemIcon src={iconURL} alt={deliveryType} />
        ) : undefined}
      </ListContent.Regular>

      <ListContent.Regular flex={QUICK_LISTITEM_FLEX[1].flex}>
        <StatusTag status={item.delivery_status} item={item} />
      </ListContent.Regular>

      <ListContent.Regular flex={QUICK_LISTITEM_FLEX[2].flex}>
        {item.patient?.name}
        {item.telemedicine ? (
          <>{` (${item.telemedicine.telemedicine_hash})`}</>
        ) : null}
      </ListContent.Regular>

      <ListContent.Regular flex={QUICK_LISTITEM_FLEX[3].flex}>
        {phoneFormatter.prettyPhoneNumberFormatter(
          item.patient?.phone || item.requester?.phone,
        )}
      </ListContent.Regular>

      <ListContent.Element flex={QUICK_LISTITEM_FLEX[4].flex}>
        <Font fontType="body2">
          {`${priceFormatter.commaFormatter(
            item.amount ? item.amount?.toString() : '0',
          )}원`}
        </Font>
      </ListContent.Element>

      <ListContent.Regular flex={QUICK_LISTITEM_FLEX[5].flex}>
        <Font fontType="body2">{item.address_string || '-'}</Font>
      </ListContent.Regular>
    </Style.ListItem>
  );
}

export default ListItem;
