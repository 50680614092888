import styled from 'styled-components';

export const TitleArea = styled.div``;

export const DescriptionArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 0px;
`;

export const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const ContentArea = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0;
  position: relative;
`;

export const ContentIconArea = styled.div`
  margin-right: 4px;
`;

export const ContentTitleArea = styled.div``;

export const ContentDetailArea = styled.div`
  position: absolute;
  left: 140px;
`;

export const CompanyInfoButtonArea = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 0px;
`;
