import { Font } from '@components/atoms';
import BButton from '@components/meraki-ui/BButton';
import { BColor } from '@components/meraki-ui/BColor';
import BFlex from '@components/meraki-ui/BFlex';
import BModalFooter from '@components/meraki-ui/BModal/BModalFooter';
import BTypography from '@components/meraki-ui/BTypography';
import {
  CallOutMessage,
  ModalCommonStructure as Modal,
} from '@components/molecules';
import React, { ReactNode } from 'react';

interface VATModalProps {
  open: boolean;
  onClose: () => void;
  callOutMessage: string;
  title: string | ReactNode;
}

function EmptyVATModal({
  open,
  onClose,
  title,
  callOutMessage,
}: VATModalProps) {
  return (
    <Modal
      isOpen={open}
      onRequestClose={onClose}
      modalHeader={
        <Font fontType="h1" color="greyscale6">
          {title}
        </Font>
      }
      modalBody={
        <CallOutMessage>
          <BTypography size={16} color="mono08" text={callOutMessage} />
        </CallOutMessage>
      }
      modalFooter={
        <BFlex isFull borderTop={`1px solid ${BColor.mono05}`}>
          <BModalFooter
            buttonText={
              <BFlex isColumn isFull>
                <BButton
                  onClick={onClose}
                  buttonType="primary"
                  size="heightForFullWidth"
                  height={60}
                  text="확인"
                />
              </BFlex>
            }
          />
        </BFlex>
      }
    />
  );
}

export default EmptyVATModal;
