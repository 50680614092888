import {
  Blank,
  Button,
  CheckButton,
  COLOR,
  Font,
  TextInput,
} from '@components/atoms';
import {
  CallOutMessage,
  ModalCommonStructure as Modal,
  PageLoader,
} from '@components/molecules';
import { formatters } from '@functions';
import { notifyOtherClients } from '@hooks/featureHooks/useSocket';
import { myDoctorAPI } from '@services';
import { useAppDispatch, useAppSelector } from '@stores/hooks';
import {
  closeModal_acceptTelepharmacyModal,
  selectAcceptTelepharmacyModalItem,
  selectAcceptTelepharmacyModalVisible,
} from '@stores/modalStore/acceptTelepharmacyModal';
import { getTelepharmacyCount } from '@stores/telepharmacyStore/telepharmacyCount';
import {
  getTelepharmacyList_accepted,
  getTelepharmacyList_requested,
} from '@stores/telepharmacyStore/telepharmacyList';
import { FormEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Style from './index.style';

function AcceptTelepharmacyModal() {
  const { phoneFormatter, priceFormatter } = formatters;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const isOpen = useAppSelector(selectAcceptTelepharmacyModalVisible);
  const item = useAppSelector(selectAcceptTelepharmacyModalItem);
  const [isPageLoading, setIsPageLoading] = useState(false);

  const [amount, setAmount] = useState('');
  const [is_uninsured, setIs_uninsured] = useState(false);

  const [isZeroAmount, setIsZeroAmount] = useState(false);
  const [amountDisable, setAmountDisable] = useState(false);

  const [isCheckedProofUserType, setIsCheckedProofUserType] = useState(false);

  const onChangeAmount = (e: FormEvent<HTMLInputElement>) => {
    setAmount(priceFormatter.uncommaFormatter(e.currentTarget.value));
  };

  const acceptTelepharmacy = () => {
    if (
      window.confirm(
        `${item.tm?.patient?.name} 환자의 조제를 수락하시겠습니까?`,
      )
    ) {
      setIsPageLoading(true);

      const id = item.id ? item.id : 0;

      myDoctorAPI
        .postAcceptTelepharmacy(
          { amount: Number(amount), guide: '', is_uninsured: null },
          id,
        )
        .then((responseJSON) => {
          if (responseJSON.id) {
            alert('조제를 수락하였습니다.');
            closeModal();
            Promise.all([
              dispatch(getTelepharmacyCount()),
              dispatch(getTelepharmacyList_requested({ limit: 60, offset: 0 })),
              dispatch(getTelepharmacyList_accepted({ limit: 60, offset: 0 })),
            ]).then(() => {
              setTimeout(() => navigate('/home/newReservation/requested'), 700);
              notifyOtherClients('telepharmacy');
            });
          } else {
            alert('요청에 실패했습니다.');
            window.location.reload();
          }
        })
        .finally(() => setIsPageLoading(false));
    }
  };

  const closeModal = () => {
    setIsPageLoading(false);
    dispatch(closeModal_acceptTelepharmacyModal());
    setAmount('');
    setIsZeroAmount(false);
    setAmountDisable(false);
    setIs_uninsured(false);
  };

  const buttonVisible = amount.length > 0 && isCheckedProofUserType;

  useEffect(() => {
    if (isZeroAmount) setAmount('0');
    else setAmount('');

    return () => setAmount('');
  }, [isZeroAmount]);

  return (
    <>
      {isPageLoading && <PageLoader />}
      <Modal
        isRecheckModal
        isOpen={isOpen}
        onRequestClose={() => closeModal()}
        modalHeader={
          <Style.TitleArea>
            <Font fontType="display2">조제수락</Font>
          </Style.TitleArea>
        }
        modalBody={
          <>
            <Style.DescriptionArea>
              <CallOutMessage backgroundColor={COLOR.yellow50}>
                <Font fontType="body1" color="greyscale6">
                  수락 및 가격 입력 전에 약 배송 가능 여부를 약국 프로그램에서
                  확인해주세요.
                </Font>
              </CallOutMessage>
            </Style.DescriptionArea>
            <Style.PatientNameArea>
              <Font fontType="h2" color="fill/black">
                {item.tm?.patient?.name} 환자 (
                {phoneFormatter.lastFourPhoneNumberFormatter(item.tm?.phone)})
              </Font>
            </Style.PatientNameArea>
            <Blank appoint="Vertical" size={16} />
            <Style.PatientPaymentArea>
              {/* <Style.InformationLine>
                <Style.InformationTitle>
                  <Font fontType="body1" color={COLOR['fill/dark']}>
                    대체조제
                  </Font>
                </Style.InformationTitle>
                <Style.CheckBoxWidth>
                  <CheckBox
                    title="기본조제"
                    isSelected={isAlternative === false}
                    onClick={() => setIsAlternative(false)}
                  />
                </Style.CheckBoxWidth>
                <Style.CheckBoxWidth>
                  <CheckBox
                    title="대체조제"
                    isSelected={isAlternative === true}
                    onClick={() => setIsAlternative(true)}
                  />
                </Style.CheckBoxWidth>
              </Style.InformationLine>
              <Blank appoint="Vertical" size={12} /> */}
              {/* <Style.InformationLine>
                <Style.InformationTitle>
                  <Font fontType="body1" color={COLOR['fill/dark']}>
                    급여/비급여
                  </Font>
                </Style.InformationTitle>
                <Style.CheckBoxWidth>
                  <CheckBox
                    title="급여 조제"
                    isSelected={is_uninsured === false}
                    onClick={() => setIs_uninsured(false)}
                  />
                </Style.CheckBoxWidth>
                <Style.CheckBoxWidth>
                  <CheckBox
                    title="비급여 조제"
                    isSelected={is_uninsured === true}
                    onClick={() => setIs_uninsured(true)}
                  />
                </Style.CheckBoxWidth>
              </Style.InformationLine>
              <Blank appoint="Vertical" size={12} /> */}
              <Style.InformationLine>
                <Style.InformationTitle>
                  <Font fontType="body1">약제비</Font>
                </Style.InformationTitle>
                <Style.InformationColumnArea>
                  <TextInput
                    onChange={onChangeAmount}
                    width={260}
                    height={44}
                    value={`${priceFormatter.prettyPriceFormatter(amount)}`}
                    type="text"
                    placeholder="숫자만 입력해주세요."
                    disabled={amountDisable}
                    fontSize={16}
                  />
                  <Style.CheckButtonMargin />
                  <CheckButton
                    title="약제비가 0원입니다."
                    isSelected={isZeroAmount}
                    onClick={() => {
                      setIsZeroAmount(!isZeroAmount);
                      setAmountDisable(!amountDisable);
                    }}
                  />
                </Style.InformationColumnArea>
              </Style.InformationLine>
            </Style.PatientPaymentArea>
          </>
        }
        modalFooter={
          <Style.FooterArea>
            <Style.CheckButtonArea>
              <CheckButton
                title="해당 환자의 약 배송 가능 여부를 확인했습니다."
                isSelected={isCheckedProofUserType}
                onClick={() => {
                  setIsCheckedProofUserType(!isCheckedProofUserType);
                }}
              />
            </Style.CheckButtonArea>
            <Blank appoint="Vertical" size={24} />
            <Style.ConfirmButtonArea>
              <Button
                onClick={closeModal}
                backgroundColor={COLOR['fill/light']}
                width="100%"
                height="60px"
                borderRadius="8px"
                visible
                title={
                  <Style.ButtonContainer>
                    <Font fontType="h3" color="greyscale6">
                      취소
                    </Font>
                  </Style.ButtonContainer>
                }
              />
              <Blank appoint="Horizontal" size={12} />
              <Button
                onClick={acceptTelepharmacy}
                disableColor={COLOR['fill/mediumLight']}
                backgroundColor={COLOR['fill/accent']}
                width="100%"
                height="60px"
                borderRadius="8px"
                visible={buttonVisible}
                title={
                  <Style.ButtonContainer>
                    <Font fontType="h3" color="fill/light">
                      수락하기
                    </Font>
                  </Style.ButtonContainer>
                }
              />
            </Style.ConfirmButtonArea>
          </Style.FooterArea>
        }
      />
    </>
  );
}

export default AcceptTelepharmacyModal;
