import { COLOR, ColorType } from '@components/atoms/color';
import { ReactNode } from 'react';
import styled from 'styled-components';

export type FontStyleType =
  | 'display1'
  | 'display2'
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'body1'
  | 'body1_medium'
  | 'body2'
  | 'body2_medium'
  | 'body3'
  | 'body3_medium';

interface FontValueType {
  'font-size': number;
  'font-weight': number;
  'line-height': number;
  'letter-spacing': string;
}

export type FontConfigType = Record<FontStyleType, FontValueType>;

const fontConfig: FontConfigType = {
  display1: {
    'font-size': 32,
    'font-weight': 700,
    'line-height': 42,
    'letter-spacing': '0em',
  },
  display2: {
    'font-size': 28,
    'font-weight': 700,
    'line-height': 36,
    'letter-spacing': '0em',
  },
  h1: {
    'font-size': 24,
    'font-weight': 600,
    'line-height': 32,
    'letter-spacing': '0em',
  },
  h2: {
    'font-size': 20,
    'font-weight': 600,
    'line-height': 26,
    'letter-spacing': '0em',
  },
  h3: {
    'font-size': 18,
    'font-weight': 600,
    'line-height': 24,
    'letter-spacing': '0em',
  },
  h4: {
    'font-size': 16,
    'font-weight': 600,
    'line-height': 22,
    'letter-spacing': '-0.01em',
  },
  h5: {
    'font-size': 14,
    'font-weight': 600,
    'line-height': 20,
    'letter-spacing': '-0.01em',
  },
  body1: {
    'font-size': 16,
    'font-weight': 400,
    'line-height': 24,
    'letter-spacing': '-0.02em',
  },
  body1_medium: {
    'font-size': 16,
    'font-weight': 500,
    'line-height': 24,
    'letter-spacing': '-0.02em',
  },
  body2: {
    'font-size': 14,
    'font-weight': 400,
    'line-height': 22,
    'letter-spacing': '-0.02em',
  },
  body2_medium: {
    'font-size': 14,
    'font-weight': 500,
    'line-height': 22,
    'letter-spacing': '-0.02em',
  },
  body3: {
    'font-size': 12,
    'font-weight': 400,
    'line-height': 18,
    'letter-spacing': '0em',
  },
  body3_medium: {
    'font-size': 12,
    'font-weight': 500,
    'line-height': 18,
    'letter-spacing': '0em',
  },
};

interface FontProps {
  children?: ReactNode;
  fontType: FontStyleType;
  color?: ColorType;
  noWhiteSpace?: boolean;
}

export default function Font({
  children,
  fontType,
  color = 'fill/dark',
  noWhiteSpace,
}: FontProps) {
  return (
    <FontStyle
      {...fontConfig[fontType]}
      color={color}
      noWhiteSpace={noWhiteSpace}
    >
      {children}
    </FontStyle>
  );
}

interface FontStyleProps extends FontValueType {
  color: ColorType;
  noWhiteSpace?: boolean;
}

const FontStyle = styled.span<FontStyleProps>`
  font-size: ${(props) => props['font-size']}px;
  font-weight: ${(props) => props['font-weight']};
  color: ${(props) => COLOR[props.color]};
  line-height: ${(props) => props['line-height']}px;
  white-space: ${(props) => (props.noWhiteSpace ? 'nowrap' : undefined)};
  text-overflow: ${(props) => (props.noWhiteSpace ? 'ellipsis' : undefined)};
  overflow: ${(props) => (props.noWhiteSpace ? 'hidden' : undefined)};
  letter-spacing: ${(props) => props['letter-spacing']};
  white-space: pre-line;
`;
