import { Mixpanel } from '@utils/mixpanel';
import { clickAdBanner, pageType } from '@utils/mixpanel/constants';

export const newReservationRequestedMixpanelEvents = {
  pageNewReservationRequested: '[Page] New Reservation Requested',

  clickAdBanner,
} as const;

type NewReservationRequestedMixpanelEvents =
  keyof typeof newReservationRequestedMixpanelEvents;

const newReservationRequestedMixpanelEventsMap: Record<
  NewReservationRequestedMixpanelEvents,
  (adID?: string, adType?: string) => void
> = {
  pageNewReservationRequested: () =>
    Mixpanel.track(
      newReservationRequestedMixpanelEvents.pageNewReservationRequested,
    ),

  clickAdBanner: (adID?: string, adType?: string) =>
    Mixpanel.track(newReservationRequestedMixpanelEvents.clickAdBanner, {
      'Page Type': pageType.newReservationRequested,
      adID,
      adType,
    }),
};

export const trackNewReservationRequestedMixpanelEvents = (
  newReservationRequestedMixpanelEvent: NewReservationRequestedMixpanelEvents,
) => {
  return newReservationRequestedMixpanelEventsMap[
    newReservationRequestedMixpanelEvent
  ];
};
