import styled from 'styled-components';

export const DefaultListContent = styled.div<{
  flex: number;
}>`
  height: 60px;
  flex: ${({ flex }) => flex};
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;
