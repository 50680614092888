import { Blank, Font } from '@components/atoms';
import ReservationInformationItem, {
  IReservationInformationItem,
} from '@components/organisms/telepharmacyDetailInformation/sections/reservationInformationPartItem/ReservationInformationItem';
import { formatters } from '@functions';
import moment from 'moment';
import { ReservationInformationPartItemType } from './index.d';
import * as Style from './index.style';

function ReservationInformationPartItem({
  item,
  deliveryType,
  iconURL,
  patientName,
}: ReservationInformationPartItemType) {
  const { phoneFormatter, timeFormatter } = formatters;

  // 조제요청일시 관련
  let reservationTime;
  const targetTime = item.requested_time;
  const requestedTimeDate = targetTime
    ? moment(targetTime).format('YYYY-MM-DD')
    : undefined;
  const requestedTimeDateText =
    timeFormatter.dateTextFormatter(requestedTimeDate);

  if (targetTime) {
    reservationTime = `${requestedTimeDateText} ${moment(targetTime).format(
      'HH:mm',
    )}`;
  } else {
    reservationTime = `${requestedTimeDateText} 가능한 빨리`;
  }

  const proofUserType = item.tm?.telemedicine_proof?.proof_user_type;

  const proofUserTypeText =
    proofUserType === 'remote_resident_user'
      ? '섬•벽지 지역 거주자'
      : proofUserType === 'disabled_person_user'
      ? '장애인'
      : proofUserType === 'long_term_care_need_user'
      ? '장기요양등급 대상자'
      : '약 배송 불가 대상';

  const reservationInformationList: IReservationInformationItem[] = [
    {
      id: 0,
      title: '약 수령 방법',
      text: deliveryType === '방문' ? '방문수령' : deliveryType,
      iconURL: null,
      canCopy: false,
    },
    {
      id: 1,
      title: '약 배송 가능 여부',
      text: proofUserTypeText,
      iconURL: null,
      canCopy: false,
    },
    {
      id: 2,
      title: '환자명',
      text: patientName,
      iconURL: null,
      canCopy: false,
    },
    {
      id: 3,
      title: '휴대폰번호',
      text: phoneFormatter.prettyPhoneNumberFormatter(item.tm?.phone),
      iconURL: null,
      canCopy: false,
    },
    {
      id: 4,
      title: '조제요청일시',
      text: reservationTime,
      iconURL: null,
      canCopy: false,
    },
    {
      id: 5,
      title: '증상',
      text: item.tm?.symptom_keyword_history[0].symptom_keyword.name,
      iconURL: null,
      canCopy: false,
    },
    {
      id: 6,
      title: '예약번호',
      text: item.telepharmacy_hash,
      iconURL: null,
      canCopy: false,
    },
  ];

  return (
    <Style.RightContentItem>
      <Style.ContentHeaderArea>
        <Style.ContentHeaderTitle>
          <Font fontType="h1" color="fill/black">
            예약정보
          </Font>
        </Style.ContentHeaderTitle>
      </Style.ContentHeaderArea>
      <Blank appoint="Vertical" size={12} />
      <Style.ContentBodyArea>
        {reservationInformationList.map((reservationInformationItem) => {
          return (
            <ReservationInformationItem
              reservationInformationItem={reservationInformationItem}
              key={reservationInformationItem.id}
            />
          );
        })}
      </Style.ContentBodyArea>
    </Style.RightContentItem>
  );
}

export default ReservationInformationPartItem;
