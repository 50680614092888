import { Button, COLOR, Font } from '@components/atoms';
import BModal from '@components/meraki-ui/BModal';
import { medicineNameConfig } from '@components/organisms/managementMedicineTable/config/domain';
import { commonHooks } from '@hooks';
import { myDoctorAPI } from '@services';
import { useAppDispatch, useAppSelector } from '@stores/hooks';
import { getManagementMedicineList } from '@stores/telepharmacyStore/telepharmacyList';
import { selectUserInformationPharmacistData } from '@stores/userInformationStore';
import { useState } from 'react';
import useMedicineManagementModalHook from '../medicineManagementModal/hooks';
import useMedicineManagementCommonModalHook from './hooks';
import * as Style from './index.style';

function MedicineManagementCommonModal() {
  const pharmacist = useAppSelector(selectUserInformationPharmacistData);
  const {
    isVisible,
    modalType,
    targetMedicine,
    closeModal: closeConfirmModal,
  } = useMedicineManagementCommonModalHook();
  const {
    setManagementMedicineModalType,
    setManagementMedicineTargetMedicine,
    openModal: openMedicineManagementModal,
  } = useMedicineManagementModalHook();
  const { useQuery } = commonHooks;
  const query = useQuery();
  const dispatch = useAppDispatch();

  const [limit, setLimit] = useState<number>(30);
  const [isLoading, setIsLoading] = useState(false);

  const pageQuery = query.get('page');
  const page = Number(pageQuery) || 0;

  const handleClickExecute = () => {
    if (modalType === 'delete') {
      handleClickDeleteManagedMedicine();
    } else if (modalType === 'soldOut') {
      handleClickSoldOutManagedMedicine();
    }
  };

  const handleClickDeleteManagedMedicine = async () => {
    if (!targetMedicine) return;

    await myDoctorAPI
      .patchManagementMedicine({
        ...targetMedicine,
        id: targetMedicine?.id,
        pharmacy_medicine: {
          ...targetMedicine?.pharmacy_medicine,
          created: null,
          modified: null,
        },
        created: null,
        modified: null,
        is_deleted: true,
      })
      .then(() => {
        dispatch(
          getManagementMedicineList({
            id: pharmacist.pharmacy_id || 0,
            offset: page * limit,
            limit,
          }),
        );
      })
      .then(() => {
        closeConfirmModal();
      });
  };

  const handleOpenEditMedicineModal = () => {
    if (targetMedicine) {
      setManagementMedicineModalType('edit');
      setManagementMedicineTargetMedicine({ ...targetMedicine });
      closeConfirmModal();
      openMedicineManagementModal();
    }
  };

  const handleClickSoldOutManagedMedicine = async () => {
    if (!targetMedicine) return;

    await myDoctorAPI
      .patchManagementMedicine({
        ...targetMedicine,
        id: targetMedicine.id,
        pharmacy_medicine: {
          ...targetMedicine?.pharmacy_medicine,
          is_active: false,
        },
        is_deleted: false,
      })
      .then(() => {
        dispatch(
          getManagementMedicineList({
            id: pharmacist.pharmacy_id || 0,
            offset: page * limit,
            limit,
          }),
        );
      })
      .then(() => {
        closeConfirmModal();
      });
  };

  return (
    <BModal
      open={isVisible}
      width={480}
      padding={30}
      onOpenChange={closeConfirmModal}
    >
      <Style.ModalArea>
        <Style.ModalBodyArea>
          <Font fontType="h1" color="fill/black">
            {modalType === 'delete' && '해당 의약품을 삭제하시겠어요?'}
            {modalType === 'soldOut' && '해당 의약품을 품절 처리하시겠어요?'}
            {modalType === 'alreadyExist' && '이미 등록된 의약품입니다.'}
          </Font>
          <Font fontType="body1_medium" color="fill/dark">
            {modalType === 'delete' &&
              `‘${
                targetMedicine?.pharmacy_medicine.standard_code &&
                medicineNameConfig[
                  targetMedicine?.pharmacy_medicine.standard_code
                ]
              }’ 삭제를 진행하시면 나만의닥터 앱에서 해당 의약품의 가격이 노출되지않고, 자동 결제도 진행되지 않아요.`}
            {modalType === 'soldOut' &&
              `품절 처리를 하시면 ‘${
                targetMedicine?.pharmacy_medicine.standard_code &&
                medicineNameConfig[
                  targetMedicine?.pharmacy_medicine.standard_code
                ]
              }’ 처방전이 접수되어도 설정하신 가격으로 자동 결제되지 않아요.`}
            {modalType === 'alreadyExist' &&
              `${
                targetMedicine?.pharmacy_medicine.standard_code &&
                medicineNameConfig[
                  targetMedicine?.pharmacy_medicine.standard_code
                ]
              }의 정보를 수정하시려면 [수정] 버튼을 눌러 수정해주세요.`}
          </Font>
        </Style.ModalBodyArea>
        <Style.ModalFooterArea>
          {modalType === 'alreadyExist' ? (
            <Button
              width="204px"
              height="56px"
              borderRadius="12px"
              backgroundColor={COLOR['fill/white']}
              borderColor={COLOR['border/outline']}
              title={
                <Font fontType="body1" color="fill/dark">
                  약품 정보 수정
                </Font>
              }
              onClick={handleOpenEditMedicineModal}
            />
          ) : (
            <Button
              width="204px"
              height="56px"
              borderRadius="12px"
              backgroundColor={COLOR['fill/white']}
              borderColor={COLOR['border/outline']}
              title={
                <Font fontType="body1" color="fill/dark">
                  아니오
                </Font>
              }
              onClick={closeConfirmModal}
            />
          )}
          {modalType === 'alreadyExist' ? (
            <Button
              width="204px"
              height="56px"
              borderRadius="12px"
              backgroundColor={COLOR['fill/accent']}
              title={
                <Font fontType="body1" color="fill/white">
                  확인
                </Font>
              }
              onClick={closeConfirmModal}
            />
          ) : (
            <Button
              width="204px"
              height="56px"
              borderRadius="12px"
              backgroundColor={COLOR['fill/accent']}
              title={
                <Font fontType="body1" color="fill/white">
                  네
                </Font>
              }
              onClick={handleClickExecute}
            />
          )}
        </Style.ModalFooterArea>
      </Style.ModalArea>
    </BModal>
  );
}

export default MedicineManagementCommonModal;
