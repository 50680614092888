import styled from 'styled-components';
import { StatusTagStyleType } from './index.d';

export const StatusTagContainer = styled.div<{ data: StatusTagStyleType }>`
  display: flex;
  padding: 2px 8px;
  border-radius: 30px;
  // border: ${({ data }) => `1px solid ${data.borderColor}`};
  // background-color: ${({ data }) => data.backgroundColor};
`;
