import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { myDoctorAPI } from '@services';
import { TelepharmacyListParameterType } from '@services/myDoctor/types/index.d';
import { RootState } from '@stores';
import {
  TelepharmacyCompletedListParameterType,
  TelepharmacyListType,
} from './index.d';

const initialState: TelepharmacyListType = {
  telepharmacyList_requested: {
    count: 0,
    next: '',
    previous: '',
    results: [],
  },
  telepharmacyList_accepted: {
    count: 0,
    next: '',
    previous: '',
    results: [],
  },
  telepharmacyList_confirmed: {
    count: 0,
    next: '',
    previous: '',
    results: [],
  },
  telepharmacyList_finished_quick: {
    count: 0,
    next: '',
    previous: '',
    results: [],
  },
  telepharmacyList_finished_parcel: {
    count: 0,
    next: '',
    previous: '',
    results: [],
  },
  telepharmacyList_completed: {
    count: -1,
    next: '',
    previous: '',
    results: [],
  },
  telepharmacyList_finished_pickup: {
    count: 0,
    next: '',
    previous: '',
    results: [],
  },
  medicine_management_list: {
    count: 0,
    next: '',
    previous: '',
    results: [],
  },
  pharmacy_auto_confirmed: {
    data: {
      auto_confirmed: false,
    },
  },
};

export const getTelepharmacyList = async ({
  limit,
  offset,
  status,
  completed_status,
  from_date,
  to_date,
  delivery_method,
  is_tp_uninsured,
  patient_name,
  phone_num,
  address,
  hospital_name,
  doctor_name,
  waybill_num,
}: TelepharmacyListParameterType) => {
  const telepharmacyListRequestParameter: TelepharmacyListParameterType = {
    limit,
    offset,
    status,
  };

  if (completed_status && completed_status.length) {
    telepharmacyListRequestParameter.completed_status = completed_status;
  }

  if (from_date && from_date.length) {
    telepharmacyListRequestParameter.from_date = from_date;
  }

  if (to_date && to_date.length) {
    telepharmacyListRequestParameter.to_date = to_date;
  }

  if (delivery_method && delivery_method.length) {
    telepharmacyListRequestParameter.delivery_method = delivery_method;
  }

  if (is_tp_uninsured && is_tp_uninsured.length) {
    telepharmacyListRequestParameter.is_tp_uninsured = is_tp_uninsured;
  }

  if (patient_name && patient_name.length) {
    telepharmacyListRequestParameter.patient_name = patient_name;
  }

  if (phone_num && phone_num.length) {
    telepharmacyListRequestParameter.phone_num = phone_num;
  }

  if (address && address.length) {
    telepharmacyListRequestParameter.address = address;
  }

  if (hospital_name && hospital_name.length) {
    telepharmacyListRequestParameter.hospital_name = hospital_name;
  }

  if (doctor_name && doctor_name.length) {
    telepharmacyListRequestParameter.doctor_name = doctor_name;
  }

  if (waybill_num && waybill_num.length) {
    telepharmacyListRequestParameter.waybill_num = waybill_num;
  }

  const { data } = await myDoctorAPI.getTelepharmacyTpprocessList(
    telepharmacyListRequestParameter,
  );

  return data;
};

export const getTelepharmacyList_requested = createAsyncThunk(
  'telepharmacyList/getTelepharmacyList_requested',
  async ({ limit, offset }: { limit: number; offset: number }) => {
    const result = await getTelepharmacyList({
      limit,
      offset,
      status: 'requested',
    });
    return result;
  },
);

export const getTelepharmacyList_accepted = createAsyncThunk(
  'telepharmacyList/getTelepharmacyList_accepted',
  async ({ limit, offset }: { limit: number; offset: number }) => {
    const result = await getTelepharmacyList({
      limit,
      offset,
      status: 'waited_payment',
    });
    return result;
  },
);

export const getTelepharmacyList_confirmed = createAsyncThunk(
  'telepharmacyList/getTelepharmacyList_confirmed',
  async ({ limit, offset }: { limit: number; offset: number }) => {
    const result = await getTelepharmacyList({
      limit,
      offset,
      status: 'confirmed',
    });
    return result;
  },
);

export const getTelepharmacyList_finished_quick = createAsyncThunk(
  'telepharmacyList/getTelepharmacyList_finished_quick',
  async ({ limit, offset }: { limit: number; offset: number }) => {
    const result = await getTelepharmacyList({
      limit,
      offset,
      status: 'finished_quick',
    });
    return result;
  },
);

export const getTelepharmacyList_finished_parcel = createAsyncThunk(
  'telepharmacyList/getTelepharmacyList_finished_parcel',
  async ({ limit, offset }: { limit: number; offset: number }) => {
    const result = await getTelepharmacyList({
      limit,
      offset,
      status: 'finished_parcel',
    });
    return result;
  },
);

export const getTelepharmacyList_finished_pickup = createAsyncThunk(
  'telepharmacyList/getTelepharmacyList_finished_pickup',
  async ({ limit, offset }: { limit: number; offset: number }) => {
    const result = await getTelepharmacyList({
      limit,
      offset,
      status: 'finished_pickup',
    });
    return result;
  },
);

export const getTelepharmacyList_completed = createAsyncThunk(
  'telepharmacyList/getTelepharmacyList_completed',
  async ({
    limit,
    offset,
    completed_status,
    from_date,
    to_date,
    delivery_method,
    is_tp_uninsured,
    patient_name,
    phone_num,
    address,
    hospital_name,
    doctor_name,
    waybill_num,
  }: TelepharmacyCompletedListParameterType) => {
    const result = await getTelepharmacyList({
      limit,
      offset,
      status: 'completed',
      completed_status,
      from_date,
      to_date,
      delivery_method,
      is_tp_uninsured,
      patient_name,
      phone_num,
      address,
      hospital_name,
      doctor_name,
      waybill_num,
    });
    return result;
  },
);

export const getTelepharmacyList_ALL = createAsyncThunk(
  'telepharmacyList/getTelepharmacyList_ALL',
  async (action, { dispatch }) => {
    dispatch(getTelepharmacyList_requested({ limit: 60, offset: 0 }));
    dispatch(getTelepharmacyList_accepted({ limit: 30, offset: 0 }));
    dispatch(getTelepharmacyList_confirmed({ limit: 30, offset: 0 }));
    dispatch(getTelepharmacyList_finished_quick({ limit: 30, offset: 0 }));
    dispatch(getTelepharmacyList_finished_parcel({ limit: 30, offset: 0 }));
  },
);

export const getManagementMedicineList = createAsyncThunk(
  '/pharmacist-api/pharmacist/pharmacy/medicines',
  async ({
    limit,
    offset,
    id,
  }: {
    limit: number;
    offset: number;
    id: number;
  }) => {
    const result = await myDoctorAPI.getManagementMedicineList(
      { limit, offset },
      id,
    );

    return result;
  },
);

export const getPharmacyAutoConfirmed = createAsyncThunk(
  'pharmacist-api/pharmacist/pharmacy/{id}/auto-confirmed/',
  async ({ id }: { id: number }) => {
    const result = await myDoctorAPI.getPharmacyAutoConfirmed(id);

    return result;
  },
);

export const telepharmacyList = createSlice({
  name: 'telepharmacyList',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getTelepharmacyList_requested.fulfilled,
      (state, action) => {
        if (typeof action.payload?.count === 'number') {
          state.telepharmacyList_requested = action.payload;
        }
      },
    );
    builder.addCase(getTelepharmacyList_accepted.fulfilled, (state, action) => {
      if (typeof action.payload?.count === 'number') {
        state.telepharmacyList_accepted = action.payload;
      }
    });
    builder.addCase(
      getTelepharmacyList_confirmed.fulfilled,
      (state, action) => {
        if (typeof action.payload?.count === 'number') {
          state.telepharmacyList_confirmed = action.payload;
        }
      },
    );
    builder.addCase(
      getTelepharmacyList_finished_quick.fulfilled,
      (state, action) => {
        if (typeof action.payload?.count === 'number') {
          state.telepharmacyList_finished_quick = action.payload;
        }
      },
    );
    builder.addCase(
      getTelepharmacyList_finished_parcel.fulfilled,
      (state, action) => {
        if (typeof action.payload?.count === 'number') {
          state.telepharmacyList_finished_parcel = action.payload;
        }
      },
    );
    builder.addCase(
      getTelepharmacyList_completed.fulfilled,
      (state, action) => {
        if (typeof action.payload?.count === 'number') {
          state.telepharmacyList_completed = action.payload;
        }
      },
    );
    builder.addCase(
      getTelepharmacyList_finished_pickup.fulfilled,
      (state, action) => {
        if (typeof action.payload?.count === 'number') {
          state.telepharmacyList_finished_pickup = action.payload;
        }
      },
    );
    builder.addCase(getManagementMedicineList.fulfilled, (state, action) => {
      if (typeof action.payload?.count === 'number') {
        state.medicine_management_list = action.payload;
      }
    });
    builder.addCase(getPharmacyAutoConfirmed.fulfilled, (state, action) => {
      // if (typeof action.payload? === 'number') {
      state.pharmacy_auto_confirmed = action.payload;
      // }
    });
  },
});

export const selectTelepharmacyList_requested = (state: RootState) =>
  state.telepharmacyList.telepharmacyList_requested.results;
export const selectTelepharmacyList_accepted = (state: RootState) =>
  state.telepharmacyList.telepharmacyList_accepted.results;
export const selectTelepharmacyList_confirmed = (state: RootState) =>
  state.telepharmacyList.telepharmacyList_confirmed.results;
export const selectTelepharmacyList_finished_quick = (state: RootState) =>
  state.telepharmacyList.telepharmacyList_finished_quick.results;
export const selectTelepharmacyList_finished_pickup = (state: RootState) =>
  state.telepharmacyList.telepharmacyList_finished_pickup.results;
export const selectTelepharmacyList_finished_parcel = (state: RootState) =>
  state.telepharmacyList.telepharmacyList_finished_parcel.results;
export const selectTelepharmacyList_completed = (state: RootState) =>
  state.telepharmacyList.telepharmacyList_completed.results;
// completed count는 여기서 밖에 추출 불가
export const selectTelepharmacyCount_requested = (state: RootState) =>
  state.telepharmacyList.telepharmacyList_requested.count;
export const selectTelepharmacyCount_accepted = (state: RootState) =>
  state.telepharmacyList.telepharmacyList_accepted.count;
export const selectTelepharmacyCount_confirmed = (state: RootState) =>
  state.telepharmacyList.telepharmacyList_confirmed.count;
export const selectTelepharmacyCount_completed = (state: RootState) =>
  state.telepharmacyList.telepharmacyList_completed.count;
export const selectTelepharmacyCount_finished_quick = (state: RootState) =>
  state.telepharmacyList.telepharmacyList_finished_quick.count;
export const selectTelepharmacyCount_finished_parcel = (state: RootState) =>
  state.telepharmacyList.telepharmacyList_finished_parcel.count;
export const selectTelepharmacyCount_finished_pickup = (state: RootState) =>
  state.telepharmacyList.telepharmacyList_finished_pickup.count;
export const selectMedicineManagementList = (state: RootState) =>
  state.telepharmacyList.medicine_management_list.results;
export const selectPharmacyAutoConfirmed = (state: RootState) =>
  state.telepharmacyList.pharmacy_auto_confirmed.data;

export default telepharmacyList.reducer;
