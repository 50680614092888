/* eslint-disable @typescript-eslint/no-shadow */
import { ReactBootstrapIcons } from '@assets';
import { Blank, Button, COLOR, Font } from '@components/atoms';
import { PageLoader } from '@components/molecules';
import { commonCode } from '@constants';
import { formatters } from '@functions';
import { myDoctorAPI } from '@services';
import {
  TelepharmacyListCompletedStatusType,
  TelepharmacyListParameterType
} from '@services/myDoctor/types';
import moment from 'moment';
import { useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import {
  CSVDownloadButtonType,
  ManufacturedResultsType,
  TransactionDataType
} from './index.d';
import * as Style from './index.style';

const { COMMON_CODE } = commonCode;

const STATUS_TEXT = {
  TP_REJECTED: '조제거절',
  TP_P_CANCEL: '환자취소',
  TP_PH_CANCEL: '약사취소',
  TP_DEFAULT: '조제완료',
  DELIVERY_REQUESTED: '수거대기',
  DELIVERY_COLLECTED: '배송 중',
  DELIVERY_FINISHED: '배송완료',
  DELIVERY_CANCEL: '배송취소',
};

const headers = [
  { label: '진행상태', key: 'status' },
  { label: '배송방법', key: 'delivery_method' },
  { label: '환자 이름', key: 'patient_name' },
  { label: '약제비', key: 'amount' },
  { label: '비급여/급여', key: 'is_tp_uninsured' },
  { label: '완료일시', key: 'complete_time' },
];

function CSVDownloadButton({
  csvFilter,
  telepharmacyCount,
}: CSVDownloadButtonType) {
  const { timeFormatter } = formatters;
  const {
    statusString,
    delivery_method,
    from_date,
    to_date,
    is_uninsured,
    q_type,
    q,
  } = csvFilter;
  const [isLoading, setIsLoading] = useState(false);
  const [transactionData, setTransactionData] = useState<TransactionDataType>(
    [],
  );
  const csvLink = useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null);

  const getCompleteStatus = (type: string | undefined) => {
    let result: TelepharmacyListCompletedStatusType | undefined;
    switch (type) {
      case 'DELIVERY_FINISH':
        result = 'delivery_finished';
        break;
      case 'DELIVERY_INPROGRESS':
        result = 'delivery_inprogress';
        break;
      case 'PATIENT_CANCEL':
        result = 'cancel_by_patient';
        break;
      case 'PHARMACIST_CANCEL':
        result = 'cancel_by_pharmacist';
        break;
      default:
        break;
    }

    return result;
  };

  const getKoreanStatus = ({
    tpStatus,
    deliveryStatus,
  }: {
    tpStatus?: string;
    deliveryStatus?: string;
  }) => {
    let title = '조제완료';

    let status;
    if (tpStatus === COMMON_CODE.TP_STATUS.FINISHED) {
      if (deliveryStatus === COMMON_CODE.DELIVERY_STATUS.COLLECTED) {
        status = STATUS_TEXT.DELIVERY_COLLECTED;
      } else if (deliveryStatus === COMMON_CODE.DELIVERY_STATUS.FINISHED) {
        status = STATUS_TEXT.DELIVERY_FINISHED;
      } else if (deliveryStatus === COMMON_CODE.DELIVERY_STATUS.CANCEL) {
        status = STATUS_TEXT.DELIVERY_CANCEL;
      } else {
        status = STATUS_TEXT.DELIVERY_REQUESTED;
      }
    } else if (tpStatus === COMMON_CODE.TP_STATUS.PH_CANCEL) {
      status = STATUS_TEXT.TP_PH_CANCEL;
    } else if (tpStatus === COMMON_CODE.TP_STATUS.P_CANCEL) {
      status = STATUS_TEXT.TP_P_CANCEL;
    } else if (tpStatus === COMMON_CODE.TP_STATUS.REJECTED) {
      status = STATUS_TEXT.TP_REJECTED;
    } else {
      status = STATUS_TEXT.TP_DEFAULT;
    }

    switch (status) {
      case STATUS_TEXT.DELIVERY_COLLECTED:
        title = '배송 중';
        break;
      case STATUS_TEXT.DELIVERY_FINISHED:
        title = '배송완료';
        break;
      case STATUS_TEXT.DELIVERY_REQUESTED:
        title = '수거대기';
        break;
      case STATUS_TEXT.DELIVERY_CANCEL:
        title = '배송취소';
        break;
      case STATUS_TEXT.TP_PH_CANCEL:
        title = '약사취소';
        break;
      case STATUS_TEXT.TP_P_CANCEL:
        title = '환자취소';
        break;
      case STATUS_TEXT.TP_REJECTED:
        title = '조제거절';
        break;
      default:
        title = '조제완료';
        break;
    }

    return title;
  };

  const getKoreanDeliveryMethod = (method: string | undefined) => {
    let deliveryType: string | undefined;
    switch (method) {
      case COMMON_CODE.TP_METHOD.PARCEL:
        deliveryType = '택배';
        break;
      case COMMON_CODE.TP_METHOD.PICKUP:
        deliveryType = '방문';
        break;
      case COMMON_CODE.TP_METHOD.QUICK:
      case COMMON_CODE.TP_METHOD.ECONOMY_QUICK:
        deliveryType = '퀵';
        break;
      case COMMON_CODE.TP_METHOD.COLD_CHAIN:
        deliveryType = '냉장';
        break;
      default:
        deliveryType = '택배';
        break;
    }

    return deliveryType;
  };

  const completed_status = getCompleteStatus(statusString);

  const getTelepharmacyList = async (
    telepharmacyListRequestParameter: TelepharmacyListParameterType,
  ) => {
    const finalResults: ManufacturedResultsType[] = [];

    for (let offset = 0; offset < 10; offset += 1) {
      let isDataExist = true;

      // eslint-disable-next-line no-await-in-loop
      await myDoctorAPI
        .getTelepharmacyTpprocessList({
          ...telepharmacyListRequestParameter,
          offset: offset * 100,
        })
        .then((responseJSON) => {
          if (
            responseJSON.status === 200 &&
            responseJSON.data.results &&
            responseJSON.data.results.length
          ) {
            const { results } = responseJSON.data;
            const manufacturedResults = results?.map((result) => ({
              status: getKoreanStatus({
                tpStatus: result.status,
                deliveryStatus: result.delivery_status,
              }),
              delivery_method: getKoreanDeliveryMethod(result.method),
              patient_name: result.patient?.name,
              amount: result.amount,
              is_tp_uninsured: result.is_tp_uninsured ? '비급여' : '급여',
              complete_time: result.complete_time
                ? timeFormatter.getInjectTimeFormatter(result.complete_time)
                : '-',
            }));
            finalResults.push(...manufacturedResults);
          }
          if (responseJSON.data.next === null) isDataExist = false;
        });

      if (!isDataExist) break;
    }
    setTransactionData(finalResults);
  };

  const getTransactionData = async () => {
    if (
      window.confirm(`조회하신 조제 내역을 엑셀 파일로 다운로드 하시겠습니까?
(* 조제 내역은 최신순으로 최대 1,000건까지만 다운로드 됩니다.)
총 조회 내역: ${telepharmacyCount || '-'}건`)
    ) {
      setIsLoading(true);

      let patient_name: string | undefined;
      let phone_num: string | undefined;
      let address: string | undefined;
      let hospital_name: string | undefined;
      let doctor_name: string | undefined;
      let waybill_num: string | undefined;

      if (q_type === 'patient_name') patient_name = q;
      if (q_type === 'phone_num') phone_num = q;
      if (q_type === 'address') address = q;
      if (q_type === 'hospital_name') hospital_name = q;
      if (q_type === 'doctor_name') doctor_name = q;
      if (q_type === 'waybill_num') waybill_num = q;

      const telepharmacyListRequestParameter: TelepharmacyListParameterType = {
        limit: 100,
        status: 'completed',
      };

      if (completed_status && completed_status.length) {
        telepharmacyListRequestParameter.completed_status = completed_status;
      }

      if (from_date && from_date.length) {
        telepharmacyListRequestParameter.from_date = from_date;
      }

      if (to_date && to_date.length) {
        telepharmacyListRequestParameter.to_date = to_date;
      }

      if (delivery_method && delivery_method.length) {
        telepharmacyListRequestParameter.delivery_method = delivery_method;
      }

      if (is_uninsured && is_uninsured.length) {
        telepharmacyListRequestParameter.is_tp_uninsured = is_uninsured;
      }

      if (patient_name && patient_name.length) {
        telepharmacyListRequestParameter.patient_name = patient_name;
      }

      if (phone_num && phone_num.length) {
        telepharmacyListRequestParameter.phone_num = phone_num;
      }

      if (address && address.length) {
        telepharmacyListRequestParameter.address = address;
      }

      if (hospital_name && hospital_name.length) {
        telepharmacyListRequestParameter.hospital_name = hospital_name;
      }

      if (doctor_name && doctor_name.length) {
        telepharmacyListRequestParameter.doctor_name = doctor_name;
      }

      if (waybill_num && waybill_num.length) {
        telepharmacyListRequestParameter.waybill_num = waybill_num;
      }

      await getTelepharmacyList(telepharmacyListRequestParameter);

      csvLink?.current?.link.click();

      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading && <PageLoader />}
      <Button
        width="124px"
        height="32px"
        borderColor={COLOR['fill/dark']}
        backgroundColor={COLOR['fill/white']}
        title={
          <Style.IconButtonItem>
            <ReactBootstrapIcons.Download
              size={12}
              color={COLOR['fill/dark']}
            />
            <Blank appoint="Horizontal" size={8} />
            <Font fontType="body3">엑셀 다운로드</Font>
          </Style.IconButtonItem>
        }
        onClick={getTransactionData}
      />
      <CSVLink
        headers={headers}
        data={transactionData}
        filename={`myDoctor_${moment().format()}.csv`}
        className="hidden"
        ref={csvLink}
        target="_blank"
      />
    </>
  );
}

export default CSVDownloadButton;
