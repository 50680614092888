import { ReactBootstrapIcons } from '@assets/index';
import DownloadIcon from '@assets/svg/DownloadIcon';
import { Blank, Button, COLOR, Font } from '@components/atoms';
import { CallOutMessage } from '@components/molecules';
import printJS from 'print-js';
import { useEffect, useState } from 'react';
import { pdfjs } from 'react-pdf';
import { TelepharmacyType } from 'types/index';
import * as Style from './index.style';

function PrescriptionItemHeader({
  item,
}: {
  item: TelepharmacyType.TelepharmacyItemType;
}) {
  const [numPages, setNumPages] = useState<number | null>(null);

  useEffect(() => {
    if (item.tm && item.tm.prescription[0].image) {
      try {
        const pdfDocument = pdfjs.getDocument(item.tm.prescription[0].image);
        pdfDocument.promise.then((doc) => {
          setNumPages(doc._pdfInfo.numPages);
        });
      } catch (err) {
        console.log('PrescriptionItemHeader', err);
      }
    }
  }, [item]);

  const isMultiplePrescription = numPages && numPages > 1;

  return (
    <Style.ContentHeaderItem>
      <Style.ContentHeaderArea>
        <Style.ContentHeaderTitle>
          <Font fontType="h1" color="fill/black">
            처방전{isMultiplePrescription ? ` (총 ${numPages}장)` : ''}
          </Font>
        </Style.ContentHeaderTitle>
        <Style.ContentHeaderButton>
          <Button
            onClick={() => {
              window.open(item.tm?.prescription[0].image, '_blank');
            }}
            width="91px"
            height="32px"
            borderColor="fill/black"
            backgroundColor="fill/white"
            title={
              <Style.ButtonContainer>
                <DownloadIcon />
                <Blank appoint="Horizontal" size={8} />
                <Font fontType="body3" color="fill/black">
                  다운로드
                </Font>
              </Style.ButtonContainer>
            }
          />
          <Blank appoint="Horizontal" size={12} />
          <Button
            onClick={() =>
              printJS({
                printable: item.tm?.prescription[0].image,
                type: 'pdf',
                showModal: true,
              })
            }
            width="80px"
            height="32px"
            borderColor="fill/black"
            backgroundColor="fill/white"
            title={
              <Style.ButtonContainer>
                <ReactBootstrapIcons.Printer color={COLOR['fill/black']} />
                <Blank appoint="Horizontal" size={8} />
                <Font fontType="body3" color="fill/black">
                  프린트
                </Font>
              </Style.ButtonContainer>
            }
          />
        </Style.ContentHeaderButton>
      </Style.ContentHeaderArea>

      {isMultiplePrescription && (
        <Style.ContentBodyArea>
          <CallOutMessage iconColor="fill/light" backgroundColor="greyscale6">
            <Font fontType="body1" color="fill/light">
              {`접수된 처방전이 총 ${numPages}장입니다.
            스크롤을 내려 모든 처방전을 확인해주세요.`}
            </Font>
          </CallOutMessage>
        </Style.ContentBodyArea>
      )}
    </Style.ContentHeaderItem>
  );
}

export default PrescriptionItemHeader;
