import { prescription } from '@assets/index';
import Caution from '@assets/svg/Caution';
import useTPprocessRequestList from '@mobile/api/hooks/useTPprocessRequestList';
import { LoadingContainer } from '@mobile/components/layout/LoadingContainer';
import Header from '@mobile/pages/RequestList/components/Header';
import PharmacyInfo from '@mobile/pages/RequestList/components/Header/contents/PharmacyInfo';
import useToggleHeader from '@mobile/pages/RequestList/components/Header/hooks';
import { Mixpanel } from '@utils/mixpanel';
import { useEffect, useLayoutEffect, useRef } from 'react';
import { BeatLoader } from 'react-spinners';
import styled from 'styled-components';
import RequestItem from './components/RequestItem';

function RequestList() {
  const infoContainerRef = useRef<HTMLDivElement>(null);
  const { isOpen, handleToggle } = useToggleHeader();
  const { data, isLoading } = useTPprocessRequestList();

  useLayoutEffect(() => {
    if (infoContainerRef.current) {
      document.documentElement.style.setProperty(
        '--infoContainer-height',
        `${infoContainerRef.current.offsetHeight}px`,
      );
    }
  }, [isLoading]);

  useEffect(() => {
    if (data)
      Mixpanel.track('[Page] TP Requested List (Mobile)', {
        'Number of List': data?.length,
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (isLoading)
    return (
      <LoadingContainer>
        <BeatLoader size={15} loading color="#3478F6" />
      </LoadingContainer>
    );

  return (
    <>
      <Header isOpen={isOpen} onToggle={handleToggle}>
        <PharmacyInfo onToggle={handleToggle} />
      </Header>

      <InfoContainer ref={infoContainerRef}>
        <TopSection>
          <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
            <LargeText>접수대기</LargeText>
            <LargeText countText>{`${data?.length}건`}</LargeText>
          </div>
          <SmallText>요청순</SmallText>
        </TopSection>
        <BottomSection>
          <Caution />
          10분 안에 수락되지 않은 조제는 자동 취소됩니다.
        </BottomSection>
      </InfoContainer>

      {data?.length === 0 ? (
        <NotRequest>
          <PrediscriptionImg src={prescription} />
          <EmptyText>{'대기 중인\n접수 요청이 없어요'}</EmptyText>
        </NotRequest>
      ) : (
        <div style={{ paddingBottom: '40px' }}>
          {data?.map((item, index) => (
            <RequestItem
              key={index}
              index={index}
              tppId={item.id}
              prescriptionId={item.prescription_id}
              deliveryMethod={item.method}
              patientName={item.name}
              rejectTime={item.auto_reject_time}
              reservationCode={item.telepharmacy_hash}
              symptom={item.symptom_keyword_name}
            />
          ))}
        </div>
      )}
    </>
  );
}

export default RequestList;

const InfoContainer = styled.div`
  display: flex;
  padding: 24px 20px 12px 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
`;

const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`;

const BottomSection = styled.div`
  display: flex;
  width: 100%;
  padding: 16px;

  align-items: flex-start;
  gap: 7px;
  flex: 1 0 0;
  border-radius: 8px;
  background: #f9fafa;

  color: #474a52;

  font-family: Pretendard, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
`;

const LargeText = styled.div<{ countText?: boolean }>`
  color: ${({ countText }) => (countText ? '#2F6EFF' : '#1e2024')};

  font-family: Pretendard, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
`;

const SmallText = styled.div`
  color: #212529;

  font-family: Pretendard, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
`;

const NotRequest = styled.div`
  height: calc(100vh - var(--infoContainer-height) - var(--header-height));
  height: calc(100dvh - var(--infoContainer-height) - var(--header-height));
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding-bottom: 80px;
`;

const PrediscriptionImg = styled.img`
  width: 88px;
  height: 88px;
`;

const EmptyText = styled.div`
  color: #474a52;
  text-align: center;

  font-family: Pretendard, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  white-space: pre-line;
`;
