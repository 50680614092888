import { BColor } from '@components/meraki-ui/BColor';
import BFlex from '@components/meraki-ui/BFlex';
import BTypography from '@components/meraki-ui/BTypography';
import { getLength } from '@components/meraki-ui/style';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import styled from 'styled-components';

type SideType = 'top' | 'bottom' | 'left' | 'right';

interface BTooltipProps {
  visible: boolean;
  margin?: string;
  marginNumber: number;
  side?: SideType;
  width?: number;

  title?: string;
  description?: string | string[];
  delayDuration?: number;
}

function BTooltip({
  visible,
  width,
  side,
  margin,
  marginNumber,
  title,
  description,

  delayDuration,
}: BTooltipProps) {
  return (
    <BFlex position="absolute">
      <TooltipPrimitive.Provider delayDuration={delayDuration ?? 800}>
        <TooltipPrimitive.Root open={visible}>
          <StyledTrigger>
            <BFlex />
          </StyledTrigger>
          <TooltipPrimitive.Portal>
            <TooltipContainer
              margin={margin}
              sideOffset={marginNumber}
              side={side ?? 'top'}
            >
              <BFlex
                isColumn
                gap={8}
                position="relative"
                width={width}
                padding="1rem 1.25rem"
              >
                {title ? (
                  <BTypography
                    size={16}
                    isBold
                    text={title}
                    color="mono01"
                    justNoWrap={width ? false : undefined}
                  />
                ) : undefined}

                {typeof description === 'string' ? (
                  <BTypography
                    size={16}
                    text={description}
                    color="mono01"
                    justNoWrap={width ? false : undefined}
                  />
                ) : description?.length ? (
                  description.map((d) => (
                    <BTypography
                      size={16}
                      text={d}
                      color="mono01"
                      key={d}
                      justNoWrap={width ? false : undefined}
                    />
                  ))
                ) : undefined}
              </BFlex>
              <TooltipPrimitive.Arrow />
            </TooltipContainer>
          </TooltipPrimitive.Portal>
        </TooltipPrimitive.Root>
      </TooltipPrimitive.Provider>
    </BFlex>
  );
}

const StyledTrigger = styled(TooltipPrimitive.Trigger)`
  visibility: hidden;
  transition: all 0.5s ease-in-out;
`;

const TooltipContainer = styled(TooltipPrimitive.Content)<{
  side: SideType;
  margin?: string;
  width?: number;
}>`
  display: flex;
  position: absolute;
  width: ${(props) => (props.width ? getLength(props.width) : undefined)};
  top: ${(props) => (props.side === 'top' ? '-4.5rem' : undefined)};
  right: ${(props) => (props.side === 'left' ? '-3.5rem' : undefined)};
  left: ${(props) => (props.side === 'right' ? '-3.5rem' : undefined)};
  margin: ${(props) => props.margin};
  background-color: ${BColor.mono10};
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  white-space: nowrap;
  transition: all 0.5s ease-in-out;
`;

export default BTooltip;
