import { getTPProcessDetail } from '@mobile/api';
import AuthError from '@mobile/pages/AuthError';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

const useTPProcessDetail = () => {
  const { id } = useParams();
  const tppId = Number(id);

  const navigate = useNavigate();

  return useQuery({
    queryFn: () => getTPProcessDetail({ tppId }),
    queryKey: [`TP_PROCESS_DETAIL_${id}`],
    retry: false,
    onSuccess: (data) => {
      if (!(data.status === 'TP_STATUS__REQUESTED')) {
        alert('이미 수락 및 거절한 요청입니다.');
        navigate('/mobile/request');
      }
    },
    onError: (error) => {
      if (error instanceof AuthError) {
        return navigate('/mobile/auth-error');
      }

      alert('처방전 데이터를 불러오는데 실패했습니다.');

      return navigate('/mobile/request');
    },
  });
};

export default useTPProcessDetail;
