import { BoxContainer, COLOR } from '@components/atoms';
import styled from 'styled-components';

export const Container = styled(BoxContainer)<{ isFilterAreaOpen: boolean }>`
  display: flex;
  padding: 0px 0px
    ${({ isFilterAreaOpen }) => (isFilterAreaOpen ? '20px' : '0')} 0px;
  margin-bottom: 20px;
  flex-direction: column;
  &.smallContainer {
    width: 902px;
  }
`;

export const FilterItemArea = styled.div`
  display: flex;
  border-bottom: 1px solid ${COLOR['disabled/foreground']};
  padding: 12px 0px;
  flex-direction: row;
  // height: 62px;
  align-items: center;
`;

export const FilterItemLeftArea = styled.div`
  padding-left: 40px;
  width: 168px;
  display: flex;
  align-items: center;
`;

export const FilterItemRightArea = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
  width: 900px;
`;

export const SearchButtonContainer = styled.div<{
  width: number;
  height: number;
  borderColor: string;
}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};
  border: 1px solid ${({ borderColor }) => borderColor};
  border-radius: 100px;
  padding: 0 16px;
  cursor: pointer;
`;

export const DatePickerArea = styled.div`
  display: flex;
  align-items: center;
  height: 38px;
`;

export const DatePickerInput = styled.input.attrs({
  type: 'date',
})`
  width: 180px;
  height: 38px;
  cursor: pointer;
  padding: 0 8px;
  border: 1px solid ${COLOR['fill/medium']};
  border-radius: 4px;
`;

export const CheckBoxArea = styled.div`
  display: flex;
  align-items: center;
  height: 38px;
`;

export const CheckBoxLongWidth = styled.div`
  width: 108px;
`;

export const CheckBoxShortWidth = styled.div`
  width: 80px;
`;

export const IconButtonItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 12px;
  margin-right: 12px;
`;

export const OptionRightAreaDropdownList = styled.div`
  width: 220px;
  height: 220px;
  background-color: ${COLOR['fill/white']};
  border-radius: 8px;
  filter: drop-shadow(0px 1px 20px rgba(0, 0, 0, 0.25));
  position: absolute;
  top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const OptionRightAreaDropdownItem = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  color: ${COLOR['fill/dark']};
  &:hover {
    color: ${COLOR['fill/black']};
  }
`;

export const OptionRightAreaTextInputArea = styled.div`
  width: 350px;
  height: 38px;
`;

export const ButtonArea = styled.div`
  display: flex;
  margin-top: 20px;
  flex-direction: row;
  justify-content: center;
`;
