import { formatDate } from '@mobile/pages/TPDetail/utils';
import styled from 'styled-components';

type Props = {
  requestTime?: string;
  symptom?: string;
  methodText?: string;
  requestInfo?: string;
};

function ReservationInfo({
  methodText = '방문수령 👟',
  requestInfo = '요청사항 없음',
  requestTime,
  symptom = '-',
}: Props) {
  const reservationTime = formatDate(requestTime);

  return (
    <Container>
      <Title>예약 정보</Title>
      <ContentsWrapper>
        <Row>
          <Key>조제요청일시</Key>
          <Value>{reservationTime}</Value>
        </Row>
        <Row>
          <Key>증상</Key>
          <Value>{symptom}</Value>
        </Row>
        <Row>
          <Key>약 수령방법</Key>
          <Value>{methodText}</Value>
        </Row>
        <Row>
          <Key>약국 요청사항</Key>
          <Value>{requestInfo ?? '요청사항 없음'}</Value>
        </Row>
      </ContentsWrapper>
    </Container>
  );
}

export default ReservationInfo;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  padding: 20px;
  border-top: 4px solid #f1f2f4;
  border-bottom: 4px solid #f1f2f4;
  width: 100%;
`;

const Title = styled.div`
  color: #1e2024;

  font-family: Pretendard, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
`;

const ContentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`;

const Row = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
`;

const Key = styled.div`
  width: 120px;
  color: #9fa1a8;

  font-family: Pretendard, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

const Value = styled.div`
  flex: 1 0 0;
  color: #474a52;

  font-family: Pretendard, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
`;
