import { ReactBootstrapIcons } from '@assets';
import { Blank, Button, COLOR, Font, TextInput } from '@components/atoms';
import {
  CallOutMessage,
  ModalCommonStructure as Modal,
} from '@components/molecules';
import { SetFirstLoginModalTemplateType } from './index.d';
import * as Style from './index.style';

function SetFirstLoginModalTemplate({
  isVisible,
  password,
  passwordConfirm,
  onChangePassword,
  onChangePasswordConfirm,
  buttonVisible,
  setFirstPassword,
}: SetFirstLoginModalTemplateType) {
  return (
    <Modal
      isOpen={isVisible}
      closeButton={false}
      modalHeader={
        <Style.TitleArea>
          <Font fontType="display2" color="fill/dark">
            초기 비밀번호 설정
          </Font>
        </Style.TitleArea>
      }
      modalBody={
        <>
          <Style.DescriptionArea>
            <CallOutMessage>
              <Font fontType="body1" color="fill/dark">
                비밀번호를 영문/숫자/특수문자를 모두 포함하여 8자 이상
                입력해주세요.
              </Font>
            </CallOutMessage>
          </Style.DescriptionArea>
          <Style.TextInputArea>
            <Style.TextInputContent>
              <TextInput
                value={password}
                type="password"
                onChange={onChangePassword}
                fontSize={16}
                placeholder="영문/숫자/특수문자 모두 포함 8자 이상"
              />
            </Style.TextInputContent>
            <Style.TextInputContent>
              <TextInput
                value={passwordConfirm}
                type="password"
                onChange={onChangePasswordConfirm}
                fontSize={16}
                placeholder="비밀번호 재입력"
              />
            </Style.TextInputContent>
          </Style.TextInputArea>
        </>
      }
      modalFooter={
        <Button
          onClick={() => setFirstPassword()}
          disableColor={COLOR['fill/medium']}
          backgroundColor={COLOR['fill/accent']}
          visible={buttonVisible}
          width="104px"
          title={
            <Style.ButtonContainer>
              <ReactBootstrapIcons.Check2 color={COLOR['fill/white']} />
              <Blank appoint="Horizontal" size={4} />
              <Font fontType="body2" color="fill/white">
                설정하기
              </Font>
            </Style.ButtonContainer>
          }
        />
      }
    />
  );
}

export default SetFirstLoginModalTemplate;
