import { Button, COLOR, Font } from '@components/atoms';
import { CallOutMessage } from '@components/molecules';
import {
  CardAlertArea,
  CardHeaderArea,
  CardHeaderTitleArea,
  NoOffDayContainer,
  RestDayCardArea,
} from '@pages/timeManagementPage/index.style';
import OffDayItem from '@pages/timeManagementPage/OffDayItem';
import RegisterOffDayModal from '@pages/timeManagementPage/registerOffDayModal';
import { myDoctorAPI } from '@services/myDoctor';
import { useCallback, useMemo, useState } from 'react';
import { useQuery } from 'react-query';

const disableOffScheduleFunction = true;

export const offScheduleKey = 'off-schedule';

export default function OffDayContainer() {
  const [offDayModalVisible, offDayModalControl] = useState<boolean>(false);

  const toggleOffDayModal = useCallback(() => {
    offDayModalControl((current) => !current);
  }, []);

  const { isLoading, error, data } = useQuery(
    [offScheduleKey],
    () => myDoctorAPI.getDoctorInfoOffSchedule({ limit: 60, offset: 0 }),
    {
      enabled: true,
    },
  );

  const offDays = useMemo(() => data?.results, [data?.results]);

  return (
    <RestDayCardArea>
      <CardHeaderArea>
        <CardHeaderTitleArea>
          <Font fontType="h2" noWhiteSpace>
            휴무일
          </Font>
          {!disableOffScheduleFunction && (
            <Button
              title="휴무 등록하기"
              onClick={toggleOffDayModal}
              width="93px"
              height="32px"
              backgroundColor={COLOR['fill/accent']}
              textColor={COLOR['fill/white']}
            />
          )}
        </CardHeaderTitleArea>
      </CardHeaderArea>
      <CardAlertArea>
        {disableOffScheduleFunction ? (
          <CallOutMessage iconColor="state/warning">
            <Font fontType="body3">
              휴무일 등록 기능이 아직 출시 준비 중입니다.
            </Font>
          </CallOutMessage>
        ) : offDays?.length ? (
          offDays
            .filter((offDay) => offDay.is_enabled)
            .map((offDay) => <OffDayItem offDay={offDay} key={offDay.id} />)
        ) : (
          <NoOffDayContainer>
            <Font fontType="body2" color="fill/medium">
              등록된 휴무일이 없습니다.
            </Font>
          </NoOffDayContainer>
        )}
      </CardAlertArea>
      <RegisterOffDayModal
        offDayModalVisible={offDayModalVisible}
        closeOffDayModal={toggleOffDayModal}
      />
    </RestDayCardArea>
  );
}
