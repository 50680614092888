import { Mixpanel } from '@utils/mixpanel';
import { clickAdBanner, pageType } from '@utils/mixpanel/constants';

export const newReservationAcceptedMixpanelEvents = {
  pageNewReservationAccepted: '[Page] New Reservation Accepted',

  clickAdBanner,
} as const;

type NewReservationAcceptedMixpanelEvents =
  keyof typeof newReservationAcceptedMixpanelEvents;

const newReservationAcceptedMixpanelEventsMap: Record<
  NewReservationAcceptedMixpanelEvents,
  (adID?: string, adType?: string) => void
> = {
  pageNewReservationAccepted: () =>
    Mixpanel.track(
      newReservationAcceptedMixpanelEvents.pageNewReservationAccepted,
    ),

  clickAdBanner: (adID?: string, adType?: string) =>
    Mixpanel.track(newReservationAcceptedMixpanelEvents.clickAdBanner, {
      'Page Type': pageType.newReservationAccepted,
      'AD ID': adID,
      'AD Type': adType,
    }),
};

export const trackNewReservationAcceptedMixpanelEvents = (
  newReservationAcceptedMixpanelEvent: NewReservationAcceptedMixpanelEvents,
) => {
  return newReservationAcceptedMixpanelEventsMap[
    newReservationAcceptedMixpanelEvent
  ];
};
