import { supporters } from '@functions';
import { commonHooks } from '@hooks';
import useSocket, {
  disconnectSocketAndReset,
} from '@hooks/featureHooks/useSocket';
import {
  close_globalNavBar,
  open_globalNavBar,
  selectGlobalNavBarOpen,
} from '@stores/globalNavBarStore';
import { useAppDispatch, useAppSelector } from '@stores/hooks';
import { openModal_companyInfoModal } from '@stores/modalStore/companyInfoModal';
import { openModal_customerCenterModal } from '@stores/modalStore/customerCenterModal';
import { openModal_getReadyModal } from '@stores/modalStore/getReadyModal';
import {
  getTelepharmacyCount,
  selectTelepharmacyCount_pre_confirmed,
} from '@stores/telepharmacyStore/telepharmacyCount';
import {
  getTelepharmacyList_ALL,
  getTelepharmacyList_finished_pickup,
  getTelepharmacyList_requested,
  selectTelepharmacyCount_accepted,
  selectTelepharmacyCount_confirmed,
  selectTelepharmacyCount_finished_parcel,
  selectTelepharmacyCount_finished_pickup,
  selectTelepharmacyCount_finished_quick,
  selectTelepharmacyCount_requested,
} from '@stores/telepharmacyStore/telepharmacyList';
import {
  selectUserInformationIsLogin,
  selectUserInformationPharmacistData,
  selectUserInformationUserData,
  storeLogout,
} from '@stores/userInformationStore';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const useGlobalNavBarHook = () => {
  const { useQuery } = commonHooks;
  const { logoutSupporter } = supporters;
  const { useMount, useInterval, useWindowSize } = commonHooks;
  const { windowWidth } = useWindowSize();
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isLogin = useAppSelector(selectUserInformationIsLogin);
  const isOpen = useAppSelector(selectGlobalNavBarOpen);
  const [isConfirmedLinkButtonClicked, setIsConfirmedLinkButtonClicked] =
    useState(false);
  const [isDescriptionDropdownClicked, setDescriptionDropdownClicked] =
    useState(false);
  const userData = useAppSelector(selectUserInformationUserData);
  const pharmacistData = useAppSelector(selectUserInformationPharmacistData);
  const requestedCount = useAppSelector(selectTelepharmacyCount_requested);
  const acceptedCount = useAppSelector(selectTelepharmacyCount_accepted);
  const preConfirmedCount = useAppSelector(
    selectTelepharmacyCount_pre_confirmed,
  );
  const confirmedCount = useAppSelector(selectTelepharmacyCount_confirmed);
  const finishedQuickCount = useAppSelector(
    selectTelepharmacyCount_finished_quick,
  );
  const finishedParcelCount = useAppSelector(
    selectTelepharmacyCount_finished_parcel,
  );

  const finishedPickupCount = useAppSelector(
    selectTelepharmacyCount_finished_pickup,
  );

  const completedParcelCount =
    finishedQuickCount + finishedParcelCount + finishedPickupCount;

  const { socketConencted } = useSocket();

  const query = useQuery();

  const pageQuery = query.get('page');
  const page = Number(pageQuery) || 0;

  useMount(() => {
    if (isLogin) {
      dispatch(getTelepharmacyCount());
      dispatch(getTelepharmacyList_ALL());
    }
  });

  useEffect(() => {
    dispatch(
      getTelepharmacyList_finished_pickup({
        offset: page * 30,
        limit: 30,
      }),
    );
  }, [dispatch, page]);

  // socket connection fail fallback: 소켓 연결이 불가능하다면 interval 사이클 돌릴 때 마다 API 호출합니다.
  // ---------------------------
  useInterval(() => {
    if (!socketConencted()) {
      dispatch(getTelepharmacyCount());
    }
  }, 10000);
  useInterval(() => {
    if (!socketConencted()) {
      dispatch(getTelepharmacyList_requested({ limit: 60, offset: 0 }));
    }
  }, 60000);
  // ---------------------------

  const onLogout = useCallback(async () => {
    if (window.confirm('로그아웃 하시겠습니까?')) {
      await logoutSupporter.logout();
      dispatch(storeLogout());

      window.location.reload();
      disconnectSocketAndReset();
    }
  }, [dispatch, logoutSupporter]);

  const openGNB = useCallback(() => {
    dispatch(open_globalNavBar());
  }, [dispatch]);

  const closeGNB = useCallback(() => {
    dispatch(close_globalNavBar());
  }, [dispatch]);

  const handleGNB = useCallback(() => {
    if (isOpen) {
      dispatch(close_globalNavBar());
    } else {
      dispatch(open_globalNavBar());
    }
  }, [dispatch, isOpen]);

  const openCustomerCenterModal = useCallback(() => {
    dispatch(openModal_customerCenterModal());
  }, [dispatch]);

  const openCompanyInfoModal = useCallback(() => {
    dispatch(openModal_companyInfoModal());
  }, [dispatch]);

  const openGetReadyModal = useCallback(
    (title: string) => {
      dispatch(openModal_getReadyModal({ title }));
    },
    [dispatch],
  );

  const onConfirmedLinkButtonClicked = useCallback((value: boolean) => {
    setIsConfirmedLinkButtonClicked(value);
  }, []);

  const onDescriptionDropdownClicked = useCallback((value: boolean) => {
    setDescriptionDropdownClicked(value);
  }, []);

  const navigateToPage = useCallback(
    (pageUrl: string) => {
      navigate(pageUrl);
    },
    [navigate],
  );

  useMount(() => {
    if (pathname.includes('/home/confirmedTreatment')) openGNB();
  });

  return {
    windowWidth,
    requestedCount,
    acceptedCount,
    preConfirmedCount,
    confirmedCount,
    finishedQuickCount,
    finishedParcelCount,
    isOpen,
    openGNB,
    closeGNB,
    handleGNB,
    userData,
    pharmacistData,
    pathname,
    openCustomerCenterModal,
    openCompanyInfoModal,
    onLogout,
    openGetReadyModal,
    isConfirmedLinkButtonClicked,
    onConfirmedLinkButtonClicked,
    isDescriptionDropdownClicked,
    onDescriptionDropdownClicked,
    navigateToPage,
    completedParcelCount,
    finishedPickupCount,
  };
};

export default useGlobalNavBarHook;
