import { Font } from '../index';
import checkButton_checked from './icon/checkButton_checked.png';
import checkButton_unChecked from './icon/checkButton_unChecked.png';
import { CheckButtonType } from './index.d';
import { Check, Container } from './index.style';

function CheckButton({ title, onClick, isSelected }: CheckButtonType) {
  return (
    <Container onClick={() => onClick()}>
      <Check src={isSelected ? checkButton_checked : checkButton_unChecked} />
      <Font fontType={isSelected ? 'body1' : 'body1_medium'} color="fill/dark">
        {title}
      </Font>
    </Container>
  );
}

export default CheckButton;
