import { COLOR } from '@components/atoms';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(
    107.56deg,
    #00163f 0%,
    rgba(9, 46, 116, 0.75) 100%
  );
`;

export const LoginArea = styled.div`
  display: flex;
  flex-direction: column;
  padding: 60px;
  width: 520px;
  height: 504px;
  background: ${COLOR['fill/white']};
  border-radius: 12px;
`;

export const LogoArea = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 62px;
`;

export const MainLogo = styled.img`
  width: 156px;
  height: 32px;
  margin-bottom: 16px;
`;

export const SubLogo = styled.img`
  width: 220px;
  height: 32px;
`;

export const TextInputArea = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;

export const SignupFindIdPasswordContainer = styled.div`
  width: 100%;
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const SignupArea = styled.div`
  margin-right: 12px;
`;

export const VerticalLine = styled.div`
  width: 1px;
  height: 70%;
  border: 1px solid ${COLOR['fill/dark']};
`;

export const FindIdPasswordArea = styled.div`
  margin-left: 12px;
`;

export const LoginButtonArea = styled.div`
  width: 100%;
`;
