import { sentry } from '@configs';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { store } from '@stores';
import { GlobalStyle } from '@styles';
import fetchForClient from '@utils/fetchAPI/fetchForClient';
import { logOnError } from '@utils/log';
import { mixpanelConfig } from '@utils/mixpanel';
import mixpanel from 'mixpanel-browser';
import { createRoot } from 'react-dom/client';
import { pdfjs } from 'react-pdf';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { SWRConfig } from 'swr';
import App from './App';
import * as serviceWorker from './serviceWorker';

Sentry.init({
  dsn: sentry.sentryConfig.dsn,
  integrations: [new BrowserTracing()],
  sampleRate: 0.5,
  tracesSampleRate: 0.1,
});

const { api_host, token, debug } = mixpanelConfig();
mixpanel.init(token, {
  api_host,
  debug,
});

const persistor = persistStore(store);

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);
root.render(
  <>
    <Provider store={store}>
      <SWRConfig
        value={{
          fetcher: fetchForClient,

          onError: async (error) => {
            logOnError('error', error as Error);
          },

          shouldRetryOnError: false,
          revalidateOnFocus: false,
        }}
      >
        <PersistGate loading={null} persistor={persistor}>
          <GlobalStyle />
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </PersistGate>
      </SWRConfig>
    </Provider>
  </>,
);

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

serviceWorker.unregister();
