import { trackLoginMixpanelEvents } from '@utils/mixpanel/login';
import { useEffect } from 'react';
import useLoginPageHook from './hooks';
import LoginPageTemplate from './templates';

function LoginPage() {
  const {
    email,
    password,
    onChangeEmail,
    onChangePassword,
    onKeyPress,
    login,
    openFindIdPasswordModal,
    openSignupModal,
    increaseLogoClickCount,
  } = useLoginPageHook();

  useEffect(() => {
    trackLoginMixpanelEvents('pageLogin');
  });

  return (
    <LoginPageTemplate
      email={email}
      password={password}
      onChangeEmail={onChangeEmail}
      onChangePassword={onChangePassword}
      onKeyPress={onKeyPress}
      login={login}
      openFindIdPasswordModal={openFindIdPasswordModal}
      openSignupModal={openSignupModal}
      increaseLogoClickCount={increaseLogoClickCount}
    />
  );
}

export default LoginPage;
