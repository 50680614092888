export const mixpanelVersion = '1.0.0';

export const clickAdBanner = '[Click] Ad Banner';

export const pageType = {
  newReservationRequested: 'TP Requested',
  newReservationAccepted: 'TP Accepted',
  confirmedTreatmentConfirmed: 'TP Confirmed',
  confirmedTreatmentOngoingQuick: 'TP Finished Quick',
  confirmedTreatmentOngoingParcel: 'TP Finished Parcel',
  completeTreatment: 'TP Completed',
  confirmedTreatmentPickup: 'TP Finished Pickup',
} as const;

export type PageType = keyof typeof pageType;
