import styled from 'styled-components';

export const TitleArea = styled.div``;

export const DescriptionArea = styled.div`
  margin-bottom: 40px;
`;

export const TextButtonArea = styled.div`
  margin-bottom: 20px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
