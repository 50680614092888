import { COLOR } from '@components/atoms';
import { styleVariables } from '@constants';
import styled from 'styled-components';

const { BREAK_POINTS } = styleVariables;

export const GNBLayout = styled.div`
  position: relative;
`;

export const GNBMain = styled.main<{ isOpen: boolean }>`
  h1 {
    font-size: 14px;
  }
  position: absolute;
  top: 89px;
`;

export const GNBTopbar = styled.div`
  width: 100vw;
  height: 88px;
  background-color: ${COLOR['fill/white']};
  position: fixed;
  top: 0;
  z-index: 20;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 20px;
`;

export const GNBSubTopbar = styled.div<{ isOpen: boolean }>`
  width: 100vw;
  height: ${({ isOpen }) => (isOpen ? 72 : 0)}px;
  background-color: ${COLOR['fill/white']};
  position: fixed;
  top: 88px;
  z-index: 19;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 20px;
`;

export const GNBLogoArea = styled.div`
  cursor: pointer;
  flex: 4;
`;

export const GNBLogo = styled.img`
  width: 35px;
  height: 20px;
  margin-left: 20px;
`;

export const GNBLinkContainerArea = styled.div`
  display: flex;
  align-items: center;
  flex: 17;
  gap: 40px;
`;

export const GNBSubContainerArea = styled.div`
  display: flex;
  align-items: center;
  flex: 17;
`;

export const GNBLinkContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const GNBDivider = styled.div`
  width: 1px;
  height: 20px;
  background-color: ${COLOR['fill/medium']};
`;

export const GNBLinkArea = styled.div<{ isActive: boolean }>`
  height: 88px;
  display: flex;
  align-items: center;
  text-decoration: none;
  border-bottom: ${({ isActive }) =>
    isActive ? `3px solid ${COLOR['fill/black']}` : `3px solid transparent`};
  font-size: 18px;
  font-weight: ${({ isActive }) => (isActive ? 700 : 400)};
  font-stretch: normal;
  font-style: normal;
  line-height: 24px;
  letter-spacing: normal;
  text-align: center;
  color: ${({ isActive }) =>
    isActive ? COLOR['fill/black'] : COLOR['fill/medium']};
  cursor: pointer;
  position: relative;
  &:hover {
    font-weight: 700;
    border-bottom: 3px solid ${COLOR['fill/black']};
    color: ${COLOR['fill/black']};
  }
`;

export const GNBButtonLinkArea = styled.div<{ isActive: boolean }>`
  height: 88px;
  display: flex;
  align-items: center;
  text-decoration: none;
  border-bottom: ${({ isActive }) =>
    isActive ? `3px solid ${COLOR['fill/black']}` : `3px solid transparent`};
  font-size: 18px;
  font-weight: ${({ isActive }) => (isActive ? 700 : 400)};
  font-stretch: normal;
  font-style: normal;
  line-height: 24px;
  letter-spacing: normal;
  text-align: center;
  color: ${({ isActive }) =>
    isActive ? COLOR['fill/black'] : COLOR['fill/medium']};
  margin-left: 8px;
  margin-right: 8px;
  cursor: pointer;
  position: relative;
  &:hover {
    font-weight: 700;
    border-bottom: 3px solid ${COLOR['fill/black']};
    color: ${COLOR['fill/black']};
  }
`;

export const GNBLinkNotification = styled.div`
  font-size: 12px;
  height: 18px;
  font-weight: 600;
  border-radius: 4px;
  margin-left: 6px;
  padding: 2px 4px;
  background: ${COLOR['fill/accent']};
  color: ${`${COLOR['fill/white']}`};
  display: flex;
  align-items: center;
`;

export const GNBSubLinkContainerArea = styled.div<{ isActive: boolean }>`
  height: 36px;
  border-radius: 20px;
  border: 1.5px solid
    ${({ isActive }) => (isActive ? COLOR['fill/black'] : COLOR['fill/medium'])};
  background-color: ${({ isActive }) =>
    isActive ? COLOR['fill/black'] : COLOR['fill/white']};
  margin-right: 16px;
  padding: 8px 20px;
  font-size: 16px;
  font-weight: ${({ isActive }) => (isActive ? 700 : 400)};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ isActive }) =>
    isActive ? COLOR['fill/white'] : COLOR['fill/medium']};
  cursor: pointer;
  &:hover {
    color: ${COLOR['fill/white']};
    border: 2px solid ${COLOR['fill/black']};
    background-color: ${COLOR['fill/black']};
    font-weight: 700;
  }
`;

export const GNBDescriptionArea = styled.div`
  // flex: 4;
  width: 260px;
  @media (max-width: ${BREAK_POINTS.size1440}px) {
    width: 50px;
  }
`;

export const GNBDescriptionButtonContainer = styled.div`
  width: 260px;
  display: flex;
  padding: 8px 20px;
`;

export const GNBStatusArea = styled.div`
  flex: 1;
  cursor: pointer;
`;

export const GNBStatusButtonArea = styled.button`
  flex: 1;
  cursor: pointer;
`;

export const GNBStatus = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid ${COLOR['fill/white']};
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${COLOR['state/success']};
`;

export const GNBTextArea = styled.div`
  flex: 3;
  display: flex;
  flex-direction: column;
  align-items: start;
`;

export const GNBTopTextArea = styled.div``;

export const GNBBottomTextArea = styled.div``;

export const GNBIconArea = styled.div`
  display: flex;
  align-items: center;
`;

export const GNBDescriptionDropdownList = styled.div`
  width: 260px;
  background-color: ${COLOR['fill/white']};
  border-radius: 8px;
  filter: drop-shadow(0px 1px 20px rgba(0, 0, 0, 0.25));
  position: absolute;
  top: 80px;
  z-index: 999;
  padding: 10px;
  @media (max-width: ${BREAK_POINTS.size1440}px) {
    right: 20px;
  }
`;

export const GNBDescriptionDropdownItemArea = styled.div`
  padding: 5px;
`;

export const GNBDescriptionDropdownItem = styled.div`
  display: flex;
  height: 36px;
  padding: 10px 4px;
  font-size: 18px;
  font-weight: 700;
  color: ${COLOR['fill/dark']};
  cursor: pointer;
  &:hover {
    color: ${COLOR['fill/black']};
  }
`;
