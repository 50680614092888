import { COLOR, Font } from '@components/atoms';
import BFlex from '@components/meraki-ui/BFlex';
import { styleVariables } from '@constants';
import { commonHooks } from '@hooks';
import useGlobalNavBarHook from '@pages/globalNavBar/hooks';
import { useAppDispatch, useAppSelector } from '@stores/hooks';
import {
  getTelepharmacyList_finished_parcel,
  getTelepharmacyList_finished_pickup,
  getTelepharmacyList_finished_quick,
  selectTelepharmacyCount_finished_parcel,
  selectTelepharmacyCount_finished_pickup,
  selectTelepharmacyCount_finished_quick,
  selectTelepharmacyList_finished_parcel,
  selectTelepharmacyList_finished_pickup,
  selectTelepharmacyList_finished_quick,
} from '@stores/telepharmacyStore/telepharmacyList';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BeatLoader from 'react-spinners/BeatLoader';
import AcceptedTelepharmacyCarousel from '../acceptedTelepharmacyTable/AcceptedTelepharmacyCarousel';
import { ListItem as QuickListItem } from '../ongoingQuickTelepharmacyTable/sections/index';
import * as Style from './index.style';
import {
  ListContent,
  ListItem as PickupListItem,
  Pagination,
} from './sections';

const { BREAK_POINTS } = styleVariables;

function isNill(v: any) {
  return !!(v === undefined || v === null);
}

export const LISTITEM_FLEX = [
  { flex: 100, title: '약 수령 방법' },
  { flex: 100, title: '상태' },
  { flex: 220, title: '결제 완료일시' },
  { flex: 200, title: '환자명 (예약번호)' },
  { flex: 150, title: '연락처' },
  { flex: 100, title: '약제비' },
  { flex: 220, title: '' },
];

export const QUICK_LISTITEM_FLEX = [
  { flex: 100, title: '약 수령 방법' },
  { flex: 100, title: '상태' },
  { flex: 160, title: '환자명 (예약번호)' },
  { flex: 150, title: '연락처' },
  { flex: 100, title: '약제비' },
  { flex: 260, title: '주소' },
];

export const PARCEL_LISTITEM_FLEX = [
  { flex: 100, title: '약 수령 방법' },
  { flex: 100, title: '택배사 (운송장)' },
  { flex: 160, title: '환자명 (예약번호)' },
  { flex: 150, title: '연락처' },
  { flex: 100, title: '약제비' },
  { flex: 260, title: '주소' },
];

function PickupTelepharmacyTable() {
  const {
    completedParcelCount,
    preConfirmedCount,
    finishedPickupCount,
    finishedQuickCount,
    finishedParcelCount,
    isOpen,
  } = useGlobalNavBarHook();
  const { useWindowSize } = commonHooks;
  const { windowWidth } = useWindowSize();
  const telepharmacyCount = useAppSelector(
    selectTelepharmacyCount_finished_pickup,
  );

  const [selectedTabIndex, setSelectedTab] = useState(0);

  const confirmedTreatmentLinksArray = [
    {
      id: 0,
      label: `방문 수령 (${finishedPickupCount}건)`,
      component: <PickUp />,
    },
    {
      id: 1,
      label: `퀵 (${finishedQuickCount}건)`,
      component: <Quick />,
    },
    {
      id: 2,
      label: `택배 (${finishedParcelCount}건)`,
      component: <Parcel />,
    },
  ];

  return (
    <Style.Container>
      <Style.ListArea
        className={
          Number(windowWidth) < BREAK_POINTS.size1024 ? 'smallListArea' : ''
        }
      >
        <Style.TitleArea>
          <Font fontType="display2" color="fill/dark">
            수거대기
            {!isNill(completedParcelCount)
              ? `(총 ${completedParcelCount}건)`
              : ''}
          </Font>
        </Style.TitleArea>

        <Style.OptionArea
          style={{ borderBottom: `1px solid ${COLOR['border/divider']}` }}
        >
          <Style.TabContainer>
            {confirmedTreatmentLinksArray.map(({ id, label }) => (
              <Style.TabArea
                key={id}
                isActive={selectedTabIndex === id}
                onClick={() => setSelectedTab(id)}
              >
                {label}
              </Style.TabArea>
            ))}
          </Style.TabContainer>
        </Style.OptionArea>

        {!isNill(telepharmacyCount) ? (
          confirmedTreatmentLinksArray[selectedTabIndex].component
        ) : (
          <Style.EmptyContainer>
            <Font fontType="body2" color="fill/dark">
              수거 대기중인 방문 수령 내역이 없습니다.
            </Font>
          </Style.EmptyContainer>
        )}
      </Style.ListArea>

      <BFlex width="1024px" padding="1.25rem 0 0 0">
        <AcceptedTelepharmacyCarousel pageType="confirmedTreatmentPickup" />
      </BFlex>
    </Style.Container>
  );
}

function Parcel() {
  const { useQuery } = commonHooks;
  const query = useQuery();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const telepharmacyData = useAppSelector(
    selectTelepharmacyList_finished_parcel,
  );
  const telepharmacyCount = useAppSelector(
    selectTelepharmacyCount_finished_parcel,
  );

  const [limit, setLimit] = useState<number>(30);
  const [isLoading, setIsLoading] = useState(true);

  const pageQuery = query.get('page');
  const page = Number(pageQuery) || 0;
  const maxPage = telepharmacyCount
    ? Math.floor(telepharmacyCount / limit) + 1
    : 0;

  useEffect(() => {
    setIsLoading(true);
    dispatch(
      getTelepharmacyList_finished_parcel({
        offset: page * limit,
        limit,
      }),
    ).then(() => {
      setIsLoading(false);
    });
    return () => setIsLoading(false);
  }, [dispatch, page, limit]);

  return (
    <>
      <Style.ListContainer>
        <Style.ListHeaderItem>
          {PARCEL_LISTITEM_FLEX.map((item, index) => {
            return (
              <ListContent.Bold
                key={index.toString()}
                flex={item.flex}
                border="none"
              >
                {item.title}
              </ListContent.Bold>
            );
          })}
        </Style.ListHeaderItem>

        {isLoading ? (
          <Style.LoaderArea>
            <BeatLoader size={15} loading color={COLOR['fill/accent']} />
          </Style.LoaderArea>
        ) : (
          telepharmacyData?.map((item) => {
            return (
              <QuickListItem navigate={navigate} item={item} key={item.id} />
            );
          })
        )}
      </Style.ListContainer>
      <Pagination page={page} maxPage={maxPage} limit={limit} />
    </>
  );
}

function Quick() {
  const { useQuery } = commonHooks;
  const query = useQuery();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const telepharmacyData = useAppSelector(
    selectTelepharmacyList_finished_quick,
  );
  const telepharmacyCount = useAppSelector(
    selectTelepharmacyCount_finished_quick,
  );

  const [limit, setLimit] = useState<number>(30);
  const [isLoading, setIsLoading] = useState(true);

  const pageQuery = query.get('page');
  const page = Number(pageQuery) || 0;
  const maxPage = telepharmacyCount
    ? Math.floor(telepharmacyCount / limit) + 1
    : 0;

  useEffect(() => {
    setIsLoading(true);
    dispatch(
      getTelepharmacyList_finished_quick({
        offset: page * limit,
        limit,
      }),
    ).then(() => {
      setIsLoading(false);
    });
    return () => setIsLoading(false);
  }, [dispatch, page, limit]);

  return (
    <>
      <Style.ListContainer>
        <Style.ListHeaderItem>
          {QUICK_LISTITEM_FLEX.map((item, index) => {
            return (
              <ListContent.Bold
                key={index.toString()}
                flex={item.flex}
                border="none"
              >
                {item.title}
              </ListContent.Bold>
            );
          })}
        </Style.ListHeaderItem>
        {isLoading ? (
          <Style.LoaderArea>
            <BeatLoader size={15} loading color={COLOR['fill/accent']} />
          </Style.LoaderArea>
        ) : (
          telepharmacyData?.map((item) => {
            return (
              <QuickListItem navigate={navigate} item={item} key={item.id} />
            );
          })
        )}
      </Style.ListContainer>
      <Pagination page={page} maxPage={maxPage} limit={limit} />
    </>
  );
}

function PickUp() {
  const { useQuery } = commonHooks;
  const query = useQuery();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const telepharmacyData = useAppSelector(
    selectTelepharmacyList_finished_pickup,
  );
  const telepharmacyCount = useAppSelector(
    selectTelepharmacyCount_finished_pickup,
  );

  const [limit, setLimit] = useState<number>(30);
  const [isLoading, setIsLoading] = useState(true);

  const pageQuery = query.get('page');
  const page = Number(pageQuery) || 0;
  const maxPage = telepharmacyCount
    ? Math.floor(telepharmacyCount / limit) + 1
    : 0;

  useEffect(() => {
    setIsLoading(true);
    dispatch(
      getTelepharmacyList_finished_pickup({
        offset: page * limit,
        limit,
      }),
    ).then(() => {
      setIsLoading(false);
    });
    return () => setIsLoading(false);
  }, [dispatch, page, limit]);

  return (
    <>
      <Style.ListContainer>
        <Style.ListHeaderItem>
          {LISTITEM_FLEX.map((item, index) => {
            return (
              <ListContent.Bold
                key={index.toString()}
                flex={item.flex}
                border="none"
              >
                {item.title}
              </ListContent.Bold>
            );
          })}
        </Style.ListHeaderItem>
        {isLoading ? (
          <Style.LoaderArea>
            <BeatLoader size={15} loading color={COLOR['fill/accent']} />
          </Style.LoaderArea>
        ) : (
          telepharmacyData?.map((item) => {
            return (
              <PickupListItem navigate={navigate} item={item} key={item.id} />
            );
          })
        )}
      </Style.ListContainer>
      <Pagination page={page} maxPage={maxPage} limit={limit} />
    </>
  );
}

export default PickupTelepharmacyTable;
