import { BoxContainer } from '@components/atoms';
import styled from 'styled-components';
import { styleVariables } from '@constants';

const { BREAK_POINTS } = styleVariables;

export const ContentItem = styled(BoxContainer)`
  display: flex;
  flex-direction: column;
  padding: 32px;
  border-radius: 16px;
  box-shadow: 1px 1px 20px 1px rgba(196, 196, 196, 0.3);
  width: 600px;
  margin-bottom: 12px;
  margin-right: 12px;
`;

export const RightContentItem = styled(ContentItem)`
  width: 500px;
  @media (max-width: ${BREAK_POINTS.size1440}px) {
    width: 600px;
  }
`;

export const ContentHeaderArea = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ContentHeaderTitle = styled.div``;

export const ContentHeaderText = styled.div`
  display: flex;
  align-items: center;
`;

export const ContentBodyArea = styled.div``;

export const ContentBodyContainer = styled.div`
  display: flex;
  padding: 12px 0;
  justify-content: space-between;
`;

export const ContentBodyTitleArea = styled.div`
  display: flex;
  width: 180px;
  align-items: flex-start;
`;

export const ContentBodyTitle = styled.div``;

export const ContentBodyTextArea = styled.div`
  width: 320px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  @media (max-width: ${BREAK_POINTS.size1440}px) {
    width: 400px;
  }
`;

export const ContentBodyText = styled.div`
  width: 320px;
  word-wrap: break-word;
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
  @media (max-width: ${BREAK_POINTS.size1440}px) {
    width: 400px;
  }
`;

export const ContentBodyTextLeft = styled.div`
  width: 204px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  @media (max-width: ${BREAK_POINTS.size1440}px) {
    width: 272px;
  }
`;

export const ContentBodyTextRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const ContentBodyTextRightTop = styled.div``;

export const ContentBodyTextRightBottom = styled.div``;
