import { notifyOtherClients } from '@hooks/featureHooks/useSocket';
import { postRejectTelepharmacy } from '@mobile/api';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

const useRejectTP = () => {
  const navigate = useNavigate();

  const rejectTP = ({
    rejectReason,
    tppId,
  }: {
    tppId: string;
    rejectReason: string;
  }) => {
    return postRejectTelepharmacy({ reject_reason: rejectReason, tppId });
  };

  return useMutation(rejectTP, {
    onSuccess: () => {
      alert('조제 거절을 완료하였습니다.');
      notifyOtherClients('telepharmacy');
      return navigate(`/mobile/request`);
    },
    onError: () => {
      alert('조제 거절에 실패하였습니다.');
    },
  });
};

export default useRejectTP;
