import BButton from '@components/meraki-ui/BButton';
import BFlex from '@components/meraki-ui/BFlex';
import BModal from '@components/meraki-ui/BModal';
import BModalFooter from '@components/meraki-ui/BModal/BModalFooter';
import BTypography from '@components/meraki-ui/BTypography';
import { memo } from 'react';

const title = (
  <>
    개정된 나만의닥터
    <br />
    이용약관에 동의해주세요.
  </>
);

const text = (
  <>
    ·&nbsp; 비대면진료 시범사업 대응 및 나만의닥터 내 대면진료 예약 서비스
    추가로 인한 이용약관 업데이트
  </>
);

const dateText = <>·&nbsp; 2023년 8월 30일</>;

interface BPersonalInfoModalProps {
  open: boolean;
  onDetail: () => void;
  onAgree: () => void;
  onOpenChange: () => void;
  isLoading?: boolean;
}

function BPersonalInfoModal({
  open,
  onDetail,
  onOpenChange,
  onAgree,
  isLoading,
}: BPersonalInfoModalProps) {
  return (
    <BModal
      open={open}
      onOpenChange={onOpenChange}
      width={600}
      padding={0}
      disableOnOpenChangeByClickOverlay
    >
      <BFlex borderBottomExisted width="100%">
        <BFlex
          width="100%"
          padding="2rem 2rem 1.5rem 2rem"
          justifyContent="space-between"
        >
          <BTypography isBold text={title} size={24} color="mono08" />
        </BFlex>
      </BFlex>

      <BFlex
        padding="1.5rem 2.5rem"
        maxHeight={622}
        isOverflow
        isColumn
        gap={12}
      >
        <BTypography text="약관 개정 안내" isBold size={18} color="mono08" />

        <BFlex isColumn gap={32}>
          <BFlex isColumn>
            <BTypography text="이용약관 개정 사유" size={16} color="mono08" />
            <BTypography text={text} size={16} color="mono08" />
          </BFlex>

          <BFlex isColumn>
            <BTypography text="시행일시" size={16} color="mono08" />
            <BTypography text={dateText} size={16} color="mono08" />
          </BFlex>
        </BFlex>
      </BFlex>

      <BModalFooter
        borderTopExisted
        buttonText={
          <BFlex width="100%" gap={20} padding="1.25rem 2rem 2rem">
            <BButton
              text="개정된 약관 자세히 보기"
              buttonType="default"
              size="heightForFullWidth"
              onClick={onDetail}
              height={60}
              isDisabled={isLoading}
            />

            <BButton
              text="동의하고 시작하기"
              buttonType="primary"
              size="heightForFullWidth"
              onClick={onAgree}
              isLoading={isLoading}
              height={60}
            />
          </BFlex>
        }
      />
    </BModal>
  );
}

export default memo(BPersonalInfoModal);
